import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SpinnerTimer, SpinnerWheel } from "../../components";
import { SegmentType } from "../../types";
import "./styles.scss";
import { SpinnerContext } from "../../contexts";
import { stringToColor } from "../../../../utils/stringColor";
import { compress } from "../../../icons";
import { ResultDialog } from "../letsPlay/components/dialog";

interface MinimizeSpinnerType {
  openResultDialog: boolean;
  setSeconds: (value: number) => void;
  isRunning: boolean;
  startTimer: () => void;
  restartTimer: () => void;
  pauseTimer: () => void;
  handleMinutes: (value: number) => void;
  setSecondsSpinner: (id: number, seconds: number) => void;
  setOpen: (open: boolean) => void;
  results: string[];
  startSpinnerTimer: () => void;
  setResults: Dispatch<SetStateAction<string[]>>;
}

const segColors = [
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
  "#34A24F",
  "#FF9000",
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
];

export const MinimizeSpinner = ({
  setSeconds,
  isRunning,
  startTimer,
  restartTimer,
  pauseTimer,
  handleMinutes,
  setSecondsSpinner,
  openResultDialog,
  setOpen,
  results,
  startSpinnerTimer,
  setResults,
}: MinimizeSpinnerType) => {
  const {
    segments,
    selectedWheelNumber,
    setMinimize,
    minimizeAllSpinWheel,
    isTimer,
  } = useContext(SpinnerContext);
  const [divHeight, setDivHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const segmentData = useMemo(() => {
    return segments.find(
      (segment) => Number(segment.wheel) === Number(selectedWheelNumber),
    );
  }, [segments, selectedWheelNumber]);
  const realSegments = segmentData
    ? segmentData.segment.length
      ? segmentData.segment
      : Array(10).fill("")
    : Array(10).fill("");

  const segmentColors: string[] = segmentData
    ? segmentData.segment.length > 0
      ? segmentData.segment.map((data: any, index: number) =>
          stringToColor(`${data + new Date()}`, 30, 55),
        ) ?? segColors
      : segColors
    : segColors;

  const spinWheel = async (id: number) => {
    const canvas = document.getElementById(
      `minimize-wheel-${id}` ?? "canvas",
    ) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    canvas.click();
  };

  useEffect(() => {
    // Function to update div height based on window height
    const updateHeight = () => {
      setDivHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update height on window resize
    window.addEventListener("resize", updateHeight);

    // Initial update
    updateHeight();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const onFinished = (winner: string, index: number) => {
    const segmentData = segments.findIndex(
      (data) => Number(data.wheel) === Number(index),
    );
    if (segmentData !== -1) {
      const results_ = segments[segmentData].results;
      results_.push(winner);
    }

    setResults((prevResults) => [...prevResults, winner]);
    setTimeout(() => {
      setOpen(true);
    }, 1500);
  };

  return (
    <>
      <div className="minimize-spinner">
        {segments?.filter((seg) => seg?.segment?.length > 0).length > 1 && (
          <div className="spin-btns mb-2">
            <button
              type="button"
              className="spin-btn mx-auto"
              onClick={() => {
                setResults([]);
                const length = segments?.filter(
                  (seg) => seg?.segment?.length > 0,
                ).length;
                for (let index = 0; index < length; index++) {
                  const canvas = document.getElementById(
                    `minimize-wheel-${index + 1}`,
                  ) as HTMLCanvasElement;
                  setTimeout(() => {
                    canvas.click();
                  }, 1000);
                }
              }}
            >
              SPIN ALL
            </button>
          </div>
        )}
        {minimizeAllSpinWheel ? (
          <div
            className="spinners"
            style={{
              height:
                segments?.length > 4
                  ? windowWidth < 1299
                    ? `${divHeight - 315}px`
                    : `${divHeight - 245}px`
                  : "auto",
              overflowY: segments?.length > 4 ? "scroll" : "inherit",
            }}
          >
            {segments
              ?.filter((seg) => seg?.segment?.length > 0)
              ?.map((segment: SegmentType, index: number) => {
                return (
                  <div
                    key={index}
                    className={`spinner ${index === 0 ? "" : "mt-3"}`}
                  >
                    <div
                      onClick={() => {
                        setResults([]);
                        spinWheel(Number(segment.wheel));
                      }}
                    >
                      <SpinnerWheel
                        id={`minimize-wheel-${Number(segment.wheel)}`}
                        segments={
                          segment?.segment.length > 0
                            ? segment?.segment
                            : ["", "", "", "", "", "", "", "", "", ""]
                        }
                        segColors={
                          segment?.segment.length > 0
                            ? segment?.segment.map((data: any, index: number) =>
                                stringToColor(`${data + index}`),
                              ) ?? segColors
                            : segColors
                        }
                        winningSegment=""
                        onFinished={(winner) => {
                          onFinished(winner, index + 1);
                        }}
                        primaryColor="black"
                        primaryColoraround="#ffffffb4"
                        contrastColor="white"
                        buttonText="Spin"
                        isOnlyOnce={false}
                        size={150}
                        upDuration={30}
                        downDuration={2000}
                        fontFamily="proxima-nova"
                        spinnerRound={{
                          arcSize: 20,
                          fontSize: "1em",
                          moveToX: 5,
                          lineToX: 5,
                          moveToY: 20,
                          lineToY: 20,
                          lineToY2: 30,
                          segmentFontSize: ".5em",
                        }}
                      />
                    </div>
                    {index === 0 && (
                      <div
                        className="minimizeIcon"
                        onClick={() => {
                          setMinimize(false);
                        }}
                      >
                        <div className="icon">{compress()}</div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="spinner">
            <div
              onClick={() => {
                spinWheel(1);
              }}
            >
              <SpinnerWheel
                id={`minimize-wheel-1`}
                segments={realSegments}
                segColors={segmentColors}
                winningSegment=""
                onFinished={(winner) => {
                  onFinished(winner, Number(selectedWheelNumber));
                }}
                primaryColor="black"
                primaryColoraround="#ffffffb4"
                contrastColor="white"
                buttonText="Spin"
                isOnlyOnce={false}
                size={150}
                upDuration={30}
                downDuration={2000}
                fontFamily="proxima-nova"
                spinnerRound={{
                  arcSize: 20,
                  fontSize: "1em",
                  moveToX: 5,
                  lineToX: 5,
                  moveToY: 20,
                  lineToY: 20,
                  lineToY2: 30,
                  segmentFontSize: ".5em",
                }}
              />
            </div>
            <div
              className="minimizeIcon"
              onClick={() => {
                setMinimize(false);
              }}
            >
              <div className="icon">{compress()}</div>
            </div>
          </div>
        )}

        {isTimer && (
          <div className="timer-section mt-4">
            <SpinnerTimer
              onlyTime={false}
              isDisplayCheckBox={false}
              showSmallButtons={true}
              setSeconds={setSeconds}
              isRunning={isRunning}
              startTimer={startTimer}
              restartTimer={restartTimer}
              pauseTimer={pauseTimer}
              handleMinutes={handleMinutes}
              setSecondsSpinner={setSecondsSpinner}
              openResultDialog={openResultDialog}
            />
          </div>
        )}
      </div>
      {openResultDialog && (
        // @ts-ignore
        <ResultDialog
          open={openResultDialog}
          setOpen={setOpen}
          results={results}
          startSpinnerTimer={startSpinnerTimer}
          setResults={setResults}
        />
      )}
    </>
  );
};
