import { getDocument } from "pdfjs-dist";

// Helper function to convert PDF file to text
export const extractTextFromPDF = async (file: File) => {
  const pdf = await getDocument(URL.createObjectURL(file)).promise;
  let extractedText = "";

  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const content = await page.getTextContent();
    const pageText = content.items.map((item) => item.str).join(" ");
    extractedText += `${pageText}\n\n`;
  }

  return extractedText;
};

// Extract PDF page count
export const getPDFPageCount = async (file: File) => {
  const pdf = await getDocument(URL.createObjectURL(file)).promise;
  return pdf.numPages;
};
