import clsx from "clsx";
import { nanoid } from "nanoid";
import * as PDFJS from "pdfjs-dist";
import React, { useContext } from "react";
import ReactDOM, { flushSync } from "react-dom";
import { RoughCanvas } from "roughjs/bin/canvas";
import rough from "roughjs/bin/rough";

import html2canvas from "html2canvas";
import _ from "lodash";
import throttle from "lodash.throttle";
import { MathfieldElement } from "mathlive";
import {
  actionAddToLibrary,
  actionBringForward,
  actionBringToFront,
  actionCopy,
  actionCopyAsPng,
  actionCopyAsSvg,
  actionCopyStyles,
  actionCut,
  actionDeleteSelected,
  actionDuplicateSelection,
  actionFinalize,
  actionFlipHorizontal,
  actionFlipVertical,
  actionGroup,
  actionPasteStyles,
  actionSaveFileToDisk,
  actionSelectAll,
  actionSendBackward,
  actionSendToBack,
  actionToggleGridMode,
  actionToggleStats,
  actionToggleZenMode,
  actionUngroup,
} from "../actions";
import { createRedoAction, createUndoAction } from "../actions/actionHistory";
import { actionToggleViewMode } from "../actions/actionToggleViewMode";
import { ActionManager } from "../actions/manager";
import { actions } from "../actions/register";
import { ActionResult } from "../actions/types";
import { trackEvent } from "../analytics";
import { getDefaultAppState } from "../appState";
import {
  parseClipboard,
  PastedMixedContent,
  probablySupportsClipboardBlob,
  probablySupportsClipboardWriteText,
} from "../clipboard";
import {
  APP_NAME,
  ARROW_TYPE,
  CURSOR_TYPE,
  DEFAULT_COLLISION_THRESHOLD,
  DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
  DEFAULT_UI_OPTIONS,
  DEFAULT_VERTICAL_ALIGN,
  DRAGGING_THRESHOLD,
  ELEMENT_SHIFT_TRANSLATE_AMOUNT,
  ELEMENT_TRANSLATE_AMOUNT,
  ENV,
  EVENT,
  FRAME_STYLE,
  GRID_SIZE,
  IMAGE_RENDER_TIMEOUT,
  LINE_CONFIRM_THRESHOLD,
  MAX_ALLOWED_FILE_BYTES,
  MIME_TYPES,
  MQ_MAX_HEIGHT_LANDSCAPE,
  MQ_MAX_HEIGHT_TABLET,
  MQ_MAX_WIDTH_LANDSCAPE,
  MQ_MAX_WIDTH_PORTRAIT,
  MQ_MAX_WIDTH_TABLET,
  POINTER_BUTTON,
  POINTER_EVENTS,
  ROUNDNESS,
  SCROLL_TIMEOUT,
  TAP_TWICE_TIMEOUT,
  TEXT_TO_CENTER_SNAP_THRESHOLD,
  THEME,
  THEME_FILTER,
  TOOL_TYPE,
  TOUCH_CTX_MENU_TIMEOUT,
  URL_HASH_KEYS,
  URL_QUERY_KEYS,
  ZOOM_STEP,
} from "../constants";
import { loadFromBlob } from "../data";
import {
  dataURLToFile,
  generateIdFromFile,
  getDataURL,
  ImageURLToFile,
  isSupportedImageFile,
  parseLibraryJSON,
  resizeAudioFile,
  resizeImageFile,
  resizeVideoFile,
  SVGStringToFile,
} from "../data/blob";
import { fileOpen, nativeFileSystemSupported } from "../data/filesystem";
import captchScreenImage from "../data/getImage";
import { isValidLibrary } from "../data/json";
import Library, { distributeLibraryItemsOnSquareGrid } from "../data/library";
import { restore, restoreElements } from "../data/restore";
import {
  dragNewElement,
  dragSelectedElements,
  duplicateElement,
  getCommonBounds,
  getCursorForResizingElement,
  getDragOffsetXY,
  getElementAbsoluteCoords,
  getElementWithTransformHandleType,
  getNormalizedDimensions,
  getResizeArrowDirection,
  getResizeOffsetXY,
  getTransformHandleTypeFromCoords,
  isInvisiblySmallElement,
  isNonDeletedElement,
  isTextElement,
  newElement,
  newImageElement,
  newLinearElement,
  newTextElement,
  redrawTextBoundingBox,
  textWysiwyg,
  transformElements,
} from "../element";
import {
  updateAudioCache as _updateAudioCache,
  getInitializedAudioElements,
} from "../element/audio";
import {
  bindOrUnbindLinearElement,
  bindOrUnbindLinearElements,
  fixBindingsAfterDeletion,
  fixBindingsAfterDuplication,
  getEligibleElementsForBinding,
  getHoveredElementForBinding,
  getSuggestedBindingsForArrows,
  isBindingEnabled,
  isLinearElementSimpleAndAlreadyBound,
  maybeBindLinearElement,
  shouldEnableBindingForPointerEvent,
  updateBoundElements,
} from "../element/binding";
import {
  updateImageCache as _updateImageCache,
  getInitializedImageElements,
  loadHTMLImageElement,
  normalizeSVG,
} from "../element/image";
import { LinearElementEditor } from "../element/linearElementEditor";
import {
  bumpVersion,
  mutateElement,
  newElementWith,
} from "../element/mutateElement";
import {
  clearFrameElementsCache,
  deepCopyElement,
  duplicateElements,
  newArrowElement,
  newAudioElement,
  newFormulaElement,
  newFrameElement,
  newFreeDrawElement,
  newMagicFrameElement,
  newMermaidDiagramElement,
  newTextWithStylesElement,
  newVideoElement,
  refreshTextDimensions,
} from "../element/newElement";
import {
  bindTextToShapeAfterDuplication,
  getApproxMinLineHeight,
  getApproxMinLineWidth,
  getBoundTextElement,
  getContainerCenter,
  getContainerElement,
  getDefaultLineHeight,
} from "../element/textElement";
import {
  hasBoundTextElement,
  isArrowElement,
  isAudioElement,
  isBindingElement,
  isBindingElementType,
  isBoundToContainer,
  isEmbeddableElement,
  isFormulaElement,
  isFrameLikeElement,
  isIframeElement,
  isIframeLikeElement,
  isImageElement,
  isInitializedAudioElement,
  isInitializedFormulaElement,
  isInitializedImageElement,
  isInitializedMermaidDiagramElement,
  isInitializedTextWithStylesElement,
  isInitializedVideoElement,
  isLinearElement,
  isLinearElementType,
  isMermaidDiagramElement,
  isTextBindableContainer,
  isTextWithStylesElement,
  isUsingAdaptiveRadius,
  isVideoElement,
} from "../element/typeChecks";
import {
  ExcalidrawAudioElement,
  ExcalidrawBindableElement,
  ExcalidrawElement,
  ExcalidrawFormulaElement,
  ExcalidrawFrameElement,
  ExcalidrawFrameLikeElement,
  ExcalidrawFreeDrawElement,
  ExcalidrawGenericElement,
  ExcalidrawIframeElement,
  ExcalidrawIframeLikeElement,
  ExcalidrawImageElement,
  ExcalidrawLinearElement,
  ExcalidrawMermaidDiagramElement,
  ExcalidrawTextContainer,
  ExcalidrawTextElement,
  ExcalidrawTextWithStyleElement,
  ExcalidrawVideoElement,
  FileId,
  InitializedExcalidrawAudioElement,
  InitializedExcalidrawImageElement,
  InitializedExcalidrawVideoElement,
  NonDeleted,
  NonDeletedExcalidrawElement,
  Ordered,
} from "../element/types";
import {
  updateVideoCache as _updateVideoCache,
  getInitializedVideoElements,
} from "../element/video";
import {
  getPublishedLibrariesFiles,
  getUserUsedStorage,
} from "../excalidraw-app/api/collection";
import { getLessonId, getUserInfo } from "../excalidraw-app/api/getuserInfo";
import {
  uploadAudioBlob,
  uploadImageBlob,
  uploadVideoBlob,
} from "../excalidraw-app/api/loadDataFromCosmosDB";
import {
  delElemetDataFromDatabase,
  getElemetDataFromDatabase,
  getSharedWithMeWorkspace,
  getSharedWithMeWorkspaceByWorkspaceID,
  getUserWiseWorkspaceData,
  updateWorkspaceFeildInInviteWorkspaceModal,
} from "../excalidraw-app/api/storeElementData";
import {
  addUserStorage,
  getCollaborationLink,
  getSettingData,
  onSaveElementInDB,
} from "../excalidraw-app/api/userAPI";
import {
  STORAGE_KEYS as LOCAL_STORAGE_KEY,
  saveToLocalStorage,
} from "../excalidraw-app/data/localStorage";
import {
  addElementsToFrame,
  bindElementsToFramesAfterDuplication,
  elementOverlapsWithFrame,
  filterElementsEligibleAsFrameChildren,
  getContainingFrame,
  getElementsInNewFrame,
  getElementsInResizingFrame,
  getFrameChildren,
  getFrameLikeTitle,
  isCursorInFrame,
  isElementInFrame,
  removeElementsFromFrame,
  replaceAllElementsInFrame,
  updateFrameMembershipOfSelectedElements,
} from "../frame";
import { getCenter, getDistance } from "../gesture";
import {
  editGroupForSelectedElement,
  getElementsInGroup,
  getSelectedGroupIdForElement,
  getSelectedGroupIds,
  isElementInGroup,
  isSelectedViaGroup,
  selectGroupsForSelectedElements,
} from "../groups";
import { History } from "../history";
import { defaultLang, getLanguage, languages, setLanguage, t } from "../i18n";
import {
  CODES,
  isArrowKey,
  KEYS,
  shouldMaintainAspectRatio,
  shouldResizeFromCenter,
  shouldRotateWithDiscreteAngle,
} from "../keys";
import { getGridPoint, isPathALoop } from "../math";
import { invalidateShapeForElement } from "../renderer/renderElement";
import { renderStaticScene } from "../renderer/renderScene";
import {
  calculateScrollCenter,
  getElementContainingPosition,
  getElementsWithinSelection,
  getNormalizedZoom,
  getSelectedElements,
  hasBackground,
  isOverScrollBars,
  isSomeElementSelected,
} from "../scene";
import Scene from "../scene/Scene";
import {
  excludeElementsInFramesFromSelection,
  makeNextSelectedElementIds,
} from "../scene/selection";
import { RenderConfig, ScrollBars } from "../scene/types";
import { getNewZoom, getStateForZoom } from "../scene/zoom";
import { findShapeByKey, getBoundTextShape, getElementShape } from "../shapes";
import {
  AppClassProperties,
  AppProps,
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollaboratorPointer,
  DataURL,
  Device,
  ExcalidrawImperativeAPI,
  FrameNameBoundsCache,
  Gesture,
  GestureEvent,
  KeyboardModifiersObject,
  LibraryItem,
  LibraryItems,
  PointerDownState,
  RenderableElementsMap,
  SceneData,
  ToolType,
  UpdatePageDataURL,
} from "../types";
import {
  arrayToMap,
  debounce,
  distance,
  easeOut,
  easeToValuesRAF,
  getDateTime,
  getNearestScrollableContainer,
  isInputLike,
  isToolIcon,
  isTransparent,
  isWritableElement,
  resetCursor,
  resolvablePromise,
  sceneCoordsToViewportCoords,
  setCursor,
  setCursorForShape,
  tupleToCoors,
  updateActiveTool,
  updateStable,
  viewportCoordsToSceneCoords,
  withBatchedUpdates,
} from "../utils";

import { zoomToFit } from "../actions/actionCanvas";
import { actionPaste } from "../actions/actionClipboard";
import {
  actionToggleElementLock,
  actionUnlockAllElements,
} from "../actions/actionElementLock";
import { AnimationFrameHandler } from "../animation-frame-handler";
import {
  convertToExcalidrawElements,
  ExcalidrawElementSkeleton,
} from "../data/transform";
import { ImportedDataState } from "../data/types";
import { isPointHittingLink } from "../element/Hyperlink";
import {
  hitElementBoundingBoxOnly,
  hitElementBoundText,
  hitElementItself,
  isPointInShape,
} from "../element/collision";
import {
  getLockedLinearCursorAlignSize,
  isElementInViewport,
} from "../element/sizeHelpers";
import { shouldShowBoundingBox } from "../element/transformHandles";
import { syncInvalidIndices, syncMovedIndices } from "../fractionalIndex";
import { LaserTrails } from "../laser-trails";
import {
  LocalPoint,
  pointDistance,
  pointFrom,
  Radians,
} from "../packages/math";
import { Renderer } from "../scene/Renderer";
import { getSelectionBoxShape } from "../scene/Shape";
import {
  getReferenceSnapPoints,
  getSnapLinesAtPointer,
  getVisibleGaps,
  isActiveToolNonLinearSnappable,
  isSnappingEnabled,
  SnapCache,
  snapDraggedElements,
  snapNewElement,
  snapResizingElements,
} from "../snapping";
import { Store, StoreAction } from "../store";
import { convertHtmlToCanvas } from "../utils/canvas";
import { urlToDataUrl } from "../utils/convertToDataURL";
import { isTextContentAvailable } from "../utils/string";
import ConfirmDialog from "./ConfirmDialog";
import ContextMenu, { ContextMenuOption } from "./ContextMenu";
import LayerUI from "./LayerUI";
import { SVGLayer } from "./SVGLayer";
import { Stats } from "./Stats";
import { Toast } from "./Toast";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import { close } from "./icons";
import { SidebarItemsEnum } from "./libraryItemsDialog/components/constant/SidebarItems";
import { MyWorkspace } from "./myWorkSpace";
import { MyWorkspaceSidebarItemsEnum } from "./myWorkSpace/views/sidebar";
import {
  actionRemoveAllElementsFromFrame,
  actionSelectAllElementsInFrame,
} from "../actions/actionFrame";
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
require("dotenv").config();

const AppPropsContext = React.createContext<AppProps>(null!);
const AppContext = React.createContext<AppClassProperties>(null!);

const ExcalidrawAppStateContext = React.createContext<AppState>({
  ...getDefaultAppState(),
  width: 0,
  height: 0,
  offsetLeft: 0,
  offsetTop: 0,
});
ExcalidrawAppStateContext.displayName = "ExcalidrawAppStateContext";

export interface StartEditingProps {
  /** X position to insert text at */
  sceneX: number;
  /** Y position to insert text at */
  sceneY: number;
  /** whether to attempt to insert at element center if applicable */
  insertAtParentCenter?: boolean;
  text?: string;
}

const IsMobileContext = React.createContext(false);
const IsTabletContext = React.createContext(false);
const ClockIntervalContext = React.createContext<React.MutableRefObject<NodeJS.Timeout | null> | null>(
  null,
);
const CountDownIntervalContext = React.createContext<React.MutableRefObject<NodeJS.Timeout | null> | null>(
  null,
);
export const useClockContext = () => useContext(ClockIntervalContext);
export const useCountDownContext = () => useContext(CountDownIntervalContext);
export const useIsMobile = () => useContext(IsMobileContext);
export const useIsTablet = () => useContext(IsTabletContext);
const ExcalidrawContainerContext = React.createContext<{
  container: HTMLDivElement | null;
  id: string | null;
}>({ container: null, id: null });

const deviceContextInitialValue = {
  isSmScreen: false,
  isMobile: false,
  isTablet: false,
  isTouchScreen: false,
  canDeviceFitSidebar: false,
};

const DeviceContext = React.createContext<Device>(deviceContextInitialValue);

export const useDevice = () => useContext<Device>(DeviceContext);
export const useExcalidrawContainer = () =>
  useContext(ExcalidrawContainerContext);

export const useAppProps = () => useContext(AppPropsContext);
export const useApp = () => useContext(AppContext);
export const useExcalidrawAppState = () =>
  useContext(ExcalidrawAppStateContext);

let didTapTwice: boolean = false;
let tappedTwiceTimer = 0;
let cursorX = 0;
let cursorY = 0;
let isHoldingSpace: boolean = false;
let isPanning: boolean = false;
let isDraggingScrollBar: boolean = false;
let currentScrollBars: ScrollBars = { horizontal: null, vertical: null };
let touchTimeout = 0;
let invalidateContextMenu = false;
let confirmDialog: any = null;

let lastPointerUp: ((event: any) => void) | null = null;
const gesture: Gesture = {
  pointers: new Map(),
  lastCenter: null,
  initialDistance: null,
  initialScale: null,
};

export type CountdownState = {
  duration: number;
  elapsed: number;
  isRunning: boolean;
  startTime: number;
  pausedAt: number;
};

export interface StopwatchState {
  isRunning: boolean;
  startTime: number;
  elapsedTime: number;
}
class App extends React.Component<AppProps, AppState> {
  canvas: AppClassProperties["canvas"] = null;
  rc: RoughCanvas | null = null;
  unmounted: boolean = false;
  actionManager: ActionManager;
  mathfieldRef: React.RefObject<MathfieldElement>;
  appStateRef: React.RefObject<AppState>;
  chronometerScrollRef: React.RefObject<AppState>;
  countdownStates: Map<string, CountdownState> = new Map();
  stopwatchStates: Map<string, StopwatchState> = new Map();
  mathFormulaValueRef: { current: string };
  isMobile = false;
  isTabletview = false;
  detachIsMobileMqHandler?: () => void;
  intervalRef = React.createRef<NodeJS.Timeout | null>();
  countDownRef = React.createRef<NodeJS.Timeout | null>();

  private excalidrawContainerRef = React.createRef<HTMLDivElement>();

  public static defaultProps: Partial<AppProps> = {
    // needed for tests to pass since we directly render App in many tests
    UIOptions: DEFAULT_UI_OPTIONS,
  };

  public scene: Scene;
  public renderer: Renderer;
  private resizeObserver: ResizeObserver | undefined;
  private nearestScrollableContainer: HTMLElement | Document | undefined;
  public library: AppClassProperties["library"];
  public libraryItemsFromStorage: LibraryItems | undefined;
  private id: string;
  private history: History;
  private store: Store;
  private excalidrawContainerValue: {
    container: HTMLDivElement | null;
    id: string;
  };
  hitLinkElement?: NonDeletedExcalidrawElement;
  lastPointerMoveCoords: { x: number; y: number } | null = null;
  lastPointerUpEvent:
    | React.PointerEvent<HTMLElement>
    | PointerEvent
    | null = null;
  lastPointerDownEvent: React.PointerEvent<HTMLElement> | null = null;
  lastPointerMoveEvent: PointerEvent | null = null;
  lastViewportPosition = { x: 0, y: 0 };

  animationFrameHandler = new AnimationFrameHandler();
  laserTrails = new LaserTrails(this.animationFrameHandler, this);

  //
  // public files: BinaryFiles = {};
  public imageCache: AppClassProperties["imageCache"] = new Map();

  public getName = () => {
    return (
      this.state.name ||
      this.props.name ||
      `${t("labels.untitled")}-${getDateTime()}`
    );
  };

  constructor(props: AppProps) {
    super(props);
    const defaultAppState = getDefaultAppState();
    const {
      excalidrawRef,
      viewModeEnabled = false,
      zenModeEnabled = false,
      gridModeEnabled = false,
      theme = defaultAppState.theme,
      name = defaultAppState.name,
    } = props;
    this.state = {
      ...defaultAppState,
      theme,
      isLoading: true,
      ...this.getCanvasOffsets(),
      viewModeEnabled,
      zenModeEnabled,
      gridSize: gridModeEnabled ? GRID_SIZE : null,
      name,
      width: window.innerWidth,
      height: window.innerHeight,
      duration: "0:00",
      formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
      updatingFormulaElement: {
        id: "",
        width: 0,
        height: 0,
      },
      isCollaboratingWithFlexibleGroups: false,
      isShowWhiteboard: false,
      isShowNoPagesModel: false,
      whiteboardModel: false,
      isFullScreen: false,
      selectedElements: [],
      isResizableOrNot: false,
      defaultLibraryTab: "",
    };

    this.id = nanoid();
    this.store = new Store();
    this.setAppState = this.setAppState.bind(this);
    if (excalidrawRef) {
      const readyPromise =
        ("current" in excalidrawRef && excalidrawRef.current?.readyPromise) ||
        resolvablePromise<ExcalidrawImperativeAPI>();

      const api: ExcalidrawImperativeAPI = {
        ready: true,
        readyPromise,
        updateScene: this.updateScene,
        addFiles: this.addFiles,
        resetScene: this.resetScene,
        getSceneElementsIncludingDeleted: this.getSceneElementsIncludingDeleted,
        history: {
          clear: this.resetHistory,
        },
        scrollToContent: this.scrollToContent,
        getSceneElements: this.getSceneElements,
        getAppState: () => this.state,
        getFiles: () => this.state.files,
        refresh: this.refresh,
        importLibrary: this.importLibraryFromUrl,
        setToastMessage: this.setToastMessage,
        id: this.id,
      } as const;
      if (typeof excalidrawRef === "function") {
        excalidrawRef(api);
      } else {
        excalidrawRef.current = api;
      }
      readyPromise.resolve(api);
    }

    this.excalidrawContainerValue = {
      container: this.excalidrawContainerRef.current,
      id: this.id,
    };

    this.scene = new Scene();
    this.renderer = new Renderer(this.scene);
    this.library = new Library(this);
    this.history = new History();
    this.actionManager = new ActionManager(
      this.syncActionResult,
      () => this.state,
      () => this.scene.getElementsIncludingDeleted(),
      this as any,
    );
    this.actionManager.registerAll(actions);
    this.actionManager.registerAction(
      createUndoAction(this.history, this.store),
    );
    this.actionManager.registerAction(
      createRedoAction(this.history, this.store),
    );

    this.mathfieldRef = React.createRef();
    this.appStateRef = React.createRef<AppState>();
    this.appStateRef.current = {
      scrollX: 0,
      scrollY: 0,
    };
    this.chronometerScrollRef = React.createRef<AppState>();
    this.chronometerScrollRef.current = {
      scrollX: 0,
      scrollY: 0,
    };
    this.mathFormulaValueRef = { current: "" };
  }

  public removeCountdownStates(elements: ExcalidrawElement[]) {
    elements.forEach((element) => {
      if (this.countdownStates.has(element.id)) {
        this.countdownStates.delete(element.id);
      }
      if (this.stopwatchStates.has(element.id)) {
        this.stopwatchStates.delete(element.id);
      }
    });
  }

  private renderCanvas() {
    const canvasScale = window.devicePixelRatio;
    const {
      width: canvasDOMWidth,
      height: canvasDOMHeight,
      viewModeEnabled,
    } = this.state;
    const canvasWidth = canvasDOMWidth * canvasScale;
    const canvasHeight = canvasDOMHeight * canvasScale;

    if (viewModeEnabled && this.state.activeTool.type !== "laser") {
      return (
        <canvas
          className="excalidraw__canvas"
          id="to_dataURL"
          style={{
            width: canvasDOMWidth,
            height: canvasDOMHeight,
            cursor: CURSOR_TYPE.GRAB,
          }}
          width={canvasWidth}
          height={canvasHeight}
          ref={this.handleCanvasRef}
          onContextMenu={this.handleCanvasContextMenu}
          onPointerMove={this.handleCanvasPointerMove}
          onPointerUp={this.removePointer}
          onPointerCancel={this.removePointer}
          onTouchMove={this.handleTouchMove}
          onPointerDown={this.handleCanvasPointerDown}
          onClick={(e) => {
            this.handleCanvasClick(e);
            this.setState({ sidebarWrapper: false });
          }}
          onMouseMove={this.handleMouseMove}
        >
          {t("labels.drawingCanvas")}
        </canvas>
      );
    }
    return (
      <canvas
        className="excalidraw__canvas"
        style={{
          width: canvasDOMWidth,
          height: canvasDOMHeight,
        }}
        id="to_dataURL"
        width={canvasWidth}
        height={canvasHeight}
        ref={this.handleCanvasRef}
        onContextMenu={this.handleCanvasContextMenu}
        onPointerDown={this.handleCanvasPointerDown}
        onDoubleClick={this.handleCanvasDoubleClick}
        onPointerMove={this.handleCanvasPointerMove}
        onPointerUp={this.removePointer}
        onPointerCancel={this.removePointer}
        onTouchMove={this.handleTouchMove}
        onClick={(e) => {
          this.handleCanvasClick(e);
          this.setState({ sidebarWrapper: false });
        }}
      >
        {t("labels.drawingCanvas")}
      </canvas>
    );
  }

  onPageDelete = async (pageId: string) => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const slug = new URLSearchParams(window.location.search).get("slug") || "";
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    localStorage.setItem(
      LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
      JSON.stringify([]),
    );
    await delElemetDataFromDatabase(pageId, slug, user.actualRole);
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpaceData === "true") {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
        JSON.stringify([]),
      );
      window.parent.postMessage(
        {
          type: "WORKSPACE_ELEMENTS",
          personalWorkSpaceElements: JSON.stringify([]),
        },
        `${`${process.env.REACT_APP_PARENT_APP}`}`,
      );
    } else {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
        JSON.stringify([]),
      );
    }
    if (isMyWorkSpaceData === "true") {
      this.setState({
        isLoading: false,
      });
      return;
    }
    const allPages = await getElemetDataFromDatabase(lessonId, false);

    const pages = [...allPages.result].sort((a, b) => a.id - b.id);

    const nextPage = pages.length > 0 ? pages[0].page : 1;

    localStorage.setItem("ACTIVE_PAGE", String(nextPage));
    this.setAppState({
      currentPage: nextPage,
    });

    await this.onPageChange(nextPage);
    // }
    this.setState({
      isLoading: false,
    });
  };

  onPageChange = async (page: number, setPageDetails?: any) => {
    if (this.state.textEditor.open) {
      this.setState({
        textEditor: {
          ...this.state.textEditor,
          open: false,
        },
      });
    }
    this.resetHistory();
    this.setState({
      ...this.state,
      isLoading: true,
    });
    localStorage.setItem("USER_CURRENT_PAGE", String(page));
    localStorage.setItem("ACTIVE_PAGE", String(page));
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpace === "true") {
      this.setState({
        isLoading: false,
      });
      return;
    }
    if (lessonId === "MyWorkSpace") {
      this.setState({
        ...this.state,
        isLoading: false,
      });
      return;
    }

    const {
      result,
      audioData,
      videoData,
      imageData,
    } = await getElemetDataFromDatabase(lessonId, false);

    const currentPageData = result.find(
      (data: { page: string }) => Number(data.page) === Number(page),
    );
    if (result.length !== 0 && setPageDetails) {
      setPageDetails(
        // result.sort((a: any, b: any) =>
        //   a.page > b.page ? 1 : b.page > a.page ? -1 : 0,
        // ),
        [...result].sort((a, b) => a.id - b.id),
      );
    }
    if (currentPageData) {
      const current_data = currentPageData ? currentPageData.data : [];
      this.scene.replaceAllElements([...current_data]);

      this.setState({
        ...this.state,
        viewBackgroundColor: currentPageData?.canvasBackgroundColor
          ? currentPageData.canvasBackgroundColor
          : "#ffffff",
      });

      this.setState({
        updatedPageDataURL: {
          lessonId,
          page,
          dataURL: currentPageData?.dataURL,
        },
      });
      clearFrameElementsCache();
    } else {
      this.scene.replaceAllElements(result[0]?.data ? result[0]?.data : []);
    }

    this.setState({
      ...this.state,
      DBElements: result,
      isFirstLoading: true,
      isLoading: false,
    });

    //For render audio video and image element
    if (imageData.length > 0) {
      await Promise.all(
        imageData.map(async (image: any) => {
          await this.updateImageCache(
            result[0]?.data
              ? result[0]?.data
              : ([] as InitializedExcalidrawImageElement[]),
            image?.data,
          );
        }),
      );
    }

    if (videoData.length > 0) {
      await Promise.all(
        videoData.map(async (video: any) => {
          await this.updateVideoCache(
            result[0]?.data ? result[0]?.data : [],
            video?.data,
          );
        }),
      );
    }

    if (audioData.length > 0) {
      await Promise.all(
        audioData.map(async (audio: any) => {
          await this.updateAudioCache(
            result[0]?.data ? result[0]?.data : [],
            audio?.data,
          );
        }),
      );
    }
  };

  getCollaborationDetails = async () => {
    try {
      const lessonId = await getLessonId();
      const url = new URL(window.location.href);
      const searchParamsLessonId = url.searchParams.get("lessonId");
      const res = await getCollaborationLink(
        lessonId
          ? lessonId
          : (searchParamsLessonId && searchParamsLessonId) || "",
      );
      if (res.result) {
        this.setState({
          isCollaboratingWithFlexibleGroups: res.result.isFlexibleGroup,
        });
      }
    } catch (error) {
      console.error("error-from-getCollaborationDetails", error);
    }
  };

  handleItemClick = (value: MyWorkspaceSidebarItemsEnum) => {
    if (value === this.state.selectedWorkspaceTab) {
      return;
    }

    this.setState({
      selectedWorkspaceTab: value,
    });
  };

  setActiveTool = (
    tool: (
      | (
          | { type: Exclude<ToolType, "image"> }
          | {
              type: Extract<ToolType, "image">;
              insertOnCanvasDirectly?: boolean;
            }
        )
      | { type: "custom"; customType: string }
    ) & { locked?: boolean },
  ) => {
    if (!this.isToolSupported(tool.type)) {
      console.warn(
        `"${tool.type}" tool is disabled via "UIOptions.canvasActions.tools.${tool.type}"`,
      );
      return;
    }
    const nextActiveTool = updateActiveTool(this.state, tool);
    if (nextActiveTool?.type === "hand") {
      setCursor(this.canvas, CURSOR_TYPE.GRAB);
    } else if (!isHoldingSpace) {
      setCursorForShape(this.canvas, this.state);
    }
    if (isToolIcon(document.activeElement)) {
      this.focusContainer();
    }
    if (!isLinearElementType(nextActiveTool?.type)) {
      this.setState({ suggestedBindings: [] });
    }
    if (nextActiveTool?.type === "image") {
      this.onImageAction({
        insertOnCanvasDirectly:
          (tool.type === "image" && tool.insertOnCanvasDirectly) ?? false,
      });
    }

    this.setState((prevState) => {
      const commonResets = {
        snapLines: [],

        originSnapOffset: null,
        activeEmbeddable: null,
      } as const;

      if (nextActiveTool?.type === "freedraw") {
        this.store.shouldCaptureIncrement();
      }

      if (nextActiveTool?.type !== "selection") {
        return {
          ...prevState,
          activeTool: nextActiveTool,
          selectedElementIds: makeNextSelectedElementIds({}, prevState),
          selectedGroupIds: makeNextSelectedElementIds({}, prevState),
          editingGroupId: null,
          multiElement: null,
          ...commonResets,
        };
      }
      return {
        ...prevState,
        activeTool: nextActiveTool,
        ...commonResets,
      };
    });
  };

  private getFrameNameDOMId = (frameElement: ExcalidrawElement) => {
    return `${this.id}-frame-name-${frameElement.id}`;
  };

  frameNameBoundsCache: FrameNameBoundsCache = {
    get: (frameElement) => {
      let bounds = this.frameNameBoundsCache._cache.get(frameElement.id);
      if (
        !bounds ||
        bounds.zoom !== this.state.zoom.value ||
        bounds.versionNonce !== frameElement.versionNonce
      ) {
        const frameNameDiv = document.getElementById(
          this.getFrameNameDOMId(frameElement),
        );

        if (frameNameDiv) {
          const box = frameNameDiv.getBoundingClientRect();
          const boxSceneTopLeft = viewportCoordsToSceneCoords(
            { clientX: box.x, clientY: box.y },
            this.state,
          );
          const boxSceneBottomRight = viewportCoordsToSceneCoords(
            { clientX: box.right, clientY: box.bottom },
            this.state,
          );

          bounds = {
            x: boxSceneTopLeft.x,
            y: boxSceneTopLeft.y,
            width: boxSceneBottomRight.x - boxSceneTopLeft.x,
            height: boxSceneBottomRight.y - boxSceneTopLeft.y,
            angle: 0,
            zoom: this.state.zoom.value,
            versionNonce: frameElement.versionNonce,
          };

          this.frameNameBoundsCache._cache.set(frameElement.id, bounds);

          return bounds;
        }
        return null;
      }

      return bounds;
    },
    /**
     * @private
     */
    _cache: new Map(),
  };

  private renderFrameNames = () => {
    if (
      !this.state.frameRendering.enabled ||
      !this.state.frameRendering.name ||
      this.state.presentationMode
    ) {
      return null;
    }

    const isDarkTheme = this.state.theme === THEME.DARK;

    return this.scene.getNonDeletedFramesLikes().map((f) => {
      if (
        this.canvas &&
        !isElementInViewport(
          f,
          this.canvas.width / window.devicePixelRatio,
          this.canvas.height / window.devicePixelRatio,
          {
            offsetLeft: this.state.offsetLeft,
            offsetTop: this.state.offsetTop,
            scrollX: this.state.scrollX,
            scrollY: this.state.scrollY,
            zoom: this.state.zoom,
          },
          this.scene.getNonDeletedElementsMap(),
        )
      ) {
        // if frame not visible, don't render its name
        return null;
      }

      const { x: x1, y: y1 } = sceneCoordsToViewportCoords(
        { sceneX: f.x, sceneY: f.y },
        this.state,
      );

      const FRAME_NAME_EDIT_PADDING = 6;

      const reset = () => {
        mutateElement(f, { name: f.name?.trim() || null });
        this.setState({ editingFrame: null });
      };

      let frameNameJSX;

      const frameName = getFrameLikeTitle(f);

      if (f.id === this.state.editingFrame) {
        const frameNameInEdit = frameName ?? "Frame";

        frameNameJSX = (
          <input
            autoFocus
            value={frameNameInEdit}
            onChange={(e) => {
              mutateElement(f, {
                name: e.target.value,
              });
            }}
            onFocus={(e) => e.target.select()}
            onBlur={() => reset()}
            onKeyDown={(event) => {
              // for some inexplicable reason, `onBlur` triggered on ESC
              // does not reset `state.editingFrame` despite being called,
              // and we need to reset it here as well
              if (event.key === KEYS.ESCAPE || event.key === KEYS.ENTER) {
                reset();
              }
            }}
            style={{
              background: this.state.viewBackgroundColor,
              filter: isDarkTheme ? THEME_FILTER : "none",
              zIndex: 2,
              border: "1px solid #41427a",
              display: "block",
              padding: `${FRAME_NAME_EDIT_PADDING}px`,
              borderRadius: 4,
              boxShadow: "inset 0 0 0 1px var(--color-primary)",
              fontFamily: "Assistant",
              fontSize: "14px",
              transform: `translate(-${FRAME_NAME_EDIT_PADDING}px, ${FRAME_NAME_EDIT_PADDING}px)`,
              color: "var(--color-gray-80)",
              overflow: "hidden",
              maxWidth: `${
                document.body.clientWidth - x1 - FRAME_NAME_EDIT_PADDING
              }px`,
            }}
            size={frameNameInEdit.length + 1 || 1}
            dir="auto"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
          />
        );
      } else {
        frameNameJSX = frameName;
      }
      return (
        <div
          id={this.getFrameNameDOMId(f)}
          key={f.id}
          style={{
            position: "absolute",
            // Positioning from bottom so that we don't to either
            // calculate text height or adjust using transform (which)
            // messes up input position when editing the frame name.
            // This makes the positioning deterministic and we can calculate
            // the same position when rendering to canvas / svg.
            bottom: `${
              this.state.height +
              FRAME_STYLE.nameOffsetY -
              y1 +
              this.state.offsetTop
            }px`,
            left: `${x1 - this.state.offsetLeft}px`,
            zIndex: 2,
            fontSize: FRAME_STYLE.nameFontSize,
            color: isDarkTheme
              ? FRAME_STYLE.nameColorDarkTheme
              : FRAME_STYLE.nameColorLightTheme,
            lineHeight: FRAME_STYLE.nameLineHeight,
            width: "max-content",
            maxWidth: `${f.width}px`,
            overflow: f.id === this.state.editingFrame ? "visible" : "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: CURSOR_TYPE.MOVE,
            pointerEvents: this.state.viewModeEnabled
              ? POINTER_EVENTS.disabled
              : POINTER_EVENTS.enabled,
          }}
          onPointerDown={(event) => this.handleCanvasPointerDown(event)}
          onWheel={(event) => this.handleWheel(event as any)}
          onContextMenu={this.handleCanvasContextMenu}
          onDoubleClick={() => {
            this.setState({
              editingFrame: f.id,
            });
          }}
        >
          {frameNameJSX}
        </div>
      );
    });
  };

  public render() {
    const {
      zenModeEnabled,
      viewModeEnabled,
      isMyWorkSpace,
      confirmImportLibraryModel,
    } = this.state;
    const url = window.location.href;
    // Create a URL object
    const parsedUrl = new URL(url);
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");

    // Get hash (fragment identifier)
    const room = parsedUrl.hash.replace("#room=", ""); // Removes "#room=" if needed
    const shouldBlockPointerEvents =
      this.state.selectionElement ||
      this.state.selectedElementsAreBeingDragged ||
      this.state.resizingElement ||
      (this.state.activeTool?.type === "laser" &&
        // technically we can just test on this once we make it more safe
        this.state.cursorButton === "down");

    const {
      onCollabButtonClick,
      renderTopRightUI,
      renderFooter,
      renderCustomStats,
      onFlexibleGroupButtonClick,
    } = this.props;

    // if user don't have parent app like acv at that time open direct workspace
    const isClicked = localStorage.getItem("isClicked");

    return (
      <div
        className={clsx("acv acv-container", {
          "acv--view-mode": viewModeEnabled,
          "acv--mobile": this.isMobile,
        })}
        id="wb-container"
        style={{
          ["--ui-pointerEvents" as any]: shouldBlockPointerEvents
            ? POINTER_EVENTS.disabled
            : POINTER_EVENTS.enabled,
        }}
        ref={this.excalidrawContainerRef}
        onDrop={this.handleAppOnDrop}
        tabIndex={0}
        onKeyDown={
          this.props.handleKeyboardGlobally ? undefined : this.onKeyDown
        }
      >
        <AppContext.Provider value={this as any}>
          <AppPropsContext.Provider value={this.props}>
            <ExcalidrawContainerContext.Provider
              value={this.excalidrawContainerValue}
            >
              <ExcalidrawAppStateContext.Provider value={this.state}>
                <IsTabletContext.Provider value={this.isTabletview}>
                  <IsMobileContext.Provider value={this.isMobile}>
                    <ClockIntervalContext.Provider value={this.intervalRef}>
                      <CountDownIntervalContext.Provider
                        value={this.countDownRef}
                      >
                        {(window.parent !== window && !isMyWorkSpace) ||
                        (isMyWorkSpaceData !== "true" && !room) ? (
                          <>
                            <LayerUI
                              canvas={this.canvas}
                              appState={this.state}
                              sidebarWrapper={true}
                              files={this.state.files}
                              onPageDelete={this.onPageDelete}
                              setAppState={this.setAppState}
                              actionManager={this.actionManager}
                              elements={this.scene.getElements()}
                              onCollabButtonClick={onCollabButtonClick}
                              onFlexibleGroupButtonClick={
                                onFlexibleGroupButtonClick
                              }
                              onLockToggle={this.toggleLock}
                              onInsertElements={async (
                                elements: readonly NonDeletedExcalidrawElement[],
                                isCloseModal?: boolean,
                              ) => {
                                const lessonId =
                                  new URLSearchParams(window.location.search)
                                    .get("lessonId")
                                    ?.replace(/\//g, "") || "";
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: true },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                                this.addElementsFromPasteOrLibrary({
                                  elements,
                                  position: "center",
                                  files: null,
                                  isCloseModal,
                                });

                                await saveToLocalStorage(elements, this.state);

                                await onSaveElementInDB(
                                  this.state.currentPage,
                                  lessonId || null,
                                  this.state.DBElements,
                                  this.state.viewBackgroundColor,
                                );
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: false },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                              }}
                              zenModeEnabled={zenModeEnabled}
                              toggleZenMode={this.toggleZenMode}
                              langCode={getLanguage().code}
                              isCollaborating={
                                this.props.isCollaborating || false
                              }
                              renderTopRightUI={renderTopRightUI}
                              renderCustomFooter={renderFooter}
                              viewModeEnabled={viewModeEnabled}
                              showExitZenModeBtn={
                                typeof this.props?.zenModeEnabled ===
                                  "undefined" && zenModeEnabled
                              }
                              showThemeBtn={
                                typeof this.props?.theme === "undefined" &&
                                this.props.UIOptions.canvasActions.theme
                              }
                              libraryReturnUrl={this.props.libraryReturnUrl}
                              UIOptions={this.props.UIOptions}
                              focusContainer={this.focusContainer}
                              library={this.library}
                              id={this.id}
                              onImageAction={this.onImageAction}
                              onImageActionFromLibrary={
                                this.uploadImageFromLibrary
                              }
                              onVideoActionFromLibrary={
                                this.uploadVideoFromLibrary
                              }
                              onAudioActionFromLibrary={
                                this.uploadAudioFromLibrary
                              }
                              mathfieldRef={this.mathfieldRef}
                              onMathFormulaAction={this.renderFormula}
                              onTextWithStylesAction={this.renderTextWithStyles}
                              onPageChange={this.onPageChange}
                              togglekeyboardShortcut={
                                this.togglekeyboardShortcut
                              }
                              mathFormulaValueRef={this.mathFormulaValueRef}
                              executeFinalizeAction={this.executeFinalizeAction}
                              startTextEditing={this.startTextEditing}
                              handlePdfFile={this.handlePdfFile}
                              toggleZoomInZoomOut={this.toggleZoomInZoomOut}
                              importCustomCategory={this.importCustomCategory}
                              importLibraryFromUrl={this.importLibraryFromUrl}
                              resetScene={this.resetScene}
                              initializeSceneWithWorkspace={
                                this.initializeSceneWithWorkspace
                              }
                              app={this as any}
                            />
                            <SVGLayer trails={[this.laserTrails]} />
                            {this.renderFrameNames()}
                            <div className="excalidraw-textEditorContainer" />
                            <div className="excalidraw-contextMenuContainer" />
                            <div className="excalidraw-eye-dropper-container" />
                            <div className="video-controls" />
                            <div className="acv-contextMenuContainer" />

                            {this.state.showStats && (
                              <Stats
                                appState={this.state}
                                setAppState={this.setAppState}
                                elements={this.scene.getElements()}
                                onClose={this.toggleStats}
                                renderCustomStats={renderCustomStats}
                              />
                            )}
                            {this.state.toastMessage !== null && (
                              <Toast
                                message={this.state.toastMessage}
                                clearToast={this.clearToast}
                              />
                            )}
                            <main> {this.renderCanvas()}</main>
                          </>
                        ) : isClicked === "true" ? (
                          <>
                            <LayerUI
                              canvas={this.canvas}
                              appState={this.state}
                              sidebarWrapper={true}
                              files={this.state.files}
                              onPageDelete={this.onPageDelete}
                              setAppState={this.setAppState}
                              actionManager={this.actionManager}
                              elements={this.scene.getElements()}
                              onCollabButtonClick={onCollabButtonClick}
                              onFlexibleGroupButtonClick={
                                onFlexibleGroupButtonClick
                              }
                              onLockToggle={this.toggleLock}
                              onInsertElements={async (
                                elements: readonly NonDeletedExcalidrawElement[],
                                isCloseModal?: boolean,
                              ) => {
                                const lessonId =
                                  new URLSearchParams(window.location.search)
                                    .get("lessonId")
                                    ?.replace(/\//g, "") || "";
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: true },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                                this.addElementsFromPasteOrLibrary({
                                  elements,
                                  position: "center",
                                  files: null,
                                  isCloseModal,
                                });

                                await saveToLocalStorage(elements, this.state);

                                await onSaveElementInDB(
                                  this.state.currentPage,
                                  lessonId || null,
                                  this.state.DBElements,
                                  this.state.viewBackgroundColor,
                                );
                                window.parent.postMessage(
                                  { type: "STORE_ELEMENTS", isLoading: false },
                                  `${process.env.REACT_APP_PARENT_APP}`,
                                );
                              }}
                              zenModeEnabled={zenModeEnabled}
                              toggleZenMode={this.toggleZenMode}
                              langCode={getLanguage().code}
                              isCollaborating={
                                this.props.isCollaborating || false
                              }
                              renderTopRightUI={renderTopRightUI}
                              renderCustomFooter={renderFooter}
                              viewModeEnabled={viewModeEnabled}
                              showExitZenModeBtn={
                                typeof this.props?.zenModeEnabled ===
                                  "undefined" && zenModeEnabled
                              }
                              showThemeBtn={
                                typeof this.props?.theme === "undefined" &&
                                this.props.UIOptions.canvasActions.theme
                              }
                              libraryReturnUrl={this.props.libraryReturnUrl}
                              UIOptions={this.props.UIOptions}
                              focusContainer={this.focusContainer}
                              library={this.library}
                              id={this.id}
                              onImageAction={this.onImageAction}
                              onImageActionFromLibrary={
                                this.uploadImageFromLibrary
                              }
                              onVideoActionFromLibrary={
                                this.uploadVideoFromLibrary
                              }
                              onAudioActionFromLibrary={
                                this.uploadAudioFromLibrary
                              }
                              mathfieldRef={this.mathfieldRef}
                              onMathFormulaAction={this.renderFormula}
                              onTextWithStylesAction={this.renderTextWithStyles}
                              onPageChange={this.onPageChange}
                              togglekeyboardShortcut={
                                this.togglekeyboardShortcut
                              }
                              mathFormulaValueRef={this.mathFormulaValueRef}
                              executeFinalizeAction={this.executeFinalizeAction}
                              startTextEditing={this.startTextEditing}
                              handlePdfFile={this.handlePdfFile}
                              toggleZoomInZoomOut={this.toggleZoomInZoomOut}
                              importCustomCategory={this.importCustomCategory}
                              importLibraryFromUrl={this.importLibraryFromUrl}
                              resetScene={this.resetScene}
                              initializeSceneWithWorkspace={
                                this.initializeSceneWithWorkspace
                              }
                              app={this as any}
                            />
                            <SVGLayer trails={[this.laserTrails]} />
                            {this.renderFrameNames()}
                            <div className="excalidraw-textEditorContainer" />
                            <div className="excalidraw-contextMenuContainer" />
                            <div className="excalidraw-eye-dropper-container" />
                            <div className="video-controls" />
                            <div className="acv-contextMenuContainer" />

                            {this.state.showStats && (
                              <Stats
                                appState={this.state}
                                setAppState={this.setAppState}
                                elements={this.scene.getElements()}
                                onClose={this.toggleStats}
                                renderCustomStats={renderCustomStats}
                              />
                            )}
                            {this.state.toastMessage !== null && (
                              <Toast
                                message={this.state.toastMessage}
                                clearToast={this.clearToast}
                              />
                            )}
                            <main> {this.renderCanvas()}</main>
                          </>
                        ) : (
                          isMyWorkSpaceData === "true" && (
                            <MyWorkspace
                              appState={this.state}
                              setAppState={this.setAppState}
                              resetScene={this.resetScene}
                              initializeSceneWithWorkspace={
                                this.initializeSceneWithWorkspace
                              }
                              handleItemClick={this.handleItemClick}
                              toggleZoomInZoomOut={this.toggleZoomInZoomOut}
                            />
                          )
                        )}
                      </CountDownIntervalContext.Provider>
                    </ClockIntervalContext.Provider>
                  </IsMobileContext.Provider>
                </IsTabletContext.Provider>
              </ExcalidrawAppStateContext.Provider>
            </ExcalidrawContainerContext.Provider>
          </AppPropsContext.Provider>
        </AppContext.Provider>

        {confirmImportLibraryModel && confirmDialog}
      </div>
    );
  }

  public focusContainer: AppClassProperties["focusContainer"] = () => {
    if (this.props.autoFocus) {
      this.excalidrawContainerRef.current?.focus();
    }
  };

  public getSceneElementsIncludingDeleted = () => {
    return this.scene.getElementsIncludingDeleted();
  };

  public getSceneElements = () => {
    return this.scene.getElements();
  };

  private resetStore = () => {
    this.store.clear();
  };

  private getSharedWithMeWorkspaceByWorkspaceID_ = async () => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const res = await getSharedWithMeWorkspaceByWorkspaceID(
      selectedWorkspaceCardID as string,
    );
    return res;
  };

  public backToWorkspace = () => {
    localStorage.setItem("isClicked", "false");
    this.setAppState({
      selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
      isShowWhiteboard: false,
      isMyWorkSpace: true,
      isFullScreen: false,
    });
    const url = window.location.href;
    // Create a URL object
    const parsedUrl = new URL(url);
    // Get hash (fragment identifier)
    const room = parsedUrl.hash.replace("#room=", ""); // Removes "#room=" if needed
    if (room) {
      window.location.replace(`${process.env.REACT_APP_URI}/whiteboard`);
    }
  };

  public handleWorkspaceData = async () => {
    try {
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") === "true";

      if (!isMyWorkSpace) {
        return;
      }

      const workspaceData = await this.getSharedWithMeWorkspaceByWorkspaceID_();

      if (!workspaceData?.data?.length) {
        return;
      }

      const [workspaceDetails] = workspaceData.data; // Destructure first element

      const currentUser = JSON.parse(localStorage.getItem("user") || "{}");

      if (!workspaceDetails?.userEmail?.length || !currentUser.mail) {
        return;
      }

      const isCollaboration =
        localStorage.getItem("isCollaborating") === "true";
      const userWithDisabledViewMode = workspaceDetails.userEmail.some(
        (workspaceUser: { email: string; isEnabled: boolean }) =>
          workspaceUser.email !== workspaceDetails.ownerUserEmail &&
          workspaceUser.email === currentUser.mail &&
          workspaceDetails?.status === "Public" &&
          !isCollaboration,
      );
      const notOwnerOfWorkspace =
        currentUser.mail !== workspaceDetails.ownerUserEmail;

      if (!isCollaboration && userWithDisabledViewMode) {
        this.setState({ viewModeEnabled: true });
      }
      if (
        notOwnerOfWorkspace &&
        !isCollaboration &&
        workspaceDetails?.status === "Private"
      ) {
        window.toggleZoomInZoomOut();
        const isModalOpen = document
          .getElementsByClassName("modal fade Karla")[0]
          .classList.contains("show");

        if (!isModalOpen) {
          Array.from(
            document.getElementsByClassName(
              "acv",
            ) as HTMLCollectionOf<HTMLElement>,
          )[0].style.opacity = "0";
          document.getElementById("errModalBtn")?.click();
        }
      }
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  private getPrivateOrPublicWorkspace = async () => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const data = await getSharedWithMeWorkspaceByWorkspaceID(
      selectedWorkspaceCardID as string,
    );
    const workspaceData = data?.length && data?.data[0];
    if (workspaceData?.status === "Public") {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (!Object.keys(workspaceData.roomDetails).length) {
        if (workspaceData.ownerUserEmail !== user.mail) {
          this.setAppState({
            ...this.state,
            viewModeEnabled: true,
          });
        }
      }
    }
  };

  private syncActionResult = withBatchedUpdates(
    (actionResult: ActionResult) => {
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      if (isMyWorkSpace === "true") {
        this.getPrivateOrPublicWorkspace();
      }
      if (this.unmounted || actionResult === false) {
        return;
      }

      if (actionResult.storeAction === StoreAction.UPDATE) {
        this.store.shouldUpdateSnapshot();
      } else if (actionResult.storeAction === StoreAction.CAPTURE) {
        this.store.shouldCaptureIncrement();
      }

      let editingElement: AppState["editingElement"] | null = null;
      if (actionResult.elements) {
        actionResult.elements.forEach((element) => {
          if (
            this.state.editingElement?.id === element.id &&
            this.state.editingElement !== element &&
            isNonDeletedElement(element)
          ) {
            editingElement = element;
          }
        });

        this.scene.replaceAllElements(actionResult.elements);
        if (actionResult.commitToHistory) {
          this.store.shouldCaptureIncrement();
        }
      }

      if (actionResult.files) {
        this.setState({
          ...this.state,
          files: actionResult.replaceFiles
            ? actionResult.files
            : { ...this.state.files, ...actionResult.files },
        });
        this.addNewImagesToImageCache();
        this.addNewAudiosToAudioCache();
        this.addNewVideosToVideoCache();
      }

      if (actionResult.appState || editingElement) {
        if (actionResult.commitToHistory) {
          this.store.shouldCaptureIncrement();
          // this.history.resumeRecording();
        }

        let viewModeEnabled = actionResult?.appState?.viewModeEnabled || false;
        let zenModeEnabled = actionResult?.appState?.zenModeEnabled || false;
        let gridSize = actionResult?.appState?.gridSize || null;
        let theme = actionResult?.appState?.theme || THEME.LIGHT;
        let name = actionResult?.appState?.name ?? this.state.name;
        if (typeof this.props.viewModeEnabled !== "undefined") {
          viewModeEnabled = this.props.viewModeEnabled;
        }

        if (typeof this.props.zenModeEnabled !== "undefined") {
          zenModeEnabled = this.props.zenModeEnabled;
        }

        if (typeof this.props.gridModeEnabled !== "undefined") {
          gridSize = this.props.gridModeEnabled ? GRID_SIZE : null;
        }

        if (typeof this.props.theme !== "undefined") {
          theme = this.props.theme;
        }

        if (typeof this.props.name !== "undefined") {
          name = this.props.name;
        }

        this.setState(
          (state) => {
            // using Object.assign instead of spread to fool TS 4.2.2+ into
            // regarding the resulting type as not containing undefined
            // (which the following expression will never contain)
            return Object.assign(actionResult.appState || {}, {
              editingElement:
                editingElement || actionResult.appState?.editingElement || null,
              viewModeEnabled,
              zenModeEnabled,
              gridSize,
              theme,
              name,
            });
          },
          // () => {
          //   if (actionResult.syncHistory) {
          //     this.history.setCurrentState(
          //       this.state,
          //       this.scene.getElementsIncludingDeleted(),
          //     );
          //   }
          // },
        );

        const isCollaborationStart = (val: string) => {
          switch (val) {
            case "true":
              return true;
            case "false":
              return false;
            default:
              return false; // Default case to handle unexpected values
          }
        };
        const role = localStorage.getItem("Role") || "";
        const isJoinCollaboration = isCollaborationStart(
          localStorage.getItem("isCollaborating") || "false",
        );
        // this.handleWorkspaceData();
        const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
        if (
          isMyWorkSpace !== "true" &&
          role === "Student" &&
          isJoinCollaboration === false
        ) {
          this.setState({
            viewModeEnabled: true,
          });
        }
      }
    },
  );

  // Lifecycle

  private onBlur = withBatchedUpdates(() => {
    isHoldingSpace = false;
    this.setState({ isBindingEnabled: true });
  });

  private onUnload = async () => {
    localStorage.removeItem("isClicked");
    this.onBlur();
  };

  private disableEvent: EventListener = (event) => {
    event.preventDefault();
  };

  private onFontLoaded = () => {
    this.scene.getElementsIncludingDeleted().forEach((element) => {
      if (isTextElement(element)) {
        invalidateShapeForElement(element);
      }
    });
    this.onSceneUpdated();
  };

  private importLibraryFromUrl = async (
    urls: string[],
    token?: string | null,
  ) => {
    if (window.location.hash.includes(URL_HASH_KEYS.addLibrary)) {
      const hash = new URLSearchParams(window.location.hash.slice(1));
      hash.delete(URL_HASH_KEYS.addLibrary);
      window.history.replaceState({}, APP_NAME, `#${hash.toString()}`);
    } else if (window.location.search.includes(URL_QUERY_KEYS.addLibrary)) {
      const query = new URLSearchParams(window.location.search);
      query.delete(URL_QUERY_KEYS.addLibrary);
      window.history.replaceState({}, APP_NAME, `?${query.toString()}`);
    }

    try {
      let libraryItems = 0;
      const blobData: Blob[] | null = [];
      await Promise.all(
        urls.map(async (url: string) => {
          const parts = url.split(".acv");
          const trimmedUrl = `${parts[0]}.acv`;
          const request = await fetch(trimmedUrl);
          const blob = await request.blob();
          blobData.push(blob);
          const json = JSON.parse(await blob.text());
          if (json.libraryItems.length > 0) {
            libraryItems += json.libraryItems.length;

            json.libraryItems.forEach(
              (item: { elements: ExcalidrawElement[] }) => {
                if (item.elements.length > 0) {
                  item.elements.forEach(async (element: any) => {
                    const fileId = "fileId" in element ? element.fileId : null;
                    //get published libraries files (when import acv-library get images from the libraries collection)
                    if (
                      fileId !== null &&
                      (element.type === "image" ||
                        element.type === "formula" ||
                        element.type === "mermaidDiagram" ||
                        element.type === "video" ||
                        element.type === "audio" ||
                        element.type === "textWithStyles")
                    ) {
                      const publishedLibrary = await getPublishedLibrariesFiles(
                        element.lessonId,
                        fileId,
                      );

                      const files = publishedLibrary.result
                        ? publishedLibrary.result
                        : [];

                      const file = files.find(
                        (data: { id: FileId }) => data.id === fileId,
                      );

                      if (element.type === "textWithStyles") {
                        if (!file) {
                          //sometimes we can't get textWithStyles images from the libraries collection so we convert the text to image and save it in the files collection
                          convertHtmlToCanvas(
                            element.textString,
                            "textImage.png",
                            this.state.textEditor,
                          ).then((res: any) => {
                            const files: any = {
                              [fileId]: {
                                mimeType: MIME_TYPES.png as BinaryFileData["mimeType"],
                                id: fileId as BinaryFileData["id"],
                                dataURL: res.dataUrl as BinaryFileData["dataURL"],
                                created: new Date().getTime(),
                                isPublished: true,
                              },
                            };
                            this.setState({
                              files: { ...this.state.files, ...files },
                            });
                          });
                        }
                      }

                      if (file) {
                        urlToDataUrl(file.url, file.mimeType).then(
                          async (result) => {
                            if (result) {
                              const files: any = {
                                [fileId]: {
                                  mimeType:
                                    file.mimeType ||
                                    (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                                  id: fileId as BinaryFileData["id"],
                                  dataURL: result.dataUrl as BinaryFileData["dataURL"],
                                  created: file.created,
                                  isPublished: true,
                                },
                              };
                              this.setState({
                                files: { ...this.state.files, ...files },
                              });
                            } else {
                              console.error(
                                "Failed to fetch and convert the URL to a data URL.",
                              );
                            }
                          },
                        );
                      }
                    }
                  });
                }
              },
            );
          }

          if (!isValidLibrary(json)) {
            throw new Error();
          }
        }),
      );

      if (token === this.id || libraryItems) {
        this.setState({
          confirmImportLibraryModel: true,
        });
        confirmDialog = (
          <ConfirmDialog
            onConfirm={async () => {
              await this.library.importLibrary(blobData, "published");

              // hack to rerender the library items after import
              if (this.state.isLibraryOpen) {
                this.setState({ isLibraryOpen: false });
              }
              this.setState({
                isLibraryOpen: true,
                defaultLibraryTab: SidebarItemsEnum.AddedFromCollections,
                confirmImportLibraryModel: false,
              });
            }}
            onCancel={() => {
              this.setState({
                isLibraryOpen: false,
                confirmImportLibraryModel: false,
              });
            }}
            title={"Are you sure?"}
            open={this.state.confirmImportLibraryModel}
            setOpen={() => false} // eslint-disable-line @typescript-eslint/no-empty-function
            children={
              <>
                <p>
                  {t("alerts.confirmAddLibrary", {
                    numShapes: libraryItems,
                  })}
                </p>
              </>
            }
            closeOnClickOutside={false}
          />
        );
      }
    } catch (error) {
      window.alert(t("alerts.errorLoadingLibrary"));
      console.error(error);
    } finally {
      this.focusContainer();
    }
  };

  private resetHistory = () => {
    this.history.clear();
  };

  /**
   * Resets scene & history.
   * ! Do not use to clear scene user action !
   */
  private resetScene = withBatchedUpdates(
    (opts?: { resetLoadingState: boolean }) => {
      this.scene.replaceAllElements([]);
      if (!this.state.editingLibrary.isEditing) {
        this.setState((state) => ({
          ...getDefaultAppState(),
          isLoading: opts?.resetLoadingState ? false : state.isLoading,
          theme: this.state.theme,
        }));
      }

      this.resetStore();
      this.resetHistory();
    },
  );

  private initializeSceneWithWorkspace = async (
    id: string,
    sharedUserProfileEmail: string,
    isActive?: boolean,
  ) => {
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpace === "true") {
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";

      const { currentPage } = JSON.parse(
        localStorage.getItem("acv-state") || "{}",
      );
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      let result_ = await getUserWiseWorkspaceData(
        sharedUserProfileEmail ? sharedUserProfileEmail : user?.mail,
        id,
      );
      let {
        result,
        imageData,
        videoData,
        audioData,
      } = await getElemetDataFromDatabase(lessonId, currentPage);
      if (imageData.length > 0) {
        await Promise.all(
          imageData.map(async (image: any) => {
            await this.updateImageCache(
              result[0]?.data
                ? result[0]?.data
                : ([] as InitializedExcalidrawImageElement[]),
              image?.data,
            );
          }),
        );
      }
      if (videoData.length > 0) {
        await Promise.all(
          videoData.map(async (video: any) => {
            await this.updateVideoCache(
              result[0]?.data ? result[0]?.data : [],
              video?.data,
            );
          }),
        );
      }
      if (audioData.length > 0) {
        await Promise.all(
          audioData.map(async (audio: any) => {
            await this.updateAudioCache(
              result[0]?.data ? result[0]?.data : [],
              audio?.data,
            );
          }),
        );
      }
      if (isMyWorkSpace === "true") {
        const isReloadingWorkspace = localStorage.getItem(
          "isReloadingWorkspace",
        );
        const selectedWorkspaceCardID = localStorage.getItem(
          "selectedWorkspaceCard",
        );
        const res = await getSharedWithMeWorkspace(user.mail);
        const inviteWorkspaceMembers = res?.data?.map((workspace: any) => {
          return workspace.InviteWorkspaceMembers[0]?.roomDetails || null;
        });
        // Find the workspace that matches the selectedWorkspaceCardID
        const activeWorkspace = res?.data?.find(
          (workspace: any) => workspace.id === selectedWorkspaceCardID,
        );

        // If a matching workspace is found, return the userEmail, otherwise return null
        const activeWorkspaceMembers = activeWorkspace
          ? activeWorkspace.InviteWorkspaceMembers[0]?.userEmail || null
          : null;

        if (isActive) {
          const matchedUser = activeWorkspaceMembers?.find(
            (member: any) => member.email === user.mail,
          );

          const unmatchedUsers = activeWorkspaceMembers?.filter(
            (member: any) => member.email !== user.mail,
          );

          // Ensure that matchedUser is active, while unmatchedUsers remain as is
          const isActiveUser =
            unmatchedUsers &&
            ([
              ...unmatchedUsers,
              { ...matchedUser, isActive: true }, // Ensure matched user has isActive set to true
            ] as [{ email: string | null; isActive: boolean }]);

          inviteWorkspaceMembers?.filter(
            (roomData: any) =>
              roomData?.workspaceId === selectedWorkspaceCardID,
          );

          await updateWorkspaceFeildInInviteWorkspaceModal(
            selectedWorkspaceCardID,
            isActiveUser,
          );
        }

        const matchingWorkspaces = inviteWorkspaceMembers?.filter(
          (item: { workspaceId: string }) =>
            item?.workspaceId === selectedWorkspaceCardID,
        );

        if (
          isReloadingWorkspace === "true" &&
          this.state.selectedWorkspaceTab ===
            MyWorkspaceSidebarItemsEnum.MYWORKSPACE &&
          matchingWorkspaces?.length &&
          matchingWorkspaces[0]?.workspaceId === selectedWorkspaceCardID
        ) {
          await window.publicOpenPortal(false);
        }
        this.handleWorkspaceData();
        // Accessing the correct structure of the API response
        result_ = [
          {
            data: result_?.result[0]?.elements ?? [], // Access elements safely
            dataURL: result_?.result[0]?.previewImage ?? "", // Safe access to previewImage
            id: result_?.result[0]?.id ?? "", // Access id
            lessonId: "MyWorkSpace",
            page: this.state.currentPage, // Make sure currentPage is defined
          },
        ];
      }
      localStorage.setItem("ACTIVE_PAGE", "1");
      result = result_;

      this.setState({
        ...this.state,
        DBElements: result,
        isFirstLoading: false,
        isMyWorkSpace: false,
        isShowWhiteboard: true,
      });
      // if (imageData.length > 0) {
      //   await Promise.all(
      //     imageData.map(async (image: any) => {
      //       await this.updateImageCache(
      //         result[0]?.data
      //           ? result[0]?.data
      //           : ([] as InitializedExcalidrawImageElement[]),
      //         image?.data,
      //       );
      //     }),
      //   );
      // }

      if ("launchQueue" in window && "LaunchParams" in window) {
        (window as any).launchQueue.setConsumer(
          async (launchParams: { files: any[] }) => {
            if (!launchParams.files.length) {
              return;
            }
            const fileHandle = launchParams.files[0];
            const blob: Blob = await fileHandle.getFile();
            blob.handle = fileHandle;
            loadFromBlob(
              blob,
              this.state,
              this.scene.getElementsIncludingDeleted(),
            )
              .then((scene) => {
                this.syncActionResult({
                  ...scene,
                  appState: {
                    ...(scene.appState || this.state),
                    isLoading: false,
                  },
                  commitToHistory: true,
                  storeAction: StoreAction.CAPTURE,
                });
              })
              .catch((error) => {
                this.setState({
                  isLoading: false,
                  errorMessage: error.message,
                });
              });
          },
        );
      }

      if (!this.state.isLoading) {
        this.setState({ isLoading: true });
      }
      let initialData: any = null;
      try {
        initialData = (await this.props.initialData) || null;
      } catch (error) {
        console.error(error);
        initialData = {
          appState: {
            errorMessage:
              (error as Error).message ||
              "Encountered an error during importing or restoring scene data",
          },
        };
      }

      if (initialData && "elements" in initialData) {
        (initialData as ImportedDataState).elements = result[0]?.data
          ? result[0]?.data
          : [];
      }
      const scene = restore(initialData, null, null, { repairBindings: true });
      scene.appState = {
        ...scene.appState,
        activeTool:
          scene.appState.activeTool.type === "image" ||
          scene.appState.activeTool.type === "formula" ||
          scene.appState.activeTool.type === "text"
            ? { ...scene.appState.activeTool, type: "selection" }
            : scene.appState.activeTool,
        isLoading: false,
      };
      if (initialData?.scrollToContent) {
        scene.appState = {
          ...scene.appState,
          ...calculateScrollCenter(scene.elements, {
            ...scene.appState,
            width: this.state.width,
            height: this.state.height,
            offsetTop: this.state.offsetTop,
            offsetLeft: this.state.offsetLeft,
          }),
        };
      }
      this.resetStore();
      this.resetHistory();
      this.syncActionResult({
        ...scene,
        commitToHistory: true,
        storeAction: StoreAction.CAPTURE,
      });

      const libraryUrl =
        // current
        new URLSearchParams(window.location.hash.slice(1)).get(
          URL_HASH_KEYS.addLibrary,
        ) ||
        // legacy, kept for compat reasons
        new URLSearchParams(window.location.search).get(
          URL_QUERY_KEYS.addLibrary,
        );

      const urlArray = libraryUrl ? libraryUrl.split(",") : [];

      // Remove trailing slash
      if (urlArray) {
        // if (libraryUrl.endsWith("/")) {
        //   libraryUrl = libraryUrl.slice(0, -1);
        // }

        await this.importLibraryFromUrl(urlArray);
      }
    }
  };

  private initializeScene = async () => {
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpace === "true") {
      return;
    }
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const { currentPage } = JSON.parse(
      localStorage.getItem("acv-state") || "{}",
    );
    if (lessonId === "MyWorkSpace") {
      this.setState({
        ...this.state,
        isFirstLoading: false,
      });
      return;
    }

    const { result, imageData, activePage } = await getElemetDataFromDatabase(
      lessonId,
      currentPage,
    );
    localStorage.setItem("ACTIVE_PAGE", activePage);

    this.setState({
      ...this.state,
      DBElements: result,
      isFirstLoading: false,
    });
    if (imageData.length > 0) {
      await Promise.all(
        imageData.map(async (image: any) => {
          await this.updateImageCache(
            result[0]?.data
              ? result[0]?.data
              : ([] as InitializedExcalidrawImageElement[]),
            image?.data,
          );
        }),
      );
    }

    if ("launchQueue" in window && "LaunchParams" in window) {
      (window as any).launchQueue.setConsumer(
        async (launchParams: { files: any[] }) => {
          if (!launchParams.files.length) {
            return;
          }
          const fileHandle = launchParams.files[0];
          const blob: Blob = await fileHandle.getFile();
          blob.handle = fileHandle;
          loadFromBlob(
            blob,
            this.state,
            this.scene.getElementsIncludingDeleted(),
          )
            .then((scene) => {
              this.syncActionResult({
                ...scene,
                appState: {
                  ...(scene.appState || this.state),
                  isLoading: false,
                },
                commitToHistory: true,
                storeAction: StoreAction.CAPTURE,
              });
            })
            .catch((error) => {
              this.setState({ isLoading: false, errorMessage: error.message });
            });
        },
      );
    }

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }
    let initialData: any = null;
    try {
      initialData = (await this.props.initialData) || null;
      //Add timeout because after 3 sec get the initial Data
      // setTimeout(() => {
      //   if (initialData && initialData?.libraryItems) {
      //     this.libraryItemsFromStorage = restoreLibraryItems(
      //       initialData.libraryItems,
      //       "unpublished",
      //     ) as LibraryItems;
      //     initialData?.libraryItems.forEach((item: { elements: any }) => {
      //       if (item.elements?.length > 0) {
      //         item.elements.forEach(async (element: any) => {
      //           const fileId = "fileId" in element ? element.fileId : null;
      //           //get published libraries files (when import acv-library get images from the libraries collection)
      //           if (
      //             fileId !== null &&
      //             (element.type === "image" ||
      //               element.type === "formula" ||
      //               element.type === "video" ||
      //               element.type === "audio" ||
      //               element.type === "mermaidDiagram" ||
      //               element.type === "textWithStyles")
      //           ) {
      //             if (isMyWorkSpace === "true") {
      //               return;
      //             }

      //             const publishedLibrary = await getPublishedLibrariesFiles(
      //               element.lessonId,
      //               fileId,
      //             );
      //             const files = publishedLibrary.result
      //               ? publishedLibrary.result
      //               : [];
      //             const file = files.find(
      //               (data: { id: FileId }) => data.id == fileId,
      //             );

      //             if (file) {
      //               urlToDataUrl(file.url, file.mimeType).then(
      //                 async (result) => {
      //                   if (result) {
      //                     const files: any = {
      //                       [fileId]: {
      //                         mimeType:
      //                           file.mimeType ||
      //                           (MIME_TYPES.binary as BinaryFileData["mimeType"]),
      //                         id: fileId as BinaryFileData["id"],
      //                         dataURL: result.dataUrl as BinaryFileData["dataURL"],
      //                         created: file.created,
      //                         isPublished: true,
      //                       },
      //                     };
      //                     this.setState({
      //                       files: { ...this.state.files, ...files },
      //                     });
      //                   } else {
      //
      //                   }
      //                 },
      //               );
      //             }
      //           }
      //         });
      //       }
      //     });
      //   }
      // }, 2000);
    } catch (error) {
      console.error(error);
      initialData = {
        appState: {
          errorMessage:
            (error as Error).message ||
            "Encountered an error during importing or restoring scene data",
        },
      };
    }

    if (initialData && "elements" in initialData) {
      (initialData as ImportedDataState).elements = result[0]?.data
        ? result[0]?.data
        : [];
    }
    const scene = restore(initialData, null, null, { repairBindings: true });
    scene.appState = {
      ...scene.appState,
      activeTool:
        scene.appState.activeTool.type === "image" ||
        scene.appState.activeTool.type === "formula" ||
        scene.appState.activeTool.type === "text"
          ? { ...scene.appState.activeTool, type: "selection" }
          : scene.appState.activeTool,
      isLoading: false,
    };
    if (initialData?.scrollToContent) {
      scene.appState = {
        ...scene.appState,
        ...calculateScrollCenter(scene.elements, {
          ...scene.appState,
          width: this.state.width,
          height: this.state.height,
          offsetTop: this.state.offsetTop,
          offsetLeft: this.state.offsetLeft,
        }),
      };
    }

    this.resetStore();
    this.resetHistory();
    this.syncActionResult({
      ...scene,
      commitToHistory: true,
      storeAction: StoreAction.CAPTURE,
    });

    const libraryUrl =
      // current
      new URLSearchParams(window.location.hash.slice(1)).get(
        URL_HASH_KEYS.addLibrary,
      ) ||
      // legacy, kept for compat reasons
      new URLSearchParams(window.location.search).get(
        URL_QUERY_KEYS.addLibrary,
      );

    const urlArray = libraryUrl ? libraryUrl.split(",") : [];

    // Remove trailing slash
    if (urlArray) {
      // if (libraryUrl.endsWith("/")) {
      //   libraryUrl = libraryUrl.slice(0, -1);
      // }

      await this.importLibraryFromUrl(urlArray);
    }
  };

  public async componentDidMount() {
    window.publicRenderStaticSceneCallback = this.publicRenderStaticSceneCallback.bind(
      this,
    );
    window.handleWorkspaceData = this.handleWorkspaceData.bind(this);
    window.toggleZoomInZoomOut = this.toggleZoomInZoomOut.bind(this);
    window.backToWorkspace = this.backToWorkspace.bind(this);
    window.removeCountdownStates = this.removeCountdownStates.bind(this);

    const fullscreenData = localStorage.getItem("fullscreen");

    // Check if the fullscreenData is not null, not "undefined", and not an empty string
    let data = null;
    if (fullscreenData && fullscreenData !== "undefined") {
      try {
        data = JSON.parse(fullscreenData);
      } catch (error) {
        console.error("Failed to parse fullscreenData:", error);
      }
    }

    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    const isMyWorkSpace =
      isMyWorkSpaceData !== "undefined"
        ? JSON.parse(isMyWorkSpaceData ?? "false")
        : false;
    this.setState({
      isFullScreen: data,
      isMyWorkSpace,
      lessonId: "MyWorkSpace",
      selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
    });

    window.addEventListener("message", async (e) => {
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";

      if (e?.data?.type === "SEND_WHITEBOARD_DATA") {
        await onSaveElementInDB(
          this.state.currentPage,
          lessonId || null,
          this.state.DBElements,
          this.state.viewBackgroundColor,
        );
        await this.uploadImagesWhenChangePage(lessonId);
        window.parent.postMessage(
          { type: "STORE_ELEMENTS", isLoading: false },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
      }
    });
    if (
      process.env.NODE_ENV === ENV.TEST ||
      process.env.NODE_ENV === ENV.DEVELOPMENT
    ) {
      const setState = this.setState.bind(this);
      Object.defineProperties(window.h, {
        state: {
          configurable: true,
          get: () => {
            return this.state;
          },
        },
        setState: {
          configurable: true,
          value: (...args: Parameters<typeof setState>) => {
            return this.setState(...args);
          },
        },
        app: {
          configurable: true,
          value: this,
        },
        history: {
          configurable: true,
          value: this.history,
        },
        store: {
          configurable: true,
          value: this.store,
        },
      });
    }

    this.store.onStoreIncrementEmitter.on((increment) => {
      this.history.record(increment.elementsChange, increment.appStateChange);
    });

    this.getCanvasOffsets();
    this.getCollaborationDetails();
    this.scene.addCallback(this.onSceneUpdated);
    this.addEventListeners();

    this.excalidrawContainerValue.container = this.excalidrawContainerRef.current;

    if (this.excalidrawContainerRef.current) {
      this.focusContainer();
    }

    if ("ResizeObserver" in window && this.excalidrawContainerRef?.current) {
      this.resizeObserver = new ResizeObserver(() => {
        const {
          width,
          height,
        } = this.excalidrawContainerRef.current!.getBoundingClientRect();

        // Determine isMobile
        this.isMobile =
          width < MQ_MAX_WIDTH_PORTRAIT ||
          (height < MQ_MAX_HEIGHT_LANDSCAPE && width < MQ_MAX_WIDTH_LANDSCAPE);

        // Determine isTabletview
        this.isTabletview =
          !this.isMobile &&
          ((width <= MQ_MAX_WIDTH_TABLET && height <= MQ_MAX_HEIGHT_TABLET) ||
            (width <= MQ_MAX_WIDTH_LANDSCAPE &&
              height <= MQ_MAX_HEIGHT_LANDSCAPE));

        // Refresh offsets
        this.updateDOMRect();
      });
      this.resizeObserver?.observe(this.excalidrawContainerRef.current);
    } else if (window.matchMedia) {
      const mediaQuery = window.matchMedia(
        `(max-width: ${MQ_MAX_WIDTH_PORTRAIT}px), (max-height: ${MQ_MAX_HEIGHT_LANDSCAPE}px) and (max-width: ${MQ_MAX_WIDTH_LANDSCAPE}px)`,
      );
      const handler = () => {
        this.isMobile = mediaQuery.matches;
        this.isTabletview =
          !this.isMobile &&
          mediaQuery.matches &&
          window.innerWidth <= MQ_MAX_WIDTH_TABLET &&
          window.innerHeight <= MQ_MAX_HEIGHT_TABLET;
      };
      mediaQuery.addListener(handler);
      this.detachIsMobileMqHandler = () => mediaQuery.removeListener(handler);
    }

    const searchParams = new URLSearchParams(window.location.search.slice(1));

    if (searchParams.has("web-share-target")) {
      // Obtain a file that was shared via the Web Share Target API.
      this.restoreFileFromShare();
    } else {
      this.updateDOMRect(this.initializeScene);
    }

    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
    ]);

    window.addEventListener(EVENT.UNLOAD, this.onUnload);
  }

  public componentWillUnmount() {
    this.setState({
      ...this.state,
      files: {},
    });
    // this.files = {};
    this.setState({
      videoCache: new Map(),
      audioCache: new Map(),
    });
    this.imageCache.clear();
    this.resizeObserver?.disconnect();
    this.unmounted = true;
    this.removeEventListeners();
    this.scene.destroy();
    this.scene = new Scene();
    clearTimeout(touchTimeout);
    touchTimeout = 0;
    localStorage.removeItem("isClicked");
    this.store.onStoreIncrementEmitter.clear();
    this.laserTrails.stop();
  }

  private onResize = withBatchedUpdates(() => {
    this.scene
      .getElementsIncludingDeleted()
      .forEach((element) => invalidateShapeForElement(element));
    this.setState({});
  });

  private removeEventListeners() {
    document.removeEventListener(EVENT.POINTER_UP, this.removePointer);
    document.removeEventListener(EVENT.COPY, this.onCopy);
    document.removeEventListener(EVENT.PASTE, this.pasteFromClipboard);
    document.removeEventListener(EVENT.CUT, this.onCut);
    this.nearestScrollableContainer?.removeEventListener(
      EVENT.SCROLL,
      this.onScroll,
    );
    document.removeEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    document.removeEventListener(
      EVENT.POINTER_MOVE,
      this.updateCurrentCursorPosition,
      false,
    );
    document.removeEventListener(EVENT.KEYUP, this.onKeyUp);
    window.removeEventListener(EVENT.RESIZE, this.onResize, false);
    window.removeEventListener(EVENT.UNLOAD, this.onUnload, false);
    window.removeEventListener(EVENT.BLUR, this.onBlur, false);
    window.removeEventListener(EVENT.DRAG_OVER, this.disableEvent, false);
    window.removeEventListener(EVENT.DROP, this.disableEvent, false);

    document.removeEventListener(
      EVENT.GESTURE_START,
      this.onGestureStart as any,
      false,
    );
    document.removeEventListener(
      EVENT.GESTURE_CHANGE,
      this.onGestureChange as any,
      false,
    );
    document.removeEventListener(
      EVENT.GESTURE_END,
      this.onGestureEnd as any,
      false,
    );

    this.detachIsMobileMqHandler?.();
  }

  private addEventListeners() {
    this.removeEventListeners();
    document.addEventListener(EVENT.POINTER_UP, this.removePointer); // #3553
    document.addEventListener(EVENT.COPY, this.onCopy);
    if (this.props.handleKeyboardGlobally) {
      document.addEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    }
    document.addEventListener(EVENT.KEYUP, this.onKeyUp, { passive: true });
    document.addEventListener(
      EVENT.POINTER_MOVE,
      this.updateCurrentCursorPosition,
    );
    // rerender text elements on font load to fix #637 && #1553
    document.fonts?.addEventListener?.("loadingdone", this.onFontLoaded);
    // Safari-only desktop pinch zoom
    document.addEventListener(
      EVENT.GESTURE_START,
      this.onGestureStart as any,
      false,
    );
    document.addEventListener(
      EVENT.GESTURE_CHANGE,
      this.onGestureChange as any,
      false,
    );
    document.addEventListener(
      EVENT.GESTURE_END,
      this.onGestureEnd as any,
      false,
    );
    if (this.state.viewModeEnabled && this.state.activeTool.type !== "laser") {
      return;
    }

    document.addEventListener(EVENT.PASTE, this.pasteFromClipboard);
    document.addEventListener(EVENT.CUT, this.onCut);
    if (this.props.detectScroll) {
      this.nearestScrollableContainer = getNearestScrollableContainer(
        this.excalidrawContainerRef.current!,
      );
      this.nearestScrollableContainer.addEventListener(
        EVENT.SCROLL,
        this.onScroll,
      );
    }
    window.addEventListener(EVENT.RESIZE, this.onResize, false);
    window.addEventListener(EVENT.UNLOAD, this.onUnload, false);
    window.addEventListener(EVENT.BLUR, this.onBlur, false);
    window.addEventListener(EVENT.DRAG_OVER, this.disableEvent, false);
    window.addEventListener(EVENT.DROP, this.disableEvent, false);
  }

  public publicRenderStaticSceneCallback() {
    return { callback: this.renderStaticSceneCallback };
  }

  componentDidUpdate(prevProps: AppProps, prevState: AppState) {
    const nonDeletedElementsMap = this.scene.getNonDeletedElementsMap();

    if (
      this.state.selectedLinearElement &&
      !this.state.selectedElementIds[this.state.selectedLinearElement.elementId]
    ) {
      // To make sure `selectedLinearElement` is in sync with `selectedElementIds`, however this shouldn't be needed once
      // we have a single API to update `selectedElementIds`
      this.setState({ selectedLinearElement: null });
    }

    if (prevProps.langCode !== this.props.langCode) {
      this.updateLanguage();
    }

    if (prevProps.viewModeEnabled !== this.props.viewModeEnabled) {
      this.setState({ viewModeEnabled: !!this.props.viewModeEnabled });
    }

    if (prevState.viewModeEnabled !== this.state.viewModeEnabled) {
      this.addEventListeners();
      this.deselectElements();
    }

    if (prevProps.zenModeEnabled !== this.props.zenModeEnabled) {
      this.setState({ zenModeEnabled: !!this.props.zenModeEnabled });
    }

    if (prevProps.theme !== this.props.theme && this.props.theme) {
      this.setState({ theme: this.props.theme });
    }

    if (prevProps.gridModeEnabled !== this.props.gridModeEnabled) {
      this.setState({
        gridSize: this.props.gridModeEnabled ? GRID_SIZE : null,
      });
    }

    if (this.props.name && prevProps.name !== this.props.name) {
      this.setState({
        name: this.props.name,
      });
    }
    if (
      this.props.isCollaboratingWithFlexibleGroups &&
      prevProps.isCollaboratingWithFlexibleGroups !==
        this.props.isCollaboratingWithFlexibleGroups
    ) {
      this.setState({
        isCollaboratingWithFlexibleGroups: this.props
          .isCollaboratingWithFlexibleGroups,
      });
    }

    this.excalidrawContainerRef.current?.classList.toggle(
      "theme--dark",
      this.state.theme === "dark",
    );

    if (
      this.state.editingLinearElement &&
      !this.state.selectedElementIds[this.state.editingLinearElement.elementId]
    ) {
      // defer so that the commitToHistory flag isn't reset via current update
      setTimeout(() => {
        this.actionManager.executeAction(actionFinalize);
      });
    }
    const { multiElement } = prevState;
    if (
      prevState.activeTool.type !== this.state.activeTool.type &&
      multiElement != null &&
      isBindingEnabled(this.state) &&
      isBindingElement(multiElement, false)
    ) {
      maybeBindLinearElement(
        multiElement,
        this.state,
        tupleToCoors(
          LinearElementEditor.getPointAtIndexGlobalCoordinates(
            multiElement,
            -1,
            nonDeletedElementsMap,
          ),
        ),
        this.scene.getNonDeletedElementsMap(),
      );
    }

    const cursorButton: {
      [id: string]: string | undefined;
    } = {};
    const pointerViewportCoords: RenderConfig["remotePointerViewportCoords"] = {};
    const remoteSelectedElementIds: RenderConfig["remoteSelectedElementIds"] = {};
    const pointerUsernames: { [id: string]: string } = {};
    const pointerUserStates: { [id: string]: string } = {};
    this.state.collaborators.forEach((user, socketId) => {
      if (user.selectedElementIds) {
        for (const id of Object.keys(user.selectedElementIds)) {
          if (!(id in remoteSelectedElementIds)) {
            remoteSelectedElementIds[id] = [];
          }
          remoteSelectedElementIds[id].push(socketId);
        }
      }
      if (!user.pointer) {
        return;
      }
      if (user.username) {
        pointerUsernames[socketId] = user.username;
      }
      if (user.userState) {
        pointerUserStates[socketId] = user.userState;
      }
      pointerViewportCoords[socketId] = sceneCoordsToViewportCoords(
        {
          sceneX: user.pointer.x,
          sceneY: user.pointer.y,
        },
        this.state,
      );
      cursorButton[socketId] = user.button;
    });
    const sceneNonce = this.scene.getSceneNonce();
    const {
      elementsMap,
      visibleElements,
    } = this.renderer.getRenderableElements({
      sceneNonce,
      zoom: this.state.zoom,
      offsetLeft: this.state.offsetLeft,
      offsetTop: this.state.offsetTop,
      scrollX: this.state.scrollX,
      scrollY: this.state.scrollY,
      height: this.state.height,
      width: this.state.width,
      editingElement: this.state.editingElement,
      pendingImageElementId: this.state.pendingImageElementId,
      pendingAudioElementId: this.state.pendingAudioElement?.id ?? null,
      pendingVideoElementId: this.state.pendingVideoElement?.id ?? null,
    });

    this.store.commit(
      this.scene.getElementsMapIncludingDeleted() as any,
      this.state,
    );

    renderStaticScene({
      canvas: this.canvas!,
      rc: rough.canvas(this.canvas!),
      elementsMap,
      allElementsMap: this.scene.getNonDeletedElementsMap(),
      selectedElements: this.scene.getSelectedElements(this.state),
      visibleElements: visibleElements as any,
      scale: window.devicePixelRatio,
      appState: {
        ...this.state,
        zoom: this.state.zoom,
        shouldCacheIgnoreZoom: false,
        theme: this.state.exportWithDarkMode ? "dark" : "light",
      },
      renderConfig: {
        canvasBackgroundColor: this.state.viewBackgroundColor,
        imageCache: this.imageCache,
        videoCache: this.state.videoCache,
        audioCache: this.state.audioCache,
        renderGrid: true,
        isExporting: true,
        embedsValidationStatus: new Map(),
        elementsPendingErasure: new Set(),
        remoteSelectedElementIds,
        remotePointerUsernames: pointerUsernames,
        remotePointerViewportCoords: pointerViewportCoords,
        remotePointerButton: cursorButton,
        remotePointerUserStates: pointerUserStates,
        selectionColor: {} as any,
      },
      intervalRef: this.intervalRef,
      countDownRef: this.countDownRef,
      appStateRef: this.appStateRef,
      countdownStates: this.countdownStates,
      chronometerScrollRef: this.chronometerScrollRef,
      stopwatchStates: this.stopwatchStates,
    });

    this.scheduleImageRefresh();
    this.scheduleVideoRefresh();
    this.scheduleAudioRefresh();
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    // Do not notify consumers if we're still loading the scene. Among other
    // potential issues, this fixes a case where the tab isn't focused during
    // init, which would trigger onChange with empty elements, which would then
    // override whatever is in localStorage currently.
    if (!this.state.isLoading) {
      this.props.onChange?.(
        this.scene.getElementsIncludingDeleted(),
        { ...this.state, lessonId },
        this.state.files,
      );

      if (lessonId !== this.state.lessonId) {
        this.setState({
          ...this.state,
          lessonId,
        });
      }
    }
  }

  private renderStaticSceneCallback = ({
    atLeastOneVisibleElement,
    scrollBars,
    elementsMap,
  }: {
    atLeastOneVisibleElement: boolean;
    scrollBars: ScrollBars;
    elementsMap: RenderableElementsMap;
  }) => {
    if (scrollBars) {
      currentScrollBars = scrollBars;
    }

    const scrolledOutside =
      // hide when editing text
      isTextWithStylesElement(this.state.editingElement)
        ? false
        : !atLeastOneVisibleElement && elementsMap.size > 0;

    if (this.state.scrolledOutside !== scrolledOutside) {
      this.setState({ scrolledOutside });
    }

    this.scheduleImageRefresh();
    this.scheduleAudioRefresh();
    this.scheduleVideoRefresh();
  };

  private onScroll = debounce(() => {
    const { offsetTop, offsetLeft } = this.getCanvasOffsets();
    this.setState((state) => {
      if (state.offsetLeft === offsetLeft && state.offsetTop === offsetTop) {
        return null;
      }
      return { offsetTop, offsetLeft };
    });
  }, SCROLL_TIMEOUT);

  // Copy/paste

  private onCut = withBatchedUpdates((event: ClipboardEvent) => {
    const isExcalidrawActive = this.excalidrawContainerRef.current?.contains(
      document.activeElement,
    );
    if (!isExcalidrawActive || isWritableElement(event.target)) {
      return;
    }
    this.cutAll();
    event.preventDefault();
  });

  private onCopy = withBatchedUpdates((event: ClipboardEvent) => {
    if (!this.state.textEditor.open) {
      const isExcalidrawActive = this.excalidrawContainerRef.current?.contains(
        document.activeElement,
      );
      if (!isExcalidrawActive || isWritableElement(event.target)) {
        return;
      }
      this.copyAll();
      event.preventDefault();
    }
  });
  private cutAll = () => {
    if (!this.state.textEditor.open) {
      this.copyAll();
      this.actionManager.executeAction(actionDeleteSelected);
    }
  };

  private copyAll = () => {
    this.actionManager.executeAction(actionCopy);
    // copyToClipboard(this.scene.getElements(), this.state, this.state.files);
  };

  private static resetTapTwice() {
    didTapTwice = false;
  }

  private onTapStart = (event: TouchEvent) => {
    if (!didTapTwice) {
      didTapTwice = true;
      clearTimeout(tappedTwiceTimer);
      tappedTwiceTimer = window.setTimeout(
        App.resetTapTwice,
        TAP_TWICE_TIMEOUT,
      );
      return;
    }
    // insert text only if we tapped twice with a single finger
    // event.touches.length === 1 will also prevent inserting text when user's zooming
    if (didTapTwice && event.touches.length === 1) {
      const [touch] = Array.from(event.touches);
      // @ts-ignore
      this.handleCanvasDoubleClick({
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      didTapTwice = false;
      clearTimeout(tappedTwiceTimer);
    }
    event.preventDefault();
    if (event.touches.length === 2) {
      this.setState({
        selectedElementIds: makeNextSelectedElementIds({}, this.state),
      });
    }
  };

  private onTapEnd = (event: TouchEvent) => {
    if (event.touches.length > 0) {
      this.setState({
        previousSelectedElementIds: {},
        selectedElementIds: makeNextSelectedElementIds(
          this.state.previousSelectedElementIds,
          this.state,
        ),
      });
    }
  };

  private pasteFromClipboard = withBatchedUpdates(
    async (event?: ClipboardEvent) => {
      const IS_PLAIN_PASTE = false;
      const isPlainPaste = !!IS_PLAIN_PASTE;
      // #686
      const target = document.activeElement;
      // const isExcalidrawActive = this.excalidrawContainerRef.current?.contains(
      //   target,
      // );
      // if (event && !isExcalidrawActive) {
      //   return;
      // }

      const elementUnderCursor = document.elementFromPoint(cursorX, cursorY);
      if (
        // if no ClipboardEvent supplied, assume we're pasting via contextMenu
        // thus these checks don't make sense
        event &&
        (!(elementUnderCursor instanceof HTMLCanvasElement) ||
          isWritableElement(target))
      ) {
        return;
      }

      const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
        {
          clientX: cursorX,
          clientY: cursorY,
        },
        this.state,
      );

      // must be called in the same frame (thus before any awaits) as the paste
      // event else some browsers (FF...) will clear the clipboardData
      // (something something security)
      let file = event?.clipboardData?.files[0];
      const data = await parseClipboard(event as any, isPlainPaste);
      if (!file && !isPlainPaste) {
        if (data.mixedContent) {
          return this.addElementsFromMixedContentPaste(data.mixedContent, {
            isPlainPaste,
            sceneX,
            sceneY,
          });
        } else if (data.text) {
          const string = data.text.trim();
          if (string.startsWith("<svg") && string.endsWith("</svg>")) {
            // ignore SVG validation/normalization which will be done during image
            // initialization
            file = SVGStringToFile(string);
          }
        }
      }

      if (isSupportedImageFile(file)) {
        if (!this.isToolSupported("image")) {
          this.setState({ errorMessage: t("errors.imageToolNotSupported") });
          return;
        }
        const imageElement = await this.createImageElement({ sceneX, sceneY });
        this.insertImageElement(imageElement, file);
        this.initializeImageDimensions(imageElement);
        this.setState({
          selectedElementIds: makeNextSelectedElementIds(
            {
              [imageElement.id]: true,
            },
            this.state,
          ),
        });

        return;
      }

      if (this.props.onPaste) {
        try {
          if ((await this.props.onPaste(data, event as any)) === false) {
            return;
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (data.errorMessage) {
        this.setState({ errorMessage: data.errorMessage });
      } else if (data.spreadsheet) {
        this.setState({
          pasteDialog: {
            data: data.spreadsheet,
            shown: true,
          },
        });
      } else if (data.elements) {
        // this.addElementsFromPasteOrLibrary({
        //   elements: data.elements,
        //   files: data.files || null,
        //   position: "cursor",
        // });
        const elements = (data.programmaticAPI
          ? convertToExcalidrawElements(
              data.elements as ExcalidrawElementSkeleton[],
            )
          : data.elements) as readonly ExcalidrawElement[];
        // TODO remove formatting from elements if isPlainPaste
        this.addElementsFromPasteOrLibrary({
          elements,
          files: data.files || null,
          position: "cursor",
          // retainSeed: isPlainPaste,
        });
      } else if (data.text) {
        if (this.state.textEditor.open) {
          //when text editor is already open then the content only paste in the text editor no need to change position of text editor
          this.setState({
            textEditor: {
              ...this.state.textEditor,
              open: true,
              value: data.text,
              // sceneX: sceneX,
              // sceneY: sceneY,
            },
          });
        } else {
          const alreadyElementonCanvas = this.scene.getElements();
          let newX = 0; // 50 is for gap between 2 elements
          let newY = this.state.height / 2;
          if (alreadyElementonCanvas.length) {
            const maxCanvasX = alreadyElementonCanvas.reduce(
              (maxX, element) => {
                return Math.max(maxX, element.x + element.width);
              },
              0,
            );
            newX += maxCanvasX;
          } else {
            newX = this.state.width / 3.5;
            newY = this.state.height / 2;
          }
          // when user without open editor and patse the text on the canvas then open the text editor at center of the canvas
          this.setState({
            textEditor: {
              ...this.state.textEditor,
              open: true,
              value: data.text,
              sceneX: newX,
              sceneY: newY - 103, // 103 is the height of the text editor header with spacing,
            },
          });
        }
      }
      this.selectShapeTool("selection");
      event?.preventDefault();
    },
  );

  async publishedFiles(lessonId: string, fileId: FileId) {
    const publishedLibrary = await getPublishedLibrariesFiles(lessonId, fileId);
    return publishedLibrary;
  }

  async uploadImagesWhenChangePage(lessonId: string) {
    const formData = new FormData();
    if (!this.state.files || Object.keys(this.state.files).length === 0) {
      window.parent.postMessage(
        { type: "UPLOAD_IMAGE", isLoading: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      return;
    }
    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: true },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    for (const key in this.state.files) {
      if (Object.prototype.hasOwnProperty.call(this.state.files, key)) {
        const fileData = this.state.files[key];

        let file: any;
        await fetch(fileData.dataURL)
          .then((response) => response.blob())
          .then(async (blob) => {
            file = new File([blob], Object.keys(this.state.files)[0], {
              type: blob.type,
            });
          });

        formData.append("file", file);
        formData.append(
          "body",
          JSON.stringify({ ...fileData, dataURL: "", isPublished: true }),
        );
      }
    }
    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: false },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    if (formData && !formData?.entries().next().done) {
      window.parent.postMessage(
        { type: "UPLOAD_IMAGE", isLoading: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      return;
    }

    await uploadImageBlob(formData, lessonId);
  }

  private getTopLayerFrameAtSceneCoords = (sceneCoords: {
    x: number;
    y: number;
  }) => {
    const frames = this.scene
      .getNonDeletedFramesLikes()
      .filter((frame): frame is ExcalidrawFrameLikeElement =>
        isCursorInFrame(
          sceneCoords,
          frame,
          this.scene.getNonDeletedElementsMap(),
        ),
      );

    return frames.length ? frames[frames.length - 1] : null;
  };

  addMermaidElements = async (opts: {
    elements: readonly ExcalidrawElement[];
    files: BinaryFiles | null;
    position: { clientX: number; clientY: number } | "cursor" | "center";
    retainSeed?: boolean;
    fitToContent?: boolean;
    updatedElementIds?: {
      elementId: string | null;
      fileId: string | null;
    };
  }) => {
    const updatedElement = opts?.updatedElementIds;
    if (updatedElement?.elementId) {
      //remove the element from the selectedElementIds
      const selectedElementIds = { ...this.state.selectedElementIds };
      delete selectedElementIds[updatedElement?.elementId ?? ""];

      //remove this elements file from the files
      const files = { ...this.state.files };
      delete files[updatedElement?.fileId ?? ""];

      this.setState({
        selectedElementIds,
        files,
      });

      // delete the element from the scene
      this.scene.replaceAllElements(
        this.scene.getElementsIncludingDeleted().filter((element) => {
          return element.id !== updatedElement?.elementId;
        }),
      );
    }
    const elements = restoreElements(opts.elements, null, undefined);
    const [minX, minY, maxX, maxY] = getCommonBounds(elements);

    const elementsCenterX = distance(minX, maxX) / 2;
    const elementsCenterY = distance(minY, maxY) / 2;

    const clientX =
      typeof opts.position === "object"
        ? opts.position.clientX
        : opts.position === "cursor"
        ? cursorX
        : this.state.width / 2 + this.state.offsetLeft;
    const clientY =
      typeof opts.position === "object"
        ? opts.position.clientY
        : opts.position === "cursor"
        ? cursorY
        : this.state.height / 2 + this.state.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      this.state,
    );

    const dx = x - elementsCenterX;
    const dy = y - elementsCenterY;

    const [gridX, gridY] = getGridPoint(dx, dy, this.state.gridSize);
    const lessonId = await getLessonId();

    const newElements = duplicateElements(
      elements.map((element) => {
        const alreadyElementonCanvas = this.scene.getElements();
        let newX = element.x + 50; // 50 is for gap between 2 elements
        let newY = element.y; // 50 is for gap between 2 elements
        if (alreadyElementonCanvas.length) {
          const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
            return Math.max(maxX, element.x + element.width);
          }, 0);
          newX += maxCanvasX;
        } else {
          newX = element.x + gridX - minX;
          newY = element.y + gridY - minY;
        }

        return newElementWith(element, {
          x: updatedElement?.elementId ? clientX : newX,
          y: updatedElement?.elementId ? clientY : newY,
          lessonId,
        });
      }),
      {
        randomizeSeed: !opts.retainSeed,
      },
    );

    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: true },
      `${process.env.REACT_APP_PARENT_APP}`,
    );

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.type === "mermaidDiagram") {
        await this.addNewImagesToImageCache();
        const { updatedFiles } = await this.updateImageCache(
          elements as InitializedExcalidrawImageElement[],
          this.state.files,
        );

        if (updatedFiles.size) {
          this.scene.informMutation();
        }

        const formData = new FormData();

        if (element && element.fileId) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.state.files,
              element.fileId,
            )
          ) {
            const fileData = this.state.files[element.fileId];
            let file: any;
            await fetch(fileData.dataURL)
              .then((response) => response.blob())
              .then(async (blob) => {
                file = new File(
                  [blob],
                  element.fileId || "import mermaid Diagram",
                  {
                    type: blob.type,
                  },
                );
              });

            formData.append("file", file);
            formData.append(
              "body",
              JSON.stringify({ ...fileData, dataURL: "", isPublished: true }),
            );
          }
        }
        if (formData && formData.entries().next().done === false) {
          window.parent.postMessage(
            { type: "UPLOAD_IMAGE", isLoading: false },
            `${process.env.REACT_APP_PARENT_APP}`,
          );

          await uploadImageBlob(formData, lessonId);
        }
      }
    }
    // Now newElements contains the result of each iteration
    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: false },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    const allElements = [
      ...this.scene.getElementsIncludingDeleted(),
      ...newElements,
    ];

    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({ x, y });

    if (topLayerFrame) {
      const eligibleElements = filterElementsEligibleAsFrameChildren(
        newElements,
        topLayerFrame,
      );
      addElementsToFrame(allElements, eligibleElements, topLayerFrame);
    }

    this.scene.replaceAllElements(allElements);

    newElements.forEach((newElement) => {
      if (isTextElement(newElement) && isBoundToContainer(newElement)) {
        const container = getContainerElement(
          newElement,
          this.scene.getElementsMapIncludingDeleted(),
        );
        redrawTextBoundingBox(
          newElement,
          container,
          this.scene.getElementsMapIncludingDeleted(),
        );
      }
    });

    if (opts.files) {
      this.setState({
        ...this.state,
        files: { ...this.state.files, ...opts.files },
      });
    }
    this.store.shouldCaptureIncrement();

    excludeElementsInFramesFromSelection(newElements);

    this.setState(
      selectGroupsForSelectedElements(
        {
          ...this.state,
          selectedElementIds: newElements.reduce((map, element) => {
            map[element.id] = true;
            return map;
          }, {} as any),
        },
        this.scene.getNonDeletedElements(),
        this.state,
        this as any,
      ),
      () => {
        if (opts.files) {
          this.addNewImagesToImageCache();
        }
      },
    );
    this.selectShapeTool("selection");

    if (opts.fitToContent && !updatedElement?.elementId) {
      this.scrollToContent(newElements, {
        fitToContent: true,
      });
    }
    this.togglekeyboardShortcut(true);
  };

  private addElementsFromPasteOrLibrary = async (opts: {
    elements: readonly ExcalidrawElement[];
    files: BinaryFiles | null;
    position: { clientX: number; clientY: number } | "cursor" | "center";
    isCloseModal?: boolean;
  }) => {
    const elements = restoreElements(opts.elements, null);
    const [minX, minY, maxX, maxY] = getCommonBounds(elements);

    const elementsCenterX = distance(minX, maxX) / 2;
    const elementsCenterY = distance(minY, maxY) / 2;

    const clientX =
      typeof opts.position === "object"
        ? opts.position.clientX
        : opts.position === "cursor"
        ? cursorX
        : this.state.width / 2 + this.state.offsetLeft;
    const clientY =
      typeof opts.position === "object"
        ? opts.position.clientY
        : opts.position === "cursor"
        ? cursorY
        : this.state.height / 2 + this.state.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      this.state,
    );

    const dx = x - elementsCenterX;
    const dy = y - elementsCenterY;
    const groupIdMap = new Map();

    const [gridX, gridY] = getGridPoint(dx, dy, this.state.gridSize);

    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: true },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    const lessonId = await getLessonId();
    const oldIdToDuplicatedId = new Map();

    const newElements = duplicateElements(
      elements.map((element) => {
        const alreadyElementonCanvas = this.scene.getElements();
        let newX = element.x - 200; // less 200 because there is lot of space between 2 elements
        if (alreadyElementonCanvas.length) {
          const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
            return Math.max(maxX, element.x + element.width);
          }, 0);

          newX += maxCanvasX;

          return newElementWith(element, {
            x: newX,
            y: element.y + gridY - minY,
          });
        }
        return newElementWith(element, {
          x: element.x + gridX - minX,
          y: element.y + gridY - minY,
        });
      }),
      {
        randomizeSeed: false,
      },
    );

    const prevElements = this.scene.getElementsIncludingDeleted();
    const nextElements = [...prevElements, ...newElements];

    syncMovedIndices(nextElements, arrayToMap(newElements));

    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({ x, y });

    if (topLayerFrame) {
      const eligibleElements = filterElementsEligibleAsFrameChildren(
        newElements,
        topLayerFrame,
      );
      addElementsToFrame(nextElements, eligibleElements, topLayerFrame);
    }

    this.scene.replaceAllElements(nextElements);

    newElements.forEach((newElement) => {
      if (isTextElement(newElement) && isBoundToContainer(newElement)) {
        const container = getContainerElement(
          newElement,
          this.scene.getElementsMapIncludingDeleted(),
        );
        redrawTextBoundingBox(
          newElement,
          container,
          this.scene.getElementsMapIncludingDeleted(),
        );
      }
    });
    // const newElements = [];
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "textWithStyles" ||
        element.type === "mermaidDiagram"
      ) {
        await this.addNewImagesToImageCache();
        const { updatedFiles } = await this.updateImageCache(
          elements as InitializedExcalidrawImageElement[],
          this.state.files,
        );

        if (updatedFiles.size) {
          this.scene.informMutation();
        }

        const formData = new FormData();

        if (element && element.fileId) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.state.files,
              element.fileId,
            )
          ) {
            const fileData = this.state.files[element.fileId];
            let file: any;
            await fetch(fileData.dataURL)
              .then((response) => response.blob())
              .then(async (blob) => {
                file = new File([blob], element.fileId || "import library", {
                  type: blob.type,
                });
              });

            formData.append("file", file);
            formData.append(
              "body",
              JSON.stringify({ ...fileData, dataURL: "", isPublished: true }),
            );
          }
        }
        if (formData && formData.entries().next().done === false) {
          window.parent.postMessage(
            { type: "UPLOAD_IMAGE", isLoading: false },
            `${process.env.REACT_APP_PARENT_APP}`,
          );

          await uploadImageBlob(formData, lessonId);
        }
      }

      if (element.type === "video") {
        this.addNewVideosToVideoCache();
        const { updatedFiles } = await this.updateVideoCache(
          elements as InitializedExcalidrawVideoElement[],
          this.state.files,
        );

        if (updatedFiles.size) {
          this.scene.informMutation();
        }

        const formData = new FormData();
        if (element && element.fileId) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.state.files,
              element.fileId,
            )
          ) {
            const fileData = this.state.files[element.fileId];
            let file: any;
            await fetch(fileData.dataURL)
              .then((response) => response.blob())
              .then(async (blob) => {
                file = new File([blob], element.fileId || "import library", {
                  type: blob.type,
                });
              });

            formData.append("file", file);
            formData.append(
              "body",
              JSON.stringify({ ...fileData, dataURL: "", isPublished: true }),
            );
          }
        }
        if (formData && formData.entries().next().done === false) {
          window.parent.postMessage(
            { type: "UPLOAD_IMAGE", isLoading: false },
            `${process.env.REACT_APP_PARENT_APP}`,
          );

          await uploadVideoBlob(formData, lessonId);
        }
      }
      if (element.type === "audio") {
        this.addNewAudiosToAudioCache();
        const { updatedFiles } = await this.updateAudioCache(
          elements as InitializedExcalidrawAudioElement[],
          this.state.files,
        );

        if (updatedFiles.size) {
          this.scene.informMutation();
        }

        const formData = new FormData();
        if (element && element.fileId) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.state.files,
              element.fileId,
            )
          ) {
            const fileData = this.state.files[element.fileId];
            let file: any;
            await fetch(fileData.dataURL)
              .then((response) => response.blob())
              .then(async (blob) => {
                file = new File([blob], element.fileId || "import library", {
                  type: blob.type,
                });
              });

            formData.append("file", file);
            formData.append(
              "body",
              JSON.stringify({ ...fileData, dataURL: "", isPublished: true }),
            );
          }
        }
        if (formData && !formData?.entries().next().done) {
          window.parent.postMessage(
            { type: "UPLOAD_IMAGE", isLoading: false },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
          await uploadAudioBlob(formData, lessonId);
          return;
        }
      }

      const newElement = duplicateElement(
        this.state.editingGroupId,
        groupIdMap,
        element,
        {
          x: element.x + gridX - minX,
          y: element.y + gridY - minY,
        },
      );
      oldIdToDuplicatedId.set(element.id, newElement.id);
      newElements.push(newElement);
    }
    // Now newElements contains the result of each iteration
    window.parent.postMessage(
      { type: "UPLOAD_IMAGE", isLoading: false },
      `${process.env.REACT_APP_PARENT_APP}`,
    );
    // const nextElements = [
    //   ...this.scene.getElementsIncludingDeleted(),
    //   ...newElements,
    // ];
    fixBindingsAfterDuplication(nextElements, elements, oldIdToDuplicatedId);

    if (opts.files) {
      this.setState({
        ...this.state,
        files: { ...this.state.files, ...opts.files },
      });
    }

    this.store.shouldCaptureIncrement();
    const nextElementsToSelect = excludeElementsInFramesFromSelection(
      newElements,
    );

    this.scene.replaceAllElements([...nextElements]);
    this.setState(
      {
        ...this.state,
        ...selectGroupsForSelectedElements(
          {
            editingGroupId: null,
            selectedElementIds: opts.isCloseModal
              ? {}
              : nextElementsToSelect.reduce(
                  (acc: Record<ExcalidrawElement["id"], true>, element) => {
                    if (!isBoundToContainer(element)) {
                      acc[element.id] = true;
                    }
                    return acc;
                  },
                  {},
                ),
          },
          this.scene.getNonDeletedElements(),
          this.state,
          this as any,
        ),
      },
      () => {
        if (opts.files) {
          this.addNewImagesToImageCache();
          this.addNewVideosToVideoCache();
          this.addNewAudiosToAudioCache();
        }
      },
    );
    this.selectShapeTool("selection");

    if (!opts.isCloseModal && this.state.isLibraryOpen) {
      this.setState({ ...this.state, isLibraryOpen: false });
    }

    this.scrollToContent(newElements, {
      fitToContent: true,
    });
  };

  private importCustomCategory = async (elements: LibraryItem["elements"]) => {
    if (elements.length > 0) {
      elements.forEach(async (element: any) => {
        const fileId = "fileId" in element ? element.fileId : null;
        //get published libraries files (when import acv-library get images from the libraries collection)
        if (
          fileId !== null &&
          (element.type === "image" ||
            element.type === "formula" ||
            element.type === "mermaidDiagram" ||
            element.type === "video" ||
            element.type === "audio" ||
            element.type === "textWithStyles")
        ) {
          const publishedLibrary = await getPublishedLibrariesFiles(
            element.lessonId,
            fileId,
          );
          const files = publishedLibrary.result ? publishedLibrary.result : [];

          const file = files.find((data: { id: FileId }) => data.id === fileId);

          if (element.type === "textWithStyles") {
            if (!file) {
              //sometimes we can't get textWithStyles images from the libraries collection so we convert the text to image and save it in the files collection
              convertHtmlToCanvas(
                element.textString,
                "textImage.png",
                this.state.textEditor,
              ).then((res: any) => {
                const files: any = {
                  [fileId]: {
                    mimeType: MIME_TYPES.png as BinaryFileData["mimeType"],
                    id: fileId as BinaryFileData["id"],
                    dataURL: res.dataUrl as BinaryFileData["dataURL"],
                    created: new Date().getTime(),
                    isPublished: true,
                  },
                };
                this.setState({
                  files: { ...this.state.files, ...files },
                });
              });
            }
          }

          if (file) {
            urlToDataUrl(file.url, file.mimeType).then(async (result) => {
              if (result) {
                const files: any = {
                  [fileId]: {
                    mimeType:
                      file.mimeType ||
                      (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                    id: fileId as BinaryFileData["id"],
                    dataURL: result.dataUrl as BinaryFileData["dataURL"],
                    created: file.created,
                    isPublished: true,
                  },
                };
                this.setState({
                  files: { ...this.state.files, ...files },
                });
              } else {
                console.error(
                  "Failed to fetch and convert the URL to a data URL.",
                );
              }
            });
          }
        }
      });

      // hack to rerender the library items after import
      if (this.state.isLibraryOpen) {
        this.setState({ isLibraryOpen: false });
      }

      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";

      elements.map(
        (element: any) =>
          (element.lessonId = lessonId ? lessonId : element.lessonId),
      );

      window.parent.postMessage(
        { type: "STORE_ELEMENTS", isLoading: true },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      this.addElementsFromPasteOrLibrary({
        elements,
        position: "center",
        files: null,
      });
      await saveToLocalStorage(elements, this.state);
      await onSaveElementInDB(
        this.state.currentPage,
        lessonId || null,
        this.state.DBElements,
        this.state.viewBackgroundColor,
      );
      window.parent.postMessage(
        { type: "STORE_ELEMENTS", isLoading: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
    }
  };

  // We purposely widen the `tool` type so this helper can be called with
  // any tool without having to type check it
  private isToolSupported = <T extends ToolType | "custom">(tool: T) => {
    return (
      this.props.UIOptions.tools?.[
        tool as Extract<T, keyof AppProps["UIOptions"]["tools"]>
      ] !== false
    );
  };

  // TODO rewrite this to paste both text & images at the same time if
  // pasted data contains both
  private async addElementsFromMixedContentPaste(
    mixedContent: PastedMixedContent,
    {
      isPlainPaste,
      sceneX,
      sceneY,
    }: { isPlainPaste: boolean; sceneX: number; sceneY: number },
  ) {
    if (
      !isPlainPaste &&
      mixedContent.some((node) => node.type === "imageUrl") &&
      this.isToolSupported("image")
    ) {
      const imageURLs = mixedContent
        .filter((node) => node.type === "imageUrl")
        .map((node) => node.value);
      const responses = await Promise.all(
        imageURLs.map(async (url) => {
          try {
            return { file: await ImageURLToFile(url) };
          } catch (error) {
            let errorMessage = (error as Error).message;
            if ((error as any).cause === "FETCH_ERROR") {
              errorMessage = t("errors.failedToFetchImage");
            } else if ((error as any).cause === "UNSUPPORTED") {
              errorMessage = t("errors.unsupportedFileType");
            }
            return { errorMessage };
          }
        }),
      );
      let y = sceneY;
      let firstImageYOffsetDone = false;
      const nextSelectedIds: Record<ExcalidrawElement["id"], true> = {};
      for (const response of responses) {
        if (response.file) {
          const imageElement = await this.createImageElement({
            sceneX,
            sceneY: y,
          });

          const initializedImageElement = await this.insertImageElement(
            imageElement,
            response.file,
          );
          if (initializedImageElement) {
            // vertically center first image in the batch
            if (!firstImageYOffsetDone) {
              firstImageYOffsetDone = true;
              y -= initializedImageElement.height / 2;
            }
            // hack to reset the `y` coord because we vertically center during
            // insertImageElement
            mutateElement(initializedImageElement, { y }, false);

            y = imageElement.y + imageElement.height + 25;

            nextSelectedIds[imageElement.id] = true;
          }
        }
      }

      this.setState({
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedIds,
          this.state,
        ),
      });

      const error = responses.find((response) => !!response.errorMessage);
      if (error && error.errorMessage) {
        this.setState({ errorMessage: error.errorMessage });
      }
    } else {
      const textNodes = mixedContent.filter((node) => node.type === "text");
      if (textNodes.length) {
        this.addTextFromPaste(
          textNodes.map((node) => node.value).join("\n\n"),
          isPlainPaste,
        );
      }
    }
  }

  private async addTextFromPaste(text: any, isPlainPaste = false) {
    const { x, y } = viewportCoordsToSceneCoords(
      { clientX: cursorX, clientY: cursorY },
      this.state,
    );

    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x,
      y,
    });

    const lessonId = await getLessonId();

    const element = newTextElement({
      x,
      y,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      text,
      fontSize: this.state.currentItemFontSize,
      fontFamily: this.state.currentItemFontFamily,
      fontWeight: this.state.currentItemFontWeight,
      textItalic: this.state.textItalic,
      textAlign: this.state.currentItemTextAlign,
      verticalAlign: DEFAULT_VERTICAL_ALIGN,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });

    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      element,
    ]);
    this.setState({ selectedElementIds: { [element.id]: true } });
    this.store.shouldCaptureIncrement();
  }

  // Collaboration

  setAppState = (obj: any, cb?: () => void) => {
    if (!!cb) {
      this.setState(obj, cb);
    } else {
      this.setState(obj);
    }
  };

  removePointer = async (
    event: React.PointerEvent<HTMLElement> | PointerEvent,
  ) => {
    //store the data into db when mouse pointer up
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    if (isMyWorkSpaceData === "true") {
      gesture.pointers.delete(event.pointerId);
      return;
    }

    setTimeout(async () => {
      const dataURL = await captchScreenImage(this.state.currentPage);
      const updatedPageDataURL: UpdatePageDataURL = {
        page: this.state.currentPage,
        lessonId,
        dataURL,
      };
      // console.log("updatedPageDataURL", updatedPageDataURL);
      this.setState({
        ...this.state,
        updatedPageDataURL,
      });
    }, 100);
    // remove touch handler for context menu on touch devices
    if (event.pointerType === "touch" && touchTimeout) {
      clearTimeout(touchTimeout);
      touchTimeout = 0;
      invalidateContextMenu = false;
    }

    gesture.pointers.delete(event.pointerId);
  };

  toggleLock = () => {
    this.setState((prevState) => {
      return {
        elementLocked: !prevState.elementLocked,
        activeTool: {
          ...prevState.activeTool,
          ...updateActiveTool(
            this.state,
            prevState.elementLocked
              ? { type: "selection" }
              : prevState.activeTool,
          ),
          locked: !prevState.elementLocked,
        },
      };
    });
  };

  toggleZenMode = () => {
    this.actionManager.executeAction(actionToggleZenMode);
  };

  toggleStats = () => {
    if (!this.state.showStats) {
      trackEvent("dialog", "stats");
    }
    this.actionManager.executeAction(actionToggleStats);
  };

  scrollToContent = (
    target:
      | ExcalidrawElement
      | readonly ExcalidrawElement[] = this.scene.getNonDeletedElements(),
    opts?:
      | {
          fitToContent?: boolean;
          fitToViewport?: never;
          viewportZoomFactor?: never;
          animate?: boolean;
          duration?: number;
        }
      | {
          fitToContent?: never;
          fitToViewport?: boolean;
          /** when fitToViewport=true, how much screen should the content cover,
           * between 0.1 (10%) and 1 (100%)
           */
          viewportZoomFactor?: number;
          animate?: boolean;
          duration?: number;
        },
  ) => {
    // @ts-ignore
    if (!Array.isArray(target) && target.type === "frame") {
      const frame = target as ExcalidrawFrameElement;
      this.setAppState({
        frameToHighlight: frame,
      });
    }

    // convert provided target into ExcalidrawElement[] if necessary
    const targetElements = Array.isArray(target) ? target : [target];

    let zoom = this.state.zoom;
    let scrollX = this.state.scrollX;
    let scrollY = this.state.scrollY;

    if (opts?.fitToContent || opts?.fitToViewport) {
      const { appState } = zoomToFit({
        targetElements,
        appState: this.state,
        fitToViewport: !!opts?.fitToViewport,
        viewportZoomFactor: opts?.viewportZoomFactor,
      });

      zoom = {
        value: appState.zoom.value,
        translation: {
          x: appState.scrollX,
          y: appState.scrollY,
        },
      };

      scrollX = appState.scrollX;
      scrollY = appState.scrollY;
    } else {
      // compute only the viewport location, without any zoom adjustment
      const scroll = calculateScrollCenter(targetElements, this.state);
      scrollX = scroll.scrollX;
      scrollY = scroll.scrollY;
    }

    // when animating, we use RequestAnimationFrame to prevent the animation
    // from slowing down other processes
    if (opts?.animate) {
      const origScrollX = this.state.scrollX;
      const origScrollY = this.state.scrollY;
      const origZoom = this.state.zoom.value;

      easeToValuesRAF({
        fromValues: {
          scrollX: origScrollX,
          scrollY: origScrollY,
          zoom: origZoom,
        },
        toValues: { scrollX, scrollY, zoom: zoom.value },
        interpolateValue: (from, to, progress, key) => {
          // for zoom, use different easing
          if (key === "zoom") {
            return from * Math.pow(to / from, easeOut(progress));
          }
          // handle using default
          return undefined;
        },
        onStep: ({ scrollX, scrollY, zoom }) => {
          this.setState({
            scrollX,
            scrollY,
            zoom: { value: zoom, translation: { x: scrollX, y: scrollY } },
          });
        },
        onStart: () => {
          this.setState({ shouldCacheIgnoreZoom: true });
        },
        onEnd: () => {
          this.setState({ shouldCacheIgnoreZoom: false });
        },
        onCancel: () => {
          this.setState({ shouldCacheIgnoreZoom: false });
        },
        duration: opts?.duration ?? 500,
      });

      // this.cancelInProgressAnimation = () => {
      //   cancel();
      //   this.cancelInProgressAnimation = null;
      // };
    } else {
      this.setState({ scrollX, scrollY, zoom });
    }
  };

  clearToast = () => {
    this.setState({ toastMessage: null });
  };

  setToastMessage = (toastMessage: string) => {
    this.setState({ toastMessage });
  };

  restoreFileFromShare = async () => {
    try {
      const webShareTargetCache = await caches.open("web-share-target");

      const file = await webShareTargetCache.match("shared-file");
      if (file) {
        const blob = await file.blob();
        this.loadFileToCanvas(blob);
        await webShareTargetCache.delete("shared-file");
        window.history.replaceState(null, APP_NAME, window.location.pathname);
      }
    } catch (error: any) {
      this.setState({ errorMessage: error.message });
    }
  };

  /** adds supplied files to existing files in the appState */
  public addFiles: ExcalidrawImperativeAPI["addFiles"] = withBatchedUpdates(
    (files) => {
      const filesMap = files.reduce((acc, fileData) => {
        acc.set(fileData.id, fileData);
        return acc;
      }, new Map<FileId, BinaryFileData>());

      // this.files = { ...this.files, ...Object.fromEntries(filesMap) };
      this.setState({
        ...this.state,
        files: { ...this.state.files, ...Object.fromEntries(filesMap) },
      });

      // bump versions for elements that reference added files so that
      // we/host apps can detect the change, and invalidate the image & shape
      // cache
      this.scene.getElements().forEach((element) => {
        if (
          isInitializedImageElement(element) &&
          filesMap.has(element.fileId)
        ) {
          this.imageCache.delete(element.fileId);
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
        if (
          isInitializedMermaidDiagramElement(element) &&
          filesMap.has(element.fileId)
        ) {
          this.imageCache.delete(element.fileId);
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
        if (
          isInitializedFormulaElement(element) &&
          filesMap.has(element.fileId)
        ) {
          this.imageCache.delete(element.fileId);
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
        if (
          isInitializedTextWithStylesElement(element) &&
          filesMap.has(element.fileId)
        ) {
          this.imageCache.delete(element.fileId);
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
        if (
          isInitializedVideoElement(element) &&
          filesMap.has(element.fileId)
        ) {
          // this.state.videoCache.delete(element.fileId);
          this.setState((prevState) => {
            const updatedVideoCache = new Map(prevState.videoCache);
            updatedVideoCache.delete(element.fileId);

            return {
              videoCache: updatedVideoCache,
            };
          });
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
        if (
          isInitializedAudioElement(element) &&
          filesMap.has(element.fileId)
        ) {
          // this.state.audioCache.delete(element.fileId);
          this.setState((prevState) => {
            const updatedAudioCache = new Map(prevState.audioCache);
            updatedAudioCache.delete(element.fileId);

            return {
              audioCache: updatedAudioCache,
            };
          });
          invalidateShapeForElement(element);
          bumpVersion(element);
        }
      });
      this.scene.informMutation();

      this.addNewImagesToImageCache();
      this.addNewAudiosToAudioCache();
      this.addNewVideosToVideoCache();
    },
  );

  public updateScene = withBatchedUpdates(
    <K extends keyof AppState>(sceneData: {
      elements?: SceneData["elements"];
      appState?: Pick<AppState, K> | null;
      collaborators?: SceneData["collaborators"];
      /** @default StoreAction.NONE */
      storeAction?: SceneData["storeAction"];
    }) => {
      const nextElements = syncInvalidIndices(sceneData.elements ?? []);
      if (sceneData.storeAction && sceneData.storeAction !== StoreAction.NONE) {
        const prevCommittedAppState = this.store.snapshot.appState;
        const prevCommittedElements = this.store.snapshot.elements;

        const nextCommittedAppState = sceneData.appState
          ? Object.assign({}, prevCommittedAppState, sceneData.appState) // new instance, with partial appstate applied to previously captured one, including hidden prop inside `prevCommittedAppState`
          : prevCommittedAppState;

        const nextCommittedElements = sceneData.elements
          ? this.store.filterUncomittedElements(
              this.scene.getElementsMapIncludingDeleted() as any, // Only used to detect uncomitted local elements
              arrayToMap(nextElements), // We expect all (already reconciled) elements
            )
          : prevCommittedElements;

        // WARN: store action always performs deep clone of changed elements, for ephemeral remote updates (i.e. remote dragging, resizing, drawing) we might consider doing something smarter
        // do NOT schedule store actions (execute after re-render), as it might cause unexpected concurrency issues if not handled well
        if (sceneData.storeAction === StoreAction.CAPTURE) {
          this.store.captureIncrement(
            nextCommittedElements,
            nextCommittedAppState,
          );
        } else if (sceneData.storeAction === StoreAction.UPDATE) {
          this.store.updateSnapshot(
            nextCommittedElements,
            nextCommittedAppState,
          );
        }
      }

      if (sceneData.appState) {
        this.setState(sceneData.appState);
      }

      if (sceneData.elements) {
        this.scene.replaceAllElements(nextElements);
      }

      if (sceneData.collaborators) {
        this.setState({ collaborators: sceneData.collaborators });
      }
    },
  );

  private onSceneUpdated = () => {
    this.setState({});
  };

  private updateCurrentCursorPosition = withBatchedUpdates(
    (event: MouseEvent) => {
      cursorX = event.clientX;
      cursorY = event.clientY;
    },
  );

  // Input handling

  private onKeyDown = withBatchedUpdates(
    (event: React.KeyboardEvent | KeyboardEvent) => {
      // normalize `event.key` when CapsLock is pressed #2372
      if (
        "Proxy" in window &&
        ((!event.shiftKey && /^[A-Z]$/.test(event.key)) ||
          (event.shiftKey && /^[a-z]$/.test(event.key)))
      ) {
        event = new Proxy(event, {
          get(ev: any, prop) {
            const value = ev[prop];
            if (typeof value === "function") {
              // fix for Proxies hijacking `this`
              return value.bind(ev);
            }
            return prop === "key"
              ? // CapsLock inverts capitalization based on ShiftKey, so invert
                // it back
                event.shiftKey
                ? ev.key.toUpperCase()
                : ev.key.toLowerCase()
              : value;
          },
        });
      }

      if (
        (isWritableElement(event.target) && event.key !== KEYS.ESCAPE) ||
        // case: using arrows to move between buttons
        (isArrowKey(event.key) && isInputLike(event.target))
      ) {
        return;
      }

      if (event.key === KEYS.QUESTION_MARK) {
        this.setState({
          showHelpDialog: true,
        });
      }

      if (this.actionManager.handleKeyDown(event)) {
        return;
      }

      if (
        this.state.viewModeEnabled &&
        this.state.activeTool.type !== "laser"
      ) {
        return;
      }

      if (event[KEYS.CTRL_OR_CMD] && this.state.isBindingEnabled) {
        this.setState({ isBindingEnabled: false });
      }

      if (event.code === CODES.ZERO) {
        this.setState({ isLibraryOpen: !this.state.isLibraryOpen });
      }

      if (isArrowKey(event.key)) {
        const step =
          (this.state.gridSize &&
            (event.shiftKey
              ? ELEMENT_TRANSLATE_AMOUNT
              : this.state.gridSize)) ||
          (event.shiftKey
            ? ELEMENT_SHIFT_TRANSLATE_AMOUNT
            : ELEMENT_TRANSLATE_AMOUNT);

        let offsetX = 0;
        let offsetY = 0;

        if (event.key === KEYS.ARROW_LEFT) {
          offsetX = -step;
        } else if (event.key === KEYS.ARROW_RIGHT) {
          offsetX = step;
        } else if (event.key === KEYS.ARROW_UP) {
          offsetY = -step;
        } else if (event.key === KEYS.ARROW_DOWN) {
          offsetY = step;
        }

        const selectedElements = this.scene.getSelectedElements({
          selectedElementIds: this.state.selectedElementIds,
          includeBoundTextElement: true,
          includeElementsInFrames: true,
        });

        selectedElements.forEach((element) => {
          mutateElement(element, {
            x: element.x + offsetX,
            y: element.y + offsetY,
          });

          updateBoundElements(element, this.scene.getNonDeletedElementsMap(), {
            simultaneouslyUpdated: selectedElements,
          });
        });

        this.setState({
          suggestedBindings: getSuggestedBindingsForArrows(
            selectedElements,
            this.scene.getNonDeletedElementsMap(),
          ),
        });

        event.preventDefault();
      } else if (event.key === KEYS.ENTER) {
        const selectedElements = this.scene.getSelectedElements(this.state);
        if (selectedElements.length === 1) {
          const selectedElement = selectedElements[0];
          if (event[KEYS.CTRL_OR_CMD]) {
            if (isLinearElement(selectedElement)) {
              if (
                !this.state.editingLinearElement ||
                this.state.editingLinearElement.elementId !==
                  selectedElements[0].id
              ) {
                this.store.shouldCaptureIncrement();
                this.setState({
                  editingLinearElement: new LinearElementEditor(
                    selectedElement,
                    this.scene,
                  ),
                });
              }
            }
          } else if (isTextElement(selectedElement)) {
            let container;
            if (!isTextElement(selectedElement)) {
              container = selectedElement as ExcalidrawTextContainer;
            }
            const midPoint = getContainerCenter(
              selectedElement,
              this.state,
              this.scene.getNonDeletedElementsMap(),
            );
            const sceneX = midPoint.x;
            const sceneY = midPoint.y;
            this.startTextEditing({
              sceneX,
              sceneY,
              container,
            });
            event.preventDefault();
            return;
          } else if (isFrameLikeElement(selectedElement)) {
            this.setState({
              editingFrame: selectedElement.id,
            });
          }
        }
      } else if (
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey &&
        this.state.draggingElement === null &&
        !this.state.selectionElement &&
        !this.state.selectedElementsAreBeingDragged
      ) {
        const shape = findShapeByKey(event.key);
        if (shape) {
          if (this.state.activeTool.type !== shape) {
            trackEvent(
              "toolbar",
              shape,
              `keyboard (${this.isMobile ? "mobile" : "desktop"})`,
            );
          }
          if (shape === "arrow" && this.state.activeTool.type === "arrow") {
            this.setState((prevState) => ({
              currentItemArrowType:
                prevState.currentItemArrowType === ARROW_TYPE.sharp
                  ? ARROW_TYPE.round
                  : prevState.currentItemArrowType === ARROW_TYPE.round
                  ? ARROW_TYPE.elbow
                  : ARROW_TYPE.sharp,
            }));
          }
          this.setActiveTool({ type: shape as any });
          event.stopPropagation();
        } else if (event.key === KEYS.Q) {
          this.toggleLock();
          event.stopPropagation();
        }
      }
      if (event.key === KEYS.SPACE && gesture.pointers.size === 0) {
        isHoldingSpace = true;
        setCursor(this.canvas, CURSOR_TYPE.GRABBING);
      }

      if (event.key === KEYS.G || event.key === KEYS.S) {
        const selectedElements = this.scene.getSelectedElements(this.state);
        if (
          this.state.activeTool.type === "selection" &&
          !selectedElements.length
        ) {
          return;
        }

        if (
          event.key === KEYS.G &&
          (hasBackground(this.state.activeTool.type) ||
            selectedElements.some((element) => hasBackground(element.type)))
        ) {
          this.setState({ openPopup: "backgroundColorPicker" });
          event.stopPropagation();
        }
        if (event.key === KEYS.S) {
          this.setState({ openPopup: "strokeColorPicker" });
          event.stopPropagation();
        }
      }
      if (
        !event[KEYS.CTRL_OR_CMD] &&
        !event.shiftKey &&
        !event.altKey &&
        event.key.toLowerCase() === KEYS.F
      ) {
        this.setActiveTool({ type: "frame" });
      }
      if (event.key === KEYS.K && !event.altKey && !event[KEYS.CTRL_OR_CMD]) {
        if (this.state.activeTool?.type === "laser") {
          this.setActiveTool({ type: "selection" });
        } else {
          this.setActiveTool({ type: "laser" });
        }
      }
    },
  );

  private onKeyUp = withBatchedUpdates((event: KeyboardEvent) => {
    if (event.key === KEYS.SPACE) {
      if (
        this.state.viewModeEnabled &&
        this.state.activeTool.type !== "laser"
      ) {
        setCursor(this.canvas, CURSOR_TYPE.GRAB);
      } else if (this.state.activeTool.type === "selection") {
        resetCursor(this.canvas);
      } else {
        setCursorForShape(this.canvas, this.state);
        this.setState({
          selectedElementIds: makeNextSelectedElementIds({}, this.state),
          selectedGroupIds: {},
          editingGroupId: null,
        });
      }
      isHoldingSpace = false;
    }
    if (!event[KEYS.CTRL_OR_CMD] && !this.state.isBindingEnabled) {
      this.setState({ isBindingEnabled: true });
    }
    if (isArrowKey(event.key)) {
      bindOrUnbindLinearElements(
        this.scene.getSelectedElements(this.state).filter(isLinearElement),
        this.scene.getNonDeletedElementsMap(),
        isBindingEnabled(this.state),
        this.state.selectedLinearElement?.selectedPointsIndices ?? [],
      );
      this.setState({ suggestedBindings: [] });
    }
  });

  private selectShapeTool(elementType: AppState["activeTool"]["type"]) {
    if (!isHoldingSpace) {
      setCursorForShape(this.canvas, this.state);
    }
    if (isToolIcon(document.activeElement)) {
      this.focusContainer();
    }
    if (!isLinearElementType(elementType as any)) {
      this.setState({ suggestedBindings: [] });
    }
    if (elementType === "image") {
      this.onImageAction();
    }
    if (elementType !== "selection") {
      this.setState({
        selectedElementIds: makeNextSelectedElementIds({}, this.state),
        selectedGroupIds: {},
        editingGroupId: null,
      });
    } else {
      this.setActiveTool({
        type: elementType,
      });
    }
  }

  private onGestureStart = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();
    this.setState({
      selectedElementIds: makeNextSelectedElementIds({}, this.state),
    });
    gesture.initialScale = this.state.zoom.value;
  });

  private onGestureChange = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();

    // onGestureChange only has zoom factor but not the center.
    // If we're on iPad or iPhone, then we recognize multi-touch and will
    // zoom in at the right location on the touchMove handler already.
    // On Macbook, we don't have those events so will zoom in at the
    // current location instead.
    if (gesture.pointers.size === 2) {
      return;
    }

    const initialScale = gesture.initialScale;
    if (initialScale) {
      this.setState(({ zoom, offsetLeft, offsetTop }) => ({
        zoom: getNewZoom(
          getNormalizedZoom(initialScale * event.scale),
          zoom,
          { left: offsetLeft, top: offsetTop },
          { x: cursorX, y: cursorY },
        ),
      }));
    }
  });

  private onGestureEnd = withBatchedUpdates((event: GestureEvent) => {
    event.preventDefault();
    this.setState({
      previousSelectedElementIds: {},
      selectedElementIds: makeNextSelectedElementIds(
        this.state.previousSelectedElementIds,
        this.state,
      ),
    });
    gesture.initialScale = null;
  });

  private handleTextWysiwyg(
    element: ExcalidrawTextElement,
    {
      isExistingElement = false,
    }: {
      isExistingElement?: boolean;
    },
    text?: string,
  ) {
    const elementsMap = this.scene.getElementsMapIncludingDeleted();
    const updateElement = (nextOriginalText: string, isDeleted: boolean) => {
      this.scene.replaceAllElements([
        // Not sure why we include deleted elements as well hence using deleted elements map
        ...this.scene.getElementsIncludingDeleted().map((_element) => {
          if (_element.id === element.id && isTextElement(_element)) {
            return newElementWith(_element, {
              originalText: nextOriginalText,
              isDeleted: isDeleted ?? _element.isDeleted,
              // returns (wrapped) text and new dimensions
              ...refreshTextDimensions(
                _element,
                getContainerElement(_element, elementsMap),
                elementsMap,
                nextOriginalText,
              ),
            });
          }
          return _element;
        }),
      ]);
    };
    if (text) {
      this.setState({
        draggingElement: null,
        editingElement: null,
      });
      this.deselectElements();
      updateElement(text, false);
    } else {
      mutateElement(element, { isDeleted: true });
      textWysiwyg({
        id: element.id,
        appState: this.state,
        canvas: this.canvas,
        getViewportCoords: (x, y) => {
          const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
            {
              sceneX: x,
              sceneY: y,
            },
            this.state,
          );
          return [
            viewportX - this.state.offsetLeft,
            viewportY - this.state.offsetTop,
          ];
        },
        onChange: withBatchedUpdates((text) => {
          updateElement(text, false);
          if (isNonDeletedElement(element)) {
            updateBoundElements(element, elementsMap);
          }
        }),
        onSubmit: withBatchedUpdates(({ text, viaKeyboard }) => {
          const isDeleted = !text.trim();
          updateElement(text, isDeleted);
          // select the created text element only if submitting via keyboard
          // (when submitting via click it should act as signal to deselect)
          if (!isDeleted && viaKeyboard) {
            const elementIdToSelect = element.containerId
              ? element.containerId
              : element.id;
            this.setState((prevState) => ({
              selectedElementIds: makeNextSelectedElementIds(
                {
                  ...prevState.selectedElementIds,
                  [elementIdToSelect]: true,
                },
                prevState,
              ),
            }));
            // this.setState((prevState) => ({
            //   selectedElementIds: {
            //     ...prevState.selectedElementIds,
            //     [element.id]: true,
            //   },
            // }));
          }

          if (isDeleted) {
            fixBindingsAfterDeletion(this.scene.getNonDeletedElements(), [
              element,
            ]);
          }
          if (!isDeleted || isExistingElement) {
            this.store.shouldCaptureIncrement();
          }

          this.setState({
            draggingElement: null,
            editingElement: null,
          });
          if (this.state.elementLocked) {
            setCursorForShape(this.canvas, this.state);
          }

          this.focusContainer();
        }),
        element,
        excalidrawContainer: this.excalidrawContainerRef.current,
        app: this,
      });
      // deselect all other elements when inserting text
      this.deselectElements();

      // do an initial update to re-initialize element position since we were
      // modifying element's x/y for sake of editor (case: syncing to remote)
      updateElement(element.originalText, false);
    }
  }

  private deselectElements() {
    this.setState({
      selectedElementIds: makeNextSelectedElementIds({}, this.state),
      selectedGroupIds: {},
      editingGroupId: null,
    });
  }

  private getElementHitThreshold() {
    return DEFAULT_COLLISION_THRESHOLD / this.state.zoom.value;
  }

  private hitElement(
    x: number,
    y: number,
    element: ExcalidrawElement,
    considerBoundingBox = true,
  ) {
    // if the element is selected, then hit test is done against its bounding box
    if (
      considerBoundingBox &&
      this.state.selectedElementIds[element.id] &&
      shouldShowBoundingBox([element], this.state)
    ) {
      const selectionShape = getSelectionBoxShape(
        element,
        this.scene.getNonDeletedElementsMap(),
        this.getElementHitThreshold(),
      );
      return isPointInShape(pointFrom(x, y), selectionShape);
    }

    // take bound text element into consideration for hit collision as well
    const hitBoundTextOfElement = hitElementBoundText(
      x,
      y,
      getBoundTextShape(element, this.scene.getNonDeletedElementsMap()),
    );
    if (hitBoundTextOfElement) {
      return true;
    }

    return hitElementItself({
      x,
      y,
      element,
      shape: getElementShape(element, this.scene.getNonDeletedElementsMap()),
      threshold: this.getElementHitThreshold(),
      frameNameBound: isFrameLikeElement(element)
        ? this.frameNameBoundsCache.get(element)
        : null,
    });
  }

  private getTextElementAtPosition(
    x: number,
    y: number,
  ): NonDeleted<ExcalidrawTextElement> | null {
    const element = this.getElementAtPosition(x, y);

    if (element && isTextElement(element) && !element.isDeleted) {
      return element;
    }
    return null;
  }

  private getTextWithStylesElementAtPosition(
    x: number,
    y: number,
  ): NonDeleted<ExcalidrawTextWithStyleElement> | null {
    const element = this.getElementAtPosition(x, y);

    if (element && isTextWithStylesElement(element) && !element.isDeleted) {
      return element;
    }
    return null;
  }

  private getElementAtPosition(
    x: number,
    y: number,
    opts?: {
      /** if true, returns the first selected element (with highest z-index)
        of all hit elements */
      preferSelected?: boolean;
      includeLockedElements?: boolean;
    },
  ): NonDeleted<ExcalidrawElement> | null {
    const allHitElements = this.getElementsAtPosition(
      x,
      y,
      false,
      opts?.includeLockedElements,
    );
    if (allHitElements.length > 1) {
      if (opts?.preferSelected) {
        for (let index = allHitElements.length - 1; index > -1; index--) {
          if (this.state.selectedElementIds[allHitElements[index].id]) {
            return allHitElements[index];
          }
        }
      }
      const elementWithHighestZIndex =
        allHitElements[allHitElements.length - 1];
      // If we're hitting element with highest z-index only on its bounding box
      // while also hitting other element figure, the latter should be considered.

      return hitElementItself({
        x,
        y,
        element: elementWithHighestZIndex,
        shape: getElementShape(
          elementWithHighestZIndex,
          this.scene.getNonDeletedElementsMap(),
        ),
        // when overlapping, we would like to be more precise
        // this also avoids the need to update past tests
        threshold: this.getElementHitThreshold() / 2,
        frameNameBound: isFrameLikeElement(elementWithHighestZIndex)
          ? this.frameNameBoundsCache.get(elementWithHighestZIndex)
          : null,
      })
        ? allHitElements[allHitElements.length - 2]
        : elementWithHighestZIndex;
    }
    if (allHitElements.length === 1) {
      return allHitElements[0];
    }
    return null;
  }

  handleHoverSelectedLinearElement(
    linearElementEditor: LinearElementEditor,
    scenePointerX: number,
    scenePointerY: number,
  ) {
    const elementsMap = this.scene.getNonDeletedElementsMap();

    const element = LinearElementEditor.getElement(
      linearElementEditor.elementId,
      elementsMap,
    );

    if (!element) {
      return;
    }
    if (this.state.selectedLinearElement) {
      let hoverPointIndex = -1;
      let segmentMidPointHoveredCoords = null;
      if (
        hitElementItself({
          x: scenePointerX,
          y: scenePointerY,
          element,
          shape: getElementShape(
            element,
            this.scene.getNonDeletedElementsMap(),
          ),
        })
      ) {
        hoverPointIndex = LinearElementEditor.getPointIndexUnderCursor(
          element,
          elementsMap,
          this.state.zoom,
          scenePointerX,
          scenePointerY,
        );
        segmentMidPointHoveredCoords = LinearElementEditor.getSegmentMidpointHitCoords(
          linearElementEditor,
          { x: scenePointerX, y: scenePointerY },
          this.state,
          this.scene.getNonDeletedElementsMap(),
        );

        if (hoverPointIndex >= 0 || segmentMidPointHoveredCoords) {
          setCursor(this.canvas, CURSOR_TYPE.POINTER);
        } else if (this.hitElement(scenePointerX, scenePointerY, element)) {
          setCursor(this.canvas, CURSOR_TYPE.MOVE);
        }
      } else if (this.hitElement(scenePointerX, scenePointerY, element)) {
        if (!(element.startBinding || element.endBinding)) {
          setCursor(this.canvas, CURSOR_TYPE.MOVE);
        }
      }

      if (
        this.state.selectedLinearElement?.hoverPointIndex !== hoverPointIndex
      ) {
        this.setState({
          selectedLinearElement: {
            ...this.state.selectedLinearElement,
            hoverPointIndex,
          },
        });
      }

      if (
        !LinearElementEditor.arePointsEqual(
          this.state.selectedLinearElement?.segmentMidPointHoveredCoords,
          segmentMidPointHoveredCoords,
        )
      ) {
        this.setState({
          selectedLinearElement: {
            ...this.state.selectedLinearElement,
            segmentMidPointHoveredCoords,
          },
        });
      }
    } else {
      setCursor(this.canvas, CURSOR_TYPE.AUTO);
    }
  }

  private getElementsAtPosition(
    x: number,
    y: number,
    includeBoundTextElement: boolean = false,
    includeLockedElements: boolean = false,
  ): NonDeleted<ExcalidrawElement>[] {
    const iframeLikes: Ordered<ExcalidrawIframeElement>[] = [];

    const elementsMap = this.scene.getNonDeletedElementsMap();

    const elements = (includeBoundTextElement && includeLockedElements
      ? this.scene.getNonDeletedElements()
      : this.scene
          .getNonDeletedElements()
          .filter(
            (element) =>
              (includeLockedElements || !element.locked) &&
              (includeBoundTextElement ||
                !(isTextElement(element) && element.containerId)),
          )
    )
      .filter((el) => this.hitElement(x, y, el))
      .filter((element) => {
        // hitting a frame's element from outside the frame is not considered a hit
        const containingFrame = getContainingFrame(element, elementsMap);
        return containingFrame &&
          this.state.frameRendering.enabled &&
          this.state.frameRendering.clip
          ? isCursorInFrame({ x, y }, containingFrame, elementsMap)
          : true;
      })
      .filter((el) => {
        // The parameter elements comes ordered from lower z-index to higher.
        // We want to preserve that order on the returned array.
        // Exception being embeddables which should be on top of everything else in
        // terms of hit testing.
        if (isIframeElement(el)) {
          iframeLikes.push(el as Ordered<ExcalidrawIframeElement>);
          return false;
        }
        return true;
      })
      .concat(iframeLikes) as NonDeleted<ExcalidrawElement>[];

    return elements;
  }

  startTextWithStylesEditing = async ({
    sceneX,
    sceneY,
    insertAtParentCenter = true,
    text,
  }: StartEditingProps) => {
    const existingTextWithElement = this.getTextWithStylesElementAtPosition(
      sceneX,
      sceneY,
    );

    const parentCenterPosition =
      insertAtParentCenter &&
      this.getTextWithStylesSnappedToCenterPosition(
        sceneX,
        sceneY,
        this.state,
        this.canvas,
        window.devicePixelRatio,
      );

    const lessonId = await getLessonId();

    const element = existingTextWithElement
      ? existingTextWithElement
      : newTextWithStylesElement({
          type: "textWithStyles",
          x: parentCenterPosition
            ? parentCenterPosition.elementCenterX
            : sceneX,
          y: parentCenterPosition
            ? parentCenterPosition.elementCenterY
            : sceneY,
          strokeColor: this.state.currentItemStrokeColor,
          backgroundColor: this.state.currentItemBackgroundColor,
          fillStyle: this.state.currentItemFillStyle,
          strokeWidth: this.state.currentItemStrokeWidth,
          strokeStyle: this.state.currentItemStrokeStyle,
          roughness: this.state.currentItemRoughness,
          opacity: this.state.currentItemOpacity,
          textString: "<p>Test</p>",
          page: this.state.currentPage,
          lessonId: lessonId || "",
          link: null,
          defaultWidth: this.state.updatingTextElement?.defaultWidth,
          defaultHeight: this.state.updatingTextElement?.defaultHeight,
          zoomFactor: this.state.updatingTextElement?.zoomFactor,
          textEditor: this.state.updatingTextElement?.textEditor,
          index: null,
          locked: false,
          isVisible: true,
        });

    this.setState({ editingElement: element });

    if (!existingTextWithElement) {
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted(),
        element,
      ]);
    }

    this.setState({
      editingElement: element,
    });
  };

  private getTextBindableContainerAtPosition(x: number, y: number) {
    const elements = this.scene.getNonDeletedElements();
    const selectedElements = this.scene.getSelectedElements(this.state);
    if (selectedElements.length === 1) {
      return isTextBindableContainer(selectedElements[0], false)
        ? selectedElements[0]
        : null;
    }
    let hitElement = null;
    // We need to do hit testing from front (end of the array) to back (beginning of the array)
    for (let index = elements.length - 1; index >= 0; --index) {
      if (elements[index].isDeleted) {
        continue;
      }
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(
        elements[index],
        this.scene.getNonDeletedElementsMap(),
      );
      if (
        isArrowElement(elements[index]) &&
        hitElementItself({
          x,
          y,
          element: elements[index],
          shape: getElementShape(
            elements[index],
            this.scene.getNonDeletedElementsMap(),
          ),
          threshold: this.getElementHitThreshold(),
        })
      ) {
        hitElement = elements[index];
        break;
      } else if (x1 < x && x < x2 && y1 < y && y < y2) {
        hitElement = elements[index];
        break;
      }
    }
    return isTextBindableContainer(hitElement, false) ? hitElement : null;
  }

  startTextEditing = async ({
    sceneX,
    sceneY,
    insertAtParentCenter = true,
    container,
    autoEdit = true,
  }: StartEditingProps & {
    container?: ExcalidrawTextContainer | null;
    autoEdit?: boolean;
  }) => {
    let shouldBindToContainer = false;
    let parentCenterPosition =
      insertAtParentCenter &&
      this.getTextWysiwygSnappedToCenterPosition(
        sceneX,
        sceneY,
        this.state,
        container,
      );
    if (container && parentCenterPosition) {
      const boundTextElementToContainer = getBoundTextElement(
        container,
        this.scene.getNonDeletedElementsMap(),
      );
      if (!boundTextElementToContainer) {
        shouldBindToContainer = true;
      }
    }
    let existingTextElement: NonDeleted<ExcalidrawTextElement> | null = null;

    const selectedElements = this.scene.getSelectedElements(this.state);

    if (selectedElements.length === 1) {
      if (isTextElement(selectedElements[0])) {
        existingTextElement = selectedElements[0];
      } else if (container) {
        existingTextElement = getBoundTextElement(
          selectedElements[0],
          this.scene.getNonDeletedElementsMap(),
        );
      } else {
        existingTextElement = this.getTextElementAtPosition(sceneX, sceneY);
      }
    } else {
      existingTextElement = this.getTextElementAtPosition(sceneX, sceneY);
    }

    const fontFamily =
      existingTextElement?.fontFamily || this.state.currentItemFontFamily;

    const lineHeight =
      existingTextElement?.lineHeight || getDefaultLineHeight(fontFamily);
    const fontSize = this.state.currentItemFontSize;

    if (
      !existingTextElement &&
      shouldBindToContainer &&
      container &&
      !isArrowElement(container)
    ) {
      const fontString = {
        fontSize,
        fontFamily,
      };
      const minWidth = getApproxMinLineWidth(
        getFontString(fontString),
        lineHeight,
      );
      const minHeight = getApproxMinLineHeight(fontSize, lineHeight);
      const newHeight = Math.max(container.height, minHeight);
      const newWidth = Math.max(container.width, minWidth);
      mutateElement(container, { height: newHeight, width: newWidth });
      sceneX = container.x + newWidth / 2;
      sceneY = container.y + newHeight / 2;
      if (parentCenterPosition) {
        parentCenterPosition = this.getTextWysiwygSnappedToCenterPosition(
          sceneX,
          sceneY,
          this.state,
          container,
        );
      }
    }

    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: sceneX,
      y: sceneY,
    });

    const lessonId = await getLessonId();

    const element = existingTextElement
      ? existingTextElement
      : newTextElement({
          x: parentCenterPosition
            ? parentCenterPosition.elementCenterX
            : sceneX,
          y: parentCenterPosition
            ? parentCenterPosition.elementCenterY
            : sceneY,
          strokeColor: this.state.currentItemStrokeColor,
          backgroundColor: this.state.currentItemBackgroundColor,
          fillStyle: this.state.currentItemFillStyle,
          strokeWidth: this.state.currentItemStrokeWidth,
          strokeStyle: this.state.currentItemStrokeStyle,
          roughness: this.state.currentItemRoughness,
          opacity: this.state.currentItemOpacity,
          text: "",
          fontSize: this.state.currentItemFontSize,
          fontWeight: this.state.currentItemFontWeight,
          textItalic: this.state.textItalic,
          fontFamily: this.state.currentItemFontFamily,
          textAlign: parentCenterPosition
            ? "center"
            : this.state.currentItemTextAlign,
          verticalAlign: parentCenterPosition
            ? "middle"
            : DEFAULT_VERTICAL_ALIGN,
          page: this.state.currentPage,
          lessonId: lessonId || "",
          link: null,
          index: null,
          containerId: shouldBindToContainer ? container?.id : undefined,
          groupIds: container?.groupIds ?? [],
          lineHeight,
          angle: container?.angle ?? (0 as Radians),
          frameId: topLayerFrame ? topLayerFrame.id : null,
          locked: false,
          isVisible: true,
        });

    if (!existingTextElement && shouldBindToContainer && container) {
      mutateElement(container, {
        boundElements: (container.boundElements || []).concat({
          type: "text",
          id: element.id,
        }),
      });
    }
    this.setState({ editingElement: element });

    if (!existingTextElement) {
      if (container && shouldBindToContainer) {
        const containerIndex = this.scene.getElementIndex(container.id);
        this.scene.insertElementAtIndex(element, containerIndex + 1);
      } else {
        this.scene.insertElement(element);
      }
    }

    if (autoEdit || existingTextElement || container) {
      this.handleTextWysiwyg(element, {
        isExistingElement: !!existingTextElement,
      });
    } else {
      this.setState({
        draggingElement: element,
        multiElement: null,
      });
    }
  };

  private closeButtonComponent = (
    <ToolButton
      key="close-modal"
      type={ToolButtonEnum.BUTTON}
      aria-label="close"
      className={`close-icon`}
      icon={close("black")}
      size="small"
      onClick={() => {
        this.togglekeyboardShortcut(true);
        this.setState({
          formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
          updatingFormulaElement: {
            id: "",
            width: 0,
            height: 0,
          },
          showMathInputAndKeyboard: false,
        });
      }}
    ></ToolButton>
  );

  private handleCanvasDoubleClick = (
    event: React.MouseEvent<HTMLCanvasElement>,
  ) => {
    // case: double-clicking with arrow/line tool selected would both create
    // text and enter multiElement mode
    if (this.state.multiElement) {
      return;
    }
    // we should only be able to double click when mode is selection
    if (this.state.activeTool.type !== "selection") {
      return;
    }

    let isFormulaEditingFlag: boolean = false;
    let isMermaidDiagramEditingFlag: boolean = false;
    const isTextWithStylesEditingFlag: boolean = false;
    const selectedElements = this.scene.getSelectedElements(this.state);
    if (selectedElements.length === 1 && isLinearElement(selectedElements[0])) {
      if (
        event[KEYS.CTRL_OR_CMD] &&
        (!this.state.editingLinearElement ||
          this.state.editingLinearElement.elementId !== selectedElements[0].id)
      ) {
        this.store.shouldCaptureIncrement();
        this.setState({
          editingLinearElement: new LinearElementEditor(
            selectedElements[0],
            this.scene,
          ),
        });
        return;
      }
    }
    resetCursor(this.canvas);

    if (this.canvas) {
      const { x, y } = viewportCoordsToSceneCoords(
        { clientX: event.clientX, clientY: event.clientY },
        this.state,
      );

      // for open modal for formula
      this.scene.getElements().forEach((element) => {
        if (!this.canvas) {
          return;
        }
        if (isFormulaElement(element)) {
          const imageX = element.x;
          const imageY = element.y;
          if (
            x >= imageX &&
            x <= imageX + element.width &&
            y >= imageY &&
            y <= imageY + element.height
          ) {
            isFormulaEditingFlag = true;
            this.togglekeyboardShortcut(false);
            this.setState(
              {
                showMathInputAndKeyboard: true,
                formulaValue: element.formulaString || "",
                updatingFormulaElement: {
                  id: element.id,
                  width: element.width,
                  height: element.height,
                },
              },
              () => {
                this.actionManager.executeAction(actionFinalize);
                this.setState(
                  {
                    selectedElementIds: {
                      [element.id]: true,
                    },
                  },
                  () => {
                    const mainFormulaContainer = this.mathfieldRef.current?.shadowRoot?.querySelector<HTMLElement>(
                      'span[part="container"].ML__container',
                    );
                    this.mathfieldRef.current?.shadowRoot?.querySelector<HTMLElement>(
                      'span[part="content"].ML__content',
                    );
                    if (this.mathfieldRef?.current) {
                      this.mathfieldRef.current.mathVirtualKeyboardPolicy =
                        "sandboxed";
                    }

                    if (mainFormulaContainer) {
                      const saveButton = document.createElement("button");
                      saveButton.innerText = "Save";
                      saveButton.style.cssText +=
                        "position: absolute;bottom: 15px;left: 50%;width: fit-content;color: rgb(255, 255, 255);background-color: rgb(73, 75, 131);transform: translate(-50%, 0);border: none;border-radius: 0.25rem;padding: 6px 15px;font-size: 16px;font-family: 'Karla';cursor: pointer";

                      saveButton.addEventListener("click", () => {
                        this.renderFormula(
                          this.mathfieldRef,
                          this.mathFormulaValueRef.current,
                        );
                      });
                      mainFormulaContainer.appendChild(saveButton);
                      const closeButtonWrapper = document.createElement("div");
                      closeButtonWrapper.style.cssText =
                        "height: 24px;width: 23px;display: flex;position: absolute;right: 10px;top: 15px;align-items: center;justify-content: center;border-radius: 0.25rem; cursor: pointer;";
                      mainFormulaContainer.appendChild(closeButtonWrapper);
                      ReactDOM.render(
                        this.closeButtonComponent,
                        closeButtonWrapper,
                        () => {
                          const closeButton = closeButtonWrapper.querySelector<HTMLElement>(
                            'button[aria-label="close"][type="button"]',
                          );
                          const buttonWrapper = closeButtonWrapper.querySelector<HTMLElement>(
                            'button[aria-label="close"][type="button"] div[class="ToolIcon__icon"]',
                          );
                          if (closeButton && buttonWrapper) {
                            closeButtonWrapper.addEventListener(
                              "mouseenter",
                              () => {
                                closeButtonWrapper.style.backgroundColor =
                                  "#ced4da";
                              },
                            );
                            closeButtonWrapper.addEventListener(
                              "mouseleave",
                              () => {
                                closeButtonWrapper.style.backgroundColor =
                                  "transparent";
                              },
                            );
                            closeButton.style.cssText +=
                              "border: none;margin: 0px;padding: 5px 7px;font-size: 0.3em;border-radius: 3px;background: transparent;";
                            buttonWrapper.style.cssText +=
                              "width: 8px;height: 13px;background: transparent;display: flex;justify-content: center;align-items: center;transform: scale(1.5);";
                          }
                        },
                      );
                    }
                  },
                );
              },
            );
          }
        }
        if (isMermaidDiagramElement(element)) {
          const imageX = element.x;
          const imageY = element.y;
          if (
            x >= imageX &&
            x <= imageX + element.width &&
            y >= imageY &&
            y <= imageY + element.height
          ) {
            isMermaidDiagramEditingFlag = true;
            this.setState(
              {
                ...this.state,
                EditMermaidDiagramDialog: {
                  open: true,
                  syntax: element?.syntax ?? "",
                  id: element.id,
                  x: element.x,
                  y: element.y,
                  fileId: element.fileId,
                },
              },
              () => {
                this.togglekeyboardShortcut(false);
              },
            );
          }
        }
        if (isTextWithStylesElement(element)) {
          if (
            this.state.editableSelectedElement.selectedElementIds[element.id]
          ) {
            this.setState((prevState) => {
              const updatedSelectedElementIds = {
                ...prevState.editableSelectedElement.selectedElementIds,
              };
              delete updatedSelectedElementIds[element.id]; // Remove the element's id

              return {
                editableSelectedElement: {
                  ...prevState.editableSelectedElement,
                  isSelected: false,
                  elementId: element.id,
                  selectedElementIds: updatedSelectedElementIds,
                },
              };
            });
            const imageX = element.x;
            const imageY = element.y;
            if (
              x >= imageX &&
              x <= imageX + element.width &&
              y >= imageY &&
              y <= imageY + element.height
            ) {
              mutateElement(element, { isDeleted: true });
              // This is for viewing text-editor content as chat.
              // If div has the text-end class, then remove that div and add the style into the p tag.
              const htmlString = element.textString.replace(
                /<div[^>]*class="[^"]*text-end[^"]*"[^>]*>\s*<p([^>]*)>(.*?)<\/p>\s*<\/div>/g,
                '<p$1 style="text-align: right"><mark style="background-color: #5b5e9f33;">$2</mark></p>',
              );

              this.setState(
                {
                  textEditor: {
                    ...this.state.textEditor,
                    open: true,
                    sceneX: imageX,
                    sceneY: imageY - 103, // 103 is the height of the text editor header with spacing
                    value: htmlString,
                    width: element.width,
                    height: element.height,
                    resize: element.textEditor?.resize ?? false,
                    resizeHeight: element.textEditor?.resizeHeight ?? 0,
                    resizeWidth: element.textEditor?.resizeWidth ?? 0,
                  },
                  updatingTextElement: element,
                  editableSelectedElement: {
                    ...this.state.editableSelectedElement,
                    isSelected: false,
                    elementId: "",
                  },
                },
                () => {
                  this.actionManager.executeAction(actionFinalize);
                  this.togglekeyboardShortcut(false);
                  this.setState({
                    selectedElementIds: {
                      [element.id]: true,
                    },
                    hasRenderedTextWithStyles: false,
                  });
                },
              );
            }
          }
        }
      });
    }

    resetCursor(this.canvas);

    let { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
      event,
      this.state,
    );

    const selectedGroupIds = getSelectedGroupIds(this.state);

    if (selectedGroupIds.length > 0) {
      const hitElement = this.getElementAtPosition(sceneX, sceneY);

      const selectedGroupId =
        hitElement &&
        getSelectedGroupIdForElement(hitElement, this.state.selectedGroupIds);

      if (selectedGroupId) {
        this.store.shouldCaptureIncrement();
        this.setState((prevState) => ({
          ...prevState,
          ...selectGroupsForSelectedElements(
            {
              editingGroupId: selectedGroupId,
              selectedElementIds: { [hitElement!.id]: true },
            },
            this.scene.getNonDeletedElements(),
            prevState,
            this as any,
          ),
        }));
        return;
      }
    }

    resetCursor(this.canvas);
    if (
      !event[KEYS.CTRL_OR_CMD] &&
      !this.state.viewModeEnabled &&
      selectedElements.length &&
      selectedElements[0]?.type !== "textWithStyles"
    ) {
      const hitElement = this.getElementAtPosition(sceneX, sceneY);

      if (isIframeLikeElement(hitElement)) {
        this.setState({
          activeEmbeddable: { element: hitElement, state: "active" },
        });
        return;
      }
      const container = this.getTextBindableContainerAtPosition(sceneX, sceneY);
      if (container) {
        if (
          hasBoundTextElement(container) ||
          !isTransparent(container.backgroundColor) ||
          hitElementItself({
            x: sceneX,
            y: sceneY,
            element: container,
            shape: getElementShape(
              container,
              this.scene.getNonDeletedElementsMap(),
            ),
            threshold: this.getElementHitThreshold(),
          })
        ) {
          const midPoint = getContainerCenter(
            container,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          );

          sceneX = midPoint.x;
          sceneY = midPoint.y;
        }
      }
      this.startTextEditing({
        sceneX,
        sceneY,
        insertAtParentCenter: !event.altKey,
        container,
      });
    }

    if (
      !event[KEYS.CTRL_OR_CMD] &&
      !this.state.viewModeEnabled &&
      !isFormulaEditingFlag &&
      !isMermaidDiagramEditingFlag &&
      !isTextWithStylesEditingFlag &&
      !this.state.textEditor.open &&
      selectedElements.length === 0
    ) {
      this.setState({
        textEditor: {
          ...this.state.textEditor,
          open: true,
          sceneX,
          sceneY: sceneY - 103, // 103 is the height of the text editor header with spacing
          value: "",
          width: 0,
          height: 0,
          resizeHeight: 0,
          resizeWidth: 0,
          resize: false,
        },
      });

      this.togglekeyboardShortcut(false);
      if (!this.state.elementLocked) {
        this.setActiveTool({ type: "selection" });
      }
    }
  };

  private getElementLinkAtPosition = (
    scenePointer: Readonly<{ x: number; y: number }>,
    hitElement: NonDeletedExcalidrawElement | null,
  ): ExcalidrawElement | undefined => {
    // Reversing so we traverse the elements in decreasing order
    // of z-index
    const elements = this.scene.getNonDeletedElements().slice().reverse();
    let hitElementIndex = Infinity;

    return elements.find((element, index) => {
      if (hitElement && element.id === hitElement.id) {
        hitElementIndex = index;
      }
      return (
        element.link &&
        index <= hitElementIndex &&
        isPointHittingLink(
          element,
          this.state,
          [scenePointer.x, scenePointer.y],
          this.isMobile,
        )
      );
    });
  };

  private isIframeLikeElementCenter(
    el: ExcalidrawIframeLikeElement | null,
    event: React.PointerEvent<HTMLElement> | PointerEvent,
    sceneX: number,
    sceneY: number,
  ) {
    return (
      el &&
      !event.altKey &&
      !event.shiftKey &&
      !event.metaKey &&
      !event.ctrlKey &&
      (this.state.activeEmbeddable?.element !== el ||
        this.state.activeEmbeddable?.state === "hover" ||
        !this.state.activeEmbeddable) &&
      sceneX >= el.x + el.width / 3 &&
      sceneX <= el.x + (2 * el.width) / 3 &&
      sceneY >= el.y + el.height / 3 &&
      sceneY <= el.y + (2 * el.height) / 3
    );
  }

  private handleCanvasPointerMove = (
    event: React.PointerEvent<HTMLCanvasElement>,
  ) => {
    this.savePointer(event.clientX, event.clientY, this.state.cursorButton);
    this.lastPointerMoveEvent = event.nativeEvent;
    if (gesture.pointers.has(event.pointerId)) {
      gesture.pointers.set(event.pointerId, {
        x: event.clientX,
        y: event.clientY,
      });
    }

    const initialScale = gesture.initialScale;
    if (
      gesture.pointers.size === 2 &&
      gesture.lastCenter &&
      initialScale &&
      gesture.initialDistance
    ) {
      const center = getCenter(gesture.pointers);
      const deltaX = center.x - gesture.lastCenter.x;
      const deltaY = center.y - gesture.lastCenter.y;
      gesture.lastCenter = center;

      const distance = getDistance(Array.from(gesture.pointers.values()));
      const scaleFactor =
        this.state.activeTool.type === "freedraw"
          ? 1
          : distance / gesture.initialDistance;

      this.setState(({ zoom, scrollX, scrollY, offsetLeft, offsetTop }) => ({
        scrollX: scrollX + deltaX / zoom.value,
        scrollY: scrollY + deltaY / zoom.value,
        zoom: getNewZoom(
          getNormalizedZoom(initialScale * scaleFactor),
          zoom,
          { left: offsetLeft, top: offsetTop },
          center,
        ),
        shouldCacheIgnoreZoom: true,
      }));
      this.resetShouldCacheIgnoreZoomDebounced();
    } else {
      gesture.lastCenter = gesture.initialDistance = gesture.initialScale = null;
    }

    if (isHoldingSpace || isPanning || isDraggingScrollBar) {
      return;
    }

    const isPointerOverScrollBars = isOverScrollBars(
      currentScrollBars,
      event.clientX - this.state.offsetLeft,
      event.clientY - this.state.offsetTop,
    );
    const isOverScrollBar = isPointerOverScrollBars.isOverEither;

    if (
      !this.state.draggingElement &&
      !this.state.multiElement &&
      !this.state.selectedElementsAreBeingDragged
    ) {
      if (isOverScrollBar) {
        resetCursor(this.canvas);
      } else {
        setCursorForShape(this.canvas, this.state);
      }
    }

    const scenePointer = viewportCoordsToSceneCoords(event, this.state);
    const { x: scenePointerX, y: scenePointerY } = scenePointer;

    if (
      !this.state.draggingElement &&
      isActiveToolNonLinearSnappable(this.state.activeTool.type)
    ) {
      const { originOffset, snapLines } = getSnapLinesAtPointer(
        this.scene.getNonDeletedElements(),
        this.state,
        {
          x: scenePointerX,
          y: scenePointerY,
        },
        event,
        this.scene.getNonDeletedElementsMap(),
      );

      this.setState((prevState) => {
        const nextSnapLines = updateStable(prevState.snapLines, snapLines);
        const nextOriginOffset = prevState.originSnapOffset
          ? updateStable(prevState.originSnapOffset, originOffset)
          : originOffset;

        if (prevState.snapLines === nextSnapLines) {
          return null;
        }
        return {
          snapLines: nextSnapLines,
          originSnapOffset: nextOriginOffset,
        };
      });
    } else if (
      !this.state.draggingElement &&
      this.state.selectedElementsAreBeingDragged
    ) {
      this.setState((prevState) => {
        if (prevState?.snapLines?.length) {
          return {
            snapLines: [],
          };
        }
        return null;
      });
    }

    if (
      this.state.editingLinearElement &&
      !this.state.editingLinearElement.isDragging
    ) {
      const editingLinearElement = LinearElementEditor.handlePointerMove(
        event,
        scenePointerX,
        scenePointerY,
        this.state,
        this.scene.getNonDeletedElementsMap(),
      );

      if (
        editingLinearElement &&
        editingLinearElement !== this.state.editingLinearElement
      ) {
        // Since we are reading from previous state which is not possible with
        // automatic batching in React 18 hence using flush sync to synchronously
        // update the state. Check https://github.com/excalidraw/excalidraw/pull/5508 for more details.
        flushSync(() => {
          this.setState({
            editingLinearElement,
          });
        });
      }
      if (editingLinearElement?.lastUncommittedPoint != null) {
        this.maybeSuggestBindingAtCursor(scenePointer);
      } else {
        // causes stack overflow if not sync
        flushSync(() => {
          this.setState({ suggestedBindings: [] });
        });
      }
    }

    if (isBindingElementType(this.state.activeTool.type)) {
      // Hovering with a selected tool or creating new linear element via click
      // and point
      const { draggingElement } = this.state;
      if (isBindingElement(draggingElement, false)) {
        this.maybeSuggestBindingsForLinearElementAtCoords(
          draggingElement,
          [scenePointer],
          this.state.startBoundElement,
        );
      } else {
        this.maybeSuggestBindingAtCursor(scenePointer);
      }
    }

    if (this.state.multiElement) {
      const { multiElement } = this.state;
      const { x: rx, y: ry } = multiElement;

      const { points, lastCommittedPoint } = multiElement;
      const lastPoint = points[points.length - 1];

      setCursorForShape(this.canvas, this.state);

      if (lastPoint === lastCommittedPoint) {
        // if we haven't yet created a temp point and we're beyond commit-zone
        // threshold, add a point
        if (
          pointDistance(
            pointFrom(scenePointerX - rx, scenePointerY - ry),
            lastPoint as any,
          ) >= LINE_CONFIRM_THRESHOLD
        ) {
          mutateElement(
            multiElement,
            {
              points: [
                ...points,
                pointFrom<LocalPoint>(scenePointerX - rx, scenePointerY - ry),
              ],
            },
            false,
          );
        } else {
          setCursor(this.canvas, CURSOR_TYPE.POINTER);
          // in this branch, we're inside the commit zone, and no uncommitted
          // point exists. Thus do nothing (don't add/remove points).
        }
      } else if (
        points.length > 2 &&
        lastCommittedPoint &&
        pointDistance(
          pointFrom(scenePointerX - rx, scenePointerY - ry),
          lastCommittedPoint as any,
        ) < LINE_CONFIRM_THRESHOLD
      ) {
        setCursor(this.canvas, CURSOR_TYPE.POINTER);
        mutateElement(
          multiElement,
          {
            points: points.slice(0, -1),
          },
          false,
        );
      } else {
        const [gridX, gridY] = getGridPoint(
          scenePointerX,
          scenePointerY,
          event[KEYS.CTRL_OR_CMD] ? null : this.state.gridSize,
        );

        const [
          lastCommittedX,
          lastCommittedY,
        ] = multiElement?.lastCommittedPoint ?? [0, 0];

        let dxFromLastCommitted = gridX - rx - lastCommittedX;
        let dyFromLastCommitted = gridY - ry - lastCommittedY;

        if (shouldRotateWithDiscreteAngle(event)) {
          ({
            width: dxFromLastCommitted,
            height: dyFromLastCommitted,
          } = getLockedLinearCursorAlignSize(
            // actual coordinate of the last committed point
            lastCommittedX + rx,
            lastCommittedY + ry,
            // cursor-grid coordinate
            gridX,
            gridY,
          ));
        }
        if (isPathALoop(points, this.state.zoom.value)) {
          setCursor(this.canvas, CURSOR_TYPE.POINTER);
        }
        // update last uncommitted point
        mutateElement(
          multiElement,
          {
            points: [
              ...points.slice(0, -1),
              pointFrom<LocalPoint>(
                lastCommittedX + dxFromLastCommitted,
                lastCommittedY + dyFromLastCommitted,
              ),
            ],
          },
          false,
        );
      }

      return;
    }

    const hasDeselectedButton = Boolean(event.buttons);
    if (
      hasDeselectedButton ||
      (this.state.activeTool.type !== "selection" &&
        this.state.activeTool.type !== "text")
    ) {
      return;
    }

    const elements = this.scene.getNonDeletedElements();
    const selectedElements = this.scene.getSelectedElements(this.state);

    if (
      selectedElements.length === 1 &&
      !isOverScrollBar &&
      !this.state.editingLinearElement
    ) {
      // for linear elements, we'd like to prioritize point dragging over edge resizing
      // therefore, we update and check hovered point index first
      if (this.state.selectedLinearElement) {
        this.handleHoverSelectedLinearElement(
          this.state.selectedLinearElement,
          scenePointerX,
          scenePointerY,
        );
      }
      if (
        !this.state.selectedLinearElement ||
        this.state.selectedLinearElement.hoverPointIndex === -1
      ) {
        const elementWithTransformHandleType = getElementWithTransformHandleType(
          elements,
          this.state,
          scenePointerX,
          scenePointerY,
          this.state.zoom,
          event.pointerType,
          this.scene.getNonDeletedElementsMap(),
        );

        if (
          elementWithTransformHandleType &&
          elementWithTransformHandleType.transformHandleType
        ) {
          setCursor(
            this.canvas,
            getCursorForResizingElement(elementWithTransformHandleType),
          );
          return;
        }
      }
    } else if (selectedElements.length > 1 && !isOverScrollBar) {
      const transformHandleType = getTransformHandleTypeFromCoords(
        getCommonBounds(selectedElements),
        scenePointerX,
        scenePointerY,
        this.state.zoom,
        event.pointerType,
      );
      if (transformHandleType) {
        setCursor(
          this.canvas,
          getCursorForResizingElement({
            transformHandleType,
          }),
        );
        return;
      }
    }

    const hitElement = this.getElementAtPosition(
      scenePointer.x,
      scenePointer.y,
    );
    this.hitLinkElement = this.getElementLinkAtPosition(
      scenePointer,
      hitElement,
    );

    if (
      this.hitLinkElement &&
      !this.state.selectedElementIds[this.hitLinkElement.id]
    ) {
      setCursor(this.canvas, CURSOR_TYPE.POINTER);
    }
    if (this.state.activeTool.type === "text") {
      setCursor(
        this.canvas,
        isTextElement(hitElement) ? CURSOR_TYPE.TEXT : CURSOR_TYPE.CROSSHAIR,
      );
    } else if (
      this.state.viewModeEnabled &&
      this.state.activeTool.type !== "laser"
    ) {
      setCursor(this.canvas, CURSOR_TYPE.GRAB);
    } else if (isOverScrollBar) {
      setCursor(this.canvas, CURSOR_TYPE.AUTO);
    } else if (this.state.selectedLinearElement) {
      this.handleHoverSelectedLinearElement(
        this.state.selectedLinearElement,
        scenePointerX,
        scenePointerY,
      );
    } else if (
      // if using cmd/ctrl, we're not dragging
      !event[KEYS.CTRL_OR_CMD] &&
      (hitElement ||
        this.isHittingCommonBoundingBoxOfSelectedElements(
          scenePointer,
          selectedElements,
        )) &&
      !hitElement?.locked
    ) {
      if (
        hitElement &&
        isIframeLikeElement(hitElement) &&
        this.isIframeLikeElementCenter(
          hitElement,
          event,
          scenePointerX,
          scenePointerY,
        )
      ) {
        setCursor(this.canvas, CURSOR_TYPE.POINTER);
        this.setState({
          activeEmbeddable: { element: hitElement, state: "hover" },
        });
      } else {
        setCursor(this.canvas, CURSOR_TYPE.MOVE);
        if (this.state.activeEmbeddable?.state === "hover") {
          this.setState({ activeEmbeddable: null });
        }
      }
    } else {
      setCursor(this.canvas, CURSOR_TYPE.AUTO);
    }
  };

  // set touch moving for mobile context menu
  private handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    invalidateContextMenu = true;
  };

  private handleCanvasPointerDown = (
    event: React.PointerEvent<HTMLElement>,
  ) => {
    // remove any active selection when we start to interact with canvas
    // (mainly, we care about removing selection outside the component which
    //  would prevent our copy handling otherwise)
    const selection = document.getSelection();
    if (selection?.anchorNode) {
      selection.removeAllRanges();
    }

    this.maybeOpenContextMenuAfterPointerDownOnTouchDevices(event);
    if (
      isTextContentAvailable(this.state?.textEditor?.value) &&
      this.state?.textEditor?.open
    ) {
      const isTextSelection = localStorage.getItem("textSelection");
      // if text is selected then render the text with styles delay of 20ms for texteditor to update the state
      if (
        !this.state.hasRenderedTextWithStyles &&
        !this.state.editableSelectedElement.isSelected
      ) {
        if (isTextSelection === "true") {
          //wait till the texteditor state is updated
          _.delay(() => {
            this.renderTextWithStyles({
              textString: this.state.textEditor.value,
            });
          }, 20);
          this.setState({ hasRenderedTextWithStyles: true });
        } else {
          this.renderTextWithStyles({
            textString: this.state.textEditor.value,
          });
          this.setState({ hasRenderedTextWithStyles: true });
        }
      }
    } else {
      if (this.state.textEditor.open) {
        this.store.shouldUpdateSnapshot();
      }
      this.setState({
        textEditor: {
          ...this.state.textEditor,
          open: false,
          sceneX: 0,
          sceneY: 0,
          value: "",
          width: 0,
          height: 0,
          resize: false,
          resizeWidth: 0,
          resizeHeight: 0,
        },
        hasRenderedTextWithStyles: false, // reset the flag when there is no content
      });
      this.togglekeyboardShortcut(true);
    }

    if (this.state.snapLines) {
      this.setAppState({ snapLines: [] });
    }

    // this.maybeOpenContextMenuAfterPointerDownOnTouchDevices(event.nativeEvent);
    this.maybeCleanupAfterMissingPointerUp(event.nativeEvent);

    if (isPanning) {
      return;
    }

    this.setState({
      lastPointerDownWith: event.pointerType,
      cursorButton: "down",
    });
    this.savePointer(event.clientX, event.clientY, "down");

    if (this.handleCanvasPanUsingWheelOrSpaceDrag(event)) {
      return;
    }

    // only handle left mouse button or touch
    if (
      event.button !== POINTER_BUTTON.MAIN &&
      event.button !== POINTER_BUTTON.TOUCH
    ) {
      return;
    }

    this.updateGestureOnPointerDown(event);

    // if dragging element is freedraw and another pointerdown event occurs
    // a second finger is on the screen
    // discard the freedraw element if it is very short because it is likely
    // just a spike, otherwise finalize the freedraw element when the second
    // finger is lifted
    if (
      event.pointerType === "touch" &&
      this.state.draggingElement &&
      this.state.draggingElement.type === "freedraw"
    ) {
      const element = this.state.draggingElement as ExcalidrawFreeDrawElement;
      this.updateScene({
        ...(element.points.length < 10
          ? {
              elements: this.scene
                .getElementsIncludingDeleted()
                .filter((el) => el.id !== element.id),
            }
          : {}),
        appState: {
          draggingElement: null,
          editingElement: null,
          startBoundElement: null,
          suggestedBindings: [],
          selectedElementIds: makeNextSelectedElementIds(
            Object.keys(this.state.selectedElementIds)
              .filter((key) => key !== element.id)
              .reduce((obj: { [id: string]: boolean }, key) => {
                obj[key] = this.state.selectedElementIds[key];
                return obj;
              }, {}),
            this.state,
          ),
        },
        storeAction: StoreAction.UPDATE,
      });
      return;
    }

    // don't select while panning
    if (gesture.pointers.size > 1) {
      return;
    }

    this.lastPointerDownEvent = event;

    this.setState({
      lastPointerDownWith: event.pointerType,
      cursorButton: "down",
    });
    this.savePointer(event.clientX, event.clientY, "down");

    // State for the duration of a pointer interaction, which starts with a
    // pointerDown event, ends with a pointerUp event (or another pointerDown)
    const pointerDownState = this.initialPointerDownState(event);

    this.setState({
      selectedElementsAreBeingDragged: false,
    });

    if (this.handleDraggingScrollBar(event, pointerDownState)) {
      return;
    }

    this.clearSelectionIfNotUsingSelection();
    this.updateBindingEnabledOnPointerMove(event);

    if (this.handleSelectionOnPointerDown(event, pointerDownState)) {
      return;
    }
    const allowOnPointerDown =
      event.pointerType !== "touch" ||
      this.state.activeTool.type === "selection" ||
      this.state.activeTool.type === "text" ||
      this.state.activeTool.type === "image";

    if (!allowOnPointerDown) {
      return;
    }

    if (this.state.activeTool.type === "text") {
      this.handleTextOnPointerDown(event, pointerDownState);
      return;
    } else if (
      this.state.activeTool.type === "arrow" ||
      this.state.activeTool.type === "line"
    ) {
      this.handleLinearElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
    } else if (this.state.activeTool.type === "image") {
      // reset image preview on pointerdown
      setCursor(this.canvas, CURSOR_TYPE.CROSSHAIR);

      if (!this.state.pendingImageElement) {
        return;
      }

      this.setState({
        draggingElement: this.state.pendingImageElement,
        editingElement: this.state.pendingImageElement,
        pendingImageElement: null,
        multiElement: null,
      });

      const { x, y } = viewportCoordsToSceneCoords(event, this.state);
      const frame = this.getTopLayerFrameAtSceneCoords({ x, y });

      mutateElement(this.state.pendingImageElement, {
        x,
        y,
        frameId: frame ? frame.id : null,
      });
    } else if (this.state.activeTool.type === "freedraw") {
      this.handleFreeDrawElementOnPointerDown(
        event,
        this.state.activeTool.type,
        pointerDownState,
      );
    } else if (
      this.state.activeTool.type === "formula" ||
      this.state.activeTool.type === "compress"
    ) {
      setCursor(this.canvas, CURSOR_TYPE.CROSSHAIR);

      // if (!this.state.pendingImageElement) {
      //   return;
      // }
    } else if (
      this.state.activeTool.type === TOOL_TYPE.frame ||
      this.state.activeTool.type === TOOL_TYPE.magicframe
    ) {
      this.createFrameElementOnPointerDown(
        pointerDownState,
        this.state.activeTool.type,
      );
    } else if (this.state.activeTool?.type === "laser") {
      this.laserTrails.startPath(
        pointerDownState.lastCoords.x,
        pointerDownState.lastCoords.y,
      );
    } else {
      this.createGenericElementOnPointerDown(
        this.state.activeTool.type as ExcalidrawGenericElement["type"],
        pointerDownState,
      );
    }

    const onPointerMove = this.onPointerMoveFromPointerDownHandler(
      pointerDownState,
    );

    const onPointerUp = this.onPointerUpFromPointerDownHandler(
      pointerDownState,
    );

    const onKeyDown = this.onKeyDownFromPointerDownHandler(pointerDownState);
    const onKeyUp = this.onKeyUpFromPointerDownHandler(pointerDownState);
    lastPointerUp = onPointerUp;
    if (
      !this.state.viewModeEnabled ||
      this.state.activeTool?.type === "laser"
    ) {
      window.addEventListener(EVENT.POINTER_MOVE, onPointerMove);
      window.addEventListener(EVENT.POINTER_UP, onPointerUp);
      window.addEventListener(EVENT.KEYDOWN, onKeyDown);
      window.addEventListener(EVENT.KEYUP, onKeyUp);
      pointerDownState.eventListeners.onMove = onPointerMove;
      pointerDownState.eventListeners.onUp = onPointerUp;
      pointerDownState.eventListeners.onKeyUp = onKeyUp;
      pointerDownState.eventListeners.onKeyDown = onKeyDown;
    }
  };

  private maybeOpenContextMenuAfterPointerDownOnTouchDevices = (
    event: React.PointerEvent<HTMLElement>,
  ): void => {
    // deal with opening context menu on touch devices
    if (event.pointerType === "touch") {
      invalidateContextMenu = false;

      if (touchTimeout) {
        // If there's already a touchTimeout, this means that there's another
        // touch down and we are doing another touch, so we shouldn't open the
        // context menu.
        invalidateContextMenu = true;
      } else {
        // open the context menu with the first touch's clientX and clientY
        // if the touch is not moving
        touchTimeout = window.setTimeout(() => {
          touchTimeout = 0;
          if (!invalidateContextMenu) {
            this.handleCanvasContextMenu(event);
          }
        }, TOUCH_CTX_MENU_TIMEOUT);
      }
    }
  };

  private maybeCleanupAfterMissingPointerUp(event: PointerEvent | null): void {
    if (lastPointerUp !== null) {
      // Unfortunately, sometimes we don't get a pointerup after a pointerdown,
      // this can happen when a contextual menu or alert is triggered. In order to avoid
      // being in a weird state, we clean up on the next pointerdown
      lastPointerUp(event);
    }
  }

  // Returns whether the event is a panning
  private handleCanvasPanUsingWheelOrSpaceDrag = (
    event: React.PointerEvent<HTMLElement> | MouseEvent,
  ): boolean => {
    if (
      !(
        gesture.pointers.size === 0 &&
        (event.button === POINTER_BUTTON.WHEEL ||
          (event.button === POINTER_BUTTON.MAIN && isHoldingSpace) ||
          (this.state.viewModeEnabled &&
            this.state.activeTool.type !== "laser"))
      )
    ) {
      return false;
    }
    isPanning = true;

    let nextPastePrevented = false;
    const isLinux = /Linux/.test(window.navigator.platform);

    setCursor(this.canvas, CURSOR_TYPE.GRABBING);
    let { clientX: lastX, clientY: lastY } = event;
    const onPointerMove = withBatchedUpdates((event: PointerEvent) => {
      const deltaX = lastX - event.clientX;
      const deltaY = lastY - event.clientY;
      lastX = event.clientX;
      lastY = event.clientY;

      /*
       * Prevent paste event if we move while middle clicking on Linux.
       * See issue #1383.
       */
      if (
        isLinux &&
        !nextPastePrevented &&
        (Math.abs(deltaX) > 1 || Math.abs(deltaY) > 1)
      ) {
        nextPastePrevented = true;

        /* Prevent the next paste event */
        const preventNextPaste = (event: ClipboardEvent) => {
          document.body.removeEventListener(EVENT.PASTE, preventNextPaste);
          event.stopPropagation();
        };

        /*
         * Reenable next paste in case of disabled middle click paste for
         * any reason:
         * - rigth click paste
         * - empty clipboard
         */
        const enableNextPaste = () => {
          setTimeout(() => {
            document.body.removeEventListener(EVENT.PASTE, preventNextPaste);
            window.removeEventListener(EVENT.POINTER_UP, enableNextPaste);
          }, 100);
        };

        document.body.addEventListener(EVENT.PASTE, preventNextPaste);
        window.addEventListener(EVENT.POINTER_UP, enableNextPaste);
      }

      this.setState({
        scrollX: this.state.scrollX - deltaX / this.state.zoom.value,
        scrollY: this.state.scrollY - deltaY / this.state.zoom.value,
      });
    });
    const teardown = withBatchedUpdates(
      (lastPointerUp = () => {
        lastPointerUp = null;
        isPanning = false;
        if (!isHoldingSpace) {
          if (
            this.state.viewModeEnabled &&
            this.state.activeTool.type !== "laser"
          ) {
            setCursor(this.canvas, CURSOR_TYPE.GRAB);
          } else {
            setCursorForShape(this.canvas, this.state);
          }
        }
        this.setState({
          cursorButton: "up",
        });
        this.savePointer(event.clientX, event.clientY, "up");
        window.removeEventListener(EVENT.POINTER_MOVE, onPointerMove);
        window.removeEventListener(EVENT.POINTER_UP, teardown);
        window.removeEventListener(EVENT.BLUR, teardown);
      }),
    );
    window.addEventListener(EVENT.BLUR, teardown);
    window.addEventListener(EVENT.POINTER_MOVE, onPointerMove, {
      passive: true,
    });
    window.addEventListener(EVENT.POINTER_UP, teardown);
    return true;
  };

  private updateGestureOnPointerDown(
    event: React.PointerEvent<HTMLElement>,
  ): void {
    gesture.pointers.set(event.pointerId, {
      x: event.clientX,
      y: event.clientY,
    });

    if (gesture.pointers.size === 2) {
      gesture.lastCenter = getCenter(gesture.pointers);
      gesture.initialScale = this.state.zoom.value;
      gesture.initialDistance = getDistance(
        Array.from(gesture.pointers.values()),
      );
    }
  }

  private initialPointerDownState(
    event: React.PointerEvent<HTMLElement>,
  ): PointerDownState {
    const origin = viewportCoordsToSceneCoords(event, this.state);

    const selectedElements = getSelectedElements(
      this.scene.getElements(),
      this.state,
    );
    const [minX, minY, maxX, maxY] = getCommonBounds(selectedElements);

    return {
      origin,
      withCmdOrCtrl: event[KEYS.CTRL_OR_CMD],
      originInGrid: tupleToCoors(
        getGridPoint(origin.x, origin.y, this.state.gridSize),
      ),
      scrollbars: isOverScrollBars(
        currentScrollBars,
        event.clientX - this.state.offsetLeft,
        event.clientY - this.state.offsetTop,
      ),
      // we need to duplicate because we'll be updating this state
      lastCoords: { ...origin },
      originalElements: this.scene
        .getNonDeletedElements()
        .reduce((acc, element) => {
          acc.set(element.id, deepCopyElement(element));
          return acc;
        }, new Map() as PointerDownState["originalElements"]),
      resize: {
        handleType: false,
        isResizing: false,
        offset: { x: 0, y: 0 },
        arrowDirection: "origin",
        center: { x: (maxX + minX) / 2, y: (maxY + minY) / 2 },
      },
      hit: {
        element: null,
        allHitElements: [],
        wasAddedToSelection: false,
        hasBeenDuplicated: false,
        hasHitCommonBoundingBoxOfSelectedElements: this.isHittingCommonBoundingBoxOfSelectedElements(
          origin,
          selectedElements,
        ),
      },
      drag: {
        hasOccurred: false,
        offset: null,
      },
      eventListeners: {
        onMove: null,
        onUp: null,
        onKeyUp: null,
        onKeyDown: null,
      },
      boxSelection: {
        hasOccurred: false,
      },
    };
  }

  // Returns whether the event is a dragging a scrollbar
  private handleDraggingScrollBar(
    event: React.PointerEvent<HTMLElement>,
    pointerDownState: PointerDownState,
  ): boolean {
    if (
      !(pointerDownState.scrollbars.isOverEither && !this.state.multiElement)
    ) {
      return false;
    }

    isDraggingScrollBar = true;
    pointerDownState.lastCoords.x = event.clientX;
    pointerDownState.lastCoords.y = event.clientY;
    const onPointerMove = withBatchedUpdates((event: PointerEvent) => {
      const target = event.target;
      if (!(target instanceof HTMLElement)) {
        return;
      }

      this.handlePointerMoveOverScrollbars(event, pointerDownState);
    });

    const onPointerUp = withBatchedUpdates(() => {
      isDraggingScrollBar = false;
      setCursorForShape(this.canvas, this.state);
      lastPointerUp = null;
      this.setState({
        cursorButton: "up",
      });
      this.savePointer(event.clientX, event.clientY, "up");
      window.removeEventListener(EVENT.POINTER_MOVE, onPointerMove);
      window.removeEventListener(EVENT.POINTER_UP, onPointerUp);
    });

    lastPointerUp = onPointerUp;

    window.addEventListener(EVENT.POINTER_MOVE, onPointerMove);
    window.addEventListener(EVENT.POINTER_UP, onPointerUp);
    return true;
  }

  private clearSelectionIfNotUsingSelection = (): void => {
    if (this.state.activeTool.type !== "selection") {
      this.setState({
        selectedElementIds: makeNextSelectedElementIds({}, this.state),
        selectedGroupIds: {},
        editingGroupId: null,
      });
    }
  };

  /**
   * @returns whether the pointer event has been completely handled
   */
  private handleSelectionOnPointerDown = (
    event: React.PointerEvent<HTMLElement>,
    pointerDownState: PointerDownState,
  ): boolean => {
    if (this.state.activeTool.type === "selection") {
      const elements = this.scene.getNonDeletedElements();
      const elementsMap = this.scene.getNonDeletedElementsMap();
      const selectedElements = this.scene.getSelectedElements(this.state);
      if (
        selectedElements.length === 1 &&
        !this.state.editingLinearElement &&
        !(
          this.state.selectedLinearElement &&
          this.state.selectedLinearElement.hoverPointIndex !== -1
        )
      ) {
        const elementWithTransformHandleType = getElementWithTransformHandleType(
          elements,
          this.state,
          pointerDownState.origin.x,
          pointerDownState.origin.y,
          this.state.zoom,
          event.pointerType,
          this.scene.getNonDeletedElementsMap(),
        );
        if (elementWithTransformHandleType != null) {
          this.setState({
            resizingElement: elementWithTransformHandleType.element,
          });
          pointerDownState.resize.handleType =
            elementWithTransformHandleType.transformHandleType;
        }
      } else if (selectedElements.length > 1) {
        pointerDownState.resize.handleType = getTransformHandleTypeFromCoords(
          getCommonBounds(selectedElements),
          pointerDownState.origin.x,
          pointerDownState.origin.y,
          this.state.zoom,
          event.pointerType,
        );
      }
      if (pointerDownState.resize.handleType) {
        // setCursor(
        //   this.canvas,
        //   getCursorForResizingElement({
        //     transformHandleType: pointerDownState.resize.handleType,
        //   }),
        // );
        pointerDownState.resize.isResizing = true;
        pointerDownState.resize.offset = tupleToCoors(
          getResizeOffsetXY(
            pointerDownState.resize.handleType,
            selectedElements,
            elementsMap,
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          ),
        );
        if (
          selectedElements.length === 1 &&
          isLinearElement(selectedElements[0]) &&
          selectedElements[0].points.length === 2
        ) {
          pointerDownState.resize.arrowDirection = getResizeArrowDirection(
            pointerDownState.resize.handleType,
            selectedElements[0],
          );
        }
      } else {
        if (this.state.selectedLinearElement) {
          const linearElementEditor =
            this.state.editingLinearElement || this.state.selectedLinearElement;
          const ret = LinearElementEditor.handlePointerDown(
            event,
            this.state,
            this.store,
            pointerDownState.origin,
            linearElementEditor,
            this as any,
          );
          if (ret.hitElement) {
            pointerDownState.hit.element = ret.hitElement;
          }
          if (ret.linearElementEditor) {
            this.setState({ selectedLinearElement: ret.linearElementEditor });

            if (this.state.editingLinearElement) {
              this.setState({ editingLinearElement: ret.linearElementEditor });
            }
          }
          if (ret.didAddPoint) {
            return true;
          }
        }

        // hitElement may already be set above, so check first
        pointerDownState.hit.element =
          pointerDownState.hit.element ??
          this.getElementAtPosition(
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          );

        if (pointerDownState.hit.element) {
          // Early return if pointer is hitting link icon
          const hitLinkElement = this.getElementLinkAtPosition(
            {
              x: pointerDownState.origin.x,
              y: pointerDownState.origin.y,
            },
            pointerDownState.hit.element,
          );
          if (hitLinkElement) {
            return false;
          }
        }

        // For overlapped elements one position may hit
        // multiple elements
        pointerDownState.hit.allHitElements = this.getElementsAtPosition(
          pointerDownState.origin.x,
          pointerDownState.origin.y,
        );

        const hitElement = pointerDownState.hit.element;
        const someHitElementIsSelected = pointerDownState.hit.allHitElements.some(
          (element) => this.isASelectedElement(element),
        );
        if (
          (hitElement === null || !someHitElementIsSelected) &&
          !event.shiftKey &&
          !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
        ) {
          this.clearSelection(hitElement);
        }

        // If we click on something
        if (this.state.editingLinearElement) {
          this.setState({
            selectedElementIds: makeNextSelectedElementIds(
              {
                [this.state.editingLinearElement.elementId]: true,
              },
              this.state,
            ),
          });
          // If we click on something
        } else if (hitElement != null) {
          // on CMD/CTRL, drill down to hit element regardless of groups etc.
          if (event[KEYS.CTRL_OR_CMD]) {
            if (!this.state.selectedElementIds[hitElement.id]) {
              pointerDownState.hit.wasAddedToSelection = true;
            }
            this.setState((prevState) => ({
              ...editGroupForSelectedElement(prevState, hitElement),
              previousSelectedElementIds: this.state.selectedElementIds,
            }));
            // mark as not completely handled so as to allow dragging etc.
            return false;
          }

          // deselect if item is selected
          // if shift is not clicked, this will always return true
          // otherwise, it will trigger selection based on current
          // state of the box
          if (!this.state.selectedElementIds[hitElement.id]) {
            // if we are currently editing a group, exiting editing mode and deselect the group.
            if (
              this.state.editingGroupId &&
              !isElementInGroup(hitElement, this.state.editingGroupId)
            ) {
              this.setState({
                selectedElementIds: makeNextSelectedElementIds({}, this.state),
                selectedGroupIds: {},
                editingGroupId: null,
              });
            }

            // Add hit element to selection. At this point if we're not holding
            // SHIFT the previously selected element(s) were deselected above
            // (make sure you use setState updater to use latest state)
            if (
              !someHitElementIsSelected &&
              !pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements
            ) {
              // Adjust the canvas scroll when an element is selected, and the left popover menu is opened.
              // If the selected element is positioned near the left edge of the canvas (x < 285),
              // scroll the canvas horizontally to ensure the element is fully visible.

              if (hitElement.x < 285 && this.state.scrollX < 285) {
                this.setState(({ zoom, scrollX }) => ({
                  // on Mac, shift+wheel tends to result in deltaX
                  scrollX: scrollX + 285 / zoom.value,
                }));
              }

              this.setState((prevState) => {
                const nextSelectedElementIds: { [id: string]: boolean } = {
                  ...prevState.selectedElementIds,
                  [hitElement.id]: true,
                };

                const previouslySelectedElements: ExcalidrawElement[] = [];

                Object.keys(prevState.selectedElementIds).forEach((id) => {
                  const element = this.scene.getElement(id);
                  element && previouslySelectedElements.push(element);
                });

                // if hitElement is frame-like, deselect all of its elements
                // if they are selected
                if (isFrameLikeElement(hitElement)) {
                  getFrameChildren(
                    previouslySelectedElements,
                    hitElement.id,
                  ).forEach((element) => {
                    delete nextSelectedElementIds[element.id];
                  });
                } else if (hitElement.frameId) {
                  // if hitElement is in a frame and its frame has been selected
                  // disable selection for the given element
                  if (nextSelectedElementIds[hitElement.frameId]) {
                    delete nextSelectedElementIds[hitElement.id];
                  }
                } else {
                  // hitElement is neither a frame nor an element in a frame
                  // but since hitElement could be in a group with some frames
                  // this means selecting hitElement will have the frames selected as well
                  // because we want to keep the invariant:
                  // - frames and their elements are not selected at the same time
                  // we deselect elements in those frames that were previously selected

                  const groupIds = hitElement.groupIds;
                  const framesInGroups = new Set(
                    groupIds
                      .flatMap((gid) =>
                        getElementsInGroup(
                          this.scene.getNonDeletedElements(),
                          gid,
                        ),
                      )
                      .filter((element) => isFrameLikeElement(element))
                      .map((frame) => frame.id),
                  );

                  if (framesInGroups.size > 0) {
                    previouslySelectedElements.forEach((element) => {
                      if (
                        element.frameId &&
                        framesInGroups.has(element.frameId)
                      ) {
                        // deselect element and groups containing the element
                        delete nextSelectedElementIds[element.id];
                        element.groupIds
                          .flatMap((gid) =>
                            getElementsInGroup(
                              this.scene.getNonDeletedElements(),
                              gid,
                            ),
                          )
                          .forEach((element) => {
                            delete nextSelectedElementIds[element.id];
                          });
                      }
                    });
                  }
                }

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: nextSelectedElementIds,
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this as any,
                  ),
                  showHyperlinkPopup:
                    hitElement.link || isEmbeddableElement(hitElement)
                      ? "info"
                      : false,
                };
              });
              pointerDownState.hit.wasAddedToSelection = true;
              // this.setState((prevState) => {
              //   return selectGroupsForSelectedElements(
              //     {
              //       ...prevState,
              //       selectedElementIds: {
              //         ...prevState.selectedElementIds,
              //         [hitElement.id]: true,
              //       },
              //     },
              //     this.scene.getElements(),
              //   );
              // });
              // pointerDownState.hit.wasAddedToSelection = true;
              // }
            }
          }
        }

        this.setState({
          previousSelectedElementIds: this.state.selectedElementIds,
        });
      }
    }
    return false;
  };

  private isASelectedElement(hitElement: ExcalidrawElement | null): boolean {
    return hitElement != null && this.state.selectedElementIds[hitElement.id];
  }

  private isHittingCommonBoundingBoxOfSelectedElements(
    point: Readonly<{ x: number; y: number }>,
    selectedElements: readonly ExcalidrawElement[],
  ): boolean {
    if (selectedElements.length < 2) {
      return false;
    }

    // How many pixels off the shape boundary we still consider a hit
    const threshold = 10 / this.state.zoom.value;
    const [x1, y1, x2, y2] = getCommonBounds(selectedElements);
    return (
      point.x > x1 - threshold &&
      point.x < x2 + threshold &&
      point.y > y1 - threshold &&
      point.y < y2 + threshold
    );
  }

  private handleTextOnPointerDown = (
    event: React.PointerEvent<HTMLElement>,
    pointerDownState: PointerDownState,
  ): void => {
    // if we're currently still editing text, clicking outside
    // should only finalize it, not create another (irrespective
    // of state.elementLocked)

    const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
      event,
      this.state,
    );

    if (this.state.editingElement?.type === "text") {
      return;
    }

    const element = this.getElementAtPosition(sceneX, sceneY);
    const container = this.getTextBindableContainerAtPosition(sceneX, sceneY);

    // This process works when the user selects text from the 'top-menu-section' and then clicks on the canvas.
    if (element && element?.type === "text") {
      if (isTextElement(this.state.editingElement)) {
        return;
      }
      const sceneX = pointerDownState.origin.x;
      const sceneY = pointerDownState.origin.y;

      this.startTextEditing({
        sceneX,
        sceneY,
        insertAtParentCenter: !event.altKey,
        container,
        autoEdit: false,
      });
    }

    this.setState(
      {
        textEditor: {
          ...this.state.textEditor,
          open: true,
          sceneX,
          sceneY: sceneY - 103, // 103 is the height of the text editor header with spacing
          value: "",
          width: 0,
          height: 0,
          resizeHeight: 0,
          resizeWidth: 0,
        },
      },
      () => {
        this.togglekeyboardShortcut(false);
      },
    );

    resetCursor(this.canvas);
    if (!this.state.elementLocked) {
      this.setActiveTool({
        type: "selection",
      });
    }
  };

  private handleFreeDrawElementOnPointerDown = async (
    event: React.PointerEvent<HTMLElement>,
    elementType: ExcalidrawFreeDrawElement["type"],
    pointerDownState: PointerDownState,
  ) => {
    // Begin a mark capture. This does not have to update state yet.
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      null,
    );
    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });

    const lessonId = await getLessonId();
    const element = newFreeDrawElement({
      type: elementType,
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      roundness: null,
      simulatePressure: event.pressure === 0.5,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      isVisible: true,
    });

    this.setState((prevState) => {
      const nextSelectedElementIds = {
        ...prevState.selectedElementIds,
      };
      delete nextSelectedElementIds[element.id];
      return {
        selectedElementIds: makeNextSelectedElementIds(
          nextSelectedElementIds,
          prevState,
        ),
      };
    });

    const pressures = element.simulatePressure
      ? element.pressures
      : [...element.pressures, event.pressure];

    mutateElement(element, {
      points: [[0, 0]],
      pressures,
    });

    const boundElement = getHoveredElementForBinding(
      pointerDownState.origin,
      this.scene.getNonDeletedElementsMap(),
    );
    this.scene.insertElement(element);
    this.setState({
      draggingElement: element,
      editingElement: element,
      startBoundElement: boundElement,
      suggestedBindings: [],
    });
  };

  private createImageElement = async ({
    sceneX,
    sceneY,
    pdfId = "",
    addToFrameUnderCursor = true,
  }: {
    sceneX: number;
    sceneY: number;
    pdfId?: string;
    addToFrameUnderCursor?: boolean;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);

    const lessonId = await getLessonId();

    const topLayerFrame = addToFrameUnderCursor
      ? this.getTopLayerFrameAtSceneCoords({
          x: gridX,
          y: gridY,
        })
      : null;

    const element = newImageElement({
      type: "image",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      pdfId,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });

    return element;
  };
  private createVideoElement = async ({
    sceneX,
    sceneY,
    fileName,
    color,
  }: {
    sceneX: number;
    sceneY: number;
    fileName?: string | null;
    color?: string | null;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);
    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });
    const lessonId = await getLessonId();

    const element = newVideoElement({
      type: "video",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      fileName: fileName || "",
      color: color || "#000",
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });

    return element;
  };
  private createAudioElement = async ({
    sceneX,
    sceneY,
    fileName,
    color,
  }: {
    sceneX: number;
    sceneY: number;
    fileName?: string | null;
    color?: string | null;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);
    const lessonId = await getLessonId();
    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });
    const element = newAudioElement({
      type: "audio",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      fileName: fileName || "",
      color: color || "#000",
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });

    return element;
  };

  private createFormulaElement = async ({
    sceneX,
    sceneY,
    formulaString,
  }: {
    sceneX: number;
    sceneY: number;
    formulaString?: string;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);
    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });
    const lessonId = await getLessonId();

    const element = newFormulaElement({
      type: "formula",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      formulaString,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });
    return element;
  };
  private createMermaidDiagramElement = async ({
    sceneX,
    sceneY,
    syntax,
  }: {
    sceneX: number;
    sceneY: number;
    syntax?: string;
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);

    const lessonId = await getLessonId();

    const element = newMermaidDiagramElement({
      type: "mermaidDiagram",
      x: gridX,
      y: gridY,
      backgroundColor: this.state.currentItemBackgroundColor,
      syntax,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      isVisible: true,
    });

    return element;
  };

  private createTextWithStylesElement = async ({
    sceneX,
    sceneY,
    textString,
    defaultWidth,
    defaultHeight,
    zoomFactor,
    textEditor,
  }: {
    sceneX: number;
    sceneY: number;
    textString: string;
    defaultWidth?: number;
    defaultHeight?: number;
    zoomFactor?: {
      isFontSizeChanged: boolean;
    };
    textEditor?: {
      resize: boolean;
      resizeWidth: number;
      resizeHeight: number;
    };
  }) => {
    const [gridX, gridY] = getGridPoint(sceneX, sceneY, this.state.gridSize);
    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });
    const lessonId = await getLessonId();

    const element = newTextWithStylesElement({
      type: "textWithStyles",
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roughness: this.state.currentItemRoughness,
      opacity: this.state.currentItemOpacity,
      textString,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      defaultWidth,
      defaultHeight,
      zoomFactor,
      textEditor,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });
    return element;
  };

  private handleLinearElementOnPointerDown = async (
    event: React.PointerEvent<HTMLElement>,
    elementType: ExcalidrawLinearElement["type"],
    pointerDownState: PointerDownState,
  ): Promise<void> => {
    if (this.state.multiElement) {
      const { multiElement } = this.state;

      // finalize if completing a loop
      if (
        multiElement.type === "line" &&
        isPathALoop(multiElement.points, this.state.zoom.value)
      ) {
        mutateElement(multiElement, {
          lastCommittedPoint:
            multiElement.points[multiElement.points.length - 1],
        });
        this.actionManager.executeAction(actionFinalize);
        return;
      }

      const { x: rx, y: ry, lastCommittedPoint } = multiElement;

      // clicking inside commit zone → finalize arrow
      if (
        multiElement.points.length > 1 &&
        lastCommittedPoint &&
        pointDistance(
          pointFrom(
            pointerDownState.origin.x - rx,
            pointerDownState.origin.y - ry,
          ),
          lastCommittedPoint as any,
        ) < LINE_CONFIRM_THRESHOLD
      ) {
        this.actionManager.executeAction(actionFinalize);
        return;
      }

      this.setState((prevState) => ({
        selectedElementIds: makeNextSelectedElementIds(
          {
            ...prevState.selectedElementIds,
            [multiElement.id]: true,
          },
          prevState,
        ),
      }));
      // clicking outside commit zone → update reference for last committed
      // point
      mutateElement(multiElement, {
        lastCommittedPoint: multiElement.points[multiElement.points.length - 1],
      });
      setCursor(this.canvas, CURSOR_TYPE.POINTER);
    } else {
      const [gridX, gridY] = getGridPoint(
        pointerDownState.origin.x,
        pointerDownState.origin.y,
        this.state.gridSize,
      );

      const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
        x: gridX,
        y: gridY,
      });

      /* If arrow is pre-arrowheads, it will have undefined for both start and end arrowheads.
      If so, we want it to be null for start and "arrow" for end. If the linear item is not
      an arrow, we want it to be null for both. Otherwise, we want it to use the
      values from appState. */

      const { currentItemStartArrowhead, currentItemEndArrowhead } = this.state;
      const [startArrowhead, endArrowhead] =
        elementType === "arrow"
          ? [currentItemStartArrowhead, currentItemEndArrowhead]
          : [null, null];

      const lessonId = await getLessonId();

      const element =
        elementType === "arrow"
          ? newArrowElement({
              type: elementType,
              x: gridX,
              y: gridY,
              strokeColor: this.state.currentItemStrokeColor,
              backgroundColor: this.state.currentItemBackgroundColor,
              fillStyle: this.state.currentItemFillStyle,
              strokeWidth: this.state.currentItemStrokeWidth,
              strokeStyle: this.state.currentItemStrokeStyle,
              roughness: this.state.currentItemRoughness,
              opacity: this.state.currentItemOpacity,
              roundness:
                this.state.currentItemArrowType === ARROW_TYPE.round
                  ? { type: ROUNDNESS.PROPORTIONAL_RADIUS }
                  : // note, roundness doesn't have any effect for elbow arrows,
                    // but it's best to set it to null as well
                    null,
              startArrowhead,
              endArrowhead,
              locked: false,
              frameId: topLayerFrame ? topLayerFrame.id : null,
              elbowed: this.state.currentItemArrowType === ARROW_TYPE.elbow,
              page: this.state.currentPage,
              lessonId: lessonId || "",
              link: null,
              index: null,
              isVisible: true,
            })
          : newLinearElement({
              type: elementType,
              x: gridX,
              y: gridY,
              strokeColor: this.state.currentItemStrokeColor,
              backgroundColor: this.state.currentItemBackgroundColor,
              fillStyle: this.state.currentItemFillStyle,
              strokeWidth: this.state.currentItemStrokeWidth,
              strokeStyle: this.state.currentItemStrokeStyle,
              roughness: this.state.currentItemRoughness,
              opacity: this.state.currentItemOpacity,
              roundness:
                this.state.currentItemRoundness === "round"
                  ? { type: ROUNDNESS.PROPORTIONAL_RADIUS }
                  : null,
              startArrowhead,
              endArrowhead,
              page: this.state.currentPage,
              lessonId: lessonId || "",
              link: null,
              index: null,
              frameId: topLayerFrame ? topLayerFrame.id : null,
              locked: false,
              isVisible: true,
            });
      this.setState((prevState) => {
        const nextSelectedElementIds = {
          ...prevState.selectedElementIds,
        };
        delete nextSelectedElementIds[element.id];
        return {
          selectedElementIds: makeNextSelectedElementIds(
            nextSelectedElementIds,
            prevState,
          ),
        };
      });
      mutateElement(element, {
        points: [...element.points, [0, 0]],
      });
      const boundElement = getHoveredElementForBinding(
        pointerDownState.origin,
        this.scene.getNonDeletedElementsMap(),
      );
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted(),
        element,
      ]);
      this.setState({
        draggingElement: element,
        editingElement: element,
        startBoundElement: boundElement,
        suggestedBindings: [],
      });
    }
  };

  private getCurrentItemRoundness(
    elementType:
      | "selection"
      | "rectangle"
      | "diamond"
      | "ellipse"
      | "iframe"
      | "embeddable"
      | "clock"
      | "countdown"
      | "chronometer",
  ) {
    return this.state.currentItemRoundness === "round"
      ? {
          type: isUsingAdaptiveRadius(elementType)
            ? ROUNDNESS.ADAPTIVE_RADIUS
            : ROUNDNESS.PROPORTIONAL_RADIUS,
        }
      : null;
  }

  private createGenericElementOnPointerDown = async (
    elementType: ExcalidrawGenericElement["type"],
    pointerDownState: PointerDownState,
  ): Promise<void> => {
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.state.gridSize,
    );

    const topLayerFrame = this.getTopLayerFrameAtSceneCoords({
      x: gridX,
      y: gridY,
    });

    const lessonId = await getLessonId();

    const element = newElement({
      type: elementType,
      x: gridX,
      y: gridY,
      strokeColor: this.state.currentItemStrokeColor,
      backgroundColor: this.state.currentItemBackgroundColor,
      fillStyle: this.state.currentItemFillStyle,
      strokeWidth: this.state.currentItemStrokeWidth,
      strokeStyle: this.state.currentItemStrokeStyle,
      roundness: this.getCurrentItemRoundness(elementType),
      opacity: this.state.currentItemOpacity,
      page: this.state.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: topLayerFrame ? topLayerFrame.id : null,
      isVisible: true,
    });

    if (element.type === "selection") {
      this.setState({
        selectionElement: element,
        draggingElement: element,
      });
    } else {
      this.scene.replaceAllElements([
        ...this.scene.getElementsIncludingDeleted(),
        element,
      ]);
      this.setState({
        multiElement: null,
        draggingElement: element,
        editingElement: element,
      });
    }
  };

  private createFrameElementOnPointerDown = (
    pointerDownState: PointerDownState,
    type: Extract<ToolType, "frame" | "magicframe">,
  ): void => {
    const [gridX, gridY] = getGridPoint(
      pointerDownState.origin.x,
      pointerDownState.origin.y,
      this.lastPointerDownEvent?.[KEYS.CTRL_OR_CMD]
        ? null
        : this.state.gridSize,
    );

    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const constructorOpts = {
      x: gridX,
      y: gridY,
      opacity: this.state.currentItemOpacity,
      locked: false,
      lessonId,
      page: this.state.currentPage,

      ...FRAME_STYLE,
    } as const;

    const frame =
      type === TOOL_TYPE.magicframe
        ? newMagicFrameElement(constructorOpts as any)
        : newFrameElement(constructorOpts as any);

    this.scene.insertElement(frame);

    this.setState({
      multiElement: null,
      draggingElement: frame,
    });
  };

  private onKeyDownFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: KeyboardEvent) => void {
    return withBatchedUpdates((event: KeyboardEvent) => {
      if (this.maybeHandleResize(pointerDownState, event)) {
        return;
      }
      this.maybeDragNewGenericElement(pointerDownState, event);
    });
  }

  private onKeyUpFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: KeyboardEvent) => void {
    return withBatchedUpdates((event: KeyboardEvent) => {
      // Prevents focus from escaping excalidraw tab
      event.key === KEYS.ALT && event.preventDefault();
      if (this.maybeHandleResize(pointerDownState, event)) {
        return;
      }
      this.maybeDragNewGenericElement(pointerDownState, event);
    });
  }

  private maybeCacheVisibleGaps(
    event: KeyboardModifiersObject,
    selectedElements: ExcalidrawElement[],
    recomputeAnyways: boolean = false,
  ) {
    if (
      isSnappingEnabled({
        event,
        appState: this.state,
        selectedElements,
      }) &&
      (recomputeAnyways || !SnapCache.getVisibleGaps())
    ) {
      SnapCache.setVisibleGaps(
        getVisibleGaps(
          this.scene.getNonDeletedElements(),
          selectedElements,
          this.state,
          this.scene.getNonDeletedElementsMap(),
        ),
      );
    }
  }

  private onPointerMoveFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: PointerEvent) => void {
    return withBatchedUpdates((event: PointerEvent) => {
      const pointerCoords = viewportCoordsToSceneCoords(event, this.state);

      // We need to initialize dragOffsetXY only after we've updated
      // `state.selectedElementIds` on pointerDown. Doing it here in pointerMove
      // event handler should hopefully ensure we're already working with
      // the updated state.
      if (pointerDownState.drag.offset === null) {
        pointerDownState.drag.offset = tupleToCoors(
          getDragOffsetXY(
            this.scene.getSelectedElements(this.state),
            pointerDownState.origin.x,
            pointerDownState.origin.y,
          ),
        );
      }

      const target = event.target;
      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (this.handlePointerMoveOverScrollbars(event, pointerDownState)) {
        return;
      }

      if (this.state.activeTool?.type === "laser") {
        this.laserTrails.addPointToPath(pointerCoords.x, pointerCoords.y);
      }

      const [gridX, gridY] = getGridPoint(
        pointerCoords.x,
        pointerCoords.y,
        this.state.gridSize,
      );

      // for arrows/lines, don't start dragging until a given threshold
      // to ensure we don't create a 2-point arrow by mistake when
      // user clicks mouse in a way that it moves a tiny bit (thus
      // triggering pointermove)
      if (
        !pointerDownState.drag.hasOccurred &&
        (this.state.activeTool.type === "arrow" ||
          this.state.activeTool.type === "line")
      ) {
        if (
          pointDistance(
            pointFrom(pointerCoords.x, pointerCoords.y),
            pointFrom(pointerDownState.origin.x, pointerDownState.origin.y),
          ) < DRAGGING_THRESHOLD
        ) {
          return;
        }
      }
      if (pointerDownState.resize.isResizing) {
        pointerDownState.lastCoords.x = pointerCoords.x;
        pointerDownState.lastCoords.y = pointerCoords.y;

        if (this.maybeHandleResize(pointerDownState, event)) {
          return true;
        }
      }
      const elementsMap = this.scene.getNonDeletedElementsMap();
      if (this.state.selectedLinearElement) {
        const linearElementEditor =
          this.state.editingLinearElement || this.state.selectedLinearElement;

        if (
          LinearElementEditor.shouldAddMidpoint(
            this.state.selectedLinearElement,
            pointerCoords,
            this.state,
            elementsMap,
          )
        ) {
          const ret = LinearElementEditor.addMidpoint(
            this.state.selectedLinearElement,
            pointerCoords,
            this.state,
            !event[KEYS.CTRL_OR_CMD],
            elementsMap,
          );
          if (!ret) {
            return;
          }

          // Since we are reading from previous state which is not possible with
          // automatic batching in React 18 hence using flush sync to synchronously
          // update the state. Check https://github.com/excalidraw/excalidraw/pull/5508 for more details.

          flushSync(() => {
            if (this.state.selectedLinearElement) {
              this.setState({
                selectedLinearElement: {
                  ...this.state.selectedLinearElement,
                  pointerDownState: ret.pointerDownState,
                  selectedPointsIndices: ret.selectedPointsIndices,
                },
              });
            }
            if (this.state.editingLinearElement) {
              this.setState({
                editingLinearElement: {
                  ...this.state.editingLinearElement,
                  pointerDownState: ret.pointerDownState,
                  selectedPointsIndices: ret.selectedPointsIndices,
                },
              });
            }
          });

          return;
        } else if (
          linearElementEditor?.pointerDownState?.segmentMidpoint?.value !==
            null &&
          !linearElementEditor?.pointerDownState?.segmentMidpoint?.added
        ) {
          return;
        }
        const didDrag = LinearElementEditor.handlePointDragging(
          event,
          this.state,
          pointerCoords.x,
          pointerCoords.y,
          (element, pointsSceneCoords) => {
            this.maybeSuggestBindingsForLinearElementAtCoords(
              element,
              pointsSceneCoords,
            );
          },
          linearElementEditor,
          this.scene.getNonDeletedElementsMap(),
        );

        if (didDrag) {
          pointerDownState.lastCoords.x = pointerCoords.x;
          pointerDownState.lastCoords.y = pointerCoords.y;
          pointerDownState.drag.hasOccurred = true;
          if (
            this.state.editingLinearElement &&
            !this.state.editingLinearElement.isDragging
          ) {
            this.setState({
              editingLinearElement: {
                ...this.state.editingLinearElement,
                isDragging: true,
              },
            });
          }
          if (!this.state.selectedLinearElement.isDragging) {
            this.setState({
              selectedLinearElement: {
                ...this.state.selectedLinearElement,
                isDragging: true,
              },
            });
          }
          return;
        }
      }

      const hasHitASelectedElement = pointerDownState.hit.allHitElements.some(
        (element) => this.isASelectedElement(element),
      );

      const isSelectingPointsInLineEditor =
        this.state.editingLinearElement &&
        event.shiftKey &&
        this.state.editingLinearElement.elementId ===
          pointerDownState.hit.element?.id;

      if (
        (hasHitASelectedElement ||
          pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements) &&
        !isSelectingPointsInLineEditor
      ) {
        // Marking that click was used for dragging to check
        // if elements should be deselected on pointerup
        const selectedElements = this.scene.getSelectedElements(this.state);

        if (selectedElements.every((element) => element.locked)) {
          return;
        }
        const selectedElementsHasAFrame = selectedElements.find((e) =>
          isFrameLikeElement(e),
        );
        const topLayerFrame = this.getTopLayerFrameAtSceneCoords(pointerCoords);
        this.setState({
          frameToHighlight:
            topLayerFrame && !selectedElementsHasAFrame ? topLayerFrame : null,
        });

        pointerDownState.drag.hasOccurred = true;
        this.setState({
          selectedElementsAreBeingDragged: true,
        });
        // prevent dragging even if we're no longer holding cmd/ctrl otherwise
        // it would have weird results (stuff jumping all over the screen)
        if (
          selectedElements.length > 0 &&
          !pointerDownState.withCmdOrCtrl &&
          !this.state.editingElement &&
          this.state.activeEmbeddable?.state !== "active"
        ) {
          const dragOffset = {
            x: pointerCoords.x - pointerDownState.origin.x,
            y: pointerCoords.y - pointerDownState.origin.y,
          };
          const originalElements = [
            ...pointerDownState.originalElements.values(),
          ];

          // We only drag in one direction if shift is pressed
          const lockDirection = event.shiftKey;

          if (lockDirection) {
            const distanceX = Math.abs(dragOffset.x);
            const distanceY = Math.abs(dragOffset.y);

            const lockX = lockDirection && distanceX < distanceY;
            const lockY = lockDirection && distanceX > distanceY;

            if (lockX) {
              dragOffset.x = 0;
            }

            if (lockY) {
              dragOffset.y = 0;
            }
          }
          // Snap cache *must* be synchronously popuplated before initial drag,
          // otherwise the first drag even will not snap, causing a jump before
          // it snaps to its position if previously snapped already.
          this.maybeCacheVisibleGaps(event, selectedElements);
          this.maybeCacheReferenceSnapPoints(event, selectedElements);

          const { snapOffset, snapLines } = snapDraggedElements(
            originalElements,
            dragOffset,
            this.state,
            event,
            this.scene.getNonDeletedElementsMap(),
          );

          this.setState({ snapLines });
          // when we're editing the name of a frame, we want the user to be
          // able to select and interact with the text input
          !this.state.editingFrame &&
            dragSelectedElements(
              pointerDownState,
              selectedElements,
              dragOffset,
              this.state,
              this.scene,
              snapOffset,
              event[KEYS.CTRL_OR_CMD] ? null : this.state.gridSize,
            );
          this.setState({
            suggestedBindings: getSuggestedBindingsForArrows(
              selectedElements,
              this.scene.getNonDeletedElementsMap(),
            ),
            selectedElementsAreBeingDragged: true,
          });

          // We duplicate the selected element if alt is pressed on pointer move
          if (event.altKey && !pointerDownState.hit.hasBeenDuplicated) {
            // Move the currently selected elements to the top of the z index stack, and
            // put the duplicates where the selected elements used to be.
            // (the origin point where the dragging started)

            pointerDownState.hit.hasBeenDuplicated = true;

            const nextElements = [];
            const elementsToAppend = [];
            const groupIdMap = new Map();
            const oldIdToDuplicatedId = new Map();
            const hitElement = pointerDownState.hit.element;
            const selectedElementIds = new Set(
              this.scene
                .getSelectedElements({
                  selectedElementIds: this.state.selectedElementIds,
                  includeBoundTextElement: true,
                  includeElementsInFrames: true,
                })
                .map((element) => element.id),
            );
            for (const element of this.scene.getElementsIncludingDeleted()) {
              if (
                selectedElementIds.has(element.id) ||
                // case: the state.selectedElementIds might not have been
                // updated yet by the time this mousemove event is fired
                (element.id === hitElement?.id &&
                  pointerDownState.hit.wasAddedToSelection)
              ) {
                const duplicatedElement = duplicateElement(
                  this.state.editingGroupId,
                  groupIdMap,
                  element,
                );
                const origElement = pointerDownState.originalElements.get(
                  element.id,
                )!;
                mutateElement(duplicatedElement, {
                  x: origElement.x,
                  y: origElement.y,
                });

                // put duplicated element to pointerDownState.originalElements
                // so that we can snap to the duplicated element without releasing
                pointerDownState.originalElements.set(
                  duplicatedElement.id,
                  duplicatedElement,
                );
                nextElements.push(duplicatedElement);
                elementsToAppend.push(element);
                oldIdToDuplicatedId.set(element.id, duplicatedElement.id);
              } else {
                nextElements.push(element);
              }
            }
            const nextSceneElements = [...nextElements, ...elementsToAppend];
            syncMovedIndices(nextSceneElements, arrayToMap(elementsToAppend));
            bindTextToShapeAfterDuplication(
              nextElements,
              elementsToAppend,
              oldIdToDuplicatedId,
            );
            fixBindingsAfterDuplication(
              nextSceneElements,
              elementsToAppend,
              oldIdToDuplicatedId,
              "duplicatesServeAsOld",
            );
            bindElementsToFramesAfterDuplication(
              nextSceneElements,
              elementsToAppend,
              oldIdToDuplicatedId,
            );

            this.scene.replaceAllElements(nextSceneElements);
            this.maybeCacheVisibleGaps(event, selectedElements, true);
            this.maybeCacheReferenceSnapPoints(event, selectedElements, true);
          }
          return;
        }
      }
      if (this.state.selectionElement) {
        pointerDownState.lastCoords.x = pointerCoords.x;
        pointerDownState.lastCoords.y = pointerCoords.y;
        this.maybeDragNewGenericElement(pointerDownState, event);
      } else {
        // It is very important to read this.state within each move event,
        // otherwise we would read a stale one!
        const draggingElement = this.state.draggingElement;
        if (!draggingElement) {
          return;
        }
        if (draggingElement.type === "freedraw") {
          const points = draggingElement.points;
          const dx = pointerCoords.x - draggingElement.x;
          const dy = pointerCoords.y - draggingElement.y;

          const lastPoint = points.length > 0 && points[points.length - 1];
          const discardPoint =
            lastPoint && lastPoint[0] === dx && lastPoint[1] === dy;

          if (!discardPoint) {
            const pressures = draggingElement.simulatePressure
              ? draggingElement.pressures
              : [...draggingElement.pressures, event.pressure];

            mutateElement(
              draggingElement,
              {
                points: [...points, pointFrom<LocalPoint>(dx, dy)],
                pressures,
              },
              false,
            );

            this.setState({
              draggingElement,
            });
          }
        } else if (isLinearElement(draggingElement)) {
          pointerDownState.drag.hasOccurred = true;
          this.setState({
            selectedElementsAreBeingDragged: true,
          });
          const points = draggingElement.points;
          let dx = gridX - draggingElement.x;
          let dy = gridY - draggingElement.y;

          if (shouldRotateWithDiscreteAngle(event) && points.length === 2) {
            ({ width: dx, height: dy } = getLockedLinearCursorAlignSize(
              draggingElement.x,
              draggingElement.y,
              pointerCoords.x,
              pointerCoords.y,
            ));
          }

          if (points.length === 1) {
            mutateElement(
              draggingElement,
              {
                points: [...points, pointFrom<LocalPoint>(dx, dy)],
              },
              false,
            );
          } else if (points.length === 2) {
            mutateElement(
              draggingElement,
              {
                points: [...points.slice(0, -1), pointFrom<LocalPoint>(dx, dy)],
              },
              false,
            );
          }
          if (isBindingElement(draggingElement, false)) {
            // When creating a linear element by dragging
            this.maybeSuggestBindingsForLinearElementAtCoords(
              draggingElement,
              [pointerCoords],
              this.state.startBoundElement,
            );
          }
        } else {
          pointerDownState.lastCoords.x = pointerCoords.x;
          pointerDownState.lastCoords.y = pointerCoords.y;
          this.maybeDragNewGenericElement(pointerDownState, event);
        }
      }

      if (this.state.activeTool.type === "selection") {
        pointerDownState.boxSelection.hasOccurred = true;
        const elements = this.scene.getNonDeletedElements();
        // box-select line editor points
        if (this.state.editingLinearElement) {
          LinearElementEditor.handleBoxSelection(
            event,
            this.state,
            this.setState.bind(this),
            this.scene.getNonDeletedElementsMap(),
          );
          // regular box-select
        } else {
          let shouldReuseSelection = true;

          if (!event.shiftKey && isSomeElementSelected(elements, this.state)) {
            if (
              pointerDownState.withCmdOrCtrl &&
              pointerDownState.hit.element
            ) {
              this.setState((prevState) =>
                selectGroupsForSelectedElements(
                  {
                    ...prevState,
                    selectedElementIds: {
                      [pointerDownState.hit.element!.id]: true,
                    },
                  },
                  this.scene.getNonDeletedElements(),
                  prevState,
                  this as any,
                ),
              );
            } else {
              shouldReuseSelection = false;
              // this.setState({
              //   selectedElementIds: {},
              //   selectedGroupIds: {},
              //   editingGroupId: null,
              // });
            }
          }
          const elementsWithinSelection = this.state.selectionElement
            ? getElementsWithinSelection(
                elements,
                this.state.selectionElement,
                this.scene.getNonDeletedElementsMap(),
              )
            : [];
          this.setState((prevState) => {
            const nextSelectedElementIds = {
              ...(shouldReuseSelection && prevState.selectedElementIds),
              ...elementsWithinSelection.reduce(
                (acc: Record<ExcalidrawElement["id"], true>, element) => {
                  acc[element.id] = true;
                  return acc;
                },
                {},
              ),
            };

            if (pointerDownState.hit.element) {
              // if using ctrl/cmd, select the hitElement only if we
              // haven't box-selected anything else
              if (!elementsWithinSelection.length) {
                nextSelectedElementIds[pointerDownState.hit.element.id] = true;
              } else {
                delete nextSelectedElementIds[pointerDownState.hit.element.id];
              }
            }

            prevState = !shouldReuseSelection
              ? { ...prevState, selectedGroupIds: {}, editingGroupId: null }
              : prevState;

            return {
              ...selectGroupsForSelectedElements(
                {
                  editingGroupId: prevState.editingGroupId,
                  selectedElementIds: nextSelectedElementIds,
                },
                this.scene.getNonDeletedElements(),
                prevState,
                this as any,
              ),
              // select linear element only when we haven't box-selected anything else
              selectedLinearElement:
                elementsWithinSelection.length === 1 &&
                isLinearElement(elementsWithinSelection[0])
                  ? new LinearElementEditor(
                      elementsWithinSelection[0],
                      this.scene,
                    )
                  : null,
              showHyperlinkPopup:
                elementsWithinSelection.length === 1 &&
                (elementsWithinSelection[0].link ||
                  isEmbeddableElement(elementsWithinSelection[0]))
                  ? "info"
                  : false,
            };
          });
        }
      }
    });
  }

  // Returns whether the pointer move happened over either scrollbar
  private handlePointerMoveOverScrollbars(
    event: PointerEvent,
    pointerDownState: PointerDownState,
  ): boolean {
    if (pointerDownState.scrollbars.isOverHorizontal) {
      const x = event.clientX;
      const dx = x - pointerDownState.lastCoords.x;
      this.setState({
        scrollX: this.state.scrollX - dx / this.state.zoom.value,
      });
      pointerDownState.lastCoords.x = x;
      return true;
    }

    if (pointerDownState.scrollbars.isOverVertical) {
      const y = event.clientY;
      const dy = y - pointerDownState.lastCoords.y;
      this.setState({
        scrollY: this.state.scrollY - dy / this.state.zoom.value,
      });
      pointerDownState.lastCoords.y = y;
      return true;
    }
    return false;
  }

  private handleEmbeddableCenterClick(element: ExcalidrawIframeLikeElement) {
    if (
      this.state.activeEmbeddable?.element === element &&
      this.state.activeEmbeddable?.state === "active"
    ) {
      return;
    }

    // The delay serves two purposes
    // 1. To prevent first click propagating to iframe on mobile,
    //    else the click will immediately start and stop the video
    // 2. If the user double clicks the frame center to activate it
    //    without the delay youtube will immediately open the video
    //    in fullscreen mode
    setTimeout(() => {
      this.setState({
        activeEmbeddable: { element, state: "active" },
        selectedElementIds: { [element.id]: true },
        draggingElement: null,
        selectionElement: null,
      });
    }, 100);

    if (isIframeElement(element)) {
      return null;
    }
  }

  private onPointerUpFromPointerDownHandler(
    pointerDownState: PointerDownState,
  ): (event: PointerEvent) => void {
    //isClicked is used for check iframe is clicked
    localStorage.setItem("isClicked", "true");

    return withBatchedUpdates(async (childEvent: PointerEvent) => {
      const {
        draggingElement,
        resizingElement,
        multiElement,
        activeTool,
        elementLocked,
        isResizing,
        isRotating,
      } = this.state;

      this.setState((prevState) => ({
        isResizing: false,
        isRotating: false,
        resizingElement: null,
        frameToHighlight: null,
        elementsToHighlight: null,
        selectionElement: null,
        cursorButton: "up",
        // text elements are reset on finalize, and resetting on pointerup
        // may cause issues with double taps
        editingElement:
          multiElement || isTextElement(this.state.editingElement)
            ? this.state.editingElement
            : null,
        snapLines: updateStable(prevState.snapLines, []),
        originSnapOffset: null,
      }));

      SnapCache.setReferenceSnapPoints(null);
      SnapCache.setVisibleGaps(null);

      this.savePointer(childEvent.clientX, childEvent.clientY, "up");

      this.setState({
        selectedElementsAreBeingDragged: false,
      });

      // Handle end of dragging a point of a linear element, might close a loop
      // and sets binding element
      if (this.state.editingLinearElement) {
        if (
          !pointerDownState.boxSelection.hasOccurred &&
          pointerDownState.hit?.element?.id !==
            this.state.editingLinearElement.elementId
        ) {
          this.actionManager.executeAction(actionFinalize);
        } else {
          const editingLinearElement = LinearElementEditor.handlePointerUp(
            childEvent,
            this.state.editingLinearElement,
            this.state,
            this as any,
          );
          if (editingLinearElement !== this.state.editingLinearElement) {
            this.setState({
              editingLinearElement,
              suggestedBindings: [],
            });
          }
        }
      } else if (this.state.selectedLinearElement) {
        if (
          pointerDownState.hit?.element?.id !==
          this.state.selectedLinearElement.elementId
        ) {
          const selectedELements = this.scene.getSelectedElements(this.state);
          // set selectedLinearElement to null if there is more than one element selected since we don't want to show linear element handles
          if (selectedELements.length > 1) {
            this.setState({ selectedLinearElement: null });
          }
        } else {
          const linearElementEditor = LinearElementEditor.handlePointerUp(
            childEvent,
            this.state.selectedLinearElement,
            this.state,
            this as any,
          );

          const {
            startBindingElement,
            endBindingElement,
          } = linearElementEditor;
          const element = this.scene.getElement(linearElementEditor.elementId);
          if (isBindingElement(element)) {
            bindOrUnbindLinearElement(
              element,
              startBindingElement,
              endBindingElement,
              this.scene.getNonDeletedElementsMap(),
            );
          }

          if (linearElementEditor !== this.state.selectedLinearElement) {
            this.setState({
              selectedLinearElement: {
                ...linearElementEditor,
                selectedPointsIndices: null,
              },
              suggestedBindings: [],
            });
          }
        }
      }

      lastPointerUp = null;

      window.removeEventListener(
        EVENT.POINTER_MOVE,
        pointerDownState.eventListeners.onMove!,
      );
      window.removeEventListener(
        EVENT.POINTER_UP,
        pointerDownState.eventListeners.onUp!,
      );
      window.removeEventListener(
        EVENT.KEYDOWN,
        pointerDownState.eventListeners.onKeyDown!,
      );
      window.removeEventListener(
        EVENT.KEYUP,
        pointerDownState.eventListeners.onKeyUp!,
      );

      if (this.state.pendingImageElement) {
        this.setState({ pendingImageElement: null });
      }

      if (draggingElement?.type === "freedraw") {
        const pointerCoords = viewportCoordsToSceneCoords(
          childEvent,
          this.state,
        );

        const points = draggingElement.points;
        let dx = pointerCoords.x - draggingElement.x;
        let dy = pointerCoords.y - draggingElement.y;

        // Allows dots to avoid being flagged as infinitely small
        if (dx === points[0][0] && dy === points[0][1]) {
          dy += 0.0001;
          dx += 0.0001;
        }

        const pressures = draggingElement.simulatePressure
          ? []
          : [...draggingElement.pressures, childEvent.pressure];

        mutateElement(draggingElement, {
          points: [...points, pointFrom<LocalPoint>(dx, dy)],
          pressures,
          lastCommittedPoint: pointFrom<LocalPoint>(dx, dy),
        });

        this.actionManager.executeAction(actionFinalize);

        return;
      }
      if (
        isImageElement(draggingElement) ||
        isFormulaElement(draggingElement) ||
        isTextWithStylesElement(draggingElement)
      ) {
        const imageElement = draggingElement;
        try {
          this.initializeImageDimensions(imageElement);
          this.setState(
            {
              selectedElementIds: makeNextSelectedElementIds(
                { [imageElement.id]: true },
                this.state,
              ),
            },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } catch (error) {
          console.error(error);

          this.scene.replaceAllElements(
            this.scene
              .getElementsIncludingDeleted()
              .filter((el) => el.id !== imageElement.id),
          );
          this.actionManager.executeAction(actionFinalize);
        }
        return;
      }
      if (isVideoElement(draggingElement)) {
        const videoElement = draggingElement;
        try {
          this.initializeVideoDimensions(videoElement);
          this.setState(
            { selectedElementIds: { [videoElement.id]: true } },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } catch (error) {
          console.error(error);

          this.scene.replaceAllElements(
            this.scene
              .getElementsIncludingDeleted()
              .filter((el) => el.id !== videoElement.id),
          );
          this.actionManager.executeAction(actionFinalize);
        }
        return;
      }
      if (isAudioElement(draggingElement)) {
        const audioElement = draggingElement;
        try {
          this.initializeAudioDimensions(audioElement);
          this.setState(
            { selectedElementIds: { [audioElement.id]: true } },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } catch (error) {
          console.error(error);

          this.scene.replaceAllElements(
            this.scene
              .getElementsIncludingDeleted()
              .filter((el) => el.id !== audioElement.id),
          );
          this.actionManager.executeAction(actionFinalize);
        }
        return;
      }

      if (isLinearElement(draggingElement)) {
        if (draggingElement!.points.length > 1) {
          this.store.shouldCaptureIncrement();
        }
        const pointerCoords = viewportCoordsToSceneCoords(
          childEvent,
          this.state,
        );

        if (
          !pointerDownState.drag.hasOccurred &&
          draggingElement &&
          !multiElement
        ) {
          mutateElement(draggingElement, {
            points: [
              ...draggingElement.points,
              pointFrom<LocalPoint>(
                pointerCoords.x - draggingElement.x,
                pointerCoords.y - draggingElement.y,
              ),
            ],
          });
          this.setState({
            multiElement: draggingElement,
            editingElement: this.state.draggingElement,
          });
        } else if (pointerDownState.drag.hasOccurred && !multiElement) {
          if (
            isBindingEnabled(this.state) &&
            isBindingElement(draggingElement, false)
          ) {
            maybeBindLinearElement(
              draggingElement,
              this.state,
              pointerCoords,
              this.scene.getNonDeletedElementsMap(),
            );
          }
          this.setState({ suggestedBindings: [], startBoundElement: null });
          if (!elementLocked) {
            resetCursor(this.canvas);
            this.setActiveTool({
              type: "selection",
            });
            this.setState((prevState) => ({
              draggingElement: null,

              selectedElementIds: makeNextSelectedElementIds(
                {
                  ...prevState.selectedElementIds,
                  [draggingElement.id]: true,
                },
                prevState,
              ),
              selectedLinearElement: new LinearElementEditor(
                draggingElement,
                this.scene,
              ),
            }));
          } else {
            window.parent.postMessage(
              { type: "STORE_ELEMENTS", isLoading: true },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
            this.setState((prevState) => ({
              draggingElement: null,
              selectedElementIds: {
                ...prevState.selectedElementIds,
                [this.state.draggingElement!.id]: true,
              },
            }));
            onSaveElementInDB(
              this.state.currentPage,
              this.state.lessonId,
              this.state.DBElements,
              this.state.viewBackgroundColor,
            );
            window.parent.postMessage(
              { type: "STORE_ELEMENTS", isLoading: false },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
          }
        }
        return;
      }

      if (isTextElement(draggingElement)) {
        getMinTextElementWidth(
          getFontString({
            fontSize: draggingElement.fontSize,
            fontFamily: draggingElement.fontFamily,
          }),
          draggingElement.lineHeight,
        );

        this.resetCursor();

        this.handleTextWysiwyg(draggingElement, {
          isExistingElement: true,
        });
      }

      if (
        activeTool.type !== "selection" &&
        draggingElement &&
        isInvisiblySmallElement(draggingElement)
      ) {
        // remove invisible element which was added in onPointerDown
        // update the store snapshot, so that invisible elements are not captured by the store
        this.updateScene({
          elements: this.scene
            .getElementsIncludingDeleted()
            .filter((el) => el.id !== draggingElement.id),
          appState: {
            draggingElement: null,
          },
          storeAction: StoreAction.UPDATE,
        });

        return;

        // this.scene.replaceAllElements(
        //   this.scene.getElementsIncludingDeleted().slice(0, -1),
        // );
        // this.setState({
        //   draggingElement: null,
        // });
        // return;
      }

      if (isFrameLikeElement(draggingElement)) {
        const elementsInsideFrame = getElementsInNewFrame(
          this.scene.getElementsIncludingDeleted(),
          draggingElement,
          this.scene.getNonDeletedElementsMap(),
        );

        this.scene.replaceAllElements(
          addElementsToFrame(
            this.scene.getElementsMapIncludingDeleted(),
            elementsInsideFrame,
            draggingElement,
          ),
        );
      }

      if (draggingElement) {
        if (pointerDownState.drag.hasOccurred) {
          const sceneCoords = viewportCoordsToSceneCoords(
            childEvent,
            this.state,
          );

          // when editing the points of a linear element, we check if the
          // linear element still is in the frame afterwards
          // if not, the linear element will be removed from its frame (if any)
          if (
            this.state.selectedLinearElement &&
            this.state.selectedLinearElement.isDragging
          ) {
            const linearElement = this.scene.getElement(
              this.state.selectedLinearElement.elementId,
            );

            if (linearElement?.frameId) {
              const frame = getContainingFrame(
                linearElement,
                this.scene.getNonDeletedElementsMap(),
              );

              if (frame && linearElement) {
                if (
                  !elementOverlapsWithFrame(
                    linearElement,
                    frame,
                    this.scene.getNonDeletedElementsMap(),
                  )
                ) {
                  // remove the linear element from all groups
                  // before removing it from the frame as well
                  mutateElement(linearElement, {
                    groupIds: [],
                  });

                  removeElementsFromFrame(
                    [linearElement],
                    this.scene.getNonDeletedElementsMap(),
                  );

                  this.scene.informMutation();
                }
              }
            }
          } else {
            // update the relationships between selected elements and frames
            const topLayerFrame = this.getTopLayerFrameAtSceneCoords(
              sceneCoords,
            );

            const selectedElements = this.scene.getSelectedElements(this.state);
            let nextElements = this.scene.getElementsMapIncludingDeleted();

            const updateGroupIdsAfterEditingGroup = (
              elements: ExcalidrawElement[],
            ) => {
              if (elements.length > 0) {
                for (const element of elements) {
                  const index = element.groupIds.indexOf(
                    this.state.editingGroupId!,
                  );

                  mutateElement(
                    element,
                    {
                      groupIds: element.groupIds.slice(0, index),
                    },
                    false,
                  );
                }

                nextElements.forEach((element) => {
                  if (
                    element.groupIds.length &&
                    getElementsInGroup(
                      nextElements,
                      element.groupIds[element.groupIds.length - 1],
                    ).length < 2
                  ) {
                    mutateElement(
                      element,
                      {
                        groupIds: [],
                      },
                      false,
                    );
                  }
                });

                this.setState({
                  editingGroupId: null,
                });
              }
            };

            if (
              topLayerFrame &&
              !this.state.selectedElementIds[topLayerFrame.id]
            ) {
              const elementsToAdd = selectedElements.filter(
                (element) =>
                  element.frameId !== topLayerFrame.id &&
                  isElementInFrame(element, nextElements, this.state),
              );

              if (this.state.editingGroupId) {
                updateGroupIdsAfterEditingGroup(elementsToAdd);
              }

              nextElements = addElementsToFrame(
                nextElements,
                elementsToAdd,
                topLayerFrame,
              );
            } else if (!topLayerFrame) {
              if (this.state.editingGroupId) {
                const elementsToRemove = selectedElements.filter(
                  (element) =>
                    element.frameId &&
                    !isElementInFrame(element, nextElements, this.state),
                );

                updateGroupIdsAfterEditingGroup(elementsToRemove);
              }
            }

            nextElements = updateFrameMembershipOfSelectedElements(
              nextElements,
              this.state,
              this as any,
            );

            this.scene.replaceAllElements(nextElements as any);
          }
        }
      }

      if (isFrameLikeElement(draggingElement)) {
        const elementsInsideFrame = getElementsInNewFrame(
          this.scene.getElementsIncludingDeleted(),
          draggingElement,
          this.scene.getNonDeletedElementsMap(),
        );
        this.scene.replaceAllElements(
          addElementsToFrame(
            this.scene.getElementsMapIncludingDeleted(),
            elementsInsideFrame,
            draggingElement,
          ) as any,
        );
      }

      mutateElement(
        draggingElement as ExcalidrawElement,
        getNormalizedDimensions(
          draggingElement as Pick<
            ExcalidrawElement,
            "width" | "height" | "x" | "y"
          >,
        ),
      );

      if (resizingElement) {
        this.store.shouldCaptureIncrement();
      }

      if (resizingElement && isInvisiblySmallElement(resizingElement)) {
        this.scene.replaceAllElements(
          this.scene
            .getElementsIncludingDeleted()
            .filter((el) => el.id !== resizingElement.id),
        );
      }

      // handle frame membership for resizing frames and/or selected elements
      if (pointerDownState.resize.isResizing) {
        let nextElements = updateFrameMembershipOfSelectedElements(
          this.scene.getElementsIncludingDeleted(),
          this.state,
          this as any,
        );

        const selectedFrames = this.scene
          .getSelectedElements(this.state)
          .filter((element): element is ExcalidrawFrameLikeElement =>
            isFrameLikeElement(element),
          );

        for (const frame of selectedFrames) {
          nextElements = replaceAllElementsInFrame(
            nextElements,
            getElementsInResizingFrame(
              this.scene.getElementsIncludingDeleted(),
              frame,
              this.state,
              this.scene.getNonDeletedElementsMap(),
            ),
            frame,
          );
        }
        this.scene.replaceAllElements(nextElements);
      }

      // Code below handles selection when element(s) weren't
      // drag or added to selection on pointer down phase.
      const hitElement = pointerDownState.hit.element;
      if (
        this.state.selectedLinearElement?.elementId !== hitElement?.id &&
        isLinearElement(hitElement)
      ) {
        const selectedELements = this.scene.getSelectedElements(this.state);
        // set selectedLinearElement when no other element selected except
        // the one we've hit
        if (selectedELements.length === 1) {
          this.setState({
            selectedLinearElement: new LinearElementEditor(
              hitElement,
              this.scene,
            ),
          });
        }
      }
      if (
        hitElement &&
        !pointerDownState.drag.hasOccurred &&
        !pointerDownState.hit.wasAddedToSelection && // if we're editing a line, pointerup shouldn't switch selection if
        // box selected
        (!this.state.editingLinearElement ||
          !pointerDownState.boxSelection.hasOccurred)
      ) {
        if (childEvent.shiftKey) {
          if (this.state.selectedElementIds[hitElement.id]) {
            if (isSelectedViaGroup(this.state, hitElement)) {
              this.setState((_prevState) => {
                const nextSelectedElementIds = {
                  ..._prevState.selectedElementIds,
                };

                // We want to unselect all groups hitElement is part of
                // as well as all elements that are part of the groups
                // hitElement is part of
                for (const groupedElement of hitElement.groupIds.flatMap(
                  (groupId) =>
                    getElementsInGroup(
                      this.scene.getNonDeletedElements(),
                      groupId,
                    ),
                )) {
                  delete nextSelectedElementIds[groupedElement.id];
                }

                return {
                  selectedGroupIds: {
                    ..._prevState.selectedElementIds,
                    ...hitElement.groupIds
                      .map((gId) => ({ [gId]: false }))
                      .reduce((prev, acc) => ({ ...prev, ...acc }), {}),
                  },
                  selectedElementIds: makeNextSelectedElementIds(
                    nextSelectedElementIds,
                    _prevState,
                  ),
                };
              });
            } else if (!this.state.selectedLinearElement?.isDragging) {
              // remove element from selection while
              // keeping prev elements selected

              this.setState((prevState) => {
                const newSelectedElementIds = {
                  ...prevState.selectedElementIds,
                };
                delete newSelectedElementIds[hitElement!.id];
                const newSelectedElements = getSelectedElements(
                  this.scene.getNonDeletedElements(),
                  { selectedElementIds: newSelectedElementIds },
                );

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: newSelectedElementIds,
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this as any,
                  ),
                  // set selectedLinearElement only if thats the only element selected
                  selectedLinearElement:
                    newSelectedElements.length === 1 &&
                    isLinearElement(newSelectedElements[0])
                      ? new LinearElementEditor(
                          newSelectedElements[0],
                          this.scene,
                        )
                      : prevState.selectedLinearElement,
                };
              });
            } else if (
              hitElement.frameId &&
              this.state.selectedElementIds[hitElement.frameId]
            ) {
              // when hitElement is part of a selected frame, deselect the frame
              // to avoid frame and containing elements selected simultaneously
              this.setState((prevState) => {
                const nextSelectedElementIds: {
                  [id: string]: boolean;
                } = {
                  ...prevState.selectedElementIds,
                  [hitElement.id]: true,
                };
                // deselect the frame
                delete nextSelectedElementIds[hitElement.frameId!];

                // deselect groups containing the frame
                (this.scene.getElement(hitElement.frameId!)?.groupIds ?? [])
                  .flatMap((gid) =>
                    getElementsInGroup(this.scene.getNonDeletedElements(), gid),
                  )
                  .forEach((element) => {
                    delete nextSelectedElementIds[element.id];
                  });

                return {
                  ...selectGroupsForSelectedElements(
                    {
                      editingGroupId: prevState.editingGroupId,
                      selectedElementIds: nextSelectedElementIds,
                    },
                    this.scene.getNonDeletedElements(),
                    prevState,
                    this as any,
                  ),
                  showHyperlinkPopup:
                    hitElement.link || isEmbeddableElement(hitElement)
                      ? "info"
                      : false,
                };
              });
            } else {
              // add element to selection while keeping prev elements selected
              this.setState((_prevState) => ({
                selectedElementIds: makeNextSelectedElementIds(
                  {
                    ..._prevState.selectedElementIds,
                    [hitElement!.id]: true,
                  },
                  _prevState,
                ),
              }));
            }
          } else {
            this.setState((prevState) => ({
              ...selectGroupsForSelectedElements(
                {
                  editingGroupId: prevState.editingGroupId,
                  selectedElementIds: { [hitElement.id]: true },
                },
                this.scene.getNonDeletedElements(),
                prevState,
                this as any,
              ),
              selectedLinearElement:
                isLinearElement(hitElement) &&
                // Don't set `selectedLinearElement` if its same as the hitElement, this is mainly to prevent resetting the `hoverPointIndex` to -1.
                // Future we should update the API to take care of setting the correct `hoverPointIndex` when initialized
                prevState.selectedLinearElement?.elementId !== hitElement.id
                  ? new LinearElementEditor(hitElement, this.scene)
                  : prevState.selectedLinearElement,
            }));
          }
        }
      }

      if (
        !pointerDownState.drag.hasOccurred &&
        !this.state.isResizing &&
        ((hitElement &&
          hitElementBoundingBoxOnly(
            {
              x: pointerDownState.origin.x,
              y: pointerDownState.origin.y,
              element: hitElement,
              shape: getElementShape(
                hitElement,
                this.scene.getNonDeletedElementsMap(),
              ),
              threshold: this.getElementHitThreshold(),
              frameNameBound: null,
            },
            this.scene.getNonDeletedElementsMap(),
          )) ||
          (!hitElement &&
            pointerDownState.hit.hasHitCommonBoundingBoxOfSelectedElements))
      ) {
        if (this.state.editingLinearElement) {
          this.setState({ editingLinearElement: null });
        } else {
          // Deselect selected elements
          this.setState({
            selectedElementIds: {},
            selectedGroupIds: {},
            editingGroupId: null,
          });
        }
        // reset cursor
        setCursor(this.canvas, CURSOR_TYPE.AUTO);
        return;
      }

      if (
        !elementLocked &&
        activeTool.type !== "freedraw" &&
        draggingElement &&
        draggingElement.type !== "selection"
      ) {
        this.setState((prevState) => ({
          selectedElementIds: makeNextSelectedElementIds(
            {
              ...prevState.selectedElementIds,
              [draggingElement.id]: true,
            },
            prevState,
          ),
          // showHyperlinkPopup:
          //   isEmbeddableElement(draggingElement) && !draggingElement.link
          //     ? "editor"
          //     : prevState.showHyperlinkPopup,
        }));
      }

      if (
        activeTool.type !== "selection" ||
        isSomeElementSelected(this.scene.getElements(), this.state)
      ) {
        this.store.shouldCaptureIncrement();
      }

      if (pointerDownState.drag.hasOccurred || isResizing || isRotating) {
        // We only allow binding via linear elements, specifically via dragging
        // the endpoints ("start" or "end").
        const linearElements = this.scene
          .getSelectedElements(this.state)
          .filter(isLinearElement);
        bindOrUnbindLinearElements(
          linearElements,
          this.scene.getNonDeletedElementsMap(),
          isBindingEnabled(this.state),
          this.state.selectedLinearElement?.selectedPointsIndices ?? [],
        );
      }
      if (this.state.activeTool?.type === "laser") {
        this.laserTrails.endPath();
        return;
      }

      if (!elementLocked && activeTool.type !== "freedraw") {
        resetCursor(this.canvas);
        this.setState({
          draggingElement: null,
          suggestedBindings: [],
        });
        this.setActiveTool({
          type: "selection",
        });
      } else {
        this.setState({
          draggingElement: null,
          suggestedBindings: [],
        });
      }

      if (
        hitElement &&
        this.lastPointerUpEvent &&
        this.lastPointerDownEvent &&
        this.lastPointerUpEvent.timeStamp -
          this.lastPointerDownEvent.timeStamp <
          300 &&
        gesture.pointers.size <= 1 &&
        isIframeLikeElement(hitElement) &&
        this.isIframeLikeElementCenter(
          hitElement,
          this.lastPointerUpEvent,
          pointerDownState.origin.x,
          pointerDownState.origin.y,
        )
      ) {
        this.handleEmbeddableCenterClick(hitElement);
      }
    });
  }
  resetCursor() {
    throw new Error("Method not implemented.");
  }

  private convertDataUrlToFile = async (dataUrl: string, filename: string) => {
    const res = await fetch(dataUrl);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: "image/png" });
  };

  private initializeImage = async ({
    imageFile,
    imageElement: _imageElement,
    showCursorImagePreview = false,
    renderWithPreviousSize = false,
    upload = true,
    fileSize = 2,
    alreadyUploadOnStorage = false,
  }: {
    imageFile: File;
    imageElement:
      | ExcalidrawImageElement
      | ExcalidrawFormulaElement
      | ExcalidrawTextWithStyleElement
      | ExcalidrawMermaidDiagramElement;
    showCursorImagePreview?: boolean;
    renderWithPreviousSize?: boolean;
    upload?: boolean;
    fileSize?: number;
    alreadyUploadOnStorage?: boolean;
  }) => {
    // at this point this should be guaranteed image file, but we do this check
    // to satisfy TS down the line
    if (!isSupportedImageFile(imageFile)) {
      console.error("Image file not supported");
      throw new Error(t("errors.unsupportedFileType"));
    }
    const mimeType = imageFile.type;

    setCursor(this.canvas, "wait");

    if (mimeType === MIME_TYPES.svg) {
      try {
        imageFile = SVGStringToFile(
          await normalizeSVG(await imageFile.text()),
          imageFile.name,
        );
      } catch (error) {
        console.warn(error);
        throw new Error(t("errors.svgImageInsertError"));
      }
    }

    // generate image id (by default the file digest) before any
    // resizing/compression takes place to keep it more portable
    const fileId = await ((this.props.generateIdForFile?.(
      imageFile,
    ) as Promise<FileId>) || generateIdFromFile(imageFile));
    if (!fileId) {
      console.warn(
        "Couldn't generate file id or the supplied `generateIdForFile` didn't resolve to one.",
      );
      const element = this.scene.getNonDeletedElements();

      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== imageElement.id),
      ]);
      throw new Error(t("errors.imageInsertError"));
    }
    const existingFileData = this.state.files[fileId];
    if (!existingFileData?.dataURL) {
      try {
        imageFile = await resizeImageFile(imageFile, {
          maxWidthOrHeight: DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
        });
      } catch (error) {
        console.error("error trying to resing image file on insertion", error);
      }

      if (
        _imageElement.type !== "textWithStyles" &&
        imageFile.size > (fileSize * 1024 * 1024 || MAX_ALLOWED_FILE_BYTES)
      ) {
        throw new Error(
          t("errors.fileTooBig", {
            maxSize: `${Math.trunc(
              fileSize || MAX_ALLOWED_FILE_BYTES / 1024 / 1024,
            )}MB`,
          }),
        );
      }
    }

    if (showCursorImagePreview) {
      const dataURL = this.state.files[fileId]?.dataURL;

      // optimization so that we don't unnecessarily resize the original
      // full-size file for cursor preview
      // (it's much faster to convert the resized dataURL to File)
      const resizedFile = dataURL && dataURLToFile(dataURL);

      this.setImagePreviewCursor(resizedFile || imageFile);
    }

    const dataURL =
      this.state.files[fileId]?.dataURL || (await getDataURL(imageFile));

    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const imageElement = mutateElement(
      _imageElement,
      {
        fileId,
      },
      false,
    ) as NonDeleted<InitializedExcalidrawImageElement>;

    return new Promise<NonDeleted<InitializedExcalidrawImageElement>>(
      async (resolve, reject) => {
        try {
          upload &&
            this.setState({
              ...this.state,
              files: {
                ...this.state.files,
                [fileId]: {
                  mimeType,
                  id: fileId,
                  dataURL,
                  created: Date.now(),
                  isPublished: false,
                },
              },
            });

          const formData = new FormData();
          // const dataUrl = this.state.files[fileId]?.dataURL;

          for (const key in this.state.files) {
            if (Object.prototype.hasOwnProperty.call(this.state.files, key)) {
              const fileData = this.state.files[key];
              let file: any;
              await fetch(fileData?.dataURL)
                .then((response) => response.blob())
                .then((blob) => {
                  file = new File([blob], Object.keys(this.state.files)[0], {
                    type: blob.type,
                  });
                });

              formData.append("file", file);

              formData.append(
                "body",
                JSON.stringify({
                  ...fileData,
                  dataURL: "",
                  alreadyUploadOnStorage,
                }),
              );
            }
          }
          if (formData && formData.entries().next().done) {
            return; // Exit the function if no data is available
          }
          // Convert FormData to object
          const formDataObject: any = {};
          formData.forEach((value, key) => {
            formDataObject[key] = value;
          });

          window.parent.postMessage(
            { type: "UPLOAD_IMAGE", isLoading: true },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
          if (formData && formData.entries().next().done === false) {
            await uploadImageBlob(formData, lessonId)
              .then((res) => {
                window.parent.postMessage(
                  { type: "UPLOAD_IMAGE", isLoading: false },
                  `${process.env.REACT_APP_PARENT_APP}`,
                );
              })
              .catch((error) => {
                window.parent.postMessage(
                  { type: "UPLOAD_IMAGE", isLoading: false },
                  `${process.env.REACT_APP_PARENT_APP}`,
                );
                console.error("error in uploadImageBlob", error);
              });
          }

          const cachedImageData = this.imageCache.get(fileId);

          if (!cachedImageData && upload) {
            this.addNewImagesToImageCache();
            await this.updateImageCache([imageElement]);
          }
          if (cachedImageData?.image instanceof Promise) {
            await cachedImageData.image;
          }
          if (
            this.state.pendingImageElement?.id !== imageElement.id &&
            this.state.draggingElement?.id !== imageElement.id
          ) {
            if (renderWithPreviousSize) {
              this.initializeImageDimensions(imageElement, false, true);
            } else {
              this.initializeImageDimensions(imageElement, true);
            }
          }

          resolve(imageElement);
          if (imageElement.type === "image") {
            const user = await getUserInfo();
            const slug =
              new URLSearchParams(window.location.search).get("slug") || "";
            if (!alreadyUploadOnStorage) {
              await addUserStorage({
                email: user.mail,
                storage: imageFile.size / (1024 * 1024 * 1024),
                slug,
                role: user.actualRole,
                extension: "whiteboard",
                feature: "image",
                type: "image",
              });
            }
          }
        } catch (error: any) {
          console.error("error in file upload", error);
          const element = this.scene.getNonDeletedElements();

          this.scene.replaceAllElements([
            ...element.filter((el) => el.id !== imageElement.id),
          ]);
          reject(new Error(t("errors.imageInsertError")));
        } finally {
          if (!showCursorImagePreview) {
            resetCursor(this.canvas);
          }
        }
      },
    );
  };

  private initializeVideo = async ({
    videoFile,
    videoElement: _videoElement,
    showCursorVideoPreview = false,
    alreadyUploadOnStorage = false,
  }: {
    videoFile: File;
    videoElement: ExcalidrawVideoElement;
    showCursorVideoPreview?: boolean;
    alreadyUploadOnStorage?: boolean;
  }) => {
    const mimeType = videoFile.type;

    const fileId = await ((this.props.generateIdForFile?.(
      videoFile,
    ) as Promise<FileId>) || generateIdFromFile(videoFile));

    if (!fileId) {
      console.warn(
        "Couldn't generate file id or the supplied `generateIdForFile` didn't resolve to one.",
      );
      const element = this.scene.getNonDeletedElements();

      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== videoElement.id),
      ]);
      throw new Error(t("errors.imageInsertError"));
    }

    const existingFileData = this.state.files[fileId];
    if (!existingFileData?.dataURL) {
      try {
        videoFile = await resizeVideoFile(videoFile, {
          maxWidthOrHeight: DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
        });
      } catch (error) {
        console.error("error trying to resing image file on insertion", error);
      }

      // if (videoFile.size > MAX_ALLOWED_FILE_BYTES) {
      //   throw new Error(
      //     t("errors.fileTooBig", {
      //       maxSize: `${Math.trunc(MAX_ALLOWED_FILE_BYTES / 1024 / 1024)}MB`,
      //     }),
      //   );
      // }
    }

    if (showCursorVideoPreview) {
      const dataURL = this.state.files[fileId]?.dataURL;
      // optimization so that we don't unnecessarily resize the original
      // full-size file for cursor preview
      // (it's much faster to convert the resized dataURL to File)
      const resizedFile = dataURL && dataURLToFile(dataURL);

      this.setVideoPreviewCursor(resizedFile || videoFile);
    }

    const dataURL =
      this.state.files[fileId]?.dataURL || (await getDataURL(videoFile));

    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const videoElement = mutateElement(
      _videoElement,
      {
        fileId,
      },
      false,
    ) as NonDeleted<InitializedExcalidrawVideoElement>;

    return new Promise<NonDeleted<InitializedExcalidrawVideoElement>>(
      async (resolve, reject) => {
        try {
          this.setState({
            ...this.state,
            files: {
              ...this.state.files,
              [fileId]: {
                mimeType,
                id: fileId,
                dataURL,
                created: Date.now(),
              },
            } as any,
          });

          const formData = new FormData();
          const dataUrl = this.state.files[fileId]?.dataURL;
          let file: any;
          await fetch(dataUrl)
            .then((response) => response.blob())
            .then((blob) => {
              file = new File([blob], Object.keys(this.state.files)[0], {
                type: blob.type,
              });
            });

          formData.append("file", file);

          for (const key in this.state.files) {
            if (Object.prototype.hasOwnProperty.call(this.state.files, key)) {
              const fileData = this.state.files[key];
              formData.append(
                "body",
                JSON.stringify({
                  ...fileData,
                  dataURL: "",
                  alreadyUploadOnStorage,
                }),
              );
            }
          }
          if (formData && formData.entries().next().done === false) {
            await uploadVideoBlob(formData, lessonId);
          }

          const cachedVideoData = this.state.videoCache.get(fileId);
          if (!cachedVideoData) {
            this.addNewVideosToVideoCache();
            await this.updateVideoCache([videoElement]);
          }
          if (cachedVideoData?.video instanceof Promise) {
            await cachedVideoData.video;
          }
          if (
            this.state.pendingVideoElement?.id !== videoElement.id &&
            this.state.draggingElement?.id !== videoElement.id
          ) {
            this.initializeVideoDimensions(videoElement, true);
          }
          resolve(videoElement);

          const user = await getUserInfo();
          const slug =
            new URLSearchParams(window.location.search).get("slug") || "";
          if (!alreadyUploadOnStorage) {
            await addUserStorage({
              email: user.mail,
              storage: videoFile.size / (1024 * 1024 * 1024),
              slug,
              role: user.actualRole,
              extension: "whiteboard",
              feature: "video",
              type: "video",
            });
          }
        } catch (error: any) {
          console.error(error);
          const element = this.scene.getNonDeletedElements();

          this.scene.replaceAllElements([
            ...element.filter((el) => el.id !== videoElement.id),
          ]);
          reject(new Error(t("errors.imageInsertError")));
        } finally {
          if (!showCursorVideoPreview) {
            resetCursor(this.canvas);
          }
        }
      },
    );
  };
  private initializeAudio = async ({
    audioFile,
    audioElement: _audioElement,
    showCursorAudioPreview = false,
    fileName,
    color,
    alreadyUploadOnStorage = false,
  }: {
    audioFile: File;
    audioElement: ExcalidrawAudioElement;
    showCursorAudioPreview?: boolean;
    fileName?: string | null;
    color?: string | null;
    alreadyUploadOnStorage?: boolean;
  }) => {
    const mimeType = audioFile.type;

    const fileId = await ((this.props.generateIdForFile?.(
      audioFile,
    ) as Promise<FileId>) || generateIdFromFile(audioFile));

    if (!fileId) {
      console.warn(
        "Couldn't generate file id or the supplied `generateIdForFile` didn't resolve to one.",
      );
      const element = this.scene.getNonDeletedElements();

      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== audioElement.id),
      ]);
      throw new Error(t("errors.imageInsertError"));
    }

    const existingFileData = this.state.files[fileId];
    if (!existingFileData?.dataURL) {
      try {
        audioFile = await resizeAudioFile(audioFile, {
          maxWidthOrHeight: DEFAULT_MAX_IMAGE_WIDTH_OR_HEIGHT,
        });
      } catch (error) {
        console.error("error trying to resing image file on insertion", error);
      }
    }

    if (showCursorAudioPreview) {
      const dataURL = this.state.files[fileId]?.dataURL;
      // optimization so that we don't unnecessarily resize the original
      // full-size file for cursor preview
      // (it's much faster to convert the resized dataURL to File)
      const resizedFile = dataURL && dataURLToFile(dataURL);

      this.setAudioPreviewCursor(resizedFile || audioFile);
    }

    const dataURL =
      this.state.files[fileId]?.dataURL || (await getDataURL(audioFile));

    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const audioElement = mutateElement(
      _audioElement,
      {
        fileId,
      },
      false,
    ) as NonDeleted<InitializedExcalidrawAudioElement>;

    return new Promise<NonDeleted<InitializedExcalidrawAudioElement>>(
      async (resolve, reject) => {
        try {
          this.setState({
            ...this.state,
            files: {
              ...this.state.files,
              [fileId]: {
                mimeType,
                id: fileId,
                dataURL,
                created: Date.now(),
              },
            } as any,
          });
          const formData = new FormData();
          const dataUrl = this.state.files[fileId]?.dataURL;
          let file: any;
          await fetch(dataUrl)
            .then((response) => response.blob())
            .then((blob) => {
              file = new File([blob], Object.keys(this.state.files)[0], {
                type: blob.type,
              });
            });

          formData.append("file", file);

          for (const key in this.state.files) {
            if (Object.prototype.hasOwnProperty.call(this.state.files, key)) {
              const fileData = this.state.files[key];
              formData.append(
                "body",
                JSON.stringify({ ...fileData, dataURL: "" }),
              );
            }
          }
          if (formData && formData.entries().next().done === false) {
            await uploadAudioBlob(formData, lessonId);
          }

          const cachedAudioData = this.state.audioCache.get(fileId);
          if (!cachedAudioData) {
            this.addNewAudiosToAudioCache();
            await this.updateAudioCache([audioElement]);
          }
          if (cachedAudioData?.audio instanceof Promise) {
            await cachedAudioData.audio;
          }
          if (
            this.state.pendingAudioElement?.id !== audioElement.id &&
            this.state.draggingElement?.id !== audioElement.id
          ) {
            this.initializeAudioDimensions(audioElement, true, fileName);
          }
          resolve(audioElement);
          const user = await getUserInfo();
          const slug =
            new URLSearchParams(window.location.search).get("slug") || "";
          if (!alreadyUploadOnStorage) {
            await addUserStorage({
              email: user.mail,
              storage: audioFile.size / (1024 * 1024 * 1024),
              slug,
              role: user.actualRole,
              extension: "whiteboard",
              feature: "audio",
              type: "audio",
            });
          }
        } catch (error: any) {
          console.error(error);
          const element = this.scene.getNonDeletedElements();

          this.scene.replaceAllElements([
            ...element.filter((el) => el.id !== audioElement.id),
          ]);
          reject(new Error(t("errors.imageInsertError")));
        } finally {
          if (!showCursorAudioPreview) {
            resetCursor(this.canvas);
          }
        }
      },
    );
  };

  /**
   * inserts image into elements array and rerenders
   */
  private insertImageElement = async (
    imageElement:
      | ExcalidrawImageElement
      | ExcalidrawFormulaElement
      | ExcalidrawTextWithStyleElement
      | ExcalidrawMermaidDiagramElement,
    imageFile: File,
    showCursorImagePreview?: boolean,
    renderWithPreviousSize: boolean = false,
    upload: boolean = true, // Remove the question mark and initializer
    fileSize: number = 2,
    alreadyUploadOnStorage: boolean = false,
  ) => {
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      imageElement,
    ]);
    try {
      return await this.initializeImage({
        imageFile,
        imageElement,
        showCursorImagePreview,
        renderWithPreviousSize,
        upload,
        fileSize,
        alreadyUploadOnStorage,
      });
    } catch (error) {
      mutateElement(imageElement, {
        isDeleted: true,
      });
      this.actionManager.executeAction(actionFinalize);
      console.error("error in insertImageElement", error);
      const element = this.scene.getNonDeletedElements();

      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== imageElement.id),
      ]);
      this.setState({
        errorMessage: (error as Error).message || t("errors.imageInsertError"),
      });
      return null;
    }
  };
  /**
   * inserts image into elements array and rerenders
   */
  private insertVideoElement = async (
    videoElement: ExcalidrawVideoElement,
    videoFile: File,
    showCursorVideoPreview?: boolean,
    alreadyUploadOnStorage: boolean = false,
  ) => {
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      videoElement,
    ]);

    try {
      await this.initializeVideo({
        videoFile,
        videoElement,
        showCursorVideoPreview,
        alreadyUploadOnStorage,
      });
    } catch (error) {
      mutateElement(videoElement, {
        isDeleted: true,
      });
      this.actionManager.executeAction(actionFinalize);
      const element = this.scene.getNonDeletedElements();
      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== videoElement.id),
      ]);
      this.setState({
        errorMessage: (error as Error).message || t("errors.imageInsertError"),
      });
    }
  };
  private insertAudioElement = async (
    audioElement: ExcalidrawAudioElement,
    audioFile: File,
    showCursorAudioPreview?: boolean,
    fileName?: string | null,
    color?: string | null,
    alreadyUploadOnStorage: boolean = false,
  ) => {
    this.scene.replaceAllElements([
      ...this.scene.getElementsIncludingDeleted(),
      audioElement,
    ]);

    try {
      await this.initializeAudio({
        audioFile,
        audioElement,
        showCursorAudioPreview,
        fileName,
        color,
        alreadyUploadOnStorage,
      });
    } catch (error) {
      mutateElement(audioElement, {
        isDeleted: true,
      });
      this.actionManager.executeAction(actionFinalize);
      const element = this.scene.getNonDeletedElements();
      this.scene.replaceAllElements([
        ...element.filter((el) => el.id !== audioElement.id),
      ]);
      this.setState({
        errorMessage: (error as Error).message || t("errors.imageInsertError"),
      });
    }
  };

  private setImagePreviewCursor = async (imageFile: File) => {
    // mustn't be larger than 128 px
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Basic_User_Interface/Using_URL_values_for_the_cursor_property
    const cursorImageSizePx = 96;

    const imagePreview = await resizeImageFile(imageFile, {
      maxWidthOrHeight: cursorImageSizePx,
    });

    let previewDataURL = await getDataURL(imagePreview);

    // SVG cannot be resized via `resizeImageFile` so we resize by rendering to
    // a small canvas
    if (imageFile.type === MIME_TYPES.svg) {
      const img = await loadHTMLImageElement(previewDataURL);

      let height = Math.min(img.height, cursorImageSizePx);
      let width = height * (img.width / img.height);

      if (width > cursorImageSizePx) {
        width = cursorImageSizePx;
        height = width * (img.height / img.width);
      }

      const canvas = document.createElement("canvas");
      canvas.height = height;
      canvas.width = width;
      const context = canvas.getContext("2d")!;

      context.drawImage(img, 0, 0, width, height);

      previewDataURL = canvas.toDataURL(MIME_TYPES.svg) as DataURL;
    }
    if (this.state.pendingImageElement) {
      setCursor(this.canvas, `url(${previewDataURL}) 4 4, auto`);
    }
  };

  private setVideoPreviewCursor = async (videoFile: File) => {
    // mustn't be larger than 128 px
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Basic_User_Interface/Using_URL_values_for_the_cursor_property
    const cursorImageSizePx = 96;

    const videoPreview = await resizeVideoFile(videoFile, {
      maxWidthOrHeight: cursorImageSizePx,
    });

    if (!videoPreview) {
      return;
    }

    const previewDataURL = await getDataURL(videoPreview);

    if (this.state.pendingVideoElement) {
      setCursor(this.canvas, `url(${previewDataURL}) 4 4, auto`);
    }
  };
  private setAudioPreviewCursor = async (audioFile: File) => {
    // mustn't be larger than 128 px
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Basic_User_Interface/Using_URL_values_for_the_cursor_property
    const cursorImageSizePx = 96;

    const audioPreview = await resizeAudioFile(audioFile, {
      maxWidthOrHeight: cursorImageSizePx,
    });

    if (!audioPreview) {
      return;
    }

    const previewDataURL = await getDataURL(audioPreview);

    if (this.state.pendingAudioElement) {
      setCursor(this.canvas, `url(${previewDataURL}) 4 4, auto`);
    }
  };

  private onImageAction = async (
    { insertOnCanvasDirectly } = { insertOnCanvasDirectly: false },
  ) => {
    try {
      //for get setting data for pdf size
      const slug =
        new URLSearchParams(window.location.search).get("slug") || "";
      const user: any = JSON.parse(localStorage.getItem("user")!);
      const usedStorage = await getUserUsedStorage(
        slug,
        user.mail,
        user.actualRole,
      );
      if (
        usedStorage?.result?.providedStorage <= usedStorage?.result?.usedStorage
      ) {
        window.parent.postMessage(
          { type: "STORAGE_REQUEST" },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        return;
      }
      const imageFile = await fileOpen({
        description: "Image/PDF",
        extensions: ["jpg", "png", "svg", "gif", "pdf"],
      });
      let importedDocumentSizeInMB = 2;

      const validSlug = slug ?? "";
      const res = await getSettingData(validSlug);
      importedDocumentSizeInMB = res.data[0]?.importedDocumentSizeInMB;
      const mimeType = imageFile.type;

      if (mimeType === MIME_TYPES.pdf) {
        if (
          usedStorage?.result?.usedStorage +
            imageFile.size / (1024 * 1024 * 1024) >=
          usedStorage?.result?.providedStorage
        ) {
          window.parent.postMessage(
            { type: "STORAGE_REQUEST" },
            `${process.env.REACT_APP_PARENT_APP}`,
          );
          this.setState(
            {
              pendingImageElement: null,
              editingElement: null,
              elementType: "selection",
            },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } else if (imageFile.size > 1024 * 1024 * importedDocumentSizeInMB) {
          this.setState({
            errorMessage: `The file you selected is larger than ${importedDocumentSizeInMB} MB. Please select a file that is under ${importedDocumentSizeInMB} MB.`,
          });
          this.setActiveTool({
            type: "selection",
          });
          this.setState(
            {
              pendingImageElement: null,
              editingElement: null,
            },
            () => {
              this.actionManager.executeAction(actionFinalize);
            },
          );
        } else {
          this.setAppState({
            pdfPageSelectionDialog: true,
            pdfFile: imageFile,
          });
        }
        // await this.handlePdfFile(imageFile);
      } else if (imageFile.size > 2097152) {
        this.setState({
          errorMessage:
            "The image you selected is larger than 2 MB. Please select an image that is under 2 MB.",
        });
        this.setActiveTool({
          type: "selection",
        });
        this.setState(
          {
            pendingImageElement: null,
            editingElement: null,
          },
          () => {
            this.actionManager.executeAction(actionFinalize);
          },
        );
      }
      if (
        usedStorage?.result?.usedStorage +
          imageFile.size / (1024 * 1024 * 1024) >=
        usedStorage?.result?.providedStorage
      ) {
        window.parent.postMessage(
          { type: "STORAGE_REQUEST" },
          `${process.env.REACT_APP_PARENT_APP}`,
        );
        this.setState(
          {
            pendingImageElement: null,
            editingElement: null,
            elementType: "selection",
          },
          () => {
            this.actionManager.executeAction(actionFinalize);
          },
        );
        return; // Early return to avoid the else block
      }

      await this.processImage(
        imageFile,
        insertOnCanvasDirectly,
        mimeType,
        importedDocumentSizeInMB,
      );

      // else {
      //   await this.processImage(imageFile, insertOnCanvasDirectly);
      // }
    } catch (error) {
      if ((error as any).name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  handlePdfFile = async (
    selectedPages: Array<{ index: number; page: string }>,
    pdfId: string,
  ) => {
    const alreadyElementonCanvas = this.scene.getElements();

    let xOffset = 0; // Horizontal offset for each new PDF
    const pageSpacing = 20; // Spacing between pages
    let yOffset = 0; // Vertical offset for pages within a PDF

    if (alreadyElementonCanvas.length) {
      // Calculate the bounding box for existing elements to find the rightmost edge
      const bounds = alreadyElementonCanvas.reduce(
        (acc, element) => {
          acc.maxX = Math.max(acc.maxX, element.x + element.width);
          acc.maxY = Math.max(acc.maxY, element.y + element.height);
          return acc;
        },
        { maxX: 0, maxY: 0 },
      );

      // Set xOffset to position the new PDF to the right of the existing elements
      xOffset = bounds.maxX + this.state.width + 100 / 2;

      // Reset yOffset for vertical stacking of new pages
      yOffset = 0;
    } else {
      // Default xOffset for the first imported PDF
      xOffset = this.state.width / 2;
    }

    // Insert each page as an image element
    for (let index = 0; index < selectedPages.length; index++) {
      const { page: dataUrl } = selectedPages[index];
      const pageNumber = index + 1;

      const response = await this.convertDataUrlToFile(
        dataUrl,
        `page-${pageNumber}.png`,
      );

      const imageElement = await this.createImageElement({
        sceneX: xOffset,
        sceneY: yOffset,
        pdfId,
      });

      let importedDocumentSizeInMB = 2;
      const slug = new URLSearchParams(window.location.search).get("slug");
      const validSlug = slug ?? "";
      const res = await getSettingData(validSlug);
      importedDocumentSizeInMB = res.data[0]?.importedDocumentSizeInMB;

      await this.insertImageElement(
        imageElement,
        response,
        true,
        false,
        true,
        importedDocumentSizeInMB,
      );

      const imageHeight = imageElement.height; // Assuming the imageElement has a height property
      // Update yOffset for the next page
      yOffset += imageHeight + pageSpacing;

      this.initializeImageDimensions(imageElement);
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          selectedElementIds: {
            ...this.state.selectedElementIds,
            [imageElement.id]: true,
          },
          pdfPageSelectionDialog: false,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  async processImage(
    imageFile: any,
    insertOnCanvasDirectly: boolean,
    mimeType: typeof MIME_TYPES | string,
    importedDocumentSizeInMB: number,
  ) {
    const clientX = this.state.width / 2 + this.state.offsetLeft;
    const clientY = this.state.height / 2 + this.state.offsetTop;
    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      this.state,
    );

    const imageElement = await this.createImageElement({
      sceneX: x,
      sceneY: y,
    });
    if (insertOnCanvasDirectly) {
      await this.insertImageElement(
        imageElement,
        imageFile,
        true,
        false,
        true,
        mimeType === MIME_TYPES.pdf ? importedDocumentSizeInMB : 2,
      );
      this.initializeImageDimensions(imageElement);
      this.setState(
        {
          selectedElementIds: makeNextSelectedElementIds(
            { [imageElement.id]: true },
            this.state,
          ),
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } else {
      this.setState(
        {
          pendingImageElement: imageElement,
        },
        async () => {
          await this.insertImageElement(
            imageElement,
            imageFile,
            /* showCursorImagePreview */ true,
            false,
            true,
            mimeType === MIME_TYPES.pdf ? importedDocumentSizeInMB : 2,
          );
        },
      );
    }
  }

  private uploadImageFromLibrary = async (imageFile: File) => {
    try {
      const clientX = this.state.width / 2 + this.state.offsetLeft;
      const clientY = this.state.height / 2 + this.state.offsetTop;

      const { x, y } = viewportCoordsToSceneCoords(
        { clientX, clientY },
        this.state,
      );

      const gap = 220; // Set the gap between elements as you wish
      let imageElement = await this.createImageElement({
        sceneX: x,
        sceneY: y,
      });
      const alreadyElementonCanvas = this.scene.getElements();
      let newX = 0;
      let newY = this.state.height / 2;

      if (alreadyElementonCanvas.length) {
        // Find the maximum X position of the existing elements
        const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
          return Math.max(maxX, element.x + element.width + gap);
        }, 0);
        newX = maxCanvasX;
      } else {
        newX = imageElement.x;
        newY = imageElement.y;
      }

      // Update the image element with the new position
      imageElement = { ...imageElement, x: newX, y: newY };

      this.insertImageElement(
        imageElement,
        imageFile,
        false,
        false,
        true,
        2,
        true,
      );
      this.initializeImageDimensions(imageElement);
      this.setState(
        {
          selectedElementIds: { [imageElement.id]: true },
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } catch (error) {
      if ((error as any).name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  private renderElements = () => {
    const cursorButton: {
      [id: string]: string | undefined;
    } = {};
    const pointerViewportCoords: RenderConfig["remotePointerViewportCoords"] = {};
    const remoteSelectedElementIds: RenderConfig["remoteSelectedElementIds"] = {};
    const pointerUsernames: { [id: string]: string } = {};
    const pointerUserStates: { [id: string]: string } = {};
    this.state.collaborators.forEach((user, socketId) => {
      if (user.selectedElementIds) {
        for (const id of Object.keys(user.selectedElementIds)) {
          if (!(id in remoteSelectedElementIds)) {
            remoteSelectedElementIds[id] = [];
          }
          remoteSelectedElementIds[id].push(socketId);
        }
      }
      if (!user.pointer) {
        return;
      }
      if (user.username) {
        pointerUsernames[socketId] = user.username;
      }
      if (user.userState) {
        pointerUserStates[socketId] = user.userState;
      }
      pointerViewportCoords[socketId] = sceneCoordsToViewportCoords(
        {
          sceneX: user.pointer.x,
          sceneY: user.pointer.y,
        },
        this.state,
      );
      cursorButton[socketId] = user.button;
    });
    const sceneNonce = this.scene.getSceneNonce();
    const {
      elementsMap,
      visibleElements,
    } = this.renderer.getRenderableElements({
      sceneNonce,
      zoom: this.state.zoom,
      offsetLeft: this.state.offsetLeft,
      offsetTop: this.state.offsetTop,
      scrollX: this.state.scrollX,
      scrollY: this.state.scrollY,
      height: this.state.height,
      width: this.state.width,
      editingElement: this.state.editingElement,
      pendingImageElementId: this.state.pendingImageElementId,
      pendingAudioElementId: this.state.pendingAudioElement?.id ?? null,
      pendingVideoElementId: this.state.pendingVideoElement?.id ?? null,
    });

    renderStaticScene({
      canvas: this.canvas!,
      rc: rough.canvas(this.canvas!),
      elementsMap,
      allElementsMap: this.scene.getNonDeletedElementsMap(),
      selectedElements: this.scene.getSelectedElements(this.state),
      visibleElements: visibleElements as any,
      scale: window.devicePixelRatio,
      appState: {
        ...this.state,
        zoom: this.state.zoom,
        shouldCacheIgnoreZoom: false,
        theme: this.state.exportWithDarkMode ? "dark" : "light",
      },
      renderConfig: {
        canvasBackgroundColor: this.state.viewBackgroundColor,
        imageCache: this.imageCache,
        videoCache: this.state.videoCache,
        audioCache: this.state.audioCache,
        renderGrid: true,
        isExporting: true,
        // empty disables embeddable rendering
        embedsValidationStatus: new Map(),
        elementsPendingErasure: new Set(),
        remoteSelectedElementIds,
        remotePointerUsernames: pointerUsernames,
        remotePointerViewportCoords: pointerViewportCoords,
        remotePointerButton: cursorButton,
        remotePointerUserStates: pointerUserStates,
        selectionColor: {} as any,
      },
      intervalRef: this.intervalRef,
      countDownRef: this.countDownRef,
      appStateRef: this.appStateRef,
      countdownStates: this.countdownStates,
      chronometerScrollRef: this.chronometerScrollRef,
      stopwatchStates: this.stopwatchStates,
    });
  };

  private handleAudio = (audio: HTMLAudioElement) => {
    if (!this.canvas) {
      return;
    }
    if (!audio.paused || !audio.ended) {
      this.setState({
        audioInterval: setInterval(() => {
          this.renderElements();
        }, 10),
      });
    }
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  private handleMuteAudio = (audio: HTMLAudioElement) => {
    if (!this.canvas) {
      return;
    }
    if (audio.muted) {
      audio.muted = false;
    } else {
      audio.muted = true;
    }
  };

  private handleAudioSeekbar = (
    event: React.MouseEvent<HTMLDivElement>,
    audio: HTMLAudioElement,
    audioX: number,
  ) => {
    if (!this.canvas) {
      return;
    }
    const clickPosition = event.clientX - audioX - 152;
    const percentClicked = clickPosition / 200;
    const newTime = percentClicked * audio.duration;

    audio.currentTime = newTime;
  };

  private handleVideo = (video: HTMLVideoElement) => {
    if (!this.canvas) {
      return;
    }
    if (!video.paused || !video.ended) {
      this.setState({
        videoInterval: setInterval(() => {
          this.renderElements();
        }, 10),
      });
    }
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };
  private handleMuteVideo = (video: HTMLVideoElement) => {
    if (!this.canvas) {
      return;
    }
    if (video.muted) {
      video.muted = false;
    } else {
      video.muted = true;
    }
  };
  private handleVideoSeekbar = (
    event: React.MouseEvent<HTMLDivElement>,
    video: HTMLVideoElement,
    videoX: number,
  ) => {
    if (!this.canvas) {
      return;
    }
    const clickPosition = event.clientX - videoX;
    const percentClicked = clickPosition / video.videoWidth;
    const newTime = percentClicked * video.duration;
    video.currentTime = newTime;
  };

  private handleCanvasClick = async (event: any) => {
    if (!this.canvas) {
      return;
    }

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX: event.clientX, clientY: event.clientY },
      this.state,
    );

    this.scene.getElements().forEach((element) => {
      if (!this.canvas) {
        return;
      }
      if (isVideoElement(element)) {
        const videoX = element.x;
        const videoY = element.y;
        if (
          x >= videoX &&
          x <= videoX + element.width &&
          y >= videoY &&
          y <= videoY + element.height
        ) {
          const video = Array.from(this.state.videoCache).filter(
            (data) => data[0] === element.fileId,
          );
          const videoArray = Array.from(video);
          if (videoArray.length > 0) {
            const videoPromise = videoArray[0][1]?.video;

            if (
              x >= videoX + element.width - 25 &&
              x <= videoX + element.width &&
              y >= videoY + element.height - 25 &&
              y <= videoY + element.height
            ) {
              if (videoPromise instanceof Promise) {
                videoPromise
                  .then((videoElement) => {
                    this.handleMuteVideo(videoElement);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                this.handleMuteVideo(videoPromise);
              }
              return;
            } else if (
              x >= videoX &&
              x <= videoX + element.width &&
              y >= videoY + element.height - 40 &&
              y <= videoY + element.height + 5 - 38
            ) {
              if (videoPromise instanceof Promise) {
                videoPromise
                  .then((videoElement) => {
                    this.handleVideoSeekbar(event, videoElement, videoX);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                this.handleVideoSeekbar(event, videoPromise, videoX);
              }
              return;
            }
            if (videoPromise instanceof Promise) {
              videoPromise
                .then((videoElement) => {
                  this.handleVideo(videoElement);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.handleVideo(videoPromise);
            }
          }
        }
      }
      if (isAudioElement(element)) {
        const audioX = element.x;
        const audioY = element.y;
        const iconWidth = 38; // The width of the audio icon
        const padding = 15; // Padding between the icon and the text
        const context = this.canvas.getContext("2d")!;
        // Set the font before measuring text
        context.font = "20px Arial";
        const textWidth = context.measureText(element.fileName as string).width;
        const width = textWidth + iconWidth + padding * 2; // icon + padding + text width
        const elementWidth = element.fileName ? width : 400;
        const elementHeight = 55;
        const seekBarWidth = 205;
        const seekBarHeight = 5;

        const audio = Array.from(this.state.audioCache).filter(
          (data) => data[0] === element.fileId,
        );
        if (Array.from(audio)[0]) {
          const audioPromise = Array.from(audio)[0][1].audio;
          if (
            x >= audioX + elementWidth - 44 &&
            x <= audioX + elementWidth &&
            y >= audioY + (elementHeight - 15) / 2 &&
            y <= audioY + elementHeight
          ) {
            if (audioPromise instanceof Promise) {
              audioPromise
                .then((audioElement) => {
                  this.handleMuteAudio(audioElement);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.handleMuteAudio(audioPromise);
            }
          } else if (
            (x >= audioX + 15 &&
              x <= audioX + 43 &&
              y >= audioY + 15 &&
              y <= audioY + elementHeight - 15) ||
            (x >= audioX &&
              x <= audioX + elementWidth &&
              y >= audioY &&
              y <= audioY + elementHeight)
          ) {
            // this.renderElements();
            if (audioPromise instanceof Promise) {
              audioPromise
                .then((audioElement) => {
                  this.handleAudio(audioElement);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.handleAudio(audioPromise);
            }
          } else if (
            x >= audioX + 145 &&
            x <= audioX + 163 + seekBarWidth &&
            y >= audioY + 24 &&
            y <= audioY + 24 + seekBarHeight
          ) {
            if (audioPromise instanceof Promise) {
              audioPromise
                .then((audioElement) => {
                  this.handleAudioSeekbar(event, audioElement, audioX);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.handleAudioSeekbar(event, audioPromise, audioX);
            }
          }
        }
      }
      if (isFormulaElement(element)) {
        const imageX = element.x;
        const imageY = element.y;
        if (
          x >= imageX &&
          x <= imageX + element.width &&
          y >= imageY &&
          y <= imageY + element.height
        ) {
          this.setState({
            selectedElementIds: {
              [element.id]: true,
            },
          });
          return;
        }
      }
      if (isMermaidDiagramElement(element)) {
        const imageX = element.x;
        const imageY = element.y;
        if (
          x >= imageX &&
          x <= imageX + element.width &&
          y >= imageY &&
          y <= imageY + element.height
        ) {
          this.setState({
            selectedElementIds: {
              [element.id]: true,
            },
          });
          return;
        }
      }

      if (isTextWithStylesElement(element)) {
        const imageX = element.x;
        const imageY = element.y;
        if (
          x >= imageX &&
          x <= imageX + element.width &&
          y >= imageY &&
          y <= imageY + element.height
        ) {
          if (
            this.state.editableSelectedElement.isSelected &&
            this.state.editableSelectedElement.elementId === element.id &&
            !this.state.editableSelectedElement.selectedElementIds[element.id]
          ) {
            mutateElement(element, { isDeleted: true });
            // This is for viewing text-editor content as chat.
            // If div has the text-end class, then remove that div and add the style into the p tag.
            const htmlString = element.textString.replace(
              /<div[^>]*class="[^"]*text-end[^"]*"[^>]*>\s*<p([^>]*)>(.*?)<\/p>\s*<\/div>/g,
              '<p$1 style="text-align: right"><mark style="background-color: #5b5e9f33;">$2</mark></p>',
            );

            this.setState(
              {
                textEditor: {
                  ...this.state.textEditor,
                  open: true,
                  sceneX: imageX,
                  sceneY: imageY - 103, // 103 is the height of the text editor header with spacing
                  value: htmlString,
                  width: element.width,
                  height: element.height,
                  resize: element.textEditor?.resize ?? false,
                  resizeHeight: element.textEditor?.resizeHeight ?? 0,
                  resizeWidth: element.textEditor?.resizeWidth ?? 0,
                },
                updatingTextElement: element,
                editableSelectedElement: {
                  ...this.state.editableSelectedElement,
                  isSelected: false,
                  elementId: "",
                },
              },
              () => {
                this.actionManager.executeAction(actionFinalize);
                this.togglekeyboardShortcut(false);
                this.setState({
                  selectedElementIds: {
                    [element.id]: true,
                  },
                });
              },
            );
          } else {
            this.setState({
              editableSelectedElement: {
                ...this.state.editableSelectedElement,
                isSelected: true,
                elementId: element.id,
                selectedElementIds: {
                  [element.id]: true,
                },
              },
            });
          }
          if (!this.state.elementLocked) {
            this.setActiveTool({
              type: "selection",
            });
          }
          return null;
        }
      }
    });

    if (this.state.showMathInputAndKeyboard) {
      this.setState({
        selectedElementIds: {
          [Object.keys(this.state.previousSelectedElementIds)[0]]: true,
        },
      });
    }
  };

  private handleMouseMove = (event: any) => {
    if (!this.canvas) {
      return;
    }

    this.scene.getElements().forEach((element) => {
      if (!this.canvas) {
        return;
      }
      if (isVideoElement(element)) {
        const videoX = element.x;
        const videoY = element.y;
        if (
          event.clientX >= videoX &&
          event.clientX <= videoX + element.width &&
          event.clientY >= videoY &&
          event.clientY <= videoY + element.height
        ) {
          this.canvas.style.cursor = "pointer";
        }
      }
    });
  };

  private uploadVideoFromLibrary = async (
    videoFile: File,
    fileName?: string | null,
    color?: string | null,
  ) => {
    try {
      const clientX = this.state.width / 2 + this.state.offsetLeft;
      const clientY = this.state.height / 2 + this.state.offsetTop;

      const { x, y } = viewportCoordsToSceneCoords(
        { clientX, clientY },
        this.state,
      );

      const videoElement = await this.createVideoElement({
        sceneX: x,
        sceneY: y,
        fileName,
        color,
      });

      this.insertVideoElement(videoElement, videoFile, false, true);
      this.initializeVideoDimensions(videoElement);
      this.setState(
        {
          selectedElementIds: { [videoElement.id]: true },
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } catch (error) {
      if ((error as any).name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  private uploadAudioFromLibrary = async (
    audioFile: File,
    fileName?: string | null,
    color?: string | null,
  ) => {
    try {
      const clientX = this.state.width / 2 + this.state.offsetLeft;
      const clientY = this.state.height / 2 + this.state.offsetTop;

      const { x, y } = viewportCoordsToSceneCoords(
        { clientX, clientY },
        this.state,
      );

      const audioElement = await this.createAudioElement({
        sceneX: x,
        sceneY: y,
        fileName,
        color,
      });

      this.insertAudioElement(
        audioElement,
        audioFile,
        false,
        fileName,
        color,
        true,
      );
      this.initializeAudioDimensions(audioElement, false, fileName, color);
      this.setState(
        {
          selectedElementIds: { [audioElement.id]: true },
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } catch (error) {
      if ((error as any).name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  private togglekeyboardShortcut = (isAdding: boolean) => {
    if (isAdding) {
      document.addEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    } else {
      document.removeEventListener(EVENT.KEYDOWN, this.onKeyDown, false);
    }
  };

  public toggleZoomInZoomOut = (isFullScreen?: boolean) => {
    if (isFullScreen) {
      window.parent.postMessage(
        { type: "ZOOM_IN_OUT", isZoom: true },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
          elementType: "selection",
          isFullScreen: true,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } else if (isFullScreen === false) {
      window.parent.postMessage(
        { type: "ZOOM_IN_OUT", isZoom: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
          elementType: "selection",
          isFullScreen: false,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
    if (!this.state.isFullScreen) {
      window.parent.postMessage(
        { type: "ZOOM_IN_OUT", isZoom: false },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    } else {
      window.parent.postMessage(
        { type: "ZOOM_IN_OUT", isZoom: true },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      this.setActiveTool({
        type: "selection",
      });
      this.setState(
        {
          pendingImageElement: null,
          editingElement: null,
        },
        () => {
          this.actionManager.executeAction(actionFinalize);
        },
      );
    }
  };

  private renderFormula = async (
    mathFormulaComponent: React.RefObject<MathfieldElement>,
    formulaString?: string,
  ) => {
    this.togglekeyboardShortcut(true);
    if (!this.canvas) {
      return;
    }
    if (!mathFormulaComponent.current) {
      return;
    }
    const insertOnCanvasDirectly = true;
    const allElements = this.scene.getElements() as readonly ExcalidrawElement[];

    const mathFormulaShadowRoot = mathFormulaComponent.current.shadowRoot;

    const mainFormulaContent = mathFormulaShadowRoot?.querySelector<HTMLElement>(
      'span[part="content"].ML__content',
    );

    const clientX = this.state.width / 2 + this.state.offsetLeft;
    const clientY = this.state.height / 2 + this.state.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      this.state,
    );

    // const imageElement = this.createImageElement({
    //   sceneX: x,
    //   sceneY: y,
    // });
    let imageElement: NonDeleted<ExcalidrawFormulaElement>;

    if (this.state.updatingFormulaElement.id) {
      const updatingFormulaElement = allElements.find(
        (elms) => elms.id === this.state.updatingFormulaElement.id,
      ) as ExcalidrawElement;
      imageElement = updatingFormulaElement as NonDeleted<ExcalidrawFormulaElement>;
      imageElement = {
        ...imageElement,
        height: updatingFormulaElement.height,
        width: updatingFormulaElement.width,
        formulaString,
      };
      this.actionManager.executeAction(actionDeleteSelected);
    } else {
      imageElement = await this.createFormulaElement({
        sceneX: x,
        sceneY: y,
        formulaString,
      });
    }

    if (!mainFormulaContent) {
      return;
    }
    html2canvas(mainFormulaContent, { scale: 3 })
      .then((imageCanvas) => {
        const dataUrl = imageCanvas.toDataURL(); // Get the image URL
        const img = new Image();
        img.src = dataUrl;
        this.convertDataUrlToFile(dataUrl, `mathFormula.png`)
          .then(async (response) => {
            if (insertOnCanvasDirectly) {
              if (this.state.updatingFormulaElement.id) {
                this.insertImageElement(imageElement, response, false, true);
                this.initializeImageDimensions(imageElement, false);
              } else {
                this.insertImageElement(imageElement, response);
                this.initializeImageDimensions(imageElement, true);
              }
              this.setState(
                {
                  selectedElementIds: { [imageElement.id]: true },
                },
                () => {
                  this.actionManager.executeAction(actionFinalize);
                },
              );
            } else {
              this.setState(
                {
                  pendingImageElement: imageElement,
                },
                () => {
                  this.insertImageElement(
                    imageElement,
                    response,
                    /* showCursorImagePreview */ true,
                  );
                },
              );
            }
          })
          .catch((e) => console.error("Convert to data file error"))
          .finally(() => {
            this.setState({
              showMathInputAndKeyboard: false,
              updatingFormulaElement: {
                id: "",
                width: 0,
                height: 0,
              },
              formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
            });
          });
      })
      .catch((e) => console.error("html2canvas error ", e));
  };

  private renderTextWithStyles = async ({
    textString,
    show = true,
    handleCloseDialogue = null,
    isAIGenerated = false,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction | null;
    isAIGenerated?: boolean;
    width?: number;
  }) => {
    if (!this.canvas) {
      return;
    }
    const insertOnCanvasDirectly = true;
    const { sceneX, sceneY } = this.state.textEditor;
    let imageElement: NonDeleted<ExcalidrawTextWithStyleElement>;

    if (this.state.updatingTextElement) {
      imageElement = this.state
        .updatingTextElement as NonDeleted<ExcalidrawTextWithStyleElement>;

      imageElement = {
        ...imageElement,
        textString,
        isDeleted: !show,
        x: sceneX,
        y: sceneY + 103, // 103 is the height of the text editor header with spacing
      };
      // this.actionManager.executeAction(actionDeleteSelected); // Execute action comment because when an element is already in editing mode and another element (circle) is clicked, followed by clicking outside, the selected element will be deleted.
    } else {
      imageElement = await this.createTextWithStylesElement({
        sceneX,
        sceneY: sceneY + 103, // 103 is the height of the text editor header with spacing
        textString,
      });
      imageElement = { ...imageElement, isDeleted: !show };
    }

    //If AI generated the insert that text on the canvas without overlapping with other elements
    if (isAIGenerated) {
      const alreadyElementonCanvas = this.scene.getElements();
      let newX = imageElement.x + 50; // 50 is for gap between 2 elements
      let newY = imageElement.y;
      if (alreadyElementonCanvas.length) {
        const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
          return Math.max(maxX, element.x + element.width);
        }, 0);

        newX += maxCanvasX;
        imageElement = {
          ...imageElement,
          x: width ? newX + width : newX,
        };
      } else {
        newX = this.state.width / 3.5;
        newY = this.state.height / 3.5;
        imageElement = {
          ...imageElement,
          x: width ? newX + width : newX,
          y: newY,
        };
      }
    }

    convertHtmlToCanvas(textString, "textImage.png", this.state.textEditor)
      .then((res: any) => {
        const { dataUrl, width, height } = res;
        const updatingTextElement = this.state
          .updatingTextElement as ExcalidrawTextWithStyleElement;
        if (updatingTextElement) {
          const isUpdateElement = updatingTextElement
            ? Object.keys(updatingTextElement).length
            : null;
          const isFontSizeChanged =
            updatingTextElement.zoomFactor?.isFontSizeChanged;
          imageElement = {
            ...imageElement,
            width,
            height,
            defaultWidth: !isUpdateElement
              ? width
              : isFontSizeChanged
              ? width
              : updatingTextElement.defaultWidth,
            defaultHeight: !isUpdateElement
              ? height
              : isFontSizeChanged
              ? height
              : updatingTextElement.defaultHeight,
            zoomFactor: {
              zoomFactor: !isUpdateElement
                ? 1
                : updatingTextElement.zoomFactor?.zoomFactor,
              isZoomFactorApplied:
                updatingTextElement.zoomFactor?.isZoomFactorApplied,
              //@ts-ignore
              height: updatingTextElement.zoomFactor?.defaultHeight,
              //@ts-ignore
              width: updatingTextElement.zoomFactor?.defaultWidth,
              isFontSizeChanged:
                updatingTextElement.zoomFactor?.isFontSizeChanged || false,
            },
            textEditor: {
              resize: this.state.textEditor.resize,
              resizeWidth: this.state.textEditor.resizeWidth,
              resizeHeight: this.state.textEditor.resizeHeight,
            },
          };
        } else {
          imageElement = {
            ...imageElement,
            width,
            height,
            defaultWidth: width,
            defaultHeight: height,
            zoomFactor: {
              zoomFactor: 1,
              isZoomFactorApplied: false,
              isFontSizeChanged: false,
            },
            textEditor: {
              resize: this.state.textEditor.resize,
              resizeWidth: this.state.textEditor.resizeWidth,
              resizeHeight: this.state.textEditor.resizeHeight,
            },
          };
        }
        if (dataUrl) {
          this.convertDataUrlToFile(dataUrl as string, `textImage.png`).then(
            async (response) => {
              if (insertOnCanvasDirectly) {
                if (
                  this.state.updatingTextElement &&
                  Object.keys(this.state.updatingTextElement).length
                ) {
                  await this.insertImageElement(
                    imageElement,
                    response,
                    false,
                    true,
                    show || true,
                  );
                  this.initializeImageDimensions(imageElement, true, true);
                  this.store.shouldCaptureIncrement();
                } else {
                  this.insertImageElement(
                    imageElement,
                    response,
                    false,
                    true,
                    show || true,
                  );
                  this.initializeImageDimensions(imageElement, true);
                  this.store.shouldCaptureIncrement();
                }
              } else {
                this.setState(
                  {
                    pendingImageElement: imageElement,
                  },
                  () => {
                    this.insertImageElement(
                      imageElement,
                      response,
                      /* showCursorImagePreview */ true,
                      false,
                      true,
                    );
                  },
                );
                this.store.shouldCaptureIncrement();
              }
            },
          );
        }
      })
      .catch((e) => {
        console.error("Convert to data file error", e);
      })
      .finally(() => {
        if (isAIGenerated) {
          this.scrollToContent(imageElement);
        }
        if (
          !this.state.updatingTextElement ||
          this.state.updatingTextElement.id === imageElement.id
        ) {
          this.setState({
            textEditor: {
              ...this.state.textEditor,
              open: false,
              sceneX: 0,
              sceneY: 0,
              value: "",
              width: 0,
              height: 0,
              resizeHeight: 0,
              resizeWidth: 0,
              resize: false,
            },
            isResizableOrNot: false,
            selectedElementIds: {},
            selectedGroupIds: {},
            editingGroupId: null,
            updatingTextElement: null,
          });
          this.togglekeyboardShortcut(true);
        } else {
          this.setState({
            textEditor: {
              ...this.state.textEditor,
              value: this.state.updatingTextElement.textString,
            },
          });
        }

        if (handleCloseDialogue) {
          handleCloseDialogue();
        }
      });
  };

  private executeFinalizeAction = () => {
    this.actionManager.executeAction(actionFinalize);
  };

  private initializeImageDimensions = (
    imageElement:
      | ExcalidrawImageElement
      | ExcalidrawFormulaElement
      | ExcalidrawTextWithStyleElement
      | ExcalidrawMermaidDiagramElement,
    forceNaturalSize = false,
    forceCustomSize: boolean = false,
  ) => {
    const image =
      (isInitializedImageElement(imageElement) ||
        isInitializedFormulaElement(imageElement) ||
        isInitializedTextWithStylesElement(imageElement) ||
        isInitializedMermaidDiagramElement(imageElement)) &&
      this.imageCache.get(imageElement.fileId)?.image;

    if (!image || image instanceof Promise) {
      if (
        imageElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        imageElement.height < DRAGGING_THRESHOLD / this.state.zoom.value
      ) {
        const placeholderSize = 100 / this.state.zoom.value;
        mutateElement(imageElement, {
          x: imageElement.x - placeholderSize / 2,
          y: imageElement.y - placeholderSize / 2,
          width: image ? imageElement.width : placeholderSize,
          height: image ? imageElement.height : placeholderSize,
        });
      }
    } else {
      let width = imageElement.width;
      let height = imageElement.height;

      if (
        forceNaturalSize ||
        (width < DRAGGING_THRESHOLD / this.state.zoom.value &&
          height < DRAGGING_THRESHOLD / this.state.zoom.value) ||
        imageElement.type !== "formula"
      ) {
        // Calculate new dimensions based on the image's natural size
        if (imageElement.type !== "textWithStyles") {
          width = image.naturalWidth;
          height = image.naturalHeight;
        }
      } else if (forceCustomSize) {
        // Calculate new dimensions based on custom logic
        // For example:
        // width = customWidth;
        // height = customHeight;
      }

      // Calculate new x and y positions to center the element
      const x = imageElement.x + (imageElement.width - width) / 2;
      const y = imageElement.y + (imageElement.height - height) / 2;

      // Update imageElement using mutateElement
      mutateElement(imageElement, { x, y, width, height });
      // if (imageElement.type === "formula") {
      const elements: ExcalidrawElement[] = [...this.scene.getElements()];
      const filterImageElement = elements.findIndex((element) => {
        return element.id === imageElement.id;
      });
      if (filterImageElement !== -1) {
        elements[filterImageElement] = imageElement;
        this.scene.replaceAllElements(elements);
      } else {
        this.scene.replaceAllElements([...elements, imageElement]);
      }

      // }
    }
  };
  private initializeVideoDimensions = (
    videoElement: ExcalidrawVideoElement,
    forceNaturalSize = false,
  ) => {
    const video =
      isInitializedVideoElement(videoElement) &&
      this.state.videoCache.get(videoElement.fileId)?.video;

    if (!video || video instanceof Promise) {
      if (
        videoElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        videoElement.height < DRAGGING_THRESHOLD / this.state.zoom.value
      ) {
        const placeholderSize = 100 / this.state.zoom.value;
        mutateElement(videoElement, {
          x: videoElement.x - placeholderSize / 2,
          y: videoElement.y - placeholderSize / 2,
          width: placeholderSize,
          height: placeholderSize,
        });
      }

      return;
    }

    if (
      forceNaturalSize ||
      // if user-created bounding box is below threshold, assume the
      // intention was to click instead of drag, and use the image's
      // intrinsic size
      (videoElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        videoElement.height < DRAGGING_THRESHOLD / this.state.zoom.value)
    ) {
      const minHeight = Math.max(this.state.height - 120, 160);
      // max 65% of canvas height, clamped to <300px, vh - 120px>
      const maxHeight = Math.min(
        minHeight,
        Math.floor(this.state.height * 0.5) / this.state.zoom.value,
      );

      const height = Math.min(video.videoHeight, maxHeight);
      const width = height * (video.videoWidth / video.videoHeight);

      // add current imageElement width/height to account for previous centering
      // of the placeholder image
      const x = videoElement.x + videoElement.width / 2 - width / 2;
      const y = videoElement.y + videoElement.height / 2 - height / 2;

      mutateElement(videoElement, { x, y, width, height });
    }
  };

  private initializeAudioDimensions = (
    audioElement: ExcalidrawAudioElement,
    forceNaturalSize = false,
    fileName?: string | null,
    color?: string | null,
  ) => {
    const audio =
      isInitializedAudioElement(audioElement) &&
      this.state.audioCache.get(audioElement.fileId)?.audio;

    if (!audio || audio instanceof Promise) {
      if (
        audioElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        audioElement.height < DRAGGING_THRESHOLD / this.state.zoom.value
      ) {
        const placeholderSize = 100 / this.state.zoom.value;
        mutateElement(audioElement, {
          x: audioElement.x - placeholderSize / 2,
          y: audioElement.y - placeholderSize / 2,
          width: placeholderSize,
          height: placeholderSize,
        });
      }

      return;
    }

    if (
      forceNaturalSize ||
      // if user-created bounding box is below threshold, assume the
      // intention was to click instead of drag, and use the image's
      // intrinsic size
      (audioElement.width < DRAGGING_THRESHOLD / this.state.zoom.value &&
        audioElement.height < DRAGGING_THRESHOLD / this.state.zoom.value)
    ) {
      let height = 0;
      let width = 0;
      const iconWidth = 38; // The width of the audio icon
      const padding = 15; // Padding between the icon and the text
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d")!;
      // Set the font before measuring text
      context.font = "20px Arial";
      const textWidth = context.measureText(fileName as string).width;
      if (fileName) {
        height = 55;
        width = textWidth + iconWidth + padding * 2; // icon + padding + text width
      } else {
        height = 55;
        width = 400;
      }

      // add current imageElement width/height to account for previous centering
      // of the placeholder image
      const x = audioElement.x + audioElement.width / 2 - width / 2;
      const y = audioElement.y + audioElement.height / 2 - height / 2;

      mutateElement(audioElement, { x, y, width, height });
    }
  };

  /** updates image cache, refreshing updated elements and/or setting status
      to error for images that fail during <img> element creation */
  private updateImageCache = async (
    elements: readonly InitializedExcalidrawImageElement[],
    files = this.state.files,
  ) => {
    const { updatedFiles, erroredFiles } = await _updateImageCache({
      imageCache: this.imageCache,
      fileIds: elements.map((element) => element.fileId),
      files,
    });
    this.setState({
      ...this.state,
      files: { ...this.state.files, ...files },
    });
    if (updatedFiles.size || erroredFiles.size) {
      for (const element of elements) {
        if (updatedFiles.has(element.fileId)) {
          invalidateShapeForElement(element);
        }
      }
    }
    if (erroredFiles.size) {
      this.scene.replaceAllElements(
        this.scene.getElementsIncludingDeleted().map((element) => {
          if (
            (isInitializedImageElement(element) &&
              erroredFiles.has(element.fileId)) ||
            (isInitializedFormulaElement(element) &&
              erroredFiles.has(element.fileId)) ||
            (isInitializedTextWithStylesElement(element) &&
              erroredFiles.has(element.fileId)) ||
            (isInitializedMermaidDiagramElement(element) &&
              erroredFiles.has(element.fileId))
          ) {
            return newElementWith(element, {
              status: "error",
            });
          }
          return element;
        }),
      );
    }

    return { updatedFiles, erroredFiles };
  };
  private updateVideoCache = async (
    elements: readonly InitializedExcalidrawVideoElement[],
    files = this.state.files,
  ) => {
    const { updatedFiles, erroredFiles, videoCache } = await _updateVideoCache({
      videoCache: this.state.videoCache,
      fileIds: elements.map((element) => element.fileId),
      files,
    });
    this.setState({
      ...this.state,
      files: { ...this.state.files, ...files },
      videoCache,
    });
    if (updatedFiles.size || erroredFiles.size) {
      for (const element of elements) {
        if (updatedFiles.has(element.fileId)) {
          invalidateShapeForElement(element);
        }
      }
    }
    if (erroredFiles.size) {
      this.scene.replaceAllElements(
        this.scene.getElementsIncludingDeleted().map((element) => {
          if (
            isInitializedVideoElement(element) &&
            erroredFiles.has(element.fileId)
          ) {
            return newElementWith(element, {
              status: "error",
            });
          }
          return element;
        }),
      );
    }

    return { updatedFiles, erroredFiles };
  };
  private updateAudioCache = async (
    elements: readonly InitializedExcalidrawAudioElement[],
    files = this.state.files,
  ) => {
    const { updatedFiles, erroredFiles, audioCache } = await _updateAudioCache({
      audioCache: this.state.audioCache,
      fileIds: elements.map((element) => element.fileId),
      files,
    });
    this.setState({
      ...this.state,
      files: { ...this.state.files, ...files },
      audioCache,
    });
    if (updatedFiles.size || erroredFiles.size) {
      for (const element of elements) {
        if (updatedFiles.has(element.fileId)) {
          invalidateShapeForElement(element);
        }
      }
    }
    if (erroredFiles.size) {
      this.scene.replaceAllElements(
        this.scene.getElementsIncludingDeleted().map((element) => {
          if (
            isInitializedAudioElement(element) &&
            erroredFiles.has(element.fileId)
          ) {
            return newElementWith(element, {
              status: "error",
            });
          }
          return element;
        }),
      );
    }

    return { updatedFiles, erroredFiles };
  };

  /** adds new images to imageCache and re-renders if needed */
  private addNewImagesToImageCache = async (
    imageElements: InitializedExcalidrawImageElement[] = getInitializedImageElements(
      this.scene.getElements(),
    ),
    files: BinaryFiles = this.state.files,
  ) => {
    const uncachedImageElements = imageElements.filter(
      (element) => !element.isDeleted && !this.imageCache.has(element.fileId),
    );
    if (uncachedImageElements.length) {
      const { updatedFiles } = await this.updateImageCache(
        uncachedImageElements,
        files,
      );
      if (updatedFiles.size) {
        this.scene.informMutation();
      }
    }
  };

  private addNewVideosToVideoCache = async (
    videoElements: InitializedExcalidrawVideoElement[] = getInitializedVideoElements(
      this.scene.getElements(),
    ),
    files: BinaryFiles = this.state.files,
  ) => {
    const uncachedVideoElements = videoElements.filter(
      (element) =>
        !element.isDeleted && !this.state.videoCache.has(element.fileId),
    );

    if (uncachedVideoElements.length) {
      const { updatedFiles } = await this.updateVideoCache(
        uncachedVideoElements,
        files,
      );
      if (updatedFiles.size) {
        this.scene.informMutation();
      }
    }
  };
  private addNewAudiosToAudioCache = async (
    audioElements: InitializedExcalidrawAudioElement[] = getInitializedAudioElements(
      this.scene.getElements(),
    ),
    files: BinaryFiles = this.state.files,
  ) => {
    const uncachedAudioElements = audioElements.filter(
      (element) =>
        !element.isDeleted && !this.state.audioCache.has(element.fileId),
    );

    if (uncachedAudioElements.length) {
      const { updatedFiles } = await this.updateAudioCache(
        uncachedAudioElements,
        files,
      );
      if (updatedFiles.size) {
        this.scene.informMutation();
      }
    }
  };

  /** generally you should use `addNewImagesToImageCache()` directly if you need
   *  to render new images. This is just a failsafe  */
  private scheduleImageRefresh = throttle(() => {
    this.addNewImagesToImageCache();
  }, IMAGE_RENDER_TIMEOUT);
  private scheduleVideoRefresh = throttle(() => {
    this.addNewVideosToVideoCache();
  }, IMAGE_RENDER_TIMEOUT);
  private scheduleAudioRefresh = throttle(() => {
    this.addNewAudiosToAudioCache();
  }, IMAGE_RENDER_TIMEOUT);

  private updateBindingEnabledOnPointerMove = (
    event: React.PointerEvent<HTMLElement>,
  ) => {
    const shouldEnableBinding = shouldEnableBindingForPointerEvent(event);
    if (this.state.isBindingEnabled !== shouldEnableBinding) {
      this.setState({ isBindingEnabled: shouldEnableBinding });
    }
  };

  private maybeSuggestBindingAtCursor = (pointerCoords: {
    x: number;
    y: number;
  }): void => {
    const hoveredBindableElement = getHoveredElementForBinding(
      pointerCoords,
      this.scene.getNonDeletedElementsMap(),
    );
    this.setState({
      suggestedBindings:
        hoveredBindableElement != null ? [hoveredBindableElement] : [],
    });
  };

  private maybeSuggestBindingsForLinearElementAtCoords = (
    linearElement: NonDeleted<ExcalidrawLinearElement>,
    /** scene coords */
    pointerCoords: {
      x: number;
      y: number;
    }[],
    // During line creation the start binding hasn't been written yet
    // into `linearElement`
    oppositeBindingBoundElement?: ExcalidrawBindableElement | null,
  ): void => {
    if (!pointerCoords.length) {
      return;
    }

    const suggestedBindings = pointerCoords.reduce(
      (acc: NonDeleted<ExcalidrawBindableElement>[], coords) => {
        const hoveredBindableElement = getHoveredElementForBinding(
          coords,
          this.scene.getNonDeletedElementsMap(),
        );
        if (
          hoveredBindableElement != null &&
          !isLinearElementSimpleAndAlreadyBound(
            linearElement,
            oppositeBindingBoundElement?.id,
            hoveredBindableElement,
          )
        ) {
          acc.push(hoveredBindableElement);
        }
        return acc;
      },
      [],
    );

    this.setState({ suggestedBindings });
  };

  private maybeSuggestBindingForAll(
    selectedElements: NonDeleted<ExcalidrawElement>[],
  ): void {
    const suggestedBindings = getEligibleElementsForBinding(
      selectedElements,
      this.scene.getNonDeletedElementsMap(),
    );
    this.setState({ suggestedBindings });
  }

  private clearSelection(hitElement: ExcalidrawElement | null): void {
    this.setState((prevState) => ({
      selectedElementIds: makeNextSelectedElementIds({}, prevState),
      selectedGroupIds: {},
      // Continue editing the same group if the user selected a different
      // element from it
      editingGroupId:
        prevState.editingGroupId &&
        hitElement != null &&
        isElementInGroup(hitElement, prevState.editingGroupId)
          ? prevState.editingGroupId
          : null,
    }));
    this.setState({
      selectedElementIds: makeNextSelectedElementIds({}, this.state),
      previousSelectedElementIds: this.state.selectedElementIds,
    });
  }

  private handleCanvasRef = (canvas: HTMLCanvasElement) => {
    // canvas is null when unmounting
    if (canvas !== null) {
      this.canvas = canvas;
      this.rc = rough.canvas(this.canvas);

      this.canvas.addEventListener(EVENT.WHEEL, this.handleWheel, {
        passive: false,
      });
      this.canvas.addEventListener(EVENT.TOUCH_START, this.onTapStart);
      this.canvas.addEventListener(EVENT.TOUCH_END, this.onTapEnd);
    } else {
      this.canvas?.removeEventListener(EVENT.WHEEL, this.handleWheel);
      this.canvas?.removeEventListener(EVENT.TOUCH_START, this.onTapStart);
      this.canvas?.removeEventListener(EVENT.TOUCH_END, this.onTapEnd);
    }
  };

  private handleAppOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    try {
      if (this.state.isDragAndDrop) {
        return;
      }
      const file = event.dataTransfer.files[0];
      if (file?.type === "image/png" || file?.type === "image/svg+xml") {
        if (nativeFileSystemSupported) {
          try {
            // This will only work as of Chrome 86,
            // but can be safely ignored on older releases.
            const item = event.dataTransfer.items[0];
            (file as any).handle = await (item as any).getAsFileSystemHandle();
          } catch (error: any) {
            console.warn(error.name, error.message);
          }
        }

        const { elements, appState } = await loadFromBlob(
          file,
          this.state,
          this.scene.getElementsIncludingDeleted(),
        );
        this.syncActionResult({
          elements,
          appState: {
            ...(appState || this.state),
            isLoading: false,
          },
          commitToHistory: true,
          storeAction: StoreAction.CAPTURE,
        });
        return;
      }
    } catch (error: any) {
      return this.setState({
        isLoading: false,
        errorMessage: error.message,
      });
    }

    const libraryJSON = event.dataTransfer.getData(MIME_TYPES.acvlib);
    if (libraryJSON && typeof libraryJSON === "string") {
      try {
        const libraryItems = parseLibraryJSON(libraryJSON);

        this.addElementsFromPasteOrLibrary({
          elements: distributeLibraryItemsOnSquareGrid(libraryItems),
          position: event,
          files: null,
        });
      } catch (error: any) {
        this.setState({ errorMessage: error.message });
      }
      return;
    }

    const file = event.dataTransfer?.files[0];
    if (file?.type === MIME_TYPES.acvlib || file?.name?.endsWith(".acvlib")) {
      this.library
        .importLibrary(file)
        .then(() => {
          // Close and then open to get the libraries updated
          this.setState({ isLibraryOpen: false });
          this.setState({ isLibraryOpen: true });
        })
        .catch((error) =>
          this.setState({ isLoading: false, errorMessage: error.message }),
        );
      // default: assume an Excalidraw file regardless of extension/MimeType
    } else {
      this.setState({ isLoading: true });
      if (nativeFileSystemSupported) {
        try {
          // This will only work as of Chrome 86,
          // but can be safely ignored on older releases.
          const item = event.dataTransfer.items[0];
          (file as any).handle = await (item as any).getAsFileSystemHandle();
        } catch (error: any) {
          console.warn(error.name, error.message);
        }
      }
      await this.loadFileToCanvas(file);
    }
  };

  loadFileToCanvas = (file: Blob) => {
    loadFromBlob(file, this.state, this.scene.getElementsIncludingDeleted())
      .then((scene) => {
        this.syncActionResult({
          ...scene,
          appState: {
            ...(scene.appState || this.state),
            isLoading: false,
          },
          replaceFiles: true,
          commitToHistory: true,
          storeAction: StoreAction.CAPTURE,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false, errorMessage: error.message });
      });
  };

  private handleCanvasContextMenu = (
    event: React.MouseEvent<HTMLElement | HTMLCanvasElement>,
  ) => {
    event.preventDefault();
    if (this.state.activeTool.type !== "selection") {
      return;
    }
    const { x, y } = viewportCoordsToSceneCoords(event, this.state);
    const element = this.getElementAtPosition(x, y, {
      preferSelected: true,
      includeLockedElements: true,
    });

    const type = element ? "element" : "canvas";

    const container = this.excalidrawContainerRef.current!;
    const {
      top: offsetTop,
      left: offsetLeft,
    } = container.getBoundingClientRect();
    const left = event.clientX - offsetLeft;
    const top = event.clientY - offsetTop;

    // if (element && !this.state.selectedElementIds[element.id]) {
    //   this.setState(
    //     {
    //       ...this.state,
    //       ...selectGroupsForSelectedElements(
    //         {
    //           editingGroupId: this.state.editingGroupId,
    //           selectedElementIds: { [element.id]: true },
    //         },
    //         this.scene.getNonDeletedElements(),
    //         this.state,
    //         this as any,
    //       ),
    //       selectedLinearElement: isLinearElement(element)
    //         ? new LinearElementEditor(element, this.scene)
    //         : null,
    //     },
    //     () => {
    //       this._openContextMenu({ top, left }, type);
    //     },
    //   );
    // } else {
    //   this._openContextMenu({ top, left }, type);
    // }
    this.setState(
      {
        ...(element && !this.state.selectedElementIds[element.id]
          ? {
              ...this.state,
              ...selectGroupsForSelectedElements(
                {
                  editingGroupId: this.state.editingGroupId,
                  selectedElementIds: { [element.id]: true },
                },
                this.scene.getNonDeletedElements(),
                this.state,
                this as any,
              ),
              selectedLinearElement: isLinearElement(element)
                ? new LinearElementEditor(element, this.scene)
                : null,
            }
          : this.state),
      },
      () => {
        this._openContextMenu({ top, left }, type);
      },
    );
  };

  private maybeCacheReferenceSnapPoints(
    event: KeyboardModifiersObject,
    selectedElements: ExcalidrawElement[],
    recomputeAnyways: boolean = false,
  ) {
    if (
      isSnappingEnabled({
        event,
        appState: this.state,
        selectedElements,
      }) &&
      (recomputeAnyways || !SnapCache.getReferenceSnapPoints())
    ) {
      SnapCache.setReferenceSnapPoints(
        getReferenceSnapPoints(
          this.scene.getNonDeletedElements(),
          selectedElements,
          this.state,
          this.scene.getNonDeletedElementsMap(),
        ),
      );
    }
  }

  private maybeDragNewGenericElement = (
    pointerDownState: PointerDownState,
    event: MouseEvent | KeyboardEvent,
  ): void => {
    const draggingElement = this.state.draggingElement;
    const pointerCoords = pointerDownState.lastCoords;
    if (!draggingElement) {
      return;
    }
    if (draggingElement.type === "selection") {
      dragNewElement(
        draggingElement,
        this.state.activeTool.type,
        pointerDownState.origin.x,
        pointerDownState.origin.y,
        pointerCoords.x,
        pointerCoords.y,
        distance(pointerDownState.origin.x, pointerCoords.x),
        distance(pointerDownState.origin.y, pointerCoords.y),
        shouldMaintainAspectRatio(event),
        shouldResizeFromCenter(event),
        this.state.zoom.value,
      );
    } else {
      let [gridX, gridY] = getGridPoint(
        pointerCoords.x,
        pointerCoords.y,
        event[KEYS.CTRL_OR_CMD] ? null : this.state.gridSize,
      );

      const image =
        isInitializedImageElement(draggingElement) &&
        this.imageCache.get(draggingElement.fileId)?.image;
      const aspectRatio =
        image && !(image instanceof Promise)
          ? image.width / image.height
          : null;

      this.maybeCacheReferenceSnapPoints(event, [draggingElement]);

      const { snapOffset, snapLines } = snapNewElement(
        draggingElement,
        this.state,
        event,
        {
          x:
            pointerDownState.originInGrid.x +
            (this.state.originSnapOffset?.x ?? 0),
          y:
            pointerDownState.originInGrid.y +
            (this.state.originSnapOffset?.y ?? 0),
        },
        {
          x: gridX - pointerDownState.originInGrid.x,
          y: gridY - pointerDownState.originInGrid.y,
        },
        this.scene.getNonDeletedElementsMap(),
      );

      gridX += snapOffset.x;
      gridY += snapOffset.y;

      this.setState({
        snapLines,
      });

      dragNewElement(
        draggingElement,
        this.state.activeTool.type,
        pointerDownState.originInGrid.x,
        pointerDownState.originInGrid.y,
        gridX,
        gridY,
        distance(pointerDownState.originInGrid.x, gridX),
        distance(pointerDownState.originInGrid.y, gridY),
        isImageElement(draggingElement)
          ? !shouldMaintainAspectRatio(event)
          : shouldMaintainAspectRatio(event),
        shouldResizeFromCenter(event),
        this.state.zoom.value,
        aspectRatio,
        this.state.originSnapOffset,
      );

      this.setState({
        draggingElement,
      });
      // highlight elements that are to be added to frames on frames creation
      if (
        this.state.activeTool.type === TOOL_TYPE.frame ||
        this.state.activeTool.type === TOOL_TYPE.magicframe
      ) {
        this.setState({
          elementsToHighlight: getElementsInResizingFrame(
            this.scene.getNonDeletedElements(),
            draggingElement as ExcalidrawFrameLikeElement,
            this.state,
            this.scene.getNonDeletedElementsMap(),
          ),
        });
      }
      this.maybeSuggestBindingForAll([draggingElement]);
    }
  };

  private maybeHandleResize = (
    pointerDownState: PointerDownState,
    event: MouseEvent | KeyboardEvent,
  ): boolean => {
    // this.setState({ isResizableOrNot: true });
    const selectedElements = this.scene.getSelectedElements(this.state);
    const selectedFrames = selectedElements.filter(
      (element): element is ExcalidrawFrameLikeElement =>
        isFrameLikeElement(element),
    );

    const transformHandleType = pointerDownState.resize.handleType;

    if (
      // Frames cannot be rotated.
      selectedFrames.length > 0 &&
      transformHandleType === "rotation"
    ) {
      return false;
    }

    this.setState({
      // TODO: rename this state field to "isScaling" to distinguish
      // it from the generic "isResizing" which includes scaling and
      // rotating
      isResizing: transformHandleType && transformHandleType !== "rotation",
      isRotating: transformHandleType === "rotation",
      activeEmbeddable: null,
    });
    const pointerCoords = pointerDownState.lastCoords;
    let [resizeX, resizeY] = getGridPoint(
      pointerCoords.x - pointerDownState.resize.offset.x,
      pointerCoords.y - pointerDownState.resize.offset.y,
      this.state.gridSize,
    );

    const frameElementsOffsetsMap = new Map<
      string,
      {
        x: number;
        y: number;
      }
    >();

    selectedFrames.forEach((frame) => {
      const elementsInFrame = getFrameChildren(
        this.scene.getNonDeletedElements(),
        frame.id,
      );

      elementsInFrame.forEach((element) => {
        frameElementsOffsetsMap.set(frame.id + element.id, {
          x: element.x - frame.x,
          y: element.y - frame.y,
        });
      });
    });

    // check needed for avoiding flickering when a key gets pressed
    // during dragging
    if (!this.state.selectedElementsAreBeingDragged) {
      const [gridX, gridY] = getGridPoint(
        pointerCoords.x,
        pointerCoords.y,
        event[KEYS.CTRL_OR_CMD] ? null : this.state.gridSize,
      );

      const dragOffset = {
        x: gridX - pointerDownState.originInGrid.x,
        y: gridY - pointerDownState.originInGrid.y,
      };

      const originalElements = [...pointerDownState.originalElements.values()];

      this.maybeCacheReferenceSnapPoints(event, selectedElements);

      const { snapOffset, snapLines } = snapResizingElements(
        selectedElements,
        getSelectedElements(originalElements, this.state),
        this.state,
        event,
        dragOffset,
        transformHandleType,
      );

      resizeX += snapOffset.x;
      resizeY += snapOffset.y;

      this.setState({
        snapLines,
      });
    }

    const [element] = selectedElements;

    if (
      transformElements(
        pointerDownState.originalElements,
        transformHandleType,
        selectedElements,
        this.scene.getElementsMapIncludingDeleted(),
        shouldRotateWithDiscreteAngle(event),
        shouldResizeFromCenter(event),
        selectedElements.some((element) => isImageElement(element))
          ? !shouldMaintainAspectRatio(event)
          : shouldMaintainAspectRatio(event),
        resizeX,
        resizeY,
        pointerDownState.resize.center.x,
        pointerDownState.resize.center.y,
      )
    ) {
      const suggestedBindings = getSuggestedBindingsForArrows(
        selectedElements,
        this.scene.getNonDeletedElementsMap(),
      );
      const elementsToHighlight = new Set<ExcalidrawElement>();
      selectedFrames.forEach((frame) => {
        getElementsInResizingFrame(
          this.scene.getNonDeletedElements(),
          frame,
          this.state,
          this.scene.getNonDeletedElementsMap(),
        ).forEach((element) => elementsToHighlight.add(element));
      });

      this.setState({
        elementsToHighlight: [...elementsToHighlight],
        suggestedBindings,
      });

      //set the zoomFactor
      if (element.type === "textWithStyles") {
        const zoomFactor_ = element?.zoomFactor;
        const isZoomFactorApplied = zoomFactor_
          ? zoomFactor_.isZoomFactorApplied
          : false;

        // if (!isZoomFactorApplied) {
        const currentWidth = element?.width as number;
        const currentHeight = element?.height as number;
        const originalWidth = element?.defaultWidth as number;
        const originalHeight = element?.defaultHeight as number;

        const width_scale = currentWidth / originalWidth;
        const height_scale = currentHeight / originalHeight;

        const newZoomFactor = (width_scale + height_scale) / 2;

        const zoom = zoomFactor_?.isFontSizeChanged ? 1 : newZoomFactor;
        const zoomFactor = {
          zoomFactor: zoom,
          isZoomFactorApplied: false,
          height: isZoomFactorApplied ? zoomFactor_?.height : currentHeight,
          width: isZoomFactorApplied ? zoomFactor_?.width : currentWidth,
        };

        const textEditor = {
          resizeHeight: currentHeight,
          resizeWidth: currentWidth,
          resize: true,
        };
        mutateElement(element, {
          zoomFactor,
          textEditor,
          width: currentWidth,
          height: currentHeight,
        });
        // }
      }

      // this.maybeSuggestBindingForAll(selectedElements);

      return true;
    }
    return false;
  };

  /** @private use this.handleCanvasContextMenu */
  private _openContextMenu = (
    {
      left,
      top,
    }: {
      left: number;
      top: number;
    },
    type: "canvas" | "element",
  ) => {
    const maybeGroupAction = actionGroup.contextItemPredicate!(
      this.actionManager.getElementsIncludingDeleted(),
      this.actionManager.getAppState(),
    );

    const maybeUngroupAction = actionUngroup.contextItemPredicate!(
      this.actionManager.getElementsIncludingDeleted(),
      this.actionManager.getAppState(),
    );

    const maybeFlipHorizontal = actionFlipHorizontal.contextItemPredicate!(
      this.actionManager.getElementsIncludingDeleted(),
      this.actionManager.getAppState(),
    );
    const maybeCopyStyle = actionFlipHorizontal.contextItemPredicate!(
      this.actionManager.getElementsIncludingDeleted(),
      this.actionManager.getAppState(),
    );

    const maybeFlipVertical = actionFlipVertical.contextItemPredicate!(
      this.actionManager.getElementsIncludingDeleted(),
      this.actionManager.getAppState(),
    );

    const maybeUnlockElement = actionUnlockAllElements.contextItemPredicate
      ? actionUnlockAllElements.contextItemPredicate(
          this.actionManager.getElementsIncludingDeleted(),
          this.actionManager.getAppState(),
        )
      : false;

    const separator = "separator";

    const elements = this.scene.getElements();

    const options: ContextMenuOption[] = [];
    if (probablySupportsClipboardBlob && elements.length > 0) {
      options.push(actionCopyAsPng);
    }

    if (probablySupportsClipboardWriteText && elements.length > 0) {
      options.push(actionCopyAsSvg);
    }
    if (type === "canvas") {
      const viewModeOptions = [
        ...options,
        typeof this.props.gridModeEnabled === "undefined" &&
          actionToggleGridMode,
        typeof this.props.zenModeEnabled === "undefined" && actionToggleZenMode,
        typeof this.props.viewModeEnabled === "undefined" &&
          actionToggleViewMode,
        // actionToggleStats,
        actionSaveFileToDisk,
      ];

      if (
        this.state.viewModeEnabled &&
        this.state.activeTool.type !== "laser"
      ) {
        ContextMenu.push({
          options: viewModeOptions,
          top,
          left,
          actionManager: this.actionManager,
          appState: this.state,
          container: this.excalidrawContainerRef.current!,
          elements: this.scene.getNonDeletedElements(),
        });
      } else {
        ContextMenu.push({
          options: [
            // this.isMobile &&
            //   navigator.clipboard && {
            //     name: "paste",
            //     perform: (elements, appStates) => {
            //       this.pasteFromClipboard(event);
            //       return {
            //         commitToHistory: false,
            //       };
            //     },
            //     contextItemLabel: "labels.paste",
            //   },
            this.isMobile && navigator.clipboard && separator,
            probablySupportsClipboardBlob &&
              elements.length > 0 &&
              actionCopyAsPng,
            probablySupportsClipboardWriteText &&
              elements.length > 0 &&
              actionCopyAsSvg,
            ((probablySupportsClipboardBlob && elements.length > 0) ||
              (probablySupportsClipboardWriteText && elements.length > 0)) &&
              separator,
            actionSelectAll,
            actionSelectAllElementsInFrame,
            actionRemoveAllElementsFromFrame,
            maybeUnlockElement && actionUnlockAllElements,
            separator,
            typeof this.props.gridModeEnabled === "undefined" &&
              actionToggleGridMode,
            typeof this.props.zenModeEnabled === "undefined" &&
              actionToggleZenMode,
            typeof this.props.viewModeEnabled === "undefined" &&
              actionToggleViewMode,
          ],
          top,
          left,
          actionManager: this.actionManager,
          appState: this.state,
          container: this.excalidrawContainerRef.current!,
          elements: this.scene.getNonDeletedElements(),
        });
      }
    } else if (type === "element") {
      if (
        this.state.viewModeEnabled &&
        this.state.activeTool.type !== "laser"
      ) {
        ContextMenu.push({
          options: [navigator.clipboard && actionCopy, ...options],
          top,
          left,
          actionManager: this.actionManager,
          appState: this.state,
          container: this.excalidrawContainerRef.current!,
          elements: this.scene.getNonDeletedElements(),
        });
      } else {
        ContextMenu.push({
          options: [
            this.isMobile && actionCut,
            this.isMobile && navigator.clipboard && actionCopy,
            this.isMobile &&
              navigator.clipboard && {
                name: "paste",
                perform: (elements, appStates) => {
                  this.pasteFromClipboard();
                  return {
                    commitToHistory: false,
                  };
                },
                contextItemLabel: "labels.paste",
              },
            this.isMobile && separator,
            ...options,
            separator,
            maybeCopyStyle && actionCopyStyles,
            actionPaste,
            actionPasteStyles,
            separator,
            maybeGroupAction && actionGroup,
            maybeUngroupAction && actionUngroup,
            (maybeGroupAction || maybeUngroupAction) && separator,
            actionAddToLibrary,
            separator,
            actionSendBackward,
            actionBringForward,
            actionSendToBack,
            actionBringToFront,
            separator,
            actionFlipHorizontal,
            actionFlipVertical,
            (maybeFlipHorizontal || maybeFlipVertical) && separator,
            actionDuplicateSelection,
            actionToggleElementLock,
            actionDeleteSelected,
          ],
          top,
          left,
          actionManager: this.actionManager,
          appState: this.state,
          container: this.excalidrawContainerRef.current!,
          elements: this.scene.getNonDeletedElements(),
        });
      }
    }
  };

  private handleWheel = withBatchedUpdates((event: WheelEvent) => {
    event.preventDefault();

    if (isPanning) {
      return;
    }

    const { deltaX, deltaY } = event;

    // note that event.ctrlKey is necessary to handle pinch zooming
    if (event.metaKey || event.ctrlKey) {
      const sign = Math.sign(deltaY);
      const MAX_STEP = ZOOM_STEP * 100;
      const absDelta = Math.abs(deltaY);
      let delta = deltaY;
      if (absDelta > MAX_STEP) {
        delta = MAX_STEP * sign;
      }

      let newZoom = this.state.zoom.value - delta / 100;
      // increase zoom steps the more zoomed-in we are (applies to >100% only)
      newZoom +=
        Math.log10(Math.max(1, this.state.zoom.value)) *
        -sign *
        // reduced amplification for small deltas (small movements on a trackpad)
        Math.min(1, absDelta / 20);

      const zoomVal = getStateForZoom(
        {
          viewportX: cursorX,
          viewportY: cursorY,
          nextZoom: getNormalizedZoom(newZoom),
        },
        this.state,
      );
      this.setState((state) => ({
        zoom: {
          ...state.zoom,
          value: zoomVal.zoom.value,
        },
        scrollX: zoomVal.scrollX,
        scrollY: zoomVal.scrollY,
        shouldCacheIgnoreZoom: true,
      }));

      if (this.appStateRef.current) {
        this.appStateRef.current.scrollX = zoomVal.scrollX;
        this.appStateRef.current.scrollY = zoomVal.scrollY;
      }
      if (this.chronometerScrollRef.current) {
        this.chronometerScrollRef.current.scrollX = zoomVal.scrollX;
        this.chronometerScrollRef.current.scrollY = zoomVal.scrollY;
      }
      this.resetShouldCacheIgnoreZoomDebounced();
      return;
    }

    // scroll horizontally when shift pressed
    // if (event.shiftKey) {
    //   this.setState(({ zoom, scrollX }) => ({
    //     // on Mac, shift+wheel tends to result in deltaX
    //     scrollX: scrollX - (deltaY || deltaX) / zoom.value,
    //   }));
    //   return;
    // }
    if (event.shiftKey) {
      this.setState(({ zoom, scrollX }) => {
        const newScrollX = scrollX - (deltaY || deltaX) / zoom.value;
        // Update the ref
        if (this.appStateRef.current) {
          this.appStateRef.current.scrollX = newScrollX;
        }
        if (this.chronometerScrollRef.current) {
          this.chronometerScrollRef.current.scrollX = newScrollX;
        }

        return { scrollX: newScrollX };
      });
      return;
    }

    // this.setState(({ zoom, scrollX, scrollY }) => ({
    //   scrollX: scrollX - deltaX / zoom.value,
    //   scrollY: scrollY - deltaY / zoom.value,
    // }));
    this.setState(({ zoom, scrollX, scrollY }) => {
      const newScrollX = scrollX - deltaX / zoom.value;
      const newScrollY = scrollY - deltaY / zoom.value;
      // Update the ref
      if (this.appStateRef.current) {
        this.appStateRef.current.scrollX = newScrollX;
        this.appStateRef.current.scrollY = newScrollY;
      }
      if (this.chronometerScrollRef.current) {
        this.chronometerScrollRef.current.scrollX = newScrollX;
        this.chronometerScrollRef.current.scrollY = newScrollY;
      }

      return {
        scrollX: newScrollX,
        scrollY: newScrollY,
      };
    });
  });

  private getTextWysiwygSnappedToCenterPosition(
    x: number,
    y: number,
    appState: AppState,
    container?: ExcalidrawTextContainer | null,
  ) {
    if (container) {
      let elementCenterX = container.x + container.width / 2;
      let elementCenterY = container.y + container.height / 2;

      const elementCenter = getContainerCenter(
        container,
        appState,
        this.scene.getNonDeletedElementsMap(),
      );
      if (elementCenter) {
        elementCenterX = elementCenter.x;
        elementCenterY = elementCenter.y;
      }
      const distanceToCenter = Math.hypot(
        x - elementCenterX,
        y - elementCenterY,
      );
      const isSnappedToCenter =
        distanceToCenter < TEXT_TO_CENTER_SNAP_THRESHOLD;
      if (isSnappedToCenter) {
        const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
          { sceneX: elementCenterX, sceneY: elementCenterY },
          appState,
        );
        return { viewportX, viewportY, elementCenterX, elementCenterY };
      }
    }
  }
  private getTextWithStylesSnappedToCenterPosition(
    x: number,
    y: number,
    appState: AppState,
    canvas: HTMLCanvasElement | null,
    scale: number,
  ) {
    const elementClickedInside = getElementContainingPosition(
      this.scene
        .getElementsIncludingDeleted()
        .filter((element) => !isTextWithStylesElement(element)),
      x,
      y,
      this.scene.getNonDeletedElementsMap(),
    );
    if (elementClickedInside) {
      const elementCenterX =
        elementClickedInside.x + elementClickedInside.width / 2;
      const elementCenterY =
        elementClickedInside.y + elementClickedInside.height / 2;
      const distanceToCenter = Math.hypot(
        x - elementCenterX,
        y - elementCenterY,
      );
      const isSnappedToCenter =
        distanceToCenter < TEXT_TO_CENTER_SNAP_THRESHOLD;
      if (isSnappedToCenter) {
        const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
          { sceneX: elementCenterX, sceneY: elementCenterY },
          appState,
        );
        return { viewportX, viewportY, elementCenterX, elementCenterY };
      }
    }
  }

  private savePointer = (x: number, y: number, button: "up" | "down") => {
    if (!x || !y) {
      return;
    }
    const pointer = viewportCoordsToSceneCoords(
      { clientX: x, clientY: y },
      this.state,
    );

    if (isNaN(pointer.x) || isNaN(pointer.y)) {
      // sometimes the pointer goes off screen
    }

    const pointer_: CollaboratorPointer = {
      x: pointer.x,
      y: pointer.y,
      tool: this.state.activeTool?.type === "laser" ? "laser" : "pointer",
    };

    this.props.onPointerUpdate?.({
      pointer: pointer_,
      button,
      pointersMap: gesture.pointers,
    });
  };

  private resetShouldCacheIgnoreZoomDebounced = debounce(() => {
    if (!this.unmounted) {
      this.setState({ shouldCacheIgnoreZoom: false });
    }
  }, 300);

  private updateDOMRect = (cb?: () => void) => {
    if (this.excalidrawContainerRef?.current) {
      const excalidrawContainer = this.excalidrawContainerRef.current;
      const {
        width,
        height,
        left: offsetLeft,
        top: offsetTop,
      } = excalidrawContainer.getBoundingClientRect();
      const {
        width: currentWidth,
        height: currentHeight,
        offsetTop: currentOffsetTop,
        offsetLeft: currentOffsetLeft,
      } = this.state;

      if (
        width === currentWidth &&
        height === currentHeight &&
        offsetLeft === currentOffsetLeft &&
        offsetTop === currentOffsetTop
      ) {
        if (cb) {
          cb();
        }
        return;
      }

      this.setState(
        {
          width,
          height,
          offsetLeft,
          offsetTop,
        },
        () => {
          cb && cb();
        },
      );
    }
  };

  public refresh = () => {
    this.setState({ ...this.getCanvasOffsets() });
  };

  private getCanvasOffsets(): Pick<AppState, "offsetTop" | "offsetLeft"> {
    if (this.excalidrawContainerRef?.current) {
      const excalidrawContainer = this.excalidrawContainerRef.current;
      const { left, top } = excalidrawContainer.getBoundingClientRect();
      return {
        offsetLeft: left,
        offsetTop: top,
      };
    }
    return {
      offsetLeft: 0,
      offsetTop: 0,
    };
  }

  private async updateLanguage() {
    const currentLang =
      languages.find((lang) => lang.code === this.props.langCode) ||
      defaultLang;
    await setLanguage(currentLang);
    this.setAppState({});
  }
}

// -----------------------------------------------------------------------------
// TEST HOOKS
// -----------------------------------------------------------------------------

declare global {
  interface Window {
    h: {
      elements: readonly ExcalidrawElement[];
      state: AppState;
      setState: React.Component<any, AppState>["setState"];
      app: InstanceType<typeof App>;
      history: History;
    };
    publicRenderStaticSceneCallback: () => void;
    handleWorkspaceData: () => void;
    toggleZoomInZoomOut: (isFullScreen?: boolean) => void;
    backToWorkspace: () => void;
    removeCountdownStates: (element: ExcalidrawElement[]) => void;
  }
}

if (
  process.env.NODE_ENV === ENV.TEST ||
  process.env.NODE_ENV === ENV.DEVELOPMENT
) {
  window.h = window.h || ({} as Window["h"]);

  Object.defineProperties(window.h, {
    elements: {
      configurable: true,
      get() {
        return this.app.scene.getElementsIncludingDeleted();
      },
      set(elements: ExcalidrawElement[]) {
        return this.app.scene.replaceAllElements(elements);
      },
    },
  });
}
export default App;
function getMinTextElementWidth(
  arg0: any,
  lineHeight: number & { _brand: "unitlessLineHeight" },
) {
  throw new Error("Function not implemented.");
}

function getFontString(arg0: { fontSize: number; fontFamily: number }): any {
  console.error("Function not implemented.");
}
