import axios from "axios";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { EVENT } from "../constants";
import Library from "../data/library";
import { NonDeletedExcalidrawElement } from "../element/types";
import { getUserUsedStorage } from "../excalidraw-app/api/collection";
import { t } from "../i18n";
import { KEYS } from "../keys";
import { randomId } from "../random";
import { getSelectedElements } from "../scene";
import {
  AppState,
  BinaryFiles,
  CollocationType,
  ExcalidrawProps,
  LibraryData,
  LibraryItem,
  LibraryItems,
  Subscription,
  UIAppState
} from "../types";
import { isShallowEqual } from "../utils";
import { Dialog } from "./Dialog";
import { LibraryItemsDialog } from "./libraryItemsDialog";
import PublishLibrary from "./PublishLibrary";
import { SegmentType, timerDuration } from "./spinner/types";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  cb: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        event.target instanceof Element &&
        (ref.current.contains(event.target) ||
          !document.body.contains(event.target))
      ) {
        return;
      }

      cb(event);
    };
    document.addEventListener("pointerdown", listener, false);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, [ref, cb]);
};

const usePendingElementsMemo = (
  appState: UIAppState,
  elements: readonly NonDeletedExcalidrawElement[],
) => {
  const create = () =>
    getSelectedElements(elements, appState, {
      includeBoundTextElement: true,
      includeElementsInFrames: true,
    });
  const val = useRef(create());
  const prevAppState = useRef<UIAppState>(appState);
  const prevElements = useRef(elements);

  if (
    !isShallowEqual(
      appState.selectedElementIds,
      prevAppState.current.selectedElementIds,
    ) ||
    !isShallowEqual(elements, prevElements.current)
  ) {
    val.current = create();
    prevAppState.current = appState;
    prevElements.current = elements;
  }
  return val.current;
};

const getSelectedItems = (
  libraryItems: LibraryItems,
  selectedItems: LibraryItem["id"][],
) => libraryItems.filter((item) => selectedItems.includes(item.id));

export const LibraryMenu = ({
  onClose,
  onInsertShape,
  onAddToLibrary,
  theme,
  setAppState,
  files,
  libraryReturnUrl,
  focusContainer,
  library,
  id,
  appState,
  insertImageOnCanvas,
  insertVideoOnCanvas,
  insertAudioOnCanvas,
  showPublishLibraryDialog,
  setShowPublishLibraryDialog,
  publishLibSuccess,
  setPublishLibSuccess,
  setLibraryItems,
  libraryItems,
  togglekeyboardShortcut,
  handleSpinnerDialog,
  subscription,
  elements,
  importCustomCategory,
  importLibraryFromUrl,
  addToCollection,
  setAddToCollection,
  deleteElelement,
  setDeleteElement,
  resetScene,
}: {
  pendingElements: LibraryItem["elements"];
  onClose: () => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  onAddToLibrary: () => void;
  theme: AppState["theme"];
  files: BinaryFiles;
  setAppState: React.Component<any, AppState>["setState"];
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  focusContainer: () => void;
  library: Library;
  id: string;
  appState: AppState;
  insertImageOnCanvas: (file: File) => void;
  insertVideoOnCanvas: (file: File) => void;
  insertAudioOnCanvas: (file: File) => void;
  showPublishLibraryDialog: boolean;
  setShowPublishLibraryDialog: (val: boolean) => void;
  setPublishLibSuccess: (
    val: { url: string; authorName: string } | null,
  ) => void;
  publishLibSuccess: { url: string; authorName: string } | null;
  setLibraryItems: (val: LibraryItems) => void;
  libraryItems: LibraryItems;
  togglekeyboardShortcut: (val: boolean) => void;
  subscription: Subscription | undefined;
  elements: readonly NonDeletedExcalidrawElement[];
  handleSpinnerDialog: (val: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => void;
  editCollection: (val: LibraryItems) => void;
  importCustomCategory: (elements: LibraryItem["elements"]) => void;
  importLibraryFromUrl: (urls: string[]) => void;
  addToCollection: number[];
  setAddToCollection: (val: number[]) => void;
  deleteElelement: number[];
  setDeleteElement: (val: number[]) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isTrusted, setIsTrusted] = useState<number>(0);
  const slug = new URLSearchParams(window.location.search).get("slug") || "";
  const user: any = JSON.parse(localStorage.getItem("user")!);

  const getIsTrustedData = () => {
    const token = localStorage.getItem("access_token");
    const validSlug = slug ?? "";
    axios
      .get(
        `${process.env.REACT_APP_ACV_BACKEND_API}/api/school/get-school-info?slug=${validSlug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        const result = response?.data?.result[0]?.isTrusted;
        setIsTrusted(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useOnClickOutside(ref, (event) => {
    // If click on the library icon, do nothing.
    if ((event.target as Element).closest(".ToolIcon__library")) {
      return;
    }
    onClose();
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEYS.ESCAPE) {
        onClose();
      }
    };
    document.addEventListener(EVENT.KEYDOWN, handleKeyDown);
    return () => {
      document.removeEventListener(EVENT.KEYDOWN, handleKeyDown);
    };
  }, [onClose]);

  const [loadingState, setIsLoading] = useState<
    "preloading" | "loading" | "ready"
  >("preloading");
  const [selectedItems, setSelectedItems] = useState<LibraryItem["id"][]>([]);

  const loadingTimerRef = useRef<number | null>(null);

  useEffect(() => {
    Promise.race([
      new Promise((resolve) => {
        loadingTimerRef.current = window.setTimeout(() => {
          resolve("loading");
        }, 100);
      }),
      library.loadLibrary().then((items) => {
        setLibraryItems(items);
        setIsLoading("ready");
      }),
    ]).then((data) => {
      if (data === "loading") {
        setIsLoading("loading");
      }
    });
    return () => {
      clearTimeout(loadingTimerRef.current!);
    };
    // eslint-disable-next-line
  }, [library]);

  useEffect(() => {
    getIsTrustedData();
    // eslint-disable-next-line
  }, []);

  const removeFromLibrary = useCallback(async () => {
    const items = await library.loadLibrary();

    const nextItems = items.filter((item) => !selectedItems.includes(item.id));
    library.saveLibrary(nextItems).catch((error) => {
      setLibraryItems(items);
      setAppState({ errorMessage: t("alerts.errorRemovingFromLibrary") });
    });
    setSelectedItems([]);
    setLibraryItems(nextItems);
    // eslint-disable-next-line
  }, [library, setAppState, selectedItems, setSelectedItems]);

  const resetLibrary = useCallback(() => {
    library.resetLibrary();
    setLibraryItems([]);
    focusContainer();
    // eslint-disable-next-line
  }, [library, focusContainer]);

  const addToLibrary = useCallback(
    async (elements: LibraryItem["elements"]) => {
      // if (elements.some((element) => element.type === "image")) {
      // setAppState({
      //   errorMessage: "Support for adding images to the library coming soon!",
      // });
      // return;
      // }
      const items = await library.loadLibrary();

      const id = randomId();
      const nextItems: LibraryItems = [
        {
          status: "unpublished",
          elements,
          id,
          created: Date.now(),
        },
        ...items,
      ];
      onAddToLibrary();
      // setSelectedItems([...selectedItems, id]);

      library.saveLibrary(nextItems).catch((error) => {
        setLibraryItems(items);
        setAppState({ errorMessage: t("alerts.errorAddingToLibrary") });
      });
      setLibraryItems(nextItems);
    },
    // eslint-disable-next-line
    [onAddToLibrary, library, setAppState, selectedItems, setSelectedItems],
  );

  const editCollection = useCallback(
    (libraryItems: LibraryItems) => {
      const nextLibItems = [...libraryItems];
      nextLibItems.forEach((libItem) => {
        libItem.status = "unpublished";
      });
      library.saveLibrary(nextLibItems);
      setLibraryItems(nextLibItems);
      setAppState({ isLibraryOpen: true });
    },
    // eslint-disable-next-line
    [libraryItems, library],
  );

  const renderPublishSuccess = useCallback(() => {
    return (
      <Dialog
        onCloseRequest={() => setPublishLibSuccess(null)}
        title={t("publishSuccessDialog.title")}
        className="publish-library-success"
        small={true}
        children={
          <>
            <p>
              {t("publishSuccessDialog.content", {
                authorName: publishLibSuccess!.authorName,
              })}{" "}
            </p>
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={t("buttons.close")}
              aria-label={t("buttons.close")}
              label={t("buttons.close")}
              onClick={() => setPublishLibSuccess(null)}
              data-testid="publish-library-success-close"
              className="publish-library-success-close"
            />
          </>
        }
        closeOnClickOutside={false}
        open={true}
        setOpen={() => false}
      />
    );
  }, [setPublishLibSuccess, publishLibSuccess]);

  const onPublishLibSuccess = useCallback(
    (data: LibraryData) => {
      setShowPublishLibraryDialog(false);
      setPublishLibSuccess({ url: data.url, authorName: data.authorName });
      const nextLibItems = libraryItems.slice();
      nextLibItems.forEach((libItem) => {
        if (selectedItems.includes(libItem.id)) {
          libItem.status = "Under Review";
        }
      });
      library.saveLibrary(nextLibItems);
      setAppState({
        editingLibrary: {
          currentPageOldElements: [],
          isEditing: false,
          libraryId: "",
          libraryInfo: {} as CollocationType,
          libraryItems: [],
          oldElements: [],
        },
      });
      setLibraryItems(nextLibItems);
    },
    // eslint-disable-next-line
    [
      setShowPublishLibraryDialog,
      setPublishLibSuccess,
      libraryItems,
      selectedItems,
      library,
    ],
  );

  const [lastSelectedItem, setLastSelectedItem] = useState<
    LibraryItem["id"] | null
  >(null);

  // BUG: pendingElements are still causing some unnecessary rerenders because clicking into canvas returns some ids even when no element is selected.
  const pendingElements = usePendingElementsMemo(appState, elements);

  return loadingState === "preloading" ? null : (
    <>
      {showPublishLibraryDialog && (
        <PublishLibrary
          onClose={() => setShowPublishLibraryDialog(false)}
          libraryItems={getSelectedItems(libraryItems, selectedItems)}
          appState={appState}
          onSuccess={(data) => {
            onPublishLibSuccess(data);
            setSelectedItems([]);
            setAddToCollection([]);
          }}
          onError={(error) => window.alert(error)}
          updateItemsInStorage={() => library.saveLibrary(libraryItems)}
          onRemove={(id: string) =>
            setSelectedItems(selectedItems.filter((_id) => _id !== id))
          }
          files={files}
          isTrusted={isTrusted}
        />
      )}
      {publishLibSuccess && renderPublishSuccess()}

      <LibraryItemsDialog
        isOpen={appState.isLibraryOpen}
        setAppState={setAppState}
        togglekeyboardShortcut={togglekeyboardShortcut}
        pendingElements={pendingElements}
        libraryItems={libraryItems}
        setLibraryItems={setLibraryItems}
        files={files}
        onRemoveFromLibrary={removeFromLibrary}
        resetLibrary={resetLibrary}
        onAddToLibrary={addToLibrary}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        library={library}
        onPublish={async () => {
          //check storage is available or not
          const usedStorage = await getUserUsedStorage(
            slug,
            user.mail,
            user.actualRole,
          );
          if (
            usedStorage?.result?.usedStorage >=
            usedStorage?.result?.providedStorage
          ) {
            window.parent.postMessage(
              { type: "STORAGE_REQUEST" },
              `${process.env.REACT_APP_PARENT_APP}`,
            );
            return;
          }
          setShowPublishLibraryDialog(true);
        }}
        onInsertShape={onInsertShape}
        handleSpinnerDialog={handleSpinnerDialog}
        insertImageOnCanvas={(file: File) => insertImageOnCanvas(file)}
        insertVideoOnCanvas={(file: File) => insertVideoOnCanvas(file)}
        insertAudioOnCanvas={(file: File) => insertAudioOnCanvas(file)}
        subscription={subscription}
        elements={elements}
        appState={appState}
        editCollection={editCollection}
        importCustomCategory={importCustomCategory}
        importLibraryFromUrl={importLibraryFromUrl}
        addToCollection={addToCollection}
        setAddToCollection={setAddToCollection}
        deleteElelement={deleteElelement}
        setDeleteElement={setDeleteElement}
        resetScene={resetScene}
        getSelectedItems={getSelectedItems}
        onPublishLibSuccess={onPublishLibSuccess}
        isTrusted={isTrusted}
      />
    </>
  );
};
