import oc from "open-color";
import { RefObject, useEffect, useRef } from "react";
import { t } from "../i18n";
import { exportToSvg } from "../packages/utils";
import { AppState, BinaryFiles, LibraryItem } from "../types";
import { close } from "./icons";

import "./EditCardDetails.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

const EditCardDetails = ({
  libItem,
  appState,
  index,
  onChange,
  onRemove,
  files,
  svgRef,
}: {
  libItem: LibraryItem;
  appState: AppState;
  index: number;
  onChange: (val: string, index: number) => void;
  onRemove?: (id: string) => void;
  files: BinaryFiles;
  svgRef: RefObject<HTMLDivElement>;
}) => {
  // const svgRef_ = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const node = svgRef.current;
    if (!node) {
      return;
    }
    (async () => {
      const svg = await exportToSvg({
        elements: libItem.elements,
        appState: {
          ...appState,
          viewBackgroundColor: oc.white,
          exportBackground: true,
        },
        files,
      });
      node.innerHTML = svg.outerHTML;
    })();
    // eslint-disable-next-line
  }, [libItem.elements, appState]);

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" key={index}>
      <div className="d-flex flex-column mb-3">
        <div className={`card preview-card`} key={index}>
          <div className="card-img-top">
            <div ref={svgRef} />

            <ToolButton
              aria-label={t("buttons.remove")}
              type={ToolButtonEnum.BUTTON}
              icon={close("black")}
              className="single-library-item--remove"
              style={{
                right: "0.3rem",
                width: "20px",
                height: "20px",
              }}
              onClick={onRemove && onRemove.bind(null, libItem.id)}
              title={t("buttons.remove")}
            />
          </div>
        </div>

        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ padding: "0.5em 0" }}>
            <span
              style={{
                fontWeight: 500,
                color: "var(--text-primary-color)",
                fontSize: "1rem",
              }}
            >
              {t("publishDialog.itemName")}
            </span>
            <span aria-hidden="true" className="required">
              *
            </span>
          </div>
          <input
            type="text"
            ref={inputRef}
            style={{
              padding: "0.4rem",
              borderRadius: 4,
              border: "1px solid #767676",
            }}
            defaultValue={libItem.name}
            placeholder="Item name"
            onChange={(event) => {
              onChange(event.target.value, index);
            }}
          />
        </label>
        <span className="error" style={{ marginLeft: "-10px" }}>
          {libItem.error}
        </span>
      </div>
    </div>
  );
};

export default EditCardDetails;
