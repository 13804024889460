import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { stringToColor } from "../../../../../../utils/stringColor";
import { useIsMobile } from "../../../../../App";
import { expand } from "../../../../../icons";
import { Tabs } from "../../../../../tabs";
import { SpinnerTimer, SpinnerWheel } from "../../../../components";
import ImageGeneratorButtons from "../../../../components/imageGeneratorButtons/ImageGeneratorButtons";
import { SpinnerContext } from "../../../../contexts";
import { QuestionGenerationResultsType, SegmentType } from "../../../../types";

const segColors = [
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
  "#34A24F",
  "#FF9000",
  "#EE4040",
  "#F0CF50",
  "#815CD1",
  "#3DA5E0",
];

type PlayPropsType = {
  segmentData: SegmentType;
  questionGenerationResults: QuestionGenerationResultsType;
  spinnerView: {
    maximize: boolean;
    setMaximize: Dispatch<SetStateAction<boolean>>;
    setSeconds: Dispatch<SetStateAction<number>>;
    isRunning: boolean;
    startTimer: () => void;
    restartTimer: () => void;
    pauseTimer: () => void;
    handleMinutes: (value: number) => void;
    setSecondsSpinner: (id: number, seconds: number) => void;
    insertImageOnCanvas: (file: File) => void;
    onClose: () => void;
  };
  languages: string[];
  language: string;
  handleSelect: (value: string) => void;
  theme: string;
};

export const Play = ({
  segmentData,
  questionGenerationResults,
  spinnerView,
  languages,
  language,
  handleSelect,
  theme,
}: PlayPropsType) => {
  const {
    segments,
    setSegments,
    setSelectedWheelNumber,
    setMinimize,
    setMinimizeAllSpinWheel,
  } = useContext(SpinnerContext);

  const [size, setSize] = useState<number>(0);
  const isMobile = useIsMobile();

  useEffect(() => {
    // Function to update div Width based on window Width
    const updateWidth = () => {
      setSize(window.innerWidth);
    };

    // Add event listener to update Width on window resize
    window.addEventListener("resize", updateWidth);

    // Initial update
    updateWidth();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const segmentColors: string[] =
    segmentData.segment.length > 0
      ? segmentData.segment.map((data: any, index: number) =>
          stringToColor(`${data + index}`),
        ) ?? segColors
      : segColors;

  const onFinished = (winner: any) => {
    questionGenerationResults.setOpenResultDialog(true);
    questionGenerationResults.results([winner]);
    const selectedWheelIndex = segments.findIndex(
      (data) => Number(data.wheel) === Number(segmentData.wheel),
    );
    if (selectedWheelIndex !== -1) {
      segments[selectedWheelIndex].results.push(winner);
      setSegments([...segments]);
    }
  };

  const realSegments = segmentData.segment.length
    ? segmentData.segment
    : Array(10).fill("");

  const spinWheel = async (id: number) => {
    const canvas = document.getElementById(
      `wheel-${id}` ?? "canvas",
    ) as HTMLCanvasElement;
    if (!canvas) {
      return;
    }
    canvas.click();
  };

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 wheel-wrap p-3">
      <Tabs
        tabs={
          segments.map((_: any, index: number) => ({
            label: `Wheel ${index + 1}`,
            value: `${index + 1}`,
            content:
              Number(segmentData.wheel) !== index + 1 ? null : (
                <>
                  <div
                    className="d-flex flex-column justify-content-between"
                    style={{ height: "94%" }}
                  >
                    <div
                      className={`${
                        isMobile && "imageGenerator"
                      } d-flex justify-content-between align-items-center mt-2 overflow-auto`}
                    >
                      <ImageGeneratorButtons
                        className={"flex-row"}
                        canvasRootId="spinner-root"
                        insertImageOnCanvas={spinnerView.insertImageOnCanvas}
                        onClose={spinnerView.onClose}
                        results={questionGenerationResults.resultsData}
                      />
                      <div className="d-flex gap-2">
                        {segments.length !== 1 && (
                          <div
                            className="minimizeIcon deleteIcon"
                            onClick={() => {
                              segments.splice(index, 1);
                              const segmentData = segments.map(
                                (data, index) => {
                                  return {
                                    ...data,
                                    wheel: index + 1,
                                  };
                                },
                              );
                              setSegments([...segmentData]);
                              setSelectedWheelNumber(1);
                            }}
                          >
                            <div className="icon">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                role="img"
                                viewBox="0 0 12 13"
                                width={18}
                                height={18}
                              >
                                <path
                                  d="M0.999919 10.6667C0.999919 11.4 1.59992 12 2.33325 12H7.66659C8.39992 12 8.99992 11.4 8.99992 10.6667V2.66667H0.999919V10.6667ZM2.33325 4H7.66659V10.6667H2.33325V4ZM7.33325 0.666667L6.66659 0H3.33325L2.66659 0.666667H0.333252V2H9.66659V0.666667H7.33325Z"
                                  fill="#C4314B"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        )}

                        <div
                          className={`minimizeIcon`}
                          onClick={() => {
                            if (
                              segments.some((obj) => obj.segment.length > 0)
                            ) {
                              setMinimize(true);
                              setMinimizeAllSpinWheel(true);
                            }
                          }}
                        >
                          <button
                            title="Minimize"
                            disabled={
                              !segments.some((obj) => obj.segment.length > 0)
                            }
                            className="icon"
                          >
                            {expand()}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center mt-3"
                      id="spinner-root"
                    >
                      <SpinnerWheel
                        id={`wheel-${index + 1}`}
                        segments={realSegments}
                        segColors={segmentColors}
                        winningSegment=""
                        onFinished={(winner) => onFinished(winner)}
                        primaryColor="black"
                        primaryColoraround="#ffffffb4"
                        contrastColor="white"
                        buttonText="Spin"
                        isOnlyOnce={false}
                        size={
                          isMobile
                            ? size
                              ? size - 50
                              : window.innerWidth - 50
                            : 480
                        }
                        upDuration={60}
                        downDuration={3000}
                        fontFamily="proxima-nova"
                        spinnerRound={{
                          arcSize: 40,
                          fontSize: "2em",
                          moveToX: 10,
                          lineToX: 10,
                          moveToY: 40,
                          lineToY: 40,
                          lineToY2: 60,
                          segmentFontSize: "1em",
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        isMobile && "flex-wrap"
                      } d-flex justify-content-between align-items-center`}
                      style={{
                        marginBottom: isMobile ? "10px" : 0,
                      }}
                    >
                      <div>
                        <div className="spin-btns d-flex">
                          <button
                            type="button"
                            className="spin-btn"
                            disabled={
                              !segments.some((obj) => obj.segment.length > 0)
                            }
                            onClick={() => {
                              questionGenerationResults.results([]);
                              spinnerView.setMaximize(true);
                              // const selectedWheelIndex = segments.findIndex(
                              //   (data) =>
                              //     Number(data.wheel) ===
                              //     Number(segmentData.wheel),
                              // );
                              // segments[
                              //   Number(selectedWheelIndex)
                              // ].timerDuration.minutes = 1;
                              // segments[
                              //   Number(selectedWheelIndex)
                              // ].timerDuration.seconds = 0;
                              // timeDuration.minutes = 1;
                              // timeDuration.seconds = 0;
                              // setTimeDuration(timeDuration);
                              spinnerView.pauseTimer();
                            }}
                          >
                            SPIN ALL
                          </button>
                          <button
                            type="button"
                            className="spin-btn"
                            onClick={() => {
                              spinWheel(index + 1);
                            }}
                          >
                            SPIN IN
                          </button>
                        </div>
                      </div>

                      <div className="timer-section">
                        <SpinnerTimer
                          onlyTime={true}
                          isDisplayCheckBox={false}
                          showSmallButtons={false}
                          setSeconds={spinnerView.setSeconds}
                          isRunning={spinnerView.isRunning}
                          startTimer={spinnerView.startTimer}
                          restartTimer={spinnerView.restartTimer}
                          pauseTimer={spinnerView.pauseTimer}
                          handleMinutes={spinnerView.handleMinutes}
                          setSecondsSpinner={spinnerView.setSecondsSpinner}
                          openResultDialog={
                            questionGenerationResults.openResultDialog
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ),
          })) || []
        }
        tab={String(segmentData.wheel)}
        onChange={(value) => {
          setSelectedWheelNumber((value as unknown) as number);
        }}
        isShowAllContent={true}
        isShowAddWheelButton={true}
      />
    </div>
  );
};
