import { useEffect } from "react";
import { t } from "../../../../i18n";
import { AppState } from "../../../../types";
import {
  back,
  collectionIcon,
  deleteIcon,
  plusOutlineIcon,
  share,
} from "../../../icons";

export enum MyWorkspaceSidebarItemsEnum {
  NEW_WORKSPACE = "New Workspace",
  MYWORKSPACE = "My Workspace",
  SHARED_WITH_ME = "Shared with me",
  ARCHIVE = "Archive",
}

const getCollectionSidebarItems = (t: Function) => [
  {
    id: MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE,
    value: MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE,
    label: t("workspace.sidebar.newWorkspace"),
    icon: plusOutlineIcon,
  },
  {
    id: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
    value: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
    label: t("workspace.sidebar.myWorkspace"),
    icon: collectionIcon,
  },
  {
    id: MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME,
    value: MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME,
    label: t("workspace.sidebar.sharedWithMe"),
    icon: share,
  },
  {
    id: MyWorkspaceSidebarItemsEnum.ARCHIVE,
    value: MyWorkspaceSidebarItemsEnum.ARCHIVE,
    label: t("workspace.sidebar.archive"),
    icon: deleteIcon,
  },
];

interface SidebarProps {
  navToggle: boolean;
  setNavToggle: (val: boolean) => void;
  selectedTab: MyWorkspaceSidebarItemsEnum;
  setSelectedTab: React.Component<any, AppState>["setState"];
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  handleItemClick: (value: MyWorkspaceSidebarItemsEnum) => void;
  isTablet: boolean;
  isMobile: boolean;
}

export const Sidebar = (props: SidebarProps) => {
  const { navToggle, selectedTab, setNavToggle } = props;
  useEffect(() => {
    props.isMobile || props.isTablet
      ? !navToggle && setNavToggle(!navToggle)
      : setNavToggle(!navToggle);
    // eslint-disable-next-line
  }, [props.isMobile, props.isTablet]);
  const CollectionSidebarItems = getCollectionSidebarItems(t);

  return (
    <>
      <div id="nav-bar">
        <input
          id="nav-toggle"
          type="checkbox"
          checked={navToggle}
          onClick={() => setNavToggle(!navToggle)}
        />
        <div id="nav-header">
          <a id="nav-title" href="/" className="nav-title">
            {t("workspace.name")}
          </a>
          <label htmlFor="nav-toggle">
            <span id="nav-toggle-burger"></span>
          </label>
        </div>
        <div id="nav-content">
          {CollectionSidebarItems.map((item, index) => {
            return (
              <>
                <div
                  className={`nav-button ${
                    (item?.value ?? item.value) === selectedTab
                      ? "activeItem"
                      : ""
                  }`}
                  key={index}
                  onClick={() => {
                    if (
                      item.value === MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE
                    ) {
                      props.resetScene();
                    }
                    props.handleItemClick(
                      item?.value as MyWorkspaceSidebarItemsEnum,
                    );
                  }}
                >
                  <i className="fas">{item.icon}</i>
                  <span>{item.label}</span>
                </div>
                <div id="nav-content-highlight" className="d-none"></div>
              </>
            );
          })}
          {window.parent !== window && (
            <div
              className={`nav-button`}
              onClick={() => {
                window.parent.postMessage(
                  {
                    type: "BACK_TO_WHITEBOARD",
                    isBackToWB: true,
                  },
                  `${`${process.env.REACT_APP_PARENT_APP}`}`,
                );
              }}
            >
              <i className="fas">{back}</i>
              <span>{t("workspace.sidebar.backToWB")}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
