import { useEffect, useState } from "react";
import {
  close,
  compress,
  expand,
  whiteTemplateIcon,
  zoomIn,
  zoomOut,
} from "../../../icons";
import "../../assets/style.scss";
import { BinaryFiles, LibraryItem } from "../../../../types";
import { ConvertElementsToSvg } from "../convertElementsToSvg";
import { t } from "../../../../i18n";

interface PreviewDialogProps {
  onClose: VoidFunction;
  elements: LibraryItem["elements"];
  files: BinaryFiles;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
}

export const PreviewDialog = (props: PreviewDialogProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const elem = document.getElementById("preview-dialog-fullscreen");

  const openFullScreen = () => {
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    }
    setIsFullscreen(true);
  };

  const closeFullScreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        /* IE11 */
        document.exitFullscreen();
      }
    }
    setIsFullscreen(false);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));
  };

  useEffect(() => {
    setIsFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  }, []);
  const appState = JSON.parse(localStorage.getItem("appState") || "{}");
  return (
    <div className="previewDialog">
      <div className="preview-dialog__header">
        <div className="preview-dialog__header__title">
          {t("cards.preview")}
        </div>
        <div className="preview-dialog__header__close" onClick={props.onClose}>
          {close("black")}
        </div>
      </div>
      <div className="preview-container" id="preview-dialog-fullscreen">
        <div
          className={
            isFullscreen
              ? "preview-dialog__content-fullscreen"
              : "preview-dialog__content"
          }
        >
          <ConvertElementsToSvg
            elements={props.elements}
            files={props.files}
            zoomLevel={zoomLevel}
            frameRendering={appState.frameRendering}
          />
        </div>
        <div
          className={
            isFullscreen
              ? "preview-dialog-icons-fullscreen"
              : "preview-dialog-icons"
          }
        >
          <button
            className="btn"
            onClick={() => {
              window.parent.postMessage(
                { type: "STORE_ELEMENT", isLoading: true },
                `${process.env.REACT_APP_PARENT_APP}`,
              );
              const lesson = JSON.parse(localStorage.getItem("lesson") || "{}");
              const elements = props.elements;
              const lessonId = lesson.Title ? lesson.Title : lesson?.LessonId;
              elements &&
                elements.map(
                  (element: any) =>
                    (element.lessonId = lessonId ? lessonId : element.lessonId),
                );

              return props.onInsertShape(elements);
            }}
          >
            <span className="d-flex" style={{ height: 15, width: 15 }}>
              {whiteTemplateIcon}
            </span>
            <p style={{ marginBottom: "0px", fontSize: "16px" }}>
              {t("cards.useThisTemplate")}
            </p>
          </button>
          <div className="icons-div">
            <div className="icon" onClick={handleZoomIn}>
              {zoomIn}
            </div>
            <div className="icon" onClick={handleZoomOut}>
              {zoomOut}
            </div>
            <div
              className="icon"
              onClick={isFullscreen ? closeFullScreen : openFullScreen}
            >
              {isFullscreen ? expand() : compress()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
