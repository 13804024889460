// import html2canvas from "html2canvas";
import { uploadImageToAzureBlob } from "../utils/uploadImageToAzureBlob";

var domtoimage = require("dom-to-image");

const captchScreenImage = async (currentPage: number) => {
  try {
    const node = document.getElementById("to_dataURL");

    // const canvas = await html2canvas(node, { scale: 0.5 });
    // const dataUrl = canvas.toDataURL();
    let dataURL;
    if (node) dataURL = await domtoimage?.toJpeg(node, { quality: 0.5 });

    let lessonId = new URLSearchParams(window.location.search).get("lessonId");

    // If the lessonId is equal to 'MyWorkSpace', store a unique ID
    if (lessonId === "MyWorkSpace" || lessonId === "MyWorkSpace/") {
      lessonId = `${lessonId}-${currentPage}-${Date.now()}`; // Create a unique ID
    }
    const blobName = `${lessonId}-${currentPage}`;

    const url = await uploadImageToAzureBlob(dataURL, blobName);

    return url || "";
  } catch (error) {
    console.error("Error capturing screenshot:", error);
    return null;
  }
};

export default captchScreenImage;
