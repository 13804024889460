import { ExcalidrawElement, NonDeleted } from "../../../../../../element/types";
import { AppState, BinaryFiles, LibraryItem } from "../../../../../../types";
import { useIsMobile } from "../../../../../App";
import { ToastType } from "../../../../../Toast";
import { CardWithSvg } from "../../../../components/card/CardWithSvg";
import {
  CollectionSidebarItemsEnum,
  SidebarItemsEnum,
} from "../../../../components/constant/SidebarItems";

interface AddedFromCollectionsProps {
  publishedItems: (
    | LibraryItem
    | {
        id: null;
        elements: readonly NonDeleted<ExcalidrawElement>[];
      }
  )[];
  files: BinaryFiles;
  setDeleteElement: (val: number[]) => void;
  deleteElelement: number[];
  addToCollection: number[];
  setAddToCollection: (value: number[]) => void;
  selectedTab: SidebarItemsEnum | CollectionSidebarItemsEnum;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
  selectedItems: LibraryItem["id"][];
  setPreviewDialog: (val: LibraryItem["elements"] | null) => void;
  appState: AppState;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  setAppState: React.Component["setState"];
}

export const AddedFromCollectionsTab = (props: AddedFromCollectionsProps) => {
  const {
    publishedItems,
    files,
    setDeleteElement,
    deleteElelement,
    addToCollection,
    setAddToCollection,
    selectedTab,
    setSelectedItems,
    onInsertShape,
    selectedItems,
    setPreviewDialog,
    appState,
    resetScene,
    setAppState,
  } = props;

  return (
    <>
      <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
        {publishedItems.map((item, index) => {
          return (
            <CardWithSvg
              key={index}
              item={item}
              index={index}
              addToCollection={addToCollection}
              setAddToCollection={setAddToCollection}
              files={files}
              setDeleteElement={setDeleteElement}
              deleteElelement={deleteElelement}
              selectedTab={selectedTab}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setPreviewDialog={setPreviewDialog}
              onInsertShape={onInsertShape}
              appState={appState}
              resetScene={resetScene}
              setAppState={setAppState}
              isMove={false}
              onSaveInProgressLibrary={() => false}
              setToastMessage={() => {}}
            />
          );
        })}
      </div>
    </>
  );
};
