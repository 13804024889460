import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { stringToColor } from "../../../../../../../../utils/stringColor";
import { useIsMobile } from "../../../../../../../App";
import { ToolButton, ToolButtonEnum } from "../../../../../../../ToolButton";
import {
  RoundCloseIcon,
  ShuffleIcon,
  SortIcon,
} from "../../../../../../../icons";
import { SpinnerTimer } from "../../../../../../components";
import { SpinnerContext } from "../../../../../../contexts";
import { WheelAndQuestionType } from "../../../../../../types";
import "./styles.scss";

export type QuestionGenerationEntriesTypes = {
  wheelAndQuestions: WheelAndQuestionType[];
  setWheelAndQuestions: Dispatch<SetStateAction<WheelAndQuestionType[]>>;
  selectedWheelIndex: number;
  setSelectedWheelIndex: Dispatch<SetStateAction<number>>;
  openResultDialog: boolean;
  setSeconds: Dispatch<SetStateAction<number>>;
  isRunning: boolean;
  startTimer: () => void;
  restartTimer: () => void;
  pauseTimer: () => void;
  handleMinutes: (value: number) => void;
  setSecondsSpinner: (id: number, seconds: number) => void;
};
export const QuestionGenerationEntries = ({
  wheelAndQuestions,
  selectedWheelIndex,
  setSelectedWheelIndex,
  setWheelAndQuestions,
  openResultDialog,
  setSeconds,
  isRunning,
  startTimer,
  restartTimer,
  pauseTimer,
  handleMinutes,
  setSecondsSpinner,
}: QuestionGenerationEntriesTypes) => {
  const { segments, selectedWheelNumber, setSegments } = useContext(
    SpinnerContext,
  );
  const [filteredQuestions, setFilteredQuestions] = useState<string[]>([]);
  const isMobile = useIsMobile();
  const findQuestionIndex = (wheel: number) => {
    return wheelAndQuestions.findIndex(
      (data) => Number(data.wheel) === Number(wheel),
    );
  };

  const shuffleArray = (array: any[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const onShuffleQuestions = () => {
    const _selectedWheelIndex = findQuestionIndex(selectedWheelNumber);
    setSelectedWheelIndex(_selectedWheelIndex);

    wheelAndQuestions[_selectedWheelIndex].questions = shuffleArray(
      wheelAndQuestions[_selectedWheelIndex].questions,
    );
    setWheelAndQuestions([...wheelAndQuestions]);
  };

  const onSortQuestions = () => {
    const _selectedWheelIndex = findQuestionIndex(selectedWheelNumber);
    setSelectedWheelIndex(_selectedWheelIndex);
    wheelAndQuestions[_selectedWheelIndex].questions.sort();
    setWheelAndQuestions([...wheelAndQuestions]);
  };

  const onAddQuestion = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
      const question = e.currentTarget.value.trim();
      const _selectedWheelIndex = findQuestionIndex(selectedWheelNumber);
      setSelectedWheelIndex(_selectedWheelIndex);
      if (_selectedWheelIndex !== -1) {
        if (question.includes(",")) {
          const separatedStrings = question.split(",");
          wheelAndQuestions[_selectedWheelIndex].questions = [
            ...wheelAndQuestions[_selectedWheelIndex].questions,
            ...separatedStrings,
          ];
          setFilteredQuestions([
            ...wheelAndQuestions[_selectedWheelIndex].questions,
          ]);
        } else {
          wheelAndQuestions[_selectedWheelIndex].questions = [
            ...wheelAndQuestions[_selectedWheelIndex].questions,
            question,
          ];
        }
        setWheelAndQuestions([...wheelAndQuestions]);
        setFilteredQuestions([
          ...wheelAndQuestions[_selectedWheelIndex].questions,
        ]);
      }
      e.currentTarget.value = "";
    }
  };

  const segmentData = segments.find(
    (data) => Number(data.wheel) === Number(selectedWheelNumber),
  );
  const onInsertIntoWheel = () => {
    const segmentData = segments.findIndex(
      (data) => Number(data.wheel) === Number(selectedWheelNumber),
    );
    const _selectedWheelIndex = findQuestionIndex(selectedWheelNumber);
    setSelectedWheelIndex(_selectedWheelIndex);
    if (segmentData !== -1) {
      const updatedSegments = [...segments];
      updatedSegments[segmentData] = {
        ...updatedSegments[segmentData],
        segment: wheelAndQuestions[_selectedWheelIndex].questions,
      };
      setSegments(updatedSegments);
    }
  };

  const buttons = [
    {
      icon: ShuffleIcon,
      onclick: onShuffleQuestions,
      title: "Shuffle",
      className: `shuffle-btn`,
      ariaLabel: "Shuffle",
      type: ToolButtonEnum.ICON,
      label: "Shuffle",
    },
    {
      icon: SortIcon,
      onclick: onSortQuestions,
      title: "Sort",
      className: `sort-btn`,
      ariaLabel: "Sort",
      type: ToolButtonEnum.ICON,
      label: "Sort",
    },
  ];

  useEffect(() => {
    const _selectedWheelIndex = findQuestionIndex(selectedWheelNumber);
    if (_selectedWheelIndex !== -1) {
      setSelectedWheelIndex(_selectedWheelIndex);
      setFilteredQuestions(
        wheelAndQuestions[_selectedWheelIndex]?.questions?.length > 0
          ? [...wheelAndQuestions[_selectedWheelIndex]?.questions]
          : [],
      );
    }
    // eslint-disable-next-line
  }, [wheelAndQuestions]);

  const spinnerDialog = document.getElementsByClassName("spinner-dialog");
  const divElements = spinnerDialog
    ? spinnerDialog[0]?.getElementsByClassName("Modal__content")
      ? spinnerDialog[0]?.getElementsByClassName("Modal__content")
      : []
    : [];
  let height = 176;
  // Iterate through each element in the collection
  for (let i = 0; i < divElements.length; i++) {
    // Get the current element
    const divElement = divElements[i];

    // Get the height of the current element
    height = divElement.clientHeight;
  }

  return (
    <>
      <div className="d-flex gap-3">
        {buttons.map((btn, index) => (
          <ToolButton
            key={index}
            type={ToolButtonEnum.ICON}
            title={btn.title}
            icon={btn.icon}
            onClick={btn.onclick}
            className={`${btn.className}`}
            aria-label={btn.ariaLabel}
            label={btn.label ?? ""}
            showAriaLabel={true}
          />
        ))}
      </div>
      <div className="question-result">
        <div
          className={`${
            isMobile ? "d-context" : "d-flex"
          } justify-content-between gap-3`}
        >
          <div
            className={`addQuestion`}
            style={{
              width: isMobile ? "100%" : "50%",
              margin: isMobile ? "10px" : 0,
            }}
          >
            <label>Press Enter to add questions</label>
            <input
              type="text"
              className="form-control"
              onKeyDown={onAddQuestion}
            />
          </div>
          <div
            className="timer-section spinner-timer"
            style={{ margin: isMobile ? "10px" : 0 }}
          >
            <SpinnerTimer
              onlyTime={false}
              isDisplayCheckBox={true}
              showSmallButtons={false}
              openResultDialog={openResultDialog}
              setSeconds={setSeconds}
              isRunning={isRunning}
              startTimer={startTimer}
              restartTimer={restartTimer}
              pauseTimer={pauseTimer}
              handleMinutes={handleMinutes}
              setSecondsSpinner={setSecondsSpinner}
            />
          </div>
        </div>
        {wheelAndQuestions[selectedWheelIndex] &&
          wheelAndQuestions[selectedWheelIndex]?.questions?.length > 0 && (
            <>
              <div className="my-2 mt-3">
                <div
                  className="d-flex justify-content-between"
                  style={{ gap: isMobile ? "5px" : 0 }}
                >
                  <div className="d-flex align-items-center mb-2 gap-2">
                    <p className="mb-0">Questions:</p>
                    <button
                      className="btn btn-remove-all"
                      onClick={() => {
                        wheelAndQuestions[selectedWheelIndex].questions = [];
                        setWheelAndQuestions(wheelAndQuestions);
                        const segmentIndex = segments.findIndex((data) => {
                          return (
                            Number(data.wheel) === Number(selectedWheelNumber)
                          );
                        });
                        segments[segmentIndex].segment = [];
                        setSegments(segments);
                        setFilteredQuestions([]);
                      }}
                    >
                      Remove All
                    </button>
                  </div>
                  <div className="addQuestion">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                      onChange={(e) => {
                        const ques = wheelAndQuestions[
                          selectedWheelIndex
                        ].questions.filter((que) =>
                          que
                            .toLocaleLowerCase()
                            .includes(e.target.value.toLocaleLowerCase()),
                        );
                        setFilteredQuestions(ques);
                      }}
                    />
                  </div>
                </div>
                <div
                  className="questionList"
                  // style={{ height: segmentData?.topic ? "176px" : "227px" }}
                  style={{
                    height: segmentData?.topic ? height - 588 : height - 535,
                    transition: "height .3s ease",
                  }}
                >
                  <div className="d-flex gap-2 flex-wrap">
                    {filteredQuestions?.map((question, index) => (
                      <div
                        key={index}
                        className="questions"
                        style={{
                          backgroundColor: `${stringToColor(question + index)}`,
                        }}
                      >
                        <div>{`${question}`}</div>
                        <div
                          onClick={() => {
                            const updatedWheelAndQuestions = [
                              ...wheelAndQuestions,
                            ];
                            let updatedQuestions = [
                              ...updatedWheelAndQuestions[selectedWheelIndex]
                                .questions,
                            ];

                            updatedQuestions = updatedQuestions.filter(
                              (que, i) => que !== question,
                            );

                            // Remove the desired element
                            // updatedQuestions.splice(index, 1);

                            // Update the nested array with the modified one
                            updatedWheelAndQuestions[
                              selectedWheelIndex
                            ].questions = updatedQuestions;
                            setWheelAndQuestions([...updatedWheelAndQuestions]);
                            setFilteredQuestions(
                              filteredQuestions.filter(
                                (que, i) => que !== question,
                              ),
                            );
                          }}
                          className="cursor-pointer ps-2"
                        >
                          {RoundCloseIcon}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3">
                <button className="question-btn" onClick={onInsertIntoWheel}>
                  Insert into wheel
                </button>
              </div>
            </>
          )}
      </div>
    </>
  );
};
