import { useMemo, useState } from "react";
import { IconPicker } from "react-fa-icon-picker";
import { t } from "../../../../i18n";
import { close } from "../../../icons";
import { CustomColorPicker } from "../../../tiptapEditor/CustomColorPicker";
import { CategoryDataTypes } from "../../LibraryItemsDialog";

interface PopoverProps {
  onAddNewCategory: (category: CategoryDataTypes) => void;
  togglePopover: VoidFunction;
  categoryData: CategoryDataTypes;
  setCategoryData: (val: CategoryDataTypes) => void;
  isEdit: boolean;
  setIsEdit: (val: boolean) => void;
  onEditCategory: (val: CategoryDataTypes) => void;
  open?: boolean;
}

export const Popover = (props: PopoverProps) => {
  const {
    onAddNewCategory,
    togglePopover,
    categoryData,
    setCategoryData,
    isEdit,
    onEditCategory,
    open,
  } = props;
  const [openTextColorPicker, setOpenTextColorPicker] = useState<boolean>(
    false,
  );

  const rendertPopover = useMemo(() => {
    return (
      <div
        id="popover-content"
        className="popover-content"
        style={{ ...(open ? { opacity: 1, zIndex: 99 } : { opacity: 0 }) }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="popover-heading">
          <p className="font-weight-bold mb-0 text-black">
            {t("popover.category")}
          </p>
          <button
            className="close"
            onClick={() => {
              togglePopover();
            }}
          >
            {close("black")}
          </button>
        </div>

        <input
          type="text"
          id="category"
          placeholder="Category name"
          className="w-100"
          value={categoryData.title}
          onChange={(e) => {
            setCategoryData({ ...categoryData, title: e.target.value });
            setOpenTextColorPicker(false);
          }}
        />
        <div className="d-flex justify-content-between gap-2">
          <div className="position-relative category_color_picker d-flex gap-2">
            <button
              className="color-picker-box"
              style={{
                backgroundColor: `${categoryData.color}`,
                opacity: "0.8",
              }}
              onClick={() => {
                setOpenTextColorPicker(!openTextColorPicker);
              }}
            ></button>
            {openTextColorPicker && (
              <CustomColorPicker
                toggleHighlight={(color) => {
                  setCategoryData({
                    ...categoryData,
                    color,
                  });
                }}
                openTextColorPicker={openTextColorPicker}
                onCloseColorPicker={() => {
                  setOpenTextColorPicker(false);
                }}
              />
            )}

            <IconPicker
              value={categoryData.icon}
              onChange={(v: any) => {
                setCategoryData({
                  ...categoryData,
                  icon: v,
                });
              }}
              size={12}
              color="#3a3838"
              style={{ width: "30px", height: "10px" }}
            />
          </div>
          <div className="d-flex">
            <input
              type="button"
              value={t("popover.cancel")}
              className="btn btn-outline"
              onClick={(e) => {
                togglePopover();
                setCategoryData({
                  id: "",
                  color: "#d9480f",
                  icon: "FaRegLaugh",
                  isCustom: false,
                  title: "",
                });
              }}
            />
            <input
              type="button"
              value={t("popover.submit")}
              className="btn create-btn"
              onClick={(e) => {
                if (isEdit) {
                  onEditCategory(categoryData);
                } else {
                  onAddNewCategory(categoryData);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }, [
    open,
    categoryData,
    isEdit,
    openTextColorPicker,
    setCategoryData,
    onAddNewCategory,
    onEditCategory,
    togglePopover,
  ]);

  if (open) {
    return (
      <div
        style={{
          zIndex: 999,
          width: "100%",
          height: "100%",
          position: "fixed",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpenTextColorPicker(false);
          togglePopover();
        }}
      >
        {rendertPopover}
      </div>
    );
  }

  return rendertPopover;
};
