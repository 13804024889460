import OpenColor from "open-color";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MIME_TYPES } from "../../../../../../constants";
import { ExcalidrawElement, FileId } from "../../../../../../element/types";
import {
  getCategoryWiseLibraryTemplate,
  getUserTemplateCategories,
  removeCategoryFromLibraryTemplate,
  userActionCollectionAPI
} from "../../../../../../excalidraw-app/api/collection";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  CustomCategies,
  LibraryFileType,
  LibraryItem,
  LibraryType,
} from "../../../../../../types";
import { useIsMobile } from "../../../../../App";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card/CardWithImage";
import { SidebarItemsEnum } from "../../../../components/constant/SidebarItems";

interface CustomCategoryProps {
  categoryId: string;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryType[]) => void;
  setSelectedSideLibraryTab: (val: SidebarItemsEnum) => void;
  setPreviewTemplateData: (data: CollocationType) => void;
  loadElementsFromDB: (source: string) => Promise<LibraryItem["elements"]>;
  setFullScreenCollectionPreview: () => void;
  search: string;
  setSearch: (val: string) => void;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
  addCollectionIdToUnPublishedItems: (id: string | null) => void;
}

export const CustomCategory = (props: CustomCategoryProps) => {
  const {
    categoryId,
    setAppState,
    appState,
    editCollection,
    setSelectedSideLibraryTab,
    search,
    setSelectedCatagoryForExportCanvas,
    selectedCatagoryForExportCanvas,
    addCollectionIdToUnPublishedItems,
  } = props;
  const [customCategoryData, setCustomCategoryData] = useState<
    CollocationType[]
  >([]);

  const [filteredCustomCategoryData, setFilteredCustomCategoryData] = useState<
    CollocationType[]
  >([]);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    type: ToastType;
  } | null>(null);
  const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
  useEffect(() => {
    const slug = new URLSearchParams(window.location.search).get("slug");

    const fetchData = async () => {
      const response = await getCategoryWiseLibraryTemplate(
        slug ?? "",
        userMail,
        categoryId,
      );

      setCustomCategoryData(response?.result ?? []);
    };

    fetchData();
    // eslint-disable-next-line
  }, [categoryId]);

  useEffect(() => {
    if (search) {
      setFilteredCustomCategoryData(
        customCategoryData.filter((data) =>
          data.name.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setFilteredCustomCategoryData(customCategoryData);
    }
  }, [search, customCategoryData]);

  const isMobile = useIsMobile();

  const getFilesByLibraryElements = (
    elements: ExcalidrawElement[],
    templateInfo: CollocationType,
  ) => {
    const files: BinaryFiles = {};
    for (let index = 0; index < elements.length; index++) {
      const element: ExcalidrawElement = elements[index];
      if (
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "mermaidDiagram" ||
        element.type === "video" ||
        element.type === "audio" ||
        element.type === "textWithStyles"
      ) {
        if (element?.fileId !== null) {
          if (appState.files.hasOwnProperty(element.fileId)) {
            files[element.fileId] = appState.files[element.fileId];
          }
          if (templateInfo && templateInfo.files) {
            const elementFileIndex = templateInfo.files.findIndex(
              (file: { id: FileId }) => file.id === element.fileId,
            );

            if (elementFileIndex !== -1) {
              const elementFile = templateInfo.files[
                elementFileIndex
              ] as LibraryFileType;
              files[elementFile.id as string] = {
                mimeType:
                  elementFile.mimeType ||
                  (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                id: elementFile.id as BinaryFileData["id"],
                dataURL: elementFile.url as BinaryFileData["dataURL"],
                created: elementFile.created,
                isPublished: true,
              };
            }
          }
        }
      }
    }
    return files ? files : null;
  };

  const generateSvgForLibrary = async (
    libraryItems: LibraryType[],
    item: CollocationType,
  ) => {
    let files_: BinaryFiles = {};
    await Promise.all(
      libraryItems.map(async (libraryItem: LibraryType) => {
        const files = getFilesByLibraryElements(libraryItem.elements, item);
        files_ = {
          ...files_,
          ...files,
        };
        const svg = libraryItem?.elements?.length
          ? await exportToSvg(
              libraryItem.elements,
              {
                exportBackground: false,
                viewBackgroundColor: OpenColor.white,
                frameRendering: appState.frameRendering,
              },
              files,
            )
          : null;
        return {
          ...libraryItem,
          svg,
        };
      }),
    );
    setAppState({
      files: {
        ...appState.files,
        ...files_,
      },
    });
  };

  const onEdit = async (item: CollocationType) => {
    const response = await fetch(item.source);
    const result = await response.json();

    setAppState({
      editingLibrary: {
        ...appState.editingLibrary,
        libraryId: appState.editingLibrary.libraryInfo.id,
        isEditing: true,
        libraryInfo: item,
        libraryItems: result?.libraryItems[0]?.elements,
        oldElements: [],
      },
    });
    const modifiedLibraryItems = result?.libraryItems?.length
      ? result?.libraryItems.map((libraryItem: LibraryType) => {
          return { ...libraryItem, isSelected: false };
        })
      : [];
    editCollection(modifiedLibraryItems);
    generateSvgForLibrary(modifiedLibraryItems, item);
    setSelectedSideLibraryTab(SidebarItemsEnum.WorkInProgress);
  };

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const slug = new URLSearchParams(window.location.search).get("slug");

  const onLikeCollection = async (templateId: string) => {
    const res = await userActionCollectionAPI({
      collectionId: templateId,
      userEmail: userMail,
      userAction: "LIKE",
    });
    const collectionActivity = res.result;
    const templateIndex = filteredCustomCategoryData.findIndex(
      (template) => template.id === templateId,
    );

    //update filteredCustomCategoryData

    const data = [...filteredCustomCategoryData];

    // Using dot notation instead of bracket notation for "CollectionActivities"
    data[templateIndex].CollectionActivities = [collectionActivity];

    if (data[templateIndex].CollectionActivities?.[0]?.isLiked) {
      data[templateIndex].likeCount += 1;
    } else {
      data[templateIndex].likeCount -= 1;
    }

    setFilteredCustomCategoryData([...data]);
  };

  const onBookmarkCollection = async (templateId: string) => {
    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
    const res = await userActionCollectionAPI({
      collectionId: templateId,
      userEmail: userMail,
      userAction: "BOOKMARK",
    });
    const collectionActivity = res.result;
    const templateIndex = filteredCustomCategoryData.findIndex(
      (template) => template.id === templateId,
    );

    const data = [...filteredCustomCategoryData];

    // Use dot notation instead of bracket notation
    data[templateIndex].CollectionActivities = [collectionActivity];

    setFilteredCustomCategoryData([...data]);
  };

  return (
    <>
      <div
        className="row overflow-auto"
        style={{
          position: "relative",
          top: isMobile ? "7%" : "",
          height: "calc(100% - 23%)",
        }}
      >
        {filteredCustomCategoryData.map((template, index) => (
          <RenderCard
            {...props}
            index={index}
            template={template}
            setSelectedCatagoryForExportCanvas={
              setSelectedCatagoryForExportCanvas
            }
            selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
            categoryId={categoryId}
            onAfterMovedToCategory={() => {}}
            onEdit={() => {
              template.userEmail === user?.mail && onEdit(template);
            }}
            onDelete={async () => {
              const templateCategory = await getUserTemplateCategories(
                user?.username ? user.username : user.mail,
                slug ?? "",
                template.id,
              );
              if (templateCategory?.result.category.length === 1) {
                addCollectionIdToUnPublishedItems &&
                  addCollectionIdToUnPublishedItems(null);
              }
              removeCategoryFromLibraryTemplate(
                template.id,
                user?.username ? user.username : user.mail,
                slug ?? "",
                categoryId,
              );
              setFilteredCustomCategoryData(
                filteredCustomCategoryData.filter(
                  (data) => data.id !== template.id,
                ),
              );
              setToastMessage({
                message: "Successfully removed collection.",
                type: ToastType.ERROR,
              });
            }}
            setToastMessage={setToastMessage}
            onAfterDeleteCategory={(id) => {
              if (template.id !== id) {
                const updatedCategory = template.category?.filter(
                  (category) => category !== id,
                );
                const index = filteredCustomCategoryData.findIndex(
                  (data) => data.id === template.id,
                );
                if (updatedCategory) {
                  const data = [...filteredCustomCategoryData];

                  data[index].category = [...updatedCategory];

                  setFilteredCustomCategoryData([...data]);
                }
              }
              if (categoryId === id) {
                setFilteredCustomCategoryData(
                  filteredCustomCategoryData.filter(
                    (data) => data.id !== template.id,
                  ),
                );
              }
            }}
            onLikeCategoryCollection={async () =>
              await onLikeCollection(template.id)
            }
            onBookmarkCategoryCollection={async () =>
              await onBookmarkCollection(template.id)
            }
          />
        ))}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    </>
  );
};

interface RenderCardProps extends CustomCategoryProps {
  template: CollocationType;
  index: number;
  onAfterMovedToCategory: () => void;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onAfterDeleteCategory: (id: string) => void;
  setSelectedCatagoryForExportCanvas?: Dispatch<
    SetStateAction<CustomCategies[]>
  >;
  selectedCatagoryForExportCanvas?: CustomCategies[];
  onLikeCategoryCollection: () => void;
  onBookmarkCategoryCollection: () => void;
  setToastMessage: React.Dispatch<{
    message: string;
    type: ToastType;
  } | null>;
}

const RenderCard = ({
  template,
  index,
  loadElementsFromDB,
  setSelectedCatagoryForExportCanvas,
  selectedCatagoryForExportCanvas,
  categoryId,
  onLikeCategoryCollection,
  onBookmarkCategoryCollection,
  ...rest
}: RenderCardProps) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const isUserTemplate = template.userEmail === user?.mail;

  const [elements, setElements] = useState<LibraryItem["elements"]>([]);

  useEffect(() => {
    const func = async () => {
      const ele = await loadElementsFromDB(template.source);
      setElements(ele);
    };
    func();
    // eslint-disable-next-line
  }, [template]);

  return (
    <CardWithImage
      {...rest}
      _key={index}
      images={template.preview}
      buttonText=""
      isDelete={true}
      userProfile={
        template.author?.url
          ? template.author?.url + process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
          : ""
      }
      userName={template.author?.name}
      created={template.createdAt}
      name={template.name}
      likes={template.likeCount}
      isLikefill={template.CollectionActivities[0]?.isLiked}
      onLike={onLikeCategoryCollection}
      isBookMarkFill={template.CollectionActivities[0]?.isBookmarked}
      onBookmark={onBookmarkCategoryCollection}
      isMove={true}
      id={template.id}
      isEdit={isUserTemplate}
      elements={elements}
      onPreview={() => {
        rest.setPreviewTemplateData(template);
      }}
      templateCategory={template.category}
      onAfterDeleteCategory={rest.onAfterDeleteCategory}
      isCustomCatagory={true}
      setSelectedCatagoryForExportCanvas={setSelectedCatagoryForExportCanvas}
      selectedCatagoryForExportCanvas={selectedCatagoryForExportCanvas}
      template={template}
      categoryId={categoryId}
      addCollectionIdToUnPublishedItems={rest.addCollectionIdToUnPublishedItems}
      setToastMessage={rest.setToastMessage}
    />
  );
};
