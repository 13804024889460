import { useState } from "react";
import { getDefaultAppState } from "../appState";
import {
  useClockContext,
  useCountDownContext,
  useIsMobile,
} from "../components/App";
import { ColorPicker } from "../components/ColorPicker";
import ConfirmDialog from "../components/ConfirmDialog";
import { DarkModeToggle } from "../components/DarkModeToggle";
import { fitToContentIcon, trash, zoomIn, zoomOut } from "../components/icons";
import { ToolButton, ToolButtonEnum } from "../components/ToolButton";
import { Tooltip } from "../components/Tooltip";
import { MAX_ZOOM, MIN_ZOOM, THEME, ZOOM_STEP } from "../constants";
import { getCommonBounds, getNonDeletedElements } from "../element";
import { SceneBounds } from "../element/bounds";
import { newElementWith } from "../element/mutateElement";
import { ExcalidrawElement } from "../element/types";
import { deleteFiles } from "../excalidraw-app/api/collection";
import { onSaveElementInDB } from "../excalidraw-app/api/userAPI";
import { STORAGE_KEYS as LOCAL_STORAGE_KEY } from "../excalidraw-app/data/localStorage";
import { t } from "../i18n";
import { CODES, KEYS } from "../keys";
import { getNormalizedZoom, getSelectedElements } from "../scene";
import { centerScrollOn } from "../scene/scroll";
import { getNewZoom, getStateForZoom } from "../scene/zoom";
import { AppState, NormalizedZoomValue } from "../types";
import { getShortcutKey } from "../utils";
import { register } from "./register";
import { clearFrameElementsCache } from "../element/newElement";
import { StoreAction } from "../store";

export const actionChangeViewBackgroundColor = register({
  name: "changeViewBackgroundColor",
  perform: (_, appState, value) => {
    return {
      appState: { ...appState, ...value },
      commitToHistory: !!value.viewBackgroundColor,
      storeAction: !!value.viewBackgroundColor
        ? StoreAction.CAPTURE
        : StoreAction.NONE,
    };
  },
  PanelComponent: ({ appState, updateData, containerId }) => {
    return (
      <div style={{ position: "relative" }}>
        <ColorPicker
          label={t("labels.canvasBackground")}
          type="canvasBackground"
          color={appState.viewBackgroundColor}
          onChange={(color) => updateData({ viewBackgroundColor: color })}
          isActive={appState.openPopup === "canvasColorPicker"}
          setActive={(active) =>
            updateData({ openPopup: active ? "canvasColorPicker" : null })
          }
          data-testid="canvas-background-picker"
          left={0}
          top={0}
          containerId={containerId}
        />
      </div>
    );
  },
});

// export const actionClearCanvas = register({
//   name: "clearCanvas",
//   perform: (elements, appState: AppState) => {
//     return {
//       elements: elements.map((element) =>
//         newElementWith(element, { isDeleted: true }),
//       ),
//       appState: {
//         ...getDefaultAppState(),
//         theme: appState.theme,
//         elementLocked: appState.elementLocked,
//         exportBackground: appState.exportBackground,
//         exportEmbedScene: appState.exportEmbedScene,
//         gridSize: appState.gridSize,
//         showStats: appState.showStats,
//         pasteDialog: appState.pasteDialog,
//       },
//       commitToHistory: true,
//     };
//   },
//   PanelComponent: ({ updateData }) => (
//     <ToolButton
//       type="button"
//       icon={trash}
//       title={t("buttons.clearReset")}
//       aria-label={t("buttons.clearReset")}
//       showAriaLabel={useIsMobile()}
//       onClick={async () => {
//         // localStorage.setItem("clearCanvasConfirmationModal", true);

//         if (window.confirm(t("alerts.clearReset"))) {
//           window.parent.postMessage(
//             { type: "STORE_ELEMENTS", isLoading: true },
//             `${`${process.env.REACT_APP_PARENT_APP}`}`,
//           );
//           localStorage.setItem(
//             LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
//             JSON.stringify([]),
//           );
//           updateData(null);
//           const appState = JSON.parse(
//             localStorage.getItem("acv-state") || "{}",
//           );
//           const lessonId =
//             new URLSearchParams(window.location.search)
//               .get("lessonId")
//               ?.replace(/\//g, "") || "";
//           onSaveElementInDB(
//             appState.currentPage,
//             lessonId,
//             appState.DBElements || [],
//             appState.viewBackgroundColor,
//           );
//           window.parent.postMessage(
//             { type: "STORE_ELEMENTS", isLoading: false },
//             `${process.env.REACT_APP_PARENT_APP}`,
//           );
//         }
//       }}
//       data-testid="clear-canvas-button"
//     />
//   ),
// });

export const actionClearCanvas = register({
  name: "clearCanvas",
  perform: (elements, appState: AppState) => {
    return {
      elements: elements.map((element) =>
        newElementWith(element, { isDeleted: true }),
      ),
      appState: {
        ...appState,
        ...(!appState.editingLibrary.isEditing
          ? {
              ...getDefaultAppState(),
              theme: appState.theme,
              elementLocked: appState.elementLocked,
              exportBackground: appState.exportBackground,
              exportEmbedScene: appState.exportEmbedScene,
              gridSize: appState.gridSize,
              showStats: appState.showStats,
              pasteDialog: appState.pasteDialog,
            }
          : {}),
      },
      commitToHistory: true,
      storeAction: StoreAction.CAPTURE,
    };
  },
  PanelComponent: ({ updateData }) => {
    const [showModal, setShowModal] = useState(false);
    const clockInterval = useClockContext();
    const countDownInterval = useCountDownContext();

    return (
      <>
        <ToolButton
          type={ToolButtonEnum.BUTTON}
          icon={trash}
          title={t("buttons.clearReset")}
          aria-label={t("buttons.clearReset")}
          showAriaLabel={useIsMobile()}
          onClick={() => setShowModal(true)}
          data-testid="clear-canvas-button"
        />
        {showModal && (
          <ConfirmDialog
            onConfirm={async () => {
              if (clockInterval?.current) {
                clearInterval(clockInterval.current);
                clockInterval.current = null; // Optionally, set to null after clearing
              }

              window.parent.postMessage(
                { type: "STORE_ELEMENTS", isLoading: true },
                `${`${process.env.REACT_APP_PARENT_APP}`}`,
              );
              //get files from localstorage and delete them from DB
              const elements = JSON.parse(
                localStorage.getItem(
                  LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
                ) || "[]",
              );
              window.removeCountdownStates(elements);
              if (countDownInterval?.current) {
                clearInterval(countDownInterval.current.intervalId);
                countDownInterval.current = null;
              }
              const slug =
                new URLSearchParams(window.location.search).get("slug") || "";
              const user = JSON.parse(localStorage.getItem("user") || "{}");
              if (elements.length > 0) {
                elements.forEach((element: any) => {
                  deleteFiles(
                    element.fileId,
                    element.type,
                    element.lessonId,
                    user?.mail,
                    slug,
                    user.actualRole,
                  );
                });
              }
              localStorage.setItem(
                LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
                JSON.stringify([]),
              );
              // clear frame elements cache
              clearFrameElementsCache();
              const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
              if (isMyWorkSpaceData === "true") {
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.LOCAL_STORAGE_WORKSPACE_ELEMENTS,
                  JSON.stringify([]),
                );
                window.parent.postMessage(
                  {
                    type: "WORKSPACE_ELEMENTS",
                    personalWorkSpaceElements: JSON.stringify([]),
                  },
                  `${`${process.env.REACT_APP_PARENT_APP}`}`,
                );
              } else {
                localStorage.setItem(
                  LOCAL_STORAGE_KEY.LOCAL_STORAGE_ELEMENTS,
                  JSON.stringify([]),
                );
              }
              updateData(null);
              const appState = JSON.parse(
                localStorage.getItem("acv-state") || "{}",
              );
              const lessonId =
                new URLSearchParams(window.location.search)
                  .get("lessonId")
                  ?.replace(/\//g, "") || "";

              onSaveElementInDB(
                appState.currentPage,
                lessonId,
                appState.DBElements || [],
                appState.viewBackgroundColor,
              );
              window.parent.postMessage(
                { type: "STORE_ELEMENTS", isLoading: false },
                `${process.env.REACT_APP_PARENT_APP}`,
              );

              setShowModal(false);
            }}
            onCancel={() => setShowModal(false)}
            title={"Are you sure?"}
            open={true}
            setOpen={() => setShowModal(false)}
            children={<p>{t("alerts.clearReset")}</p>}
            closeOnClickOutside={false}
          />
        )}
      </>
    );
  },
});

export const actionZoomIn = register({
  name: "zoomIn",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(appState.zoom.value + ZOOM_STEP),
      },
      appState,
    );

    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={zoomIn}
      title={`${t("buttons.zoomIn")} — ${getShortcutKey("CtrlOrCmd++")}`}
      aria-label={t("buttons.zoomIn")}
      disabled={appState.zoom.value >= MAX_ZOOM}
      onClick={() => {
        // if (appState.textEditor.open) {
        //   return;
        // }
        updateData(null);
      }}
      size="small"
    />
  ),
  keyTest: (event) =>
    (event.code === CODES.EQUAL || event.code === CODES.NUM_ADD) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

export const actionZoomOut = register({
  name: "zoomOut",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(appState.zoom.value - ZOOM_STEP),
      },
      appState,
    );

    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={zoomOut}
      title={`${t("buttons.zoomOut")} — ${getShortcutKey("CtrlOrCmd+-")}`}
      aria-label={t("buttons.zoomOut")}
      disabled={appState.zoom.value <= MIN_ZOOM}
      onClick={() => {
        // if (appState.textEditor.open) {
        //   return;
        // }
        updateData(null);
      }}
      size="small"
    />
  ),
  keyTest: (event) =>
    (event.code === CODES.MINUS || event.code === CODES.NUM_SUBTRACT) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

export const actionResetZoom = register({
  name: "resetZoom",
  perform: (_elements, appState) => {
    const newZoom = getStateForZoom(
      {
        viewportX: appState.width / 2 + appState.offsetLeft,
        viewportY: appState.height / 2 + appState.offsetTop,
        nextZoom: getNormalizedZoom(1),
      },
      appState,
    );
    return {
      appState: {
        ...appState,
        scrollX: newZoom.scrollX,
        scrollY: newZoom.scrollY,
        zoom: {
          value: newZoom.zoom.value,
          translation: {
            x: newZoom.scrollX,
            y: newZoom.scrollY,
          },
        },
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => (
    <Tooltip label={t("buttons.resetZoom")}>
      <ToolButton
        type={ToolButtonEnum.BUTTON}
        className="reset-zoom-button"
        title={t("buttons.resetZoom")}
        aria-label={t("buttons.resetZoom")}
        onClick={() => {
          // if (appState.textEditor.open) {
          //   return;
          // }
          updateData(null);
        }}
        size="small"
      >
        {(appState.zoom.value * 100).toFixed(0)}%
      </ToolButton>
    </Tooltip>
  ),
  keyTest: (event) =>
    (event.code === CODES.ZERO || event.code === CODES.NUM_ZERO) &&
    (event[KEYS.CTRL_OR_CMD] || event.shiftKey),
});

const zoomValueToFitBoundsOnViewport = (
  bounds: SceneBounds,
  viewportDimensions: { width: number; height: number },
) => {
  const [x1, y1, x2, y2] = bounds;
  const commonBoundsWidth = x2 - x1;
  const zoomValueForWidth = viewportDimensions.width / commonBoundsWidth;
  const commonBoundsHeight = y2 - y1;
  const zoomValueForHeight = viewportDimensions.height / commonBoundsHeight;
  const smallestZoomValue = Math.min(zoomValueForWidth, zoomValueForHeight);
  const zoomAdjustedToSteps =
    Math.floor(smallestZoomValue / ZOOM_STEP) * ZOOM_STEP;
  const clampedZoomValueToFitElements = Math.min(
    Math.max(zoomAdjustedToSteps, ZOOM_STEP),
    1,
  );
  return clampedZoomValueToFitElements as NormalizedZoomValue;
};

const zoomToFitElements = (
  elements: readonly ExcalidrawElement[],
  appState: Readonly<AppState>,
  zoomToSelection: boolean,
) => {
  const nonDeletedElements = getNonDeletedElements(elements);
  const selectedElements = getSelectedElements(nonDeletedElements, appState);

  const commonBounds =
    zoomToSelection && selectedElements.length > 0
      ? getCommonBounds(selectedElements)
      : getCommonBounds(nonDeletedElements);

  const zoomValue = zoomValueToFitBoundsOnViewport(commonBounds as any, {
    width: appState.width,
    height: appState.height,
  });
  const newZoom = getNewZoom(zoomValue, appState.zoom, {
    left: appState.offsetLeft,
    top: appState.offsetTop,
  });

  const [x1, y1, x2, y2] = commonBounds;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  return {
    appState: {
      ...appState,
      ...centerScrollOn({
        scenePoint: { x: centerX, y: centerY },
        viewportDimensions: {
          width: appState.width,
          height: appState.height,
        },
        zoom: newZoom,
      }),
      isOutlineOpen: false,
      zoom: newZoom,
      scrollX: appState.width / 2 / newZoom.value - centerX,
      scrollY: appState.height / 2 / newZoom.value - centerY,
    },
    commitToHistory: false,
  };
};

export const actionZoomToSelected = register({
  name: "zoomToSelection",
  perform: (elements, appState) => zoomToFitElements(elements, appState, true),
  keyTest: (event) =>
    event.code === CODES.TWO &&
    event.shiftKey &&
    !event.altKey &&
    !event[KEYS.CTRL_OR_CMD],
});

export const actionZoomToFit = register({
  name: "zoomToFit",
  perform: (elements, appState) => zoomToFitElements(elements, appState, false),
  PanelComponent: ({ updateData, appState }) => (
    <ToolButton
      type={ToolButtonEnum.BUTTON}
      icon={fitToContentIcon()} // Replace with your icon
      title="Fit to Content"
      aria-label="Fit to Content"
      onClick={() => updateData(null)}
      size="small"
      id="fit-to-content"
    />
  ),
  keyTest: (event) =>
    event.code === CODES.ONE &&
    event.shiftKey &&
    !event.altKey &&
    !event[KEYS.CTRL_OR_CMD],
});

export const actionToggleTheme = register({
  name: "toggleTheme",
  perform: (_, appState, value) => {
    return {
      appState: {
        ...appState,
        theme:
          value || (appState.theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT),
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <div className="darkmodeBtn">
      <DarkModeToggle
        value={appState.theme}
        onChange={(theme) => {
          updateData(theme);
        }}
      />
    </div>
  ),
  keyTest: (event) => event.altKey && event.shiftKey && event.code === CODES.D,
});

export const zoomToFitBounds = ({
  bounds,
  appState,
  fitToViewport = false,
  viewportZoomFactor = 0.7,
}: {
  bounds: SceneBounds;
  appState: Readonly<AppState>;
  /** whether to fit content to viewport (beyond >100%) */
  fitToViewport: boolean;
  /** zoom content to cover X of the viewport, when fitToViewport=true */
  viewportZoomFactor?: number;
}) => {
  const [x1, y1, x2, y2] = bounds;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  let newZoomValue;
  let scrollX;
  let scrollY;

  if (fitToViewport) {
    const commonBoundsWidth = x2 - x1;
    const commonBoundsHeight = y2 - y1;

    newZoomValue =
      Math.min(
        appState.width / commonBoundsWidth,
        appState.height / commonBoundsHeight,
      ) * Math.min(1, Math.max(viewportZoomFactor, 0.1));

    // Apply clamping to newZoomValue to be between 10% and 3000%
    newZoomValue = Math.min(
      Math.max(newZoomValue, 0.1),
      30.0,
    ) as NormalizedZoomValue;

    let appStateWidth = appState.width;

    if (appState.sidebarWrapper) {
      const sidebarDOMElem = document.querySelector(
        ".sidebar",
      ) as HTMLElement | null;
      const sidebarWidth = sidebarDOMElem?.offsetWidth ?? 0;
      const isRTL = document.documentElement.getAttribute("dir") === "rtl";

      appStateWidth = !isRTL
        ? appState.width - sidebarWidth
        : appState.width + sidebarWidth;
    }

    scrollX = (appStateWidth / 2) * (1 / newZoomValue) - centerX;
    scrollY = (appState.height / 2) * (1 / newZoomValue) - centerY;
  } else {
    newZoomValue = zoomValueToFitBoundsOnViewport(bounds, {
      width: appState.width,
      height: appState.height,
    });

    const centerScroll = centerScrollOn({
      scenePoint: { x: centerX, y: centerY },
      viewportDimensions: {
        width: appState.width,
        height: appState.height,
      },
      zoom: {
        value: newZoomValue,
        translation: {
          x: appState.scrollX,
          y: appState.scrollY,
        },
      },
    });

    scrollX = centerScroll.scrollX;
    scrollY = centerScroll.scrollY;
  }

  return {
    appState: {
      ...appState,
      scrollX,
      scrollY,
      zoom: { value: newZoomValue },
    },
    commitToHistory: false,
  };
};

export const zoomToFit = ({
  targetElements,
  appState,
  fitToViewport,
  viewportZoomFactor,
}: {
  targetElements: readonly ExcalidrawElement[];
  appState: Readonly<AppState>;
  /** whether to fit content to viewport (beyond >100%) */
  fitToViewport: boolean;
  /** zoom content to cover X of the viewport, when fitToViewport=true */
  viewportZoomFactor?: number;
}) => {
  const commonBounds = getCommonBounds(getNonDeletedElements(targetElements));

  return zoomToFitBounds({
    bounds: commonBounds,
    appState,
    fitToViewport,
    viewportZoomFactor,
  });
};
