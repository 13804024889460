import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { useIsMobile } from "./App";
import "./TipTapEditorWithCommentBox.scss";

type Props = {
  createComment: Function;
  comment: string;
  setComment: (value: { html: string; text: string }) => void;
  textCommentLength?: number;
};

export const TipTapEditorWithCommentBox = ({
  comment,
  setComment,
  createComment,
  textCommentLength,
}: Props) => {
  const extensions = [
    StarterKit.configure({
      bulletList: false,
    }),
    BulletList.configure({
      keepMarks: true,
    }).extend({
      addKeyboardShortcuts() {
        return {
          Tab: () => {
            const { editor } = this;

            // Get the current selection and the position before it
            const { from } = editor.state.selection;
            const textBefore = editor.state.doc.textBetween(
              from - 1,
              from,
              undefined,
              " ",
            );

            // Check if the last character is a dash
            if (textBefore.trim() === "-") {
              // Create a transaction to delete the previous character (dash)
              editor
                .chain()
                .focus()
                .deleteRange({ from: from - 1, to: from })
                .run();

              // Toggle bullet list
              editor.commands.toggleBulletList();
              return true; // Indicates the command was handled
            }

            // If not in bullet or ordered list, toggle bullet list
            return this.editor.isActive("bulletList") ||
              this.editor.isActive("orderedList")
              ? editor.commands.sinkListItem("listItem")
              : editor.commands.toggleBulletList();
          },
        };
      },
    }),
    OrderedList.configure({
      itemTypeName: "listItem",
    }),
  ];

  const [headingDropdownOpen, setHeadingDropdownOpen] = useState(false);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(0);

  const isMobileView = useIsMobile();

  const editor = useEditor({
    extensions,
    content: comment,
    onUpdate({ editor }) {
      setComment({
        html: editor.getHTML(),
        text: editor.getText(),
      });
    },
  });

  useEffect(() => {
    if (editor && !comment) {
      editor.commands.setContent(comment);
    }
    // eslint-disable-next-line
  }, [comment]);

  if (!editor) {
    return null;
  }

  const handleToggleHeadingDropdown = () => {
    setHeadingDropdownOpen(!headingDropdownOpen);
  };

  const handleSelectHeading = (level: number) => {
    setSelectedHeadingLevel(level);
    setHeadingDropdownOpen(false);
    //@ts-ignore
    editor?.chain().focus().toggleHeading({ level }).run();
  };

  const headingDropdownOptions = [1, 2, 3, 4, 5, 6].map((level) => (
    <button
      key={level}
      type="button"
      className="dropdown-item"
      onClick={() => handleSelectHeading(level)}
    >
      H{level}
    </button>
  ));

  return (
    <div
      className="flex-grow-1"
      style={{
        border: "1px solid #D5DAE0",
        borderRadius: "4px",
        wordBreak: "break-all",
        maxWidth: isMobileView ? "100%" : 500,
      }}
    >
      <div
        className="div"
        style={{
          padding: "6px",
          display: "flex",
          gap: "6px",
          justifyContent: "start",
          paddingTop: "0px",
          paddingBottom: "0px",
          borderBottom: "1px solid #D5DAE0",
          flexWrap: "wrap",
        }}
      >
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
          style={{
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-type-bold"
            viewBox="0 0 16 16"
          >
            <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z" />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
          style={{
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-type-italic"
            viewBox="0 0 16 16"
          >
            <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z" />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
          style={{
            borderRight: "1px solid #D5DAE0",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingRight: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-type-strikethrough"
            viewBox="0 0 16 16"
          >
            <path d="M6.333 5.686c0 .31.083.581.27.814H5.166a2.8 2.8 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967" />
          </svg>
        </button>

        <div className="heading-toolbar">
          <button
            className="dropdown-toggle"
            type="button"
            id="headingDropdown"
            data-bs-toggle="dropdown"
            aria-expanded={headingDropdownOpen}
            onClick={handleToggleHeadingDropdown}
          >
            {selectedHeadingLevel ? `H${selectedHeadingLevel}` : "Heading"}
          </button>
          <ul
            className={`dropdown-menu  ${headingDropdownOpen ? "show" : ""}`}
            aria-labelledby="headingDropdown"
            style={{ minWidth: "4rem", textAlign: "center" }}
          >
            {headingDropdownOptions}
          </ul>
        </div>

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
          style={{
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list-ul"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
            />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
          style={{
            borderRight: "1px solid #D5DAE0",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingRight: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list-ol"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"
            />
            <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z" />
          </svg>
        </button>

        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
          style={{
            borderRight: "1px solid #D5DAE0",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingRight: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-code"
            viewBox="0 0 16 16"
          >
            <path d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8z" />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          style={{
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-counterclockwise"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
          </svg>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-clockwise"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
          </svg>
        </button>
      </div>
      {editor && (
        <>
          <EditorContent
            editor={editor}
            name="text"
            placeholder="Add your comment ..."
            className="text_area w-100"
            id={"id_text"}
            value={comment}
            onKeyDown={async (e) => {
              // Check for Enter key and no Shift key
              if (e.keyCode === 13 && !e.shiftKey) {
                // Check if there are no ordered lists or bullet lists
                const hasBulletList = editor.isActive("bulletList");
                const hasOrderedList = editor.isActive("orderedList");

                if (!hasBulletList && !hasOrderedList) {
                  // Check text length
                  if (textCommentLength && textCommentLength <= 400) {
                    e.preventDefault();
                    await createComment(comment);
                  }
                }
              }
            }}
            style={{
              border: "none",
              padding: "0.5em 0.6em",
              boxShadow: "inset 0 1px 3px #ddd",
            }}
          />
          {textCommentLength ? (
            <span
              style={{
                color: textCommentLength > 400 ? "red" : "green",
              }}
              className="d-flex justify-content-end"
            >
              {textCommentLength}/400
            </span>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
