import { useEffect, useState } from "react";
import Loader from "../../../../../../App/shareComponent/Loader";
import {
  deleteSpinWheel,
  getPublicSpinWheel,
  getSpinWheels,
} from "../../../../../../excalidraw-app/api/collection";
import { getLessonId } from "../../../../../../excalidraw-app/api/getuserInfo";
import { t } from "../../../../../../i18n";
import { useIsMobile } from "../../../../../App";
import ConfirmDialog from "../../../../../ConfirmDialog";
import { SegmentType, timerDuration } from "../../../../../spinner/types";
import { CardWithTextEditor } from "../../../../components/card/CardWithTextEditor";

interface PublicSpinWheelProps {
  setPreviewDialog: (
    val: {
      id: string;
      title: string;
      description: string;
      isPublic: boolean;
      isTimer: boolean;
      timerDuration: {
        [timerDuration.MINUTES]: number;
        [timerDuration.SECONDS]: number;
      };
      wheels: SegmentType[];
    } | null,
  ) => void;
  handleSpinnerDialog: (val: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => void;
  search: string;
  fromMyLibrary?: boolean;
}

export const PublicSpinWheel = (props: PublicSpinWheelProps) => {
  const {
    setPreviewDialog,
    handleSpinnerDialog,
    search,
    fromMyLibrary = false,
  } = props;

  const [spinWheels, setSpinWheels] = useState<
    {
      id: string;
      title: string;
      description: string;
      isPublic: boolean;
      isTimer: boolean;
      timerDuration: {
        [timerDuration.MINUTES]: number;
        [timerDuration.SECONDS]: number;
      };
      wheels: SegmentType[];
    }[]
  >([]);
  const [originalSpinWheels, setOriginalSpinWheels] = useState<
    {
      id: string;
      title: string;
      description: string;
      isPublic: boolean;
      isTimer: boolean;
      timerDuration: {
        [timerDuration.MINUTES]: number;
        [timerDuration.SECONDS]: number;
      };
      wheels: SegmentType[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const getSpinWheels_ = async () => {
    try {
      setIsLoading(true);
      const lessonId = await getLessonId();
      if (fromMyLibrary) {
        const res = await getSpinWheels({
          userId: user?.username ? user.username : user.mail,
          slug: user.slug,
          lessonId,
        });
        if (res?.status === "Success" || res?.status === "success") {
          setSpinWheels(res?.result);
          setOriginalSpinWheels(res?.result);
          setIsLoading(false);
        }
      } else {
        const res = await getPublicSpinWheel();
        if (res?.status === "Success" || res?.status === "success") {
          setSpinWheels(res?.result);
          setOriginalSpinWheels(res?.result);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpinWheels_();
    // eslint-disable-next-line
  }, []);

  const deleteElement = async (id: string) => {
    setIsOpenConfirmDialog(true);
    setDeleteId(id);
  };

  useEffect(() => {
    const filtredSpinwheels = originalSpinWheels?.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    });
    setSpinWheels(filtredSpinwheels);
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      {isLoading ? (
        <Loader className="h-50" />
      ) : spinWheels?.length > 0 ? (
        <div
          className="row overflow-auto"
          style={{
            position: "relative",
            top: isMobile ? "7%" : "",
            // height: "calc(100% - 26%)",
          }}
        >
          {spinWheels?.length > 0 &&
            spinWheels?.map((item, index) => {
              // if (!item.isPublic && !fromMyLibrary) return;
              return (
                <CardWithTextEditor
                  item={item}
                  index={index}
                  setPreviewDialog={setPreviewDialog}
                  handleSpinnerDialog={handleSpinnerDialog}
                  deleteElement={deleteElement}
                  isMobile={isMobile}
                  description={
                    item.description ? item.description : t("cards.connect")
                  }
                />
              );
            })}
        </div>
      ) : (
        <div className="text-center">
          <h5>{t("spinwheels.noFound")}</h5>
        </div>
      )}
      {isOpenConfirmDialog && (
        <ConfirmDialog
          onCancel={() => {
            setIsOpenConfirmDialog(false);
          }}
          onConfirm={async () => {
            try {
              setIsOpenConfirmDialog(false);
              await deleteSpinWheel(deleteId);
              setOriginalSpinWheels(
                originalSpinWheels?.filter((item: any) => item.id !== deleteId),
              );
              setSpinWheels(
                spinWheels?.filter((item: any) => item.id !== deleteId),
              );
              setDeleteId("");
            } catch (error) {
              console.error(error);
            }
          }}
          onClose={() => {
            setIsOpenConfirmDialog(false);
          }}
          confirmText="Delete"
          title={"Remove Spin Wheel"}
          open={isOpenConfirmDialog}
          setOpen={setIsOpenConfirmDialog}
          children={"Are you sure you want to delete the spin wheel?"}
          isShowInput={false}
          isEditable={false}
          closeOnClickOutside={false}
          className={`${isMobile && "remove-spin-wheel"}`}
        />
      )}
    </>
  );
};
