import { BlobServiceClient } from "@azure/storage-blob";
import {
  createRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  EXPORT_DATA_TYPES,
  EXPORT_SOURCE,
  VERSIONS,
} from "../../../../constants";
import { ExportedLibraryData } from "../../../../data/types";
import { getLessonId } from "../../../../excalidraw-app/api/getuserInfo";
import { updateWhiteboardMedia } from "../../../../excalidraw-app/api/loadDataFromCosmosDB";
import Portall from "../../../../excalidraw-app/collab/Portall";
import { t } from "../../../../i18n";
import { exportToSvg } from "../../../../scene/export";
import {
  AppState,
  BinaryFiles,
  LibraryItem,
  LibraryItems,
  LibraryType,
} from "../../../../types";
import { urlToDataUrl } from "../../../../utils/convertToDataURL";
import { uploadSVGToAzureBlob } from "../../../../utils/uploadImageToAzureBlob";
import EditCardDetails from "../../../EditCardDetails";
import { ToolButton, ToolButtonEnum } from "../../../ToolButton";
import { UserProfileContext } from "../../../contexts/UserProfile.context";
import "./CardWithEditing.scss";

interface PublishLibraryDataParams {
  authorName: string;
  githubHandle: string;
  name: string;
  description: string;
  twitterHandle: string;
  website: string;
  title: string;
}

const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net?${process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN}`,
);

const LOCAL_STORAGE_KEY_PUBLISH_LIBRARY = "publish-library-data";

const savePublishLibDataToStorage = (data: PublishLibraryDataParams) => {
  try {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_PUBLISH_LIBRARY,
      JSON.stringify(data),
    );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

const importPublishLibDataFromStorage = () => {
  try {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY_PUBLISH_LIBRARY);
    if (data) {
      return JSON.parse(data);
    }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

const uploadFilesToBlobStorage = async (files: BinaryFiles[]) => {
  try {
    if (files?.length > 0) {
      // const uploadFiles = [];
      // Container and blob information
      const containerName = "published-files";
      const lessonId = await getLessonId();
      const uploadFiles = await Promise.all(
        files.map(async (file: any) => {
          const type = file.mimeType.split("/");
          await updateWhiteboardMedia({
            type: type[0],
            fileId: file.id,
            lessonId,
            data: {
              ...file,
              isPublished: true,
            },
          });
          const blobName = `${file.id}.${type[1]}-${Date.now()}`; // Name for the uploaded blob
          // Your Data URL
          const dataURL = file.dataURL; // Replace with your Data URL
          // Convert the Data URL to binary data
          let base64 = dataURL;
          const result = checkType(dataURL);
          if (result === "URL") {
            await urlToDataUrl(file.dataURL, file.mimeType).then(
              async (result) => {
                if (result) {
                  base64 = result.dataUrl;
                } else {
                  console.error(
                    "Failed to fetch and convert the URL to a data URL.",
                  );
                }
              },
            );
          }
          const data = base64.split(",")[1];

          const buffer = Buffer.from(data, "base64");

          // Get a reference to the container
          const containerClient = blobServiceClient.getContainerClient(
            containerName,
          );

          // Create a new blob client
          const blobClient = containerClient.getBlockBlobClient(blobName);

          // Upload the binary data to the blob
          await blobClient.uploadData(buffer, {
            blobHTTPHeaders: {
              blobContentType: file.mimeType,
            },
          });
          return {
            id: file.id,
            mimeType: file.mimeType,
            url: `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net/${containerName}/${blobName}`,
            created: file.created,
            isPublished: true,
          };
        }),
      );

      return uploadFiles;
    }
  } catch (error) {
    console.error("ERROR", error);
  }
};

const isBase64 = (str: string) => {
  try {
    // Attempt to decode the string as base64
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

const isURL = (str: string) => {
  try {
    // Attempt to create a URL object
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
};

const checkType = (str: string) => {
  if (str.startsWith("http://") || str.startsWith("https://")) {
    return isURL(str) ? "URL" : "Not a valid URL";
  } else if (isBase64(str)) {
    return "Base64";
  }
  return "Unknown";
};

const CardWithEditing = ({
  libraryItems,
  appState,
  onSuccess,
  onError,
  updateItemsInStorage,
  onRemove,
  files,
  isTrusted,
  setSaveLib,
  setDeleteElement,
  setAddToCollection,
  setSelectedItems,
}: {
  libraryItems: LibraryItems;
  appState: AppState;
  onSuccess?: (data: {
    url: string;
    authorName: string;
    items: LibraryItems | LibraryType[];
  }) => void;
  onError: (error: Error) => void;
  updateItemsInStorage?: (items: LibraryItems) => void;
  onRemove?: (id: string) => void;
  files: BinaryFiles;
  isTrusted: number;
  setSaveLib: (val: boolean) => void;
  setDeleteElement: (val: number[]) => void;
  setAddToCollection: (value: number[]) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [clonedLibItems, setClonedLibItems] = useState<LibraryItems>(
    libraryItems.slice(),
  );
  const [uploadedFiles, setUploadedFiles] = useState<BinaryFiles[]>([]);
  const [svgRefs] = useState(() =>
    Array.from({ length: clonedLibItems.length }, () =>
      createRef<HTMLDivElement>(),
    ),
  );
  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleChange = () => {
    setIsToggled(!isToggled);
  };

  const [libraryData, setLibraryData] = useState<PublishLibraryDataParams>({
    authorName: "",
    githubHandle: "",
    name: "",
    description: "",
    twitterHandle: "",
    website: "",
    title: "",
  });
  const { image } = useContext(UserProfileContext);
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  const [tags, setTags] = useState<string[]>([]);
  const [tag, setTag] = useState("");
  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.key === "Enter" && tag) {
      setTags([...(tags ?? []), tag]);
      setTag("");
    }
  };

  const removeTags = (index: number) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  useEffect(() => {
    setClonedLibItems(libraryItems.slice());
    if (files && libraryItems && libraryItems.length > 0) {
      const allIds = libraryItems.flatMap((fileData) =>
        // @ts-ignore
        fileData?.elements?.map((element) => element?.fileId),
      );
      const matchingObjects = Object.keys(files)
        .filter((id) => allIds.includes(id))
        .map((id) => files[id]);
      setUploadedFiles([...matchingObjects] as any);
    }
    // eslint-disable-next-line
  }, [libraryItems]);

  useEffect(() => {
    if (appState.editingLibrary.isEditing) {
      const libData = appState.editingLibrary.libraryInfo;
      if (!libData) {
        return;
      }

      setLibraryData({
        ...libraryData,
        name: libData?.name,
        description: libData?.description,
        title: libData?.title || "",
      });
      setTags(libData.tags);
    }
    // eslint-disable-next-line
  }, [appState.editingLibrary]);

  useEffect(() => {
    const data = importPublishLibDataFromStorage();
    if (data) {
      setLibraryData(data);
    }
  }, []);

  const onInputChange = (event: any) => {
    setLibraryData({
      ...libraryData,
      [event.target.name]: event.target.value,
    });
  };

  const updateLibraryItem = async (createNew: boolean) => {
    const erroredLibItems: LibraryItem[] = [];
    let isError = false;
    clonedLibItems.forEach((libItem) => {
      let error = "";
      if (!libItem.name || !libraryData.description || !libraryData.name) {
        error = t("publishDialog.errors.required");
        isError = true;
      }
      if (!libItem.name) {
        erroredLibItems.push({ ...libItem, error });
      } else {
        erroredLibItems.push({ ...libItem, error: "" });
      }

      if (!libraryData.description || !libraryData.name) {
        setErrors({
          ...errors,
          description: !libraryData.description ? error : "",
          name: !libraryData.name ? error : "",
        });
      } else {
        setErrors({ ...errors, description: "", name: "" });
      }
    });
    if (isError) {
      setClonedLibItems(erroredLibItems);
      return;
    }
    let fileSizeGB = 0;
    const promises = clonedLibItems.map(async (libItem, index) => {
      const svg = await exportToSvg(
        libItem.elements,
        {
          exportBackground: false,
          viewBackgroundColor: "white",
          frameRendering: appState.frameRendering,
        },
        files,
      );
      // Serialize the SVG element to a string

      const url = (await uploadSVGToAzureBlob(svg, "collecations")) || "";
      const response = await fetch(url);
      const blob = await response.blob(); // Get the file as a blob

      const sizeInBytes: number = blob.size; // Size in bytes
      const sizeInGB = sizeInBytes / Number(1024 * 1024 * 1024); // Size in megabytes

      fileSizeGB += sizeInGB;

      return url || "";
    });

    let previewImg: string[] = [];
    await Promise.all(promises)
      .then(async (images) => {
        if (!images.length) {
          return;
        }
        previewImg = images;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const libContent: ExportedLibraryData = {
      type: EXPORT_DATA_TYPES.excalidrawLibrary,
      version: VERSIONS.excalidrawLibrary,
      source: EXPORT_SOURCE,
      libraryItems: clonedLibItems,
    };

    const content = JSON.stringify(libContent, null, 2);
    const lib = new Blob([content], { type: "application/json" });
    const lessonId = new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "");

    const user: any = JSON.parse(localStorage.getItem("user")!);

    const uploadFile = await uploadFilesToBlobStorage(uploadedFiles);
    const slug = new URLSearchParams(window.location.search).get("slug");

    const formData = new FormData();

    const libData = appState.editingLibrary;
    if (!libData.libraryInfo) {
      return;
    }
    formData.append("excalidrawLib", lib);
    formData.append("previewImage", JSON.stringify(previewImg));
    formData.append("previewImageType", "image/png");
    formData.append("title", libraryData.title);
    formData.append("authorName", user?.displayName);
    formData.append("name", libraryData.name);
    formData.append("description", libraryData.description);
    formData.append("lessonId", lessonId as string);
    formData.append("userId", user.mail);
    formData.append(
      "tag",
      tags.length
        ? JSON.stringify(tags)
        : JSON.stringify(libData.libraryInfo.tags),
    );
    formData.append("profileImg", image ? image : "");
    formData.append("files", JSON.stringify(uploadFile));
    formData.append("id", libData.libraryInfo.id);
    formData.append("createNew", String(createNew));
    formData.append("slug", slug || "");
    formData.append("isPublic", isToggled.toString());
    formData.append("fileSizeInGb", String(fileSizeGB));
    formData.append("role", user.actualRole);

    fetch(`${process.env.REACT_APP_ACV_BACKEND_API}/api/library/collection`, {
      method: "put",
      body: formData,
    })
      .then(
        (response) => {
          if (response.ok) {
            return response.json().then(({ url }) => {
              // flush data from local storage1
              localStorage.removeItem(LOCAL_STORAGE_KEY_PUBLISH_LIBRARY);
              onSuccess &&
                onSuccess({
                  url,
                  authorName: libraryData.authorName,
                  items: clonedLibItems,
                });
              setSaveLib(false);
              setDeleteElement([]);
              setAddToCollection([]);
              setSelectedItems([]);
            });
          }
          return response
            .json()
            .catch(() => {
              throw new Error(response.statusText || "something went wrong");
            })
            .then((error) => {
              throw new Error(
                error.message || response.statusText || "something went wrong",
              );
            });
        },
        (err) => {
          console.error(err);
          onError(err);
        },
      )
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  };

  const onDialogClose = useCallback(() => {
    updateItemsInStorage && updateItemsInStorage(clonedLibItems);
    savePublishLibDataToStorage(libraryData);
    setDeleteElement([]);
    setSaveLib(false);
    setSelectedItems([]);
    // eslint-disable-next-line
  }, [clonedLibItems, updateItemsInStorage, libraryData]);

  const containsPublishedItems = appState.editingLibrary.isEditing;

  const editExisting = () => {
    updateLibraryItem(false);
  };

  const renderLibraryItems = () => {
    if (!svgRefs) {
      return;
    }
    const items: ReactNode[] = [];

    clonedLibItems.forEach((libItem, index) => {
      items.push(
        <EditCardDetails
          libItem={libItem}
          appState={appState}
          index={index}
          onChange={(val, index) => {
            const items = clonedLibItems.slice();
            items[index].name = val;
            setClonedLibItems(items);
            setLibraryData({ ...libraryData, title: val });
          }}
          onRemove={onRemove && onRemove}
          files={files}
          svgRef={svgRefs[index]}
        />,
      );
    });

    return (
      <div
        className="row overflow-auto"
        style={{
          position: "relative",
        }}
      >
        {items}
      </div>
    );
  };

  return (
    <div>
      {isTrusted ? (
        <div
          className="form-check form-switch"
          style={{ display: "flex", gap: "1%" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            checked={isToggled}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            Share with Everyone
          </label>
        </div>
      ) : null}
      {renderLibraryItems()}
      <div className="publish-library__fields">
        <div className="d-flex w-100">
          <div className="w-100">
            <label className="p-0">
              <div>
                <span>{t("publishDialog.libraryName")}</span>
                <span aria-hidden="true" className="required">
                  *
                </span>
              </div>
            </label>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                name="name"
                required
                value={libraryData.name}
                className="input"
                onChange={onInputChange}
                placeholder={t("publishDialog.placeholder.libraryName")}
              />
              <span className="error">{errors.name}</span>
            </div>
          </div>
          <div className="w-100">
            <label className="p-0">
              <span>
                {"Tag"}{" "}
                <span style={{ fontSize: 13 }}> (Press enter to add tags)</span>
              </span>
            </label>
            <div className="tags-input">
              <ul id="tags">
                {tags?.map((tag, index) => (
                  <li key={index} className="tag">
                    <span className="tag-title">{tag}</span>
                    <span
                      className="tag-close-icon"
                      onClick={() => removeTags(index)}
                    >
                      &#x2715;
                    </span>
                  </li>
                ))}
              </ul>
              <input
                type="text"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyUp={addTags}
                placeholder="Press enter to add tags"
              />
            </div>
          </div>
        </div>

        <label style={{ alignItems: "flex-start" }} className="mt-2 p-0">
          <div>
            <span>{t("publishDialog.libraryDesc")}</span>
            <span aria-hidden="true" className="required">
              *
            </span>
          </div>
        </label>
        <div style={{ position: "relative" }}>
          <textarea
            name="description"
            rows={4}
            required
            value={libraryData.description}
            onChange={onInputChange}
            className="input m-0"
            placeholder={t("publishDialog.placeholder.libraryDesc")}
          />
          <span className="error">{errors.description}</span>
        </div>
      </div>
      <Portall containerId="edit-lib-btn">
        <div className="publish-library__buttons">
          {containsPublishedItems ? (
            <>
              {" "}
              <ToolButton
                type={ToolButtonEnum.BUTTON}
                title={t("buttons.cancel")}
                aria-label={t("buttons.cancel")}
                label={t("buttons.cancel")}
                onClick={onDialogClose}
                data-testid="cancel-clear-canvas-button"
                className="publish-library__buttons--cancel"
              />
              <ToolButton
                type={ToolButtonEnum.BUTTON}
                title={"Submit Update"}
                aria-label={"Submit Update"}
                className="publish-library__buttons--confirm"
                showAriaLabel={true}
                onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                  editExisting()
                }
              />
              <ToolButton
                type={ToolButtonEnum.BUTTON}
                title={"Create New"}
                aria-label={"Create New"}
                className="publish-library__buttons--confirm"
                showAriaLabel={true}
                onClick={() => {
                  updateLibraryItem(true);
                }}
              />{" "}
            </>
          ) : null}
        </div>
      </Portall>
    </div>
  );
};

export default CardWithEditing;
