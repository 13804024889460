import { createContext, Dispatch, SetStateAction } from "react";

type UserProfileType = {
  image: string | null;
  gptCredit: number;
  setGptCredit: Dispatch<SetStateAction<number>>; // Function to update gptCredit
};

const UserProfileDefaultValue: UserProfileType = {
  image: null,
  gptCredit: 0,
  setGptCredit: () => {}, // Default is a no-op function
};

export const UserProfileContext = createContext<UserProfileType>(
  UserProfileDefaultValue,
);
