import _ from "lodash";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../../../../../App/shareComponent/Loader";
import {
  fetchFilteredCollectionData,
  fetchUserActionCollectionsAPI,
  userActionCollectionAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../../../excalidraw-app/api/getuserInfo";
import { CollocationType } from "../../../../../../types";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { TemplateCollectionProps } from "../template";

interface LikedCollectionPropsTypes extends TemplateCollectionProps {
  setSelectedTemplate: (val: string[]) => void;
  selectedTemplate: string[];
}

export const LikedCollection = forwardRef<FilterRef, LikedCollectionPropsTypes>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      loadElementsFromDB,
      selectedTemplate,
      setSelectedTemplate,
      selectedSortBy,
    },
    ref,
  ) => {
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [sort, setSort] = useState(1);
    const [search, setSearch] = useState("");
    const [isFilteredApplied, setIsFilteredAplied] = useState(false);
    const [selectedFilteredValues, setSelectedFilteredValues] = useState<
      Pick<FilteredValueType, "age" | "author" | "tags">
    >();

    const [isLoading, setIsLoading] = useState(false);
    const [filteredLikeData, setFilteredLikeData] = useState<CollocationType[]>(
      [],
    );
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);

    const fetchLikedCollection = async () => {
      try {
        const user = await getUserInfo();
        setIsLoading(true);

        const { result } = await fetchUserActionCollectionsAPI({
          userAction: "LIKE",
          userEmail: user.mail,
          offset: page,
        });
        setTotalCount(result.totalCount);
        setFilteredLikeData(result?.collections);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchLikedCollection();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        const user = await getUserInfo();
        setIsLoading(true);
        try {
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchUserActionCollectionsAPI({
            sort: index,
            userAction: "LIKE",
            userEmail: user.mail,
            offset: 0,
          });
          setPage(0);
          setSort(index);
          setTotalCount(result.totalCount);
          setFilteredLikeData([...result.collections]);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    const debouncedSearch = useCallback(
      _.debounce(async (searchVal) => {
        const user = await getUserInfo();
        setIsLoading(true);
        const { result } = await fetchUserActionCollectionsAPI({
          search: searchVal,
          sort: sort,
          offset: 0,
          userEmail: user.mail,
          userAction: "LIKE",
        });
        setPage(0);
        setTotalCount(result.totalCount);
        setIsLoading(false);
        setFilteredLikeData([...result.collections]);
      }, 500),
      [],
    );

    useEffect(() => {
      debouncedSearch(searchVal);
      setSearch(searchVal);
    }, [searchVal, debouncedSearch]);

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        setPage(0);
        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          userAction: "LIKE",
          search,
          offset: 0,
          userId: userMail,
        });

        setTotalCount(result.totalCount);
        setFilteredLikeData(result.collections);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    const removeFilters = async () => {
      try {
        const user = await getUserInfo();
        setIsLoading(true);
        const { result } = await fetchUserActionCollectionsAPI({
          userAction: "LIKE",
          userEmail: user.mail,
        });
        setTotalCount(result.totalCount);
        setFilteredLikeData([...result.collections]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
      onSetIsFilteredAplied: setIsFilteredAplied,
      onSetSelectedFilteredValues: setSelectedFilteredValues,
    }));

    const onAddtoCollectionBtnClick = (collectionSource: string) => {
      if (selectedTemplate.includes(collectionSource)) {
        // If the source is already selected, remove it from the array
        setSelectedTemplate(
          selectedTemplate.filter(
            (source: string) => source !== collectionSource,
          ),
        );
      } else {
        setSelectedTemplate([...selectedTemplate, collectionSource]);
      }
    };

    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    const onLikeCollection = async (templateId: string) => {
      userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "LIKE",
      });
      setTotalCount(totalCount - 1);
      setFilteredLikeData([
        ...filteredLikeData.filter((template) => template.id !== templateId),
      ]);
    };

    const onBookmarkCollection = async (templateId: string) => {
      const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
      const res = await userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "BOOKMARK",
      });
      const collectionActivity = res.result;
      const templateIndex = filteredLikeData.findIndex(
        (template) => template.id === templateId,
      );

      const data = [...filteredLikeData];

      data[templateIndex].CollectionActivities = [collectionActivity];

      setFilteredLikeData([...data]);
    };

    const fetchMoreCollections = async () => {
      const pageCount = Number(page) + 1;
      if (isFilteredApplied) {
        const pageCount = Number(page) + 1;
        if (isFilteredApplied) {
          const { result } = await fetchFilteredCollectionData({
            ...(selectedFilteredValues as any),
            userAction: "LIKE",
            search,
            offset: 0,
            userId: userMail,
          });

          setTotalCount(result.totalCount);
          setFilteredLikeData([...filteredLikeData, ...result.collections]);
        } else {
          const { result } = await fetchUserActionCollectionsAPI({
            search,
            sort,
            offset: pageCount,
            userAction: "LIKE",
            userEmail: userMail,
          });
          setTotalCount(result.totalCount);
          setFilteredLikeData([...filteredLikeData, ...result.collections]);
        }
        setPage(pageCount);
      } else {
        const { result } = await fetchUserActionCollectionsAPI({
          search,
          sort,
          offset: pageCount,
          userAction: "LIKE",
          userEmail: userMail,
        });
        setTotalCount(result.totalCount);
        setFilteredLikeData([...filteredLikeData, ...result.collections]);
      }
      setPage(pageCount);
    };

    return (
      <div
        className="row overflow-auto likeCollection"
        style={{ height: "calc(100% - 28%)" }}
      >
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          <InfiniteScroll
            hasMore={totalCount > filteredLikeData.length}
            initialLoad={false}
            loadMore={fetchMoreCollections}
            useWindow={false}
            getScrollParent={() => document.querySelector(".likeCollection")}
            className="row"
            loader="Loading..."
          >
            {filteredLikeData.map((item, index) => {
              return (
                <CardWithImage
                  key={index}
                  images={item.preview}
                  userProfile={
                    item.author?.url
                      ? item.author?.url +
                        process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
                      : ""
                  }
                  userName={item.author?.name}
                  created={item.createdAt}
                  name={item.name}
                  likes={item.likeCount}
                  isLikefill={item.CollectionActivities[0]?.isLiked}
                  onLike={() => {
                    onLikeCollection(item.id);
                  }}
                  isBookMarkFill={item.CollectionActivities[0].isBookmarked}
                  onBookmark={async () => {
                    await onBookmarkCollection(item.id);
                  }}
                  setFullScreenCollectionPreview={
                    setFullScreenCollectionPreview
                  }
                  onPreview={() => {
                    setPreviewTemplateData(item);
                  }}
                  onUseThisTemplate={() => loadElementsFromDB(item.source)}
                  isCustomCatagory={false}
                  source={item.source}
                  onAddtoCollectionBtnClick={() =>
                    onAddtoCollectionBtnClick(item.source)
                  }
                  selectedTemplate={selectedTemplate}
                  isMove={true}
                  id={item.id}
                  onAfterMovedToCategory={(category: string[]) => {
                    if (category.length) {
                      const addCategory = category?.filter(
                        (category: string) => category !== item.id,
                      );
                      const index = filteredLikeData.findIndex(
                        (data) => data.id === item.id,
                      );
                      if (addCategory) {
                        const data = [...filteredLikeData];

                        data[index].category = [...addCategory];

                        setFilteredLikeData([...data]);
                      }
                    }
                  }}
                  setToastMessage={setToastMessage}
                  onAfterDeleteCategory={(id) => {
                    if (item.id !== id) {
                      const updatedCategory = item.category?.filter(
                        (category) => category !== id,
                      );
                      const index = filteredLikeData.findIndex(
                        (data) => data.id === item.id,
                      );
                      if (updatedCategory) {
                        const data = [...filteredLikeData];

                        data[index].category = [...updatedCategory];

                        setFilteredLikeData([...data]);
                      }
                    }
                  }}
                  templateCategory={item.category}
                />
              );
            })}
          </InfiniteScroll>
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
