import _ from "lodash";
import OpenColor from "open-color";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../../../../../App/shareComponent/Loader";
import { MIME_TYPES } from "../../../../../../constants";
import { ExcalidrawElement, FileId } from "../../../../../../element/types";
import {
  fetchFilteredCollectionData,
  fetchUnderReviewCollectionsAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../../../excalidraw-app/api/getuserInfo";
import { t } from "../../../../../../i18n";
import { exportToSvg } from "../../../../../../scene/export";
import {
  AppState,
  BinaryFileData,
  BinaryFiles,
  CollocationType,
  LibraryFileType,
  LibraryItems,
  LibraryType,
} from "../../../../../../types";
import { Dialog } from "../../../../../Dialog";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { LibraryTabsEnum } from "../../../../LibraryItemsDialog";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";

interface UnderReviewCollectionProps {
  searchVal: string;
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  editCollection: (val: LibraryItems) => void;
  setSelectedsection: (val: LibraryTabsEnum) => void;
  selectedSortBy: number | null;
}

export const UnderReviewCollection = forwardRef<
  FilterRef,
  UnderReviewCollectionProps
>(
  (
    {
      searchVal,
      setAppState,
      appState,
      editCollection,
      setSelectedsection,
      selectedSortBy,
    },
    ref,
  ) => {
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [sort] = useState(1);
    const [search, setSearch] = useState("");
    const [isFilteredApplied, setIsFilteredAplied] = useState(false);
    const [selectedFilteredValues, setSelectedFilteredValues] = useState<
      Pick<FilteredValueType, "age" | "author" | "tags">
    >();

    const [filteredUnderReviewData, setFilteredUnderReviewData] = useState<
      CollocationType[]
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const fetchUnderReviewCollections = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const apiBody = {
          userEmail: user.mail,
          sort: 1,
          offset: page,
        };
        const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
        setTotalCount(result.totalCount);
        setFilteredUnderReviewData(result?.collections);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchUnderReviewCollections();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          const user = await getUserInfo();
          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const apiBody = {
            userEmail: user?.mail,
            sort: index,
            offset: 0,
          };
          const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
          setPage(0);
          setTotalCount(result.totalCount);
          setFilteredUnderReviewData([...result.collections]);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    const debouncedSearch = useCallback(
      _.debounce(async (searchVal) => {
        setIsLoading(true);
        const user = await getUserInfo();
        const { result } = await fetchUnderReviewCollectionsAPI({
          userEmail: user?.mail,
          search: searchVal,
          sort: sort,
          offset: 0,
        });
        setPage(0);
        setTotalCount(result.totalCount);
        setIsLoading(false);
        setFilteredUnderReviewData([...result.collections]);
      }, 500),
      [],
    );

    useEffect(() => {
      debouncedSearch(searchVal);
      setSearch(searchVal);
    }, [searchVal, debouncedSearch]);

    const withdrawCollectionApprovalRequest = async (id: string) => {
      try {
        // await withdrawCollectionApprovalRequestAPI(id);
        setFilteredUnderReviewData(
          filteredUnderReviewData.filter((item) => item.id !== id),
        );
        setTotalCount(totalCount - 1);
        setToastMessage("Successfully template unpublished.");
      } catch (err) {
        console.error(err);
      }
    };

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          userId: user.mail,
          search,
          offset: 0,
        });
        setTotalCount(result.totalCount);
        setFilteredUnderReviewData([...result.collections]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    const removeFilters = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();

        const apiBody = {
          userEmail: user.mail,
          sort: 1,
          offset: page,
        };
        const { result } = await fetchUnderReviewCollectionsAPI(apiBody);
        setTotalCount(result.totalCount);
        setFilteredUnderReviewData(result.collections);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
      onSetIsFilteredAplied: setIsFilteredAplied,
      onSetSelectedFilteredValues: setSelectedFilteredValues,
    }));

    const onEdit = async (item: CollocationType) => {
      const response = await fetch(item.source);
      const result = await response.json();

      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryId: appState.editingLibrary.libraryInfo.id,
          isEditing: true,
          libraryInfo: item,
          libraryItems: result?.libraryItems[0]?.elements,
          oldElements: [],
        },
      });
      const modifiedLibraryItems = result?.libraryItems?.length
        ? result?.libraryItems.map((libraryItem: LibraryType) => {
            return { ...libraryItem, isSelected: false };
          })
        : [];
      editCollection(modifiedLibraryItems);
      generateSvgForLibrary(modifiedLibraryItems, item);
      setSelectedsection(LibraryTabsEnum.MyLibrary);
    };

    const getFilesByLibraryElements = (
      elements: ExcalidrawElement[],
      templateInfo: CollocationType,
    ) => {
      const files: BinaryFiles = {};
      for (let index = 0; index < elements?.length; index++) {
        const element: ExcalidrawElement = elements[index];
        if (
          element.type === "image" ||
          element.type === "formula" ||
          element.type === "mermaidDiagram" ||
          element.type === "video" ||
          element.type === "audio" ||
          element.type === "textWithStyles"
        ) {
          if (element?.fileId !== null) {
            if (appState.files.hasOwnProperty(element.fileId)) {
              files[element.fileId] = appState.files[element.fileId];
            }
            if (templateInfo && templateInfo.files) {
              const elementFileIndex = templateInfo.files.findIndex(
                (file: { id: FileId }) => file.id === element.fileId,
              );

              if (elementFileIndex !== -1) {
                const elementFile = templateInfo.files[
                  elementFileIndex
                ] as LibraryFileType;
                const fileObj = {
                  mimeType:
                    elementFile.mimeType ||
                    (MIME_TYPES.binary as BinaryFileData["mimeType"]),
                  id: elementFile.id as BinaryFileData["id"],
                  dataURL: elementFile.url as BinaryFileData["dataURL"],
                  created: elementFile.created,
                  isPublished: true,
                };
                files[elementFile.id as string] = {
                  ...fileObj,
                };
                //set files to appState
                setAppState({
                  files: {
                    ...appState.files,
                    [elementFile.id]: {
                      ...fileObj,
                    },
                  },
                });
              }
            }
          }
        }
      }
      return files ? files : null;
    };

    const generateSvgForLibrary = async (
      libraryItems: LibraryType[],
      item: CollocationType,
    ) => {
      let files_: BinaryFiles = {};
      await Promise.all(
        libraryItems.map(async (libraryItem: LibraryType) => {
          const files = getFilesByLibraryElements(libraryItem.elements, item);
          files_ = {
            ...files_,
            ...files,
          };
          const svg = libraryItem?.elements?.length
            ? await exportToSvg(
                libraryItem.elements,
                {
                  exportBackground: false,
                  viewBackgroundColor: OpenColor.white,
                  frameRendering: appState.frameRendering,
                },
                files,
              )
            : null;
          return {
            ...libraryItem,
            svg,
          };
        }),
      );
      setAppState({
        files: {
          ...appState.files,
          ...files_,
        },
      });
    };

    const fetchMoreCollections = async () => {
      const pageCount = Number(page) + 1;
      const user = await getUserInfo();
      if (isFilteredApplied) {
        const { result } = await fetchFilteredCollectionData({
          ...(selectedFilteredValues as any),
          search,
          offset: pageCount,
          userEmail: user.mail,
        });
        setTotalCount(result.totalCount);
        setFilteredUnderReviewData([
          ...filteredUnderReviewData,
          ...result.collections,
        ]);
      } else {
        const { result } = await fetchUnderReviewCollectionsAPI({
          userEmail: user.mail,
          search,
          sort,
          offset: pageCount,
        });
        setTotalCount(result.totalCount);
        setFilteredUnderReviewData([
          ...filteredUnderReviewData,
          ...result.collections,
        ]);
      }
      setPage(pageCount);
    };

    return (
      <div
        className="row overflow-auto underReviewCollection"
        style={{ height: "calc(100% - 28%)" }}
      >
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          <InfiniteScroll
            hasMore={totalCount > filteredUnderReviewData.length}
            initialLoad={false}
            loadMore={fetchMoreCollections}
            useWindow={false}
            getScrollParent={() =>
              document.querySelector(".underReviewCollection")
            }
            className="row"
            loader="Loading..."
          >
            {filteredUnderReviewData.map((item, index) => (
              <CardWithImage
                key={index}
                images={item.preview}
                userProfile={""}
                userName={item.status || item.author?.name}
                created={item.createdAt}
                name={item.name}
                likeBookmarkIcon={false}
                buttonText={t("collection.personal.underReview.withdraw")}
                onAddtoCollectionBtnClick={() => {
                  setOpen(true);
                  setId(item.id);
                }}
                isHoverPreviewBtns={false}
                isHoverUseThisTemplateBtn={false}
                isEdit={true}
                onEdit={() => onEdit(item)}
              />
            ))}
          </InfiniteScroll>
        )}
        {open && (
          <Dialog
            children={
              <div style={{ padding: "6px" }}>
                <p>
                  If you withdraw this template this will delete permanently.
                </p>
                <div className="d-flex justify-content-end gap-2">
                  <button
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      background: "#494B83",
                      color: "white",
                    }}
                    onClick={() => {
                      withdrawCollectionApprovalRequest(id);
                      setOpen(false);
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    style={{
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      color: "white",
                      background: "#FA5252",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
            title={"Are you sure you want to withdraw this template?"}
            onCloseRequest={() => setOpen(false)}
            closeOnClickOutside={false}
            open={open}
            setOpen={setOpen}
          />
        )}
        {toastMessage !== null && (
          <Toast
            type={ToastType.ERROR}
            message={
              toastMessage ? toastMessage : "Added to library successfully"
            }
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
