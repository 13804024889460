import { useCallback, useContext, useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import {
  circleFill,
  closeIcon,
  errorIcon,
  exportIcon,
  pauseFillIcon,
  playFillIcon,
  tickCircle,
  warningIcon,
} from "../components/icons";
import { getUserUsedStorage } from "../excalidraw-app/api/collection";
import { addUserStorage } from "../excalidraw-app/api/userAPI";
import { t } from "../i18n";
import { Subscription } from "../types";
import TitleSelect from "./AudioVideoTitleSelect";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { Dialog } from "./Dialog";
import "./Videos.scss";

const VideoRecorder = ({
  onClose,
  insertVideoOnCanvas,
  subscription,
}: {
  onClose: () => void;
  insertVideoOnCanvas: (
    file: File,
    filename?: string | null,
    color?: string,
  ) => void;
  subscription?: Subscription;
}) => {
  const recordLimit = (subscription && subscription.videoRecordLimit) || 40;
  const [recordRTC, setRecordRTC] = useState<RecordRTC>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const videoNotesRef = useRef<HTMLVideoElement>(null);
  const [file, setFile] = useState<File>();
  const stopBtnRef = useRef<HTMLButtonElement>(null);
  const [second, setSecond] = useState(recordLimit);
  const [minute, setMinute] = useState("00");
  const [counter, setCounter] = useState(0);
  const [stopRecord, setIsStopRecord] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [confirmation, setConfirmation] = useState(false);
  const [videoSizeInGb, setVideoSizeInGb] = useState(0);

  const { fileName, color, onColorChange, onFileNameChange } = useContext(
    NotesTitleContext,
  );

  const [storage, setStorage] = useState({
    usedStorage: 0,
    providedStorage: 0,
  });

  // let usedStorage = {
  //   usedStorage: 0,
  //   providedStorage: 0,
  // };

  useEffect(() => {
    onColorChange("#000000");
    onFileNameChange("");
    const getStorage = async () => {
      const storage = await getUserUsedStorage(
        slug,
        user.mail,
        user.actualRole,
      );
      if (storage?.result) {
        setStorage({
          usedStorage: storage?.result?.usedStorage,
          providedStorage: storage?.result?.providedStorage,
        });
      }
    };
    getStorage();
    // eslint-disable-next-line
  }, []);

  const onDialogClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const startRecording = async () => {
    if (storage?.usedStorage >= storage?.providedStorage) {
      window.parent.postMessage(
        { type: "STORAGE_REQUEST" },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        let mimeType = "video/webm;codecs=vp8,opus";

        // Check if the browser supports WebM with VP8 and Opus
        if (!MediaRecorder.isTypeSupported(mimeType)) {
          // Fallback to a different MIME type (if needed for other browsers)
          mimeType = "video/webm;codecs=vp8"; // Try without audio codec or with default
        }

        const recorder = new RecordRTC(stream, {
          type: "video",
          mimeType: mimeType as
            | "video/webm"
            | "video/webm;codecs=vp8"
            | "video/webm;codecs=vp9"
            | "video/webm;codecs=h264"
            | "video/mp4"
            | "video/x-matroska;codecs=avc1"
            | "video/mpeg"
            | "audio/webm"
            | "audio/webm;codecs=pcm"
            | "audio/wav"
            | "audio/ogg"
            | undefined,
          timeSlice: 1000,
        });
        setRecordRTC(recorder);
        recorder.startRecording();
        setIsRecording(true);
        setIsStopRecord(false);
        setSecond(recordLimit);
        setMinute("00");
        setCounter(0);
        videoNotesRef.current!.srcObject = stream;
        videoNotesRef.current!.play();
        videoNotesRef.current!.muted = true;
        videoNotesRef.current!.controls = false;

        // Set a timer to stop recording after 30 seconds
        setTimeout(() => {
          if (stopBtnRef && stopBtnRef.current) {
            stopBtnRef.current.click();
          }
        }, (Number(recordLimit) + 1) * 1000); // 31 seconds in milliseconds

        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const stopRecording = useCallback(() => {
    videoNotesRef.current!.srcObject = null;
    recordRTC?.stopRecording(() => {
      const blob = recordRTC?.getBlob();
      const url = URL.createObjectURL(blob);

      // Calculate the file size in GB
      const sizeInGB = blob.size / (1024 * 1024 * 1024);
      setVideoSizeInGb(sizeInGB);

      const file = new File([blob], fileName as string, { type: "video/webm" });
      setFile(file);
      setIsRecording(false);
      setSecond(recordLimit);
      setMinute("00");
      setIsStopRecord(true);
      videoNotesRef.current!.src = url;
      videoNotesRef.current!.play();
      videoNotesRef.current!.muted = false;
      videoNotesRef.current!.controls = true;
    });
  }, [
    recordRTC,
    fileName,
    recordLimit,
    setFile,
    setIsRecording,
    setVideoSizeInGb,
    setIsStopRecord,
  ]);

  const slug = new URLSearchParams(window.location.search).get("slug") || "";
  const user: any = JSON.parse(localStorage.getItem("user")!);
  const insertVideo = async () => {
    if (!file) {
      return;
    }
    if (storage?.usedStorage + videoSizeInGb >= storage?.providedStorage) {
      window.parent.postMessage(
        { type: "STORAGE_REQUEST" },
        `${process.env.REACT_APP_PARENT_APP}`,
      );
    } else {
      insertVideoOnCanvas(file, fileName, color);
      await addUserStorage({
        storage: videoSizeInGb,
        role: user.actualRole,
        email: user.mail,
        slug: slug || "",
        extension: "whiteboard",
        feature: "video-notes",
        type: "video",
      });
    }
    onClose();
    setSecond(recordLimit);
    setMinute("00");
  };

  useEffect(() => {
    let intervalId: any;

    if (isRecording) {
      intervalId = setInterval(() => {
        if (counter <= Number(recordLimit)) {
          const remainingSeconds = Number(recordLimit) - counter - 1;
          const remainingSecondCounter = Math.max(remainingSeconds % 60, 0);
          const remainingMinuteCounter = Math.max(
            Math.floor(remainingSeconds / 60),
            0,
          );

          const computedRemainingSecond =
            String(remainingSecondCounter).length === 1
              ? `0${remainingSecondCounter}`
              : remainingSecondCounter;
          const computedRemainingMinute =
            String(remainingMinuteCounter).length === 1
              ? `0${remainingMinuteCounter}`
              : remainingMinuteCounter;

          setSecond(computedRemainingSecond as any);
          setMinute(computedRemainingMinute as string);

          setCounter((counter) => counter + 1);
        } else {
          clearInterval(intervalId);
          stopRecording();
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRecording, counter, recordLimit, stopRecording]);

  return (
    <Dialog
      onCloseRequest={() =>
        fileName || recordRTC ? setConfirmation(true) : onDialogClose()
      }
      title={t("notes.video.title")}
      className="publish-library video-model-width"
      closeOnClickOutside={false}
      open={true}
      setOpen={() => false}
      children={
        <>
          <div
            className="notes_div"
            style={{ border: confirmation ? "1px solid #c92a2a" : "none" }}
          >
            {(fileName || recordRTC) && confirmation ? (
              <div className="d-flex">
                <span className="confirmation">{errorIcon}</span>
                <div className="d-flex w-100">
                  <span className="text-danger" style={{ width: "90%" }}>
                    Are you sure? Your video will not be saved.
                  </span>
                  <div className="d-flex gap-1">
                    <span
                      data-toggle="tooltip"
                      data-bootstrap-placement="bottom"
                      title="Confirm"
                      onClick={onDialogClose}
                    >
                      {tickCircle}
                    </span>
                    <span
                      data-toggle="tooltip"
                      data-bootstrap-placement="bottom"
                      title="Stay here"
                      onClick={() => setConfirmation(false)}
                    >
                      {closeIcon}
                    </span>
                  </div>
                </div>
              </div>
            ) : error ? (
              <>
                <span className="me-2">{errorIcon}</span>
                <span className="text-danger">{error}</span>
              </>
            ) : (
              <>
                <span className="me-2 bg-color"> {warningIcon}</span>You are
                able to capture a video for a minimum duration of {recordLimit}{" "}
                seconds.
              </>
            )}
          </div>
          <div className="form-group d-block">
            <label htmlFor="exampleFormControlInput1" className="mb-1">
              {t("notes.video.name")}
            </label>
            <TitleSelect />
          </div>
          <div
            className={`d-flex flex-wrap gap-3 ${
              stopRecord
                ? "justify-content-around"
                : isRecording
                ? "justify-content-around"
                : "justify-content-start"
            }`}
          >
            {isRecording ? (
              <div
                className="my-3 pe-2"
                style={{ alignItems: "center", display: "flex" }}
              >
                {circleFill}
                {t("notes.video.button.circleFill")}
                <span style={{ marginLeft: "5px" }}>
                  <span className="minute text-black">{minute}</span>
                  <span>:</span>
                  <span className="second text-black">{second.toString()}</span>
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value="Start recording"
                onClick={startRecording}
              >
                {playFillIcon} {t("notes.video.button.start")}
              </button>
            )}
            {isRecording && (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value="Stop recording"
                onClick={stopRecording}
                ref={stopBtnRef}
              >
                {pauseFillIcon} {t("notes.video.button.stop")}
              </button>
            )}
            {stopRecord && (
              <button
                type="button"
                className="btn text-white my-3 bg-brand pe-2"
                value=" Export to canvas"
                onClick={insertVideo}
              >
                {exportIcon} {t("notes.video.button.export")}
              </button>
            )}
          </div>

          <video
            ref={videoNotesRef}
            className="m-auto my-3 d-block video"
          ></video>
        </>
      }
    />
  );
};

export default VideoRecorder;
