import { getNonDeletedElements } from "../element";
import { deepCopyElement } from "../element/newElement";
import { randomId } from "../random";
import { getSelectedElements } from "../scene";
import { register } from "./register";

export const actionAddToLibrary = register({
  name: "addToLibrary",
  perform: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
      includeElementsInFrames: true,
    });

    app.library.loadLibrary().then((items) => {
      app.library.saveLibrary([
        {
          id: randomId(),
          status: "unpublished",
          elements: selectedElements.map(deepCopyElement),
          created: Date.now(),
        },
        ...items,
        // selectedElements.map(deepCopyElement),
      ]);
    });
    return false;
  },
  contextItemLabel: "labels.addToLibrary",
});
