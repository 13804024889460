import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import { AppState, BinaryFiles } from "../types";
import { useApp, useIsMobile } from "./App";
import { Dialog } from "./Dialog";
import "./EditMermaidDiagramDialog.scss";
import { redo, undo, zoomIn, zoomOut } from "./icons";
import {
  convertMermaidToExcalidraw,
  insertToEditor,
  MermaidToExcalidrawLibProps,
} from "./mermaidToExcalidraw/common";
import Spinner from "./Spinner";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
interface EditMermaidDiagramDialogProps {
  editMermaidDiagramDialog: {
    open: boolean;
    syntax: string;
    id: string | null;
    x: number;
    y: number;
    fileId: string | null;
  };
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}

export const EditMermaidDiagramDialog = ({
  editMermaidDiagramDialog,
  appState,
  setAppState,
}: EditMermaidDiagramDialogProps) => {
  const app = useApp();
  const isMobile = useIsMobile();
  const someRandomDivRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [zoomFactor, setZoomFactor] = useState(1);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [syntax, setSyntax] = useState(editMermaidDiagramDialog.syntax);

  const [
    mermaidToExcalidrawLib,
    setMermaidToExcalidrawLib,
  ] = useState<MermaidToExcalidrawLibProps>({
    loaded: false,
    api: import("@excalidraw/mermaid-to-excalidraw"),
  });

  useEffect(() => {
    const fn = async () => {
      await mermaidToExcalidrawLib.api;
      setMermaidToExcalidrawLib((prev) => ({ ...prev, loaded: true }));
    };
    fn();
  }, [mermaidToExcalidrawLib.api]);

  useEffect(() => {
    setSyntax(editMermaidDiagramDialog.syntax);
  }, [editMermaidDiagramDialog.syntax]);

  useEffect(() => {
    if (error) {
      //empty the canvas when error occurs
      someRandomDivRef.current?.style.setProperty("display", "none");
    } else {
      someRandomDivRef.current?.style.setProperty("display", "block");
    }
  }, [error]);

  // useEffect hook to call convertMermaidToExcalidraw when zoomFactor changes
  useEffect(() => {
    // Define an async function to call convertMermaidToExcalidraw
    const updateCanvas = async () => {
      try {
        if (!syntax) {
          setError(new Error("Please enter a valid syntax"));
          return;
        }

        // Call convertMermaidToExcalidraw with necessary parameters
        await convertMermaidToExcalidraw({
          canvasRef: someRandomDivRef,
          mermaidToExcalidrawLib,
          mermaidDefinition: syntax,
          setError,
          data,
          zoomFactor,
          appState,
          setAppState,
        });
        // Focus the textarea after updating the canvas
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      } catch (error) {
        console.error("Error updating canvas:", error);
      }
    };

    // Call updateCanvas function when zoomFactor changes
    updateCanvas();
    // eslint-disable-next-line
  }, [
    syntax,
    editMermaidDiagramDialog.open,
    someRandomDivRef.current,
    mermaidToExcalidrawLib.loaded,
  ]); // useEffect will re-run whenever zoomFactor changes

  const data = useRef<{
    elements: readonly NonDeletedExcalidrawElement[];
    files: BinaryFiles | null;
  }>({ elements: [], files: null });

  const handleCloseDialogue = () => {
    setIsLoading(false);
    setAppState({
      EditMermaidDiagramDialog: {
        ...editMermaidDiagramDialog,
        open: false,
        syntax: "",
        id: null,
        x: 0,
        y: 0,
        fileId: null,
      },
    });
  };

  const renderUndoRedoButton = (
    <div
      className={clsx("undo-redo-buttons zen-mode-transition pb-2")}
      style={{
        position: "absolute",
        zIndex: 2,
        bottom: "2%",
        right: "2%",
      }}
    >
      <button
        className="bg-brand undo-gpt"
        onClick={() => document.execCommand("undo")}
      >
        {undo}
      </button>
      <button
        className="bg-brand undo-gpt"
        onClick={() => document.execCommand("redo")}
      >
        {redo}
      </button>
    </div>
  );

  const handleZoomIn = () => {
    setZoomFactor(zoomFactor + 0.1); // Increase zoom factor
  };

  const handleZoomOut = () => {
    setZoomFactor(Math.max(0.1, zoomFactor - 0.1)); // Decrease zoom factor, but not less than 0.1
  };

  const renderZoomInZoomOutButton = (
    <div className="d-flex ">
      <ToolButton
        type={ToolButtonEnum.BUTTON}
        icon={zoomIn}
        aria-label={t("buttons.zoomIn")}
        size="small"
        onClick={handleZoomIn}
        style={{ width: 30, height: 30, boxShadow: "none" }}
      />
      <ToolButton
        type={ToolButtonEnum.BUTTON}
        icon={zoomOut}
        aria-label={t("buttons.zoomOut")}
        size="small"
        onClick={handleZoomOut}
        style={{ width: 30, height: 30, boxShadow: "none" }}
      />
    </div>
  );

  return (
    <Dialog
      onCloseRequest={() =>
        setAppState({
          EditMermaidDiagramDialog: {
            ...EditMermaidDiagramDialog,
            open: false,
            syntax: "",
            id: null,
            x: 0,
            y: 0,
            fileId: null,
          },
        })
      }
      title={"Update Diagram"}
      className="edit-mermaid-diagram-dialog"
      children={
        <>
          <div className="row d-flex gpt-dialog mx-0">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="mx-2 my-3 my-lg-0">
                <div className="preview_with_view_as_mermaid mb-2">
                  <p
                    className="title d-flex justify-content-start w-100"
                    style={{ margin: "0px" }}
                  >
                    Syntax
                  </p>
                </div>
                <div className="position-relative">
                  {renderUndoRedoButton}
                  <textarea
                    rows={isMobile ? 9 : 18}
                    className={`w-100 myTextarea`}
                    value={syntax}
                    placeholder="Write magic sentence here..."
                    onChange={(e) => {
                      setSyntax(e.target.value);
                    }}
                    ref={textareaRef}
                    autoFocus
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="mx-2">
                <div className="preview_with_view_as_mermaid d-flex justify-content-between">
                  <p
                    className="title d-flex justify-content-start w-100"
                    style={{ margin: "0px" }}
                  >
                    Preview
                  </p>
                  {renderZoomInZoomOutButton}
                </div>
                <div className={`${someRandomDivRef ? "preview-img" : ""} `}>
                  {error && (
                    <div
                      data-testid="ttd-dialog-output-error"
                      className="ttd-dialog-output-error"
                    >
                      Error! <p>{error.message}</p>
                    </div>
                  )}
                  {mermaidToExcalidrawLib.loaded ? (
                    <div
                      ref={someRandomDivRef}
                      style={{
                        opacity: error ? "0.15" : 1,
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                        transform: `scale(${zoomFactor})`, // Apply zoom transformation
                        transformOrigin: "top left", // Adjust the origin of the transformation
                      }}
                      className="ttd-dialog-output-canvas-container"
                    ></div>
                  ) : (
                    <Spinner size="2rem" />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-3">
              <button
                className={`button d-flex ${isLoading && "disabled"}`}
                onClick={async (e) => {
                  // alert("ON PROGRESS");
                  insertToEditor({
                    app,
                    data,
                    handleCloseDialogue,
                    updatedElement: editMermaidDiagramDialog,
                  });
                }}
              >
                Update 👉
                {isLoading && (
                  <span>
                    <Spinner className="mt-1 ms-1" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </>
      }
      closeOnClickOutside={true}
      open={editMermaidDiagramDialog.open}
      setOpen={setAppState}
    />
  );
};
