import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";

export const convertHtmlToCanvas = (
  htmlContent: string,
  filename: string,
  textEditor: {
    open: boolean;
    sceneX: number;
    sceneY: number;
    value: string;
    width: number;
    height: number;
    resizeHeight: number;
    resizeWidth: number;
    resize: boolean;
  },
) => {
  return new Promise((resolve, reject) => {
    // const tipTapContainer = document.getElementById("tiptap-container");
    const container = document.createElement("div");
    container.style.display = "inline-block";
    container.style.backgroundColor = "transparent"; // Set the container's background to transparent
    // container.style.display = "block";
    // container.style.wordWrap = "break-word";
    // container.style.wordBreak = "break-all";
    container.innerHTML = htmlContent;

    const height = `${textEditor?.height}px`;

    const width = textEditor?.resize
      ? `${textEditor?.resizeWidth}px`
      : `${textEditor?.width}px`;

    if (textEditor?.height) {
      container.style.maxHeight = height + 15; // The editor's minimum height is 55px, and we're writing text into a 30px height div, so we need to add an extra 25px (55 - 30 = 25).
    }
    if (textEditor?.width || textEditor?.resizeWidth) {
      container.style.maxWidth = width;
    }

    // Select all p tags inside the container and remove margin-bottom
    const pTags = container.querySelectorAll("p");
    pTags.forEach((pTag) => {
      pTag.style.marginBottom = "0";
      pTag.style.wordWrap = "break-word";
      // pTag.style.transform = "scale(4.57812)";
      // pTag.style.transformOrigin = "left top";
    });

    // Append the container to the document body
    document.body.appendChild(container);

    // Add a CSS rule to set the background to transparent for all elements within the container
    const style = document.createElement("style");
    style.innerHTML = `
      #${container.id} * {
        background: transparent !important;
      }
    `;
    document.head.appendChild(style);
    // Wait for the container to render

    const scale = 4;
    const options = {
      width: container.offsetWidth * scale, // Double the width (adjust as needed)
      height: container.offsetHeight * scale, // Double the height (adjust as needed)
      style: {
        transform: `scale(${scale})`, // Scale the image by 2 (adjust as needed)
        transformOrigin: "top left",
      },
    };

    setTimeout(async () => {
      domtoimage
        .toPng(container, options)
        .then(async (dataUrl: string) => {
          const width = container.offsetWidth;
          const height = container.offsetHeight;

          // Remove the container and style from the document
          document.body.removeChild(container);
          document.head.removeChild(style);
          resolve({ dataUrl, width, height });
        })
        .catch((error: any) => {
          console.error("convertHtmlToCanvas:error", error);
          // Remove the container and style from the document in case of an error
          document.body.removeChild(container);
          document.head.removeChild(style);
          const url: string = "";
          resolve(url);
        });
    }, 0);
  });
};
export const convertHtmlToCanvas_ = (
  htmlContent: string,
  filename: string,
  textEditor: {
    open: boolean;
    sceneX: number;
    sceneY: number;
    value: string;
    width: number;
    height: number;
    resizeHeight: number;
    resizeWidth: number;
    resize: boolean;
  },
) => {
  return new Promise((resolve, reject) => {
    // const tipTapContainer = document.getElementById("tiptap-container");
    const container = document.createElement("div");
    container.style.display = "inline-block";
    container.style.backgroundColor = "transparent"; // Set the container's background to transparent
    container.innerHTML = htmlContent;

    const height = `${textEditor?.height}px`;

    const width = textEditor?.resize
      ? `${textEditor?.resizeWidth}px`
      : `${textEditor?.width}px`;

    if (textEditor?.height) {
      container.style.maxHeight = height + 25; // The editor's minimum height is 55px, and we're writing text into a 30px height div, so we need to add an extra 25px (55 - 30 = 25).
    }
    if (textEditor?.width || textEditor?.resizeWidth) {
      container.style.maxWidth = width;
    }
    // Select all p tags inside the container and remove margin-bottom
    const pTags = container.querySelectorAll("p");
    pTags.forEach((pTag) => {
      pTag.style.marginBottom = "0";
    });

    // Append the container to the document body
    document.body.appendChild(container);

    // Add a CSS rule to set the background to transparent for all elements within the container
    const style = document.createElement("style");
    style.innerHTML = `
      #${container.id} * {
        background: transparent !important;
      }
    `;
    document.head.appendChild(style);
    // Wait for the container to render

    setTimeout(async () => {
      html2canvas(container, { backgroundColor: "transparent" })
        .then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");
          const width = container.offsetWidth;
          const height = container.offsetHeight;

          // Remove the container and style from the document
          document.body.removeChild(container);
          document.head.removeChild(style);
          resolve({ dataUrl, width, height });
        })
        .catch((error: any) => {
          console.error("convertHtmlToCanvas:error", error);
          // Remove the container and style from the document in case of an error
          document.body.removeChild(container);
          document.head.removeChild(style);
          const url: string = "";
          resolve(url);
        });
    }, 0);
  });
};
