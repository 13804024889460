import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIsMobile } from "../../../App";
import { SpinnerContext } from "../../contexts";
import { WheelAndQuestionType } from "../../types";
import { Play } from "./components/play";
import { QuestionGeneration } from "./components/questionsGeneration";
import "./styles.scss";

export type SpinnerLetsPlayViewType = {
  maximize: boolean;
  setMaximize: Dispatch<SetStateAction<boolean>>;
  openResultDialog: boolean;
  setOpenResultDialog: Dispatch<SetStateAction<boolean>>;
  results: Dispatch<SetStateAction<string[]>>;
  resultsData: string[];
  setSeconds: Dispatch<SetStateAction<number>>;
  isRunning: boolean;
  startTimer: () => void;
  restartTimer: () => void;
  pauseTimer: () => void;
  handleMinutes: (value: number) => void;
  setSecondsSpinner: (id: number, seconds: number) => void;
  insertImageOnCanvas: (file: File) => void;
  onClose: () => void;
  languages: string[];
  language: string;
  handleSelect: (value: string) => void;
  theme: string;
};

export const SpinnerLetsPlayView = (props: SpinnerLetsPlayViewType) => {
  const { segments, selectedWheelNumber } = useContext(SpinnerContext);

  const {
    maximize,
    openResultDialog,
    results,
    resultsData,
    setMaximize,
    setOpenResultDialog,
    setSeconds,
    isRunning,
    startTimer,
    restartTimer,
    pauseTimer,
    handleMinutes,
    setSecondsSpinner,
    insertImageOnCanvas,
    onClose,
  } = props;
  const isMobile = useIsMobile();

  const [wheelAndQuestions, setWheelAndQuestions] = useState<
    WheelAndQuestionType[]
  >([]);
  const [selectedWheelIndex, setSelectedWheelIndex] = useState<number>(-1);
  useEffect(() => {
    const selectedWheelIndex = wheelAndQuestions.findIndex(
      (data) => Number(data.wheel) === Number(selectedWheelNumber),
    );
    if (selectedWheelIndex === -1) {
      const newQuestion = [
        ...wheelAndQuestions,
        {
          wheel: selectedWheelNumber,
          questions: [],
        },
      ];
      setWheelAndQuestions([...newQuestion]);
      const _selectedWheelIndex = newQuestion.findIndex(
        (data) => Number(data.wheel) === Number(selectedWheelNumber),
      );
      setSelectedWheelIndex(_selectedWheelIndex);
      return;
    }
    setSelectedWheelIndex(selectedWheelIndex);
  }, [selectedWheelNumber, wheelAndQuestions]);

  useEffect(() => {
    const segmentData = segments.findIndex(
      (data) => Number(data.wheel) === Number(selectedWheelNumber),
    );
    if (segmentData !== -1 && segments[segmentData]?.segment.length > 0) {
      const _selectedWheelIndex = wheelAndQuestions.findIndex(
        (data) => Number(data.wheel) === Number(selectedWheelNumber),
      );
      if (_selectedWheelIndex === -1) {
        const newQuestion = [
          ...wheelAndQuestions,
          {
            wheel: Number(selectedWheelNumber),
            questions: segments[segmentData]?.segment,
          },
        ];
        setWheelAndQuestions([...newQuestion]);
        const _selectedWheelIndex = newQuestion.findIndex(
          (data) => Number(data.wheel) === Number(selectedWheelNumber),
        );
        setSelectedWheelIndex(_selectedWheelIndex);
        return;
      }
      setSelectedWheelIndex(_selectedWheelIndex);
      wheelAndQuestions[_selectedWheelIndex].questions =
        segments[segmentData]?.segment;
      setWheelAndQuestions([...wheelAndQuestions]);
    } else {
      setWheelAndQuestions([]);
    }
    // eslint-disable-next-line
  }, [segments, selectedWheelNumber]);

  const segmentData = useMemo(() => {
    return segments.find(
      (segment) => Number(segment.wheel) === Number(selectedWheelNumber),
    );
  }, [segments, selectedWheelNumber]);

  // useEffect(() => {
  //   const selectedWheelIndex = segments.findIndex(
  //     (data) => Number(data.wheel) === Number(selectedWheelNumber),
  //   );
  //   if (selectedWheelIndex !== -1) return;
  //   setSegments([
  //     ...segments,
  //     {
  //       wheel: Number(selectedWheelNumber),
  //       segment: [],
  //       topic: "",
  //       topicGenerationLimit: 10,
  //       results: [],
  //       timer: false,
  //       timerDuration: {
  //         minutes: 1,
  //         seconds: 0,
  //       },
  //     },
  //   ]);
  // }, [selectedWheelNumber]);

  if (!segmentData) {
    return null;
  }
  const spinnerDialog = document.getElementsByClassName("spinner-dialog");
  const divElements = spinnerDialog
    ? spinnerDialog[0]?.getElementsByClassName("Modal__content")
      ? spinnerDialog[0]?.getElementsByClassName("Modal__content")
      : []
    : [];
  let height = 700;
  // Iterate through each element in the collection
  for (let i = 0; i < divElements.length; i++) {
    // Get the current element
    const divElement = divElements[i];

    // Get the height of the current element
    height = divElement.clientHeight;
  }

  return (
    <div
      className="row p-2"
      style={{
        height: window.innerWidth >= 992 ? height - 100 : "auto",
        transition: "height .3s ease",
        width: isMobile ? "-webkit-fill-available" : "100%",
      }}
    >
      <Play
        segmentData={segmentData}
        questionGenerationResults={{
          openResultDialog,
          setOpenResultDialog,
          results,
          resultsData,
        }}
        spinnerView={{
          maximize,
          setMaximize,
          setSeconds,
          isRunning,
          startTimer,
          pauseTimer,
          restartTimer,
          handleMinutes,
          setSecondsSpinner,
          insertImageOnCanvas,
          onClose,
        }}
        languages={props.languages}
        language={props.language}
        handleSelect={props.handleSelect}
        theme={props.theme}
      />
      <QuestionGeneration
        segmentData={segmentData}
        componentsProps={{
          questionEntriesAndResults: {
            componentsProps: {
              questionGenerationEntries: {
                selectedWheelIndex,
                setSelectedWheelIndex,
                setWheelAndQuestions,
                wheelAndQuestions,
                openResultDialog,
                setSeconds,
                isRunning,
                startTimer,
                pauseTimer,
                restartTimer,
                handleMinutes,
                setSecondsSpinner,
              },
            },
          },
        }}
        resultsData={resultsData}
        onClose={onClose}
        languages={props.languages}
        language={props.language}
        handleSelect={props.handleSelect}
        theme={props.theme}
      />
    </div>
  );
};
