import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as microsoftTeams from "@microsoft/teams-js";
import React from "react";
import ReactDOM from "react-dom";
import { msalConfig } from "./App/msal-auth/msalAuth";
import App from "./App/routes/App";
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

microsoftTeams.initialize();

const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
window.addEventListener(
  "message",
  function (e) {
    if (e.data === "clearData") {
      // Perform iframe app data clearing logic...
      this.localStorage.clear();
    }

    if (
      JSON.parse(process.env.REACT_APP_ACV_PARENT_APP || "[]")?.includes(
        e?.origin,
      ) &&
      e?.data?.source === "whiteboard"
    ) {
      const data = e?.data?.message;

      if (data?.user) {
        this.localStorage.setItem("user", JSON.stringify(data?.user));
        data?.isOpenWorkSpace
          ? this.localStorage.setItem("lesson", JSON.stringify(""))
          : this.localStorage.setItem("lesson", JSON.stringify(data?.lesson));
        this.localStorage.setItem(
          "access_token",
          data?.user.accessToken || data.access_token,
        );
        this.localStorage.setItem("i18nextLng", data?.i18nextLng);
        this.localStorage.setItem("fullscreen", data?.fullscreen);
        this.localStorage.setItem(
          "isMyWorkSpace",
          data?.isOpenWorkSpace === undefined ? "false" : data?.isOpenWorkSpace,
        );
        this.localStorage.setItem(
          "isReloadingWorkspace",
          JSON.stringify(data?.isReloadingWorkspace),
        );
      }
    } else {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const access_token = localStorage.getItem("access_token");
      this.localStorage.setItem(
        "access_token",
        user.accessToken || access_token,
      );
    }
  },
  false,
);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
