import React, { useEffect, useRef, useState } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { ActionsManagerInterface } from "../actions/types";
import { DEFAULT_EXPORT_PADDING, EXPORT_IMAGE_TYPES } from "../constants";
import { exportCanvasAsZip } from "../data";
import { canvasToBlob } from "../data/blob";
import { nativeFileSystemSupported } from "../data/filesystem";
import { NonDeletedExcalidrawElement } from "../element/types";
import { CanvasError } from "../errors";
import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { getElemetDataFromDatabase } from "../excalidraw-app/api/storeElementData";
import { t } from "../i18n";
import { getSelectedElements, isSomeElementSelected } from "../scene";
import { exportToCanvas } from "../scene/export";
import { AppState, BinaryFiles } from "../types";
import { useIsMobile } from "./App";
import Carousel from "./Carousel";
import { CheckboxItem } from "./CheckboxItem";
import { Dialog } from "./Dialog";
import "./ExportDialog.scss";
import { PngFileIcon, SvgFileIcon, imageIcon } from "./icons";
import SelectMenu from "./SelectMenu";
import Stack from "./Stack";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

const supportsContextFilters =
  "filter" in document.createElement("canvas").getContext("2d")!;

export const ErrorCanvasPreview = () => {
  return (
    <div>
      <h3>{t("canvasError.cannotShowPreview")}</h3>
      <p>
        <span>{t("canvasError.canvasTooBig")}</span>
      </p>
      <em>({t("canvasError.canvasTooBigTip")})</em>
    </div>
  );
};

const renderPreview = (
  content: HTMLCanvasElement | Error,
  previewNode: HTMLDivElement,
) => {
  unmountComponentAtNode(previewNode);
  previewNode.innerHTML = "";
  if (content instanceof HTMLCanvasElement) {
    previewNode.appendChild(content);
  } else {
    render(<ErrorCanvasPreview />, previewNode);
  }
};

export type ExportCB = (
  type: keyof typeof EXPORT_IMAGE_TYPES,
  elements: readonly NonDeletedExcalidrawElement[],
  scale?: number,
) => void;

const ImageExportModal = ({
  elements,
  appState,
  files,
  exportPadding = DEFAULT_EXPORT_PADDING,
  actionManager,
  onExportToPng,
  onExportToSvg,
  onExportToClipboard,
  allPages,
  selectedPages,
  setSelectedPages,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  files: BinaryFiles;
  exportPadding?: number;
  actionManager: ActionsManagerInterface;
  onExportToPng: ExportCB;
  onExportToSvg: ExportCB;
  onExportToClipboard: ExportCB;
  onCloseRequest: () => void;
  allPages: any[];
  selectedPages: string[];
  setSelectedPages: (val: string[]) => void;
}) => {
  const someElementIsSelected = isSomeElementSelected(elements, appState);
  const [exportSelected, setExportSelected] = useState(someElementIsSelected);
  const previewRef = useRef<HTMLDivElement>(null);
  const { exportBackground, viewBackgroundColor } = appState;
  const [selectedPagesTitle, setSelectedPagesTitle] = useState<string>("");
  const [
    isCollaboratingWithFlexibleGroups,
    setIsCollaboratingWithFlexibleGroups,
  ] = useState(false);

  const exportedElements = exportSelected
    ? getSelectedElements(elements, appState)
    : elements;

  useEffect(() => {
    setExportSelected(someElementIsSelected);
  }, [someElementIsSelected]);

  useEffect(() => {
    handleSelectedPagesTitle();
    // eslint-disable-next-line
  }, [selectedPages]);

  useEffect(() => {
    setIsCollaboratingWithFlexibleGroups(
      JSON.parse(
        localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
      ),
    );
    // eslint-disable-next-line
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);

  useEffect(() => {
    const previewNode = previewRef.current;
    if (!previewNode) {
      return;
    }

    const findedPage = allPages.find(
      (page: any) => page.page === selectedPages[0],
    );

    const exportedElements_ =
      selectedPages.length > 0
        ? Number(appState.currentPage) === Number(selectedPages[0])
          ? exportedElements
          : findedPage.data
        : exportedElements;
    exportToCanvas(exportedElements_, appState, files, {
      exportBackground,
      viewBackgroundColor,
      exportPadding,
    })
      .then((canvas) => {
        // if converting to blob fails, there's some problem that will
        // likely prevent preview and export (e.g. canvas too big)

        return canvasToBlob(canvas).then(() => {
          renderPreview(canvas, previewNode);
        });
      })
      .catch((error) => {
        console.error(error);
        renderPreview(new CanvasError(), previewNode);
      });
    // eslint-disable-next-line
  }, [
    appState,
    files,
    exportedElements,
    exportBackground,
    exportPadding,
    selectedPages,
    viewBackgroundColor,
    isCollaboratingWithFlexibleGroups,
  ]);

  const toggleSelectedPages = (checked: boolean, id: string) => {
    let clonedSelectedPages = [...selectedPages];
    if (checked) {
      clonedSelectedPages.push(id);
    } else {
      clonedSelectedPages = clonedSelectedPages.filter((page) => page !== id);
    }
    setSelectedPages(clonedSelectedPages);
  };

  const handleSelectedPagesTitle = () => {
    if (selectedPages.length > 0) {
      if (selectedPages.length > 1) {
        setSelectedPagesTitle(`Pages ${selectedPages.join(", ")}`);
      } else {
        setSelectedPagesTitle(`Page ${selectedPages[0]}`);
      }
    } else {
      setSelectedPagesTitle("");
    }
  };

  return (
    <div className="ExportDialog">
      <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: ".6em",
          }}
        >
          <p
            style={{ marginRight: "1em", userSelect: "none" }}
            className="mb-0"
          >
            Scale
          </p>
          <Stack.Row gap={2}>
            {actionManager.renderAction("changeExportScale")}
          </Stack.Row>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: ".6em 0",
          }}
        >
          {!nativeFileSystemSupported &&
            actionManager.renderAction("changeProjectName")}
        </div>
      </div>

      {supportsContextFilters &&
        actionManager.renderAction("exportWithDarkMode")}
      {!someElementIsSelected &&
        !isCollaboratingWithFlexibleGroups &&
        selectedPages.length > 1 && (
          <Carousel
            allPages={allPages}
            selectedPages={selectedPages}
            appState={appState}
            files={files}
            exportedElements={exportedElements}
            exportBackground={exportBackground}
            exportPadding={exportPadding}
            viewBackgroundColor={viewBackgroundColor}
            actionManager={actionManager}
          />
        )}
      {(selectedPages.length <= 1 ||
        someElementIsSelected ||
        isCollaboratingWithFlexibleGroups) && (
        <div className="ExportDialog__preview" ref={previewRef} />
      )}

      <div className="d-flex justify-content-around align-items-center">
        <div className="d-flex">
          {actionManager.renderAction("changeExportBackground")}
          {someElementIsSelected && (
            <CheckboxItem
              checked={exportSelected}
              onChange={(checked) => setExportSelected(checked)}
            >
              {t("labels.onlySelected")}
            </CheckboxItem>
          )}
          {actionManager.renderAction("changeExportEmbedScene")}
        </div>
        {!someElementIsSelected &&
          !isCollaboratingWithFlexibleGroups &&
          allPages &&
          allPages.length > 0 && (
            <div className="multi-page-download">
              <SelectMenu title={selectedPagesTitle || "Select Page"}>
                {allPages.map(
                  (
                    page: { id: string; page: string; pageName: string },
                    i: number,
                  ) => {
                    return (
                      <label
                        className="select-item"
                        htmlFor={page.id}
                        key={page.id}
                      >
                        <input
                          className="select-checkbox text-capitalize"
                          type="checkbox"
                          id={page.id}
                          checked={selectedPages.includes(page.page)}
                          onChange={(e) => {
                            toggleSelectedPages(e.target.checked, page.page);
                          }}
                        />
                        {page.pageName || `Page ${page.page}`}
                      </label>
                    );
                  },
                )}
              </SelectMenu>
            </div>
          )}
      </div>

      <div className="d-flex justify-content-end flex-wrap">
        <Stack.Row
          className="align-items-center mt-4"
          gap={2}
          justifyContent="center"
          // style={{ margin: "1em 0" }}
        >
          <div
            className="d-flex border p-2 rounded "
            style={{ borderColor: "#cdcdcd", cursor: "pointer" }}
            onClick={async () => {
              if (
                selectedPages.length > 1 &&
                !someElementIsSelected &&
                !isCollaboratingWithFlexibleGroups
              ) {
                exportCanvasAsZip(
                  EXPORT_IMAGE_TYPES.png,
                  allPages,
                  appState,
                  appState.files,
                  {
                    exportBackground: appState.exportBackground,
                    name: appState.name,
                    viewBackgroundColor: appState.viewBackgroundColor,
                  },
                );
              } else {
                onExportToPng(EXPORT_IMAGE_TYPES.png, exportedElements);
              }
            }}
          >
            <div style={{ width: "25px", height: "25px" }}>
              <PngFileIcon theme={appState.theme} />
            </div>
            <span className="text-nowrap ms-2"> {t("canvasError.pngBtn")}</span>
          </div>
          <div
            className="d-flex border p-2 rounded "
            style={{ borderColor: "#cdcdcd", cursor: "pointer" }}
            onClick={() => {
              if (
                selectedPages.length > 1 &&
                !someElementIsSelected &&
                !isCollaboratingWithFlexibleGroups
              ) {
                exportCanvasAsZip(
                  EXPORT_IMAGE_TYPES.svg,
                  allPages,
                  appState,
                  appState.files,
                  {
                    exportBackground: appState.exportBackground,
                    name: appState.name,
                    viewBackgroundColor: appState.viewBackgroundColor,
                  },
                );
              } else {
                onExportToSvg(EXPORT_IMAGE_TYPES.svg, exportedElements);
              }
            }}
          >
            <div style={{ width: "25px", height: "25px" }}>
              <SvgFileIcon theme={appState.theme} />
            </div>
            <span className="text-nowrap ms-2">{t("canvasError.svgBtn")} </span>
          </div>
        </Stack.Row>
      </div>
    </div>
  );
};

export const ImageExportDialog = ({
  elements,
  appState,
  files,
  exportPadding = DEFAULT_EXPORT_PADDING,
  actionManager,
  onExportToPng,
  onExportToSvg,
  onExportToClipboard,
  selectedPages,
  setSelectedPages,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  files: BinaryFiles;
  exportPadding?: number;
  actionManager: ActionsManagerInterface;
  onExportToPng: ExportCB;
  onExportToSvg: ExportCB;
  onExportToClipboard: ExportCB;
  selectedPages: string[];
  setSelectedPages: (val: string[]) => void;
}) => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [allPages, setAllPages] = useState<any[]>([]);

  const getAllPages = async () => {
    try {
      const lessonId = await getLessonId();
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      if (isMyWorkSpace === "true") {
        return;
      }

      const allPages = await getElemetDataFromDatabase(lessonId, false);
      if (allPages.result.length > 0) {
        const pagesIds = [];
        for (let index = 0; index < allPages.result.length; index++) {
          const page = allPages.result[index];
          pagesIds.push(page.page);
        }
        setSelectedPages(pagesIds);
        setAllPages(allPages.result);
        localStorage.setItem("ACTIVE_PAGE", allPages.activePage);
      } else {
        setAllPages([]);
      }
    } catch (error) {
      console.error("error:getAllPages", error);
    }
  };

  useEffect(() => {
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") ?? "false";
    if (isMyWorkSpace === "false") {
      modalIsShown && getAllPages();
    }
    // eslint-disable-next-line
  }, []); // Effect runs only once because of the empty dependency array.

  const handleClose = React.useCallback(() => {
    setModalIsShown(false);
  }, []);

  return (
    <>
      <ToolButton
        onClick={() => {
          setModalIsShown(true);
        }}
        className="image-export-button"
        data-testid="image-export-button"
        icon={imageIcon}
        type={ToolButtonEnum.BUTTON}
        aria-label={t("buttons.exportImage")}
        showAriaLabel={useIsMobile()}
        title={t("buttons.exportImage")}
      />
      {/* TODO: remove this once we find proper solution */}
      {/* added this because ToolButton not working as expected when whiteboard collaboration is end */}
      <button
        id="exportAsImage"
        className="d-none"
        onClick={() => {
          setModalIsShown(true);
        }}
      >
        Export As Image
      </button>
      {modalIsShown && (
        <Dialog onCloseRequest={handleClose} title={t("buttons.exportImage")}>
          <ImageExportModal
            elements={elements}
            appState={appState}
            files={files}
            exportPadding={exportPadding}
            actionManager={actionManager}
            onExportToPng={onExportToPng}
            onExportToSvg={onExportToSvg}
            onExportToClipboard={onExportToClipboard}
            onCloseRequest={handleClose}
            allPages={allPages}
            selectedPages={selectedPages}
            setSelectedPages={setSelectedPages}
          />
        </Dialog>
      )}
    </>
  );
};
