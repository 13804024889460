import * as PDFJS from "pdfjs-dist";
import { useCallback, useEffect, useState } from "react";
import { AppClassProperties, AppState } from "../types";
import ConfirmDialog from "./ConfirmDialog";
import "./PdfSelectionDialog.scss";
import { CircularProgress } from "./common/CircularProgress";

export const PdfPageSelectionDialog = (props: {
  setAppState: React.Component<any, AppState>["setState"];
  open: boolean;
  appState: AppState;
  pdfFile: File | null;
  onConfirmPageSelection: (
    selectedPages: Array<{ index: number; page: string }>,
  ) => void;
  app: AppClassProperties;
}) => {
  const [pdfPages, setPdfPages] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPages, setSelectedPages] = useState<
    Array<{ index: number; page: string }> | []
  >([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false,
  );
  const [progress, setProgress] = useState(0);

  const handlePageSelect = (index: number, page: string) => {
    setSelectedPages((prevSelectedPages) => {
      if (
        prevSelectedPages.some((selectedPage) => selectedPage.index === index)
      ) {
        return prevSelectedPages.filter(
          (selectedPage) => selectedPage.index !== index,
        );
      }
      return [...prevSelectedPages, { index, page }];
    });
  };

  const handleSelectAll = () => {
    if (selectedPages.length === pdfPages.length) {
      setSelectedPages([]);
    } else {
      setSelectedPages(
        Array.from({ length: pdfPages.length }, (_, i) => ({
          index: i,
          page: pdfPages[i],
        })),
      );
    }
  };

  useEffect(() => {
    if (props.pdfFile) {
      setFileName(props.pdfFile.name);

      if (props.pdfFile) {
        const pdfUrl = URL.createObjectURL(props.pdfFile);
        getPdfPages(pdfUrl);
      }
    }
    // eslint-disable-next-line
  }, [props.pdfFile]);

  const getPdfPages = useCallback(async (pdfUrl: string) => {
    try {
      const pdfPages: string[] = [];
      const pdf_doc = await PDFJS.getDocument({ url: pdfUrl }).promise;
      setProgress(0); // Reset progress

      if (pdf_doc.numPages <= 0) {
        setLoading(false);
        return pdfPages;
      }

      const totalPageCount = pdf_doc.numPages;
      let pagesRendered = 0;

      for (let i = 1; i <= totalPageCount; i++) {
        const page = await pdf_doc.getPage(i);
        const viewport = page.getViewport({ scale: 4 });
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: ctx || {}, viewport }).promise;

        const dataURL = canvas.toDataURL();
        pdfPages[i - 1] = dataURL;

        // Update progress
        pagesRendered += 1;
        setProgress(Math.round((pagesRendered / totalPageCount) * 100));
      }

      setPdfPages(pdfPages);
      setLoading(false);
    } catch (error) {
      console.error(`Failed to load PDF: ${error}`);
    }
  }, []);

  return (
    <>
      {openConfirmationDialog ? (
        <ConfirmDialog
          title={"Are you sure?"}
          className={"PdfSelectionDialog"}
          small={true}
          children={
            <>
              <p>If you close this, your selected page will be lost.</p>
            </>
          }
          closeOnClickOutside={false}
          open={openConfirmationDialog}
          setOpen={() => setOpenConfirmationDialog(false)}
          onConfirm={() => {
            setOpenConfirmationDialog(false);
            props.setAppState({
              pdfPageSelectionDialog: false,
            });
            props.app.setActiveTool({ type: "selection" });
          }}
          onCancel={() => {
            setOpenConfirmationDialog(false);
          }}
        />
      ) : (
        <ConfirmDialog
          title={"Select Page"}
          className={"PdfSelectionDialog"}
          autofocus={false}
          small={false}
          children={
            <>
              <div className="d-flex justify-content-between border-bottom">
                <p className="mb-1">{fileName}</p>
                <div className="select-all-checkbox">
                  <input
                    type="checkbox"
                    checked={
                      selectedPages.length > 0 &&
                      selectedPages.length === pdfPages.length
                    }
                    onChange={handleSelectAll}
                    id="select-all"
                  />
                  <label htmlFor="select-all d-flex align-center">
                    Select All
                  </label>
                </div>
              </div>
              <div
                className="d-flex justify-content-center py-3 flex-wrap gap-4 container"
                style={{ height: "100%", overflow: "auto" }}
              >
                {pdfPages.length > 0 ? (
                  pdfPages.map((page, index) => (
                    <div
                      className="d-flex flex-column gap-2 align-items-center"
                      key={index}
                    >
                      <label htmlFor={`page-${index}`}>
                        <div style={{ position: "relative" }}>
                          <img
                            className={`image-container ${
                              selectedPages.some(
                                (selectedPage) => selectedPage.index === index,
                              )
                                ? "selected"
                                : ""
                            }`}
                            src={page}
                            alt={`Page ${index + 1}`}
                            height="250px"
                            width="200px"
                          />
                          <div className="overlay"></div>
                          <input
                            type="checkbox"
                            checked={selectedPages.some(
                              (selectedPage) => selectedPage.index === index,
                            )}
                            onChange={() => handlePageSelect(index, page)}
                            id={`page-${index}`}
                            className="custom-checkbox"
                          />
                          <label
                            htmlFor={`page-${index}`}
                            className="custom-checkbox-label"
                          ></label>
                        </div>
                      </label>
                      <p className="text-center">Page {index + 1}</p>
                    </div>
                  ))
                ) : loading ? (
                  <CircularProgress progress={progress} />
                ) : (
                  <p>No pages found</p>
                )}
              </div>
            </>
          }
          closeOnClickOutside={false}
          open={props.open}
          setOpen={() => false}
          disabled={selectedPages.length === 0 || loading}
          onConfirm={() => props.onConfirmPageSelection(selectedPages)}
          onCancel={() => {
            if (selectedPages.length > 0) {
              setOpenConfirmationDialog(true);
            } else {
              props.setAppState({
                pdfPageSelectionDialog: false,
              });
              props.app.setActiveTool({ type: "selection" });
            }
          }}
        />
      )}
    </>
  );
};
export default PdfPageSelectionDialog;
