import React, { useEffect, useRef, useState } from "react";

interface CelebrationProps {
  trigger: boolean;
  setCelebration: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete?: () => void;
}

export const Celebration: React.FC<CelebrationProps> = ({
  trigger,
  setCelebration,
  onComplete,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (!trigger) {
      return;
    }

    setIsAnimating(trigger); // Start animation when trigger is true
  }, [trigger]);

  useEffect(() => {
    if (!isAnimating) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const confettiPieces: Confetti[] = [];

    class Confetti {
      x: number;
      y: number;
      color: string;
      size: number;
      speedX: number;
      speedY: number;
      rotation: number;
      rotationSpeed: number;
      active: boolean;

      constructor(x: number, y: number, color: string) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.size = Math.random() * 8 + 4;
        this.speedX = Math.random() * 2 - 1; // Faster horizontal speed
        this.speedY = Math.random() * 3 + 3; // Faster vertical speed
        this.rotation = Math.random() * 360;
        this.rotationSpeed = Math.random() * 6 - 3; // Slightly faster rotation
        this.active = true; // Confetti is active when created
      }

      update() {
        this.y += this.speedY;
        this.x += this.speedX;
        this.rotation += this.rotationSpeed;
        if (!canvas) {
          return;
        }
        // Mark as inactive if it falls out of the canvas
        if (
          this.y > canvas.height ||
          this.x > canvas.width ||
          this.x < -this.size
        ) {
          this.active = false;
        }
      }

      draw() {
        if (!ctx || !this.active) {
          return;
        }
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate((this.rotation * Math.PI) / 180);
        ctx.fillStyle = this.color;
        ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size);
        ctx.restore();
      }
    }

    const createConfetti = () => {
      const confettiCount = 300; // Increase the number of confetti pieces
      for (let i = 0; i < confettiCount; i++) {
        const x = Math.random() * canvas.width; // Spread across the width
        const y = Math.random() * canvas.height * 0.9; // Spread across the entire height
        const color = `hsl(${Math.random() * 360}, 100%, 50%)`; // Random vibrant colors
        confettiPieces.push(new Confetti(x, y, color));
      }
    };

    let animationFrameId: number;

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      confettiPieces.forEach((piece) => {
        piece.update();
        piece.draw();
      });

      // Filter out inactive confetti
      const activeConfetti = confettiPieces.filter((piece) => piece.active);

      // Stop the animation if no confetti is left
      if (activeConfetti.length > 0) {
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setCelebration(false); // Reset trigger
        setIsAnimating(false); // End animation
        if (onComplete) {
          onComplete();
        }
      }
    };

    // Set canvas size dynamically
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    createConfetti();
    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line
  }, [isAnimating, onComplete]);

  if (!isAnimating) {
    return null;
  }

  return (
    <canvas
      ref={canvasRef}
      style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
    />
  );
};
