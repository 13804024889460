import moment from "moment";
import { useState } from "react";
import {
  getActiveOrRestoreWorkspace,
  getFilteredData,
  getSharedWithMeWorkspace,
  getWorkspaceUsers,
} from "../../excalidraw-app/api/storeElementData";
import { t } from "../../i18n";
import { downArrow_ } from "../icons";
import { filteredValueType } from "../LibraryDialog";
import { ToolButton, ToolButtonEnum } from "../ToolButton";
import { CardData } from "./MyWorkspace";
import { MyWorkspaceSidebarItemsEnum } from "./views/sidebar";

interface Props {
  cardData: CardData[];
  filteredTags: string[];
  authors: string[];
  selectedWorkspaceTab?: MyWorkspaceSidebarItemsEnum;
  setCardData: (data: CardData[]) => void;
  isDataLoaded: boolean;
  setLoading: (val: boolean) => void;
}

export const FilterdWorkspace = (props: Props) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [searchText, setSearchText] = useState("");
  const [filterDataWithKeys, setFilterDataWithKeys] = useState<
    Pick<filteredValueType, "age" | "author" | "tags">
  >({
    age: "",
    author: [],
    tags: [],
  });

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const getSharedWithMeData = async () => {
    try {
      const data = await getSharedWithMeWorkspace(user.mail);
      const res = await getWorkspaceUsers(); // Fetch users data

      const transformedData = data?.data?.map((workspace: any) => {
        // Find matching user workspace data
        const matchingUserWorkspace = res?.find(
          (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
        );

        const isSharable = !!matchingUserWorkspace; // Check if there's a match
        const roomDetails = isSharable
          ? matchingUserWorkspace.roomDetails
          : null;
        return {
          id: workspace.id,
          title: workspace.name,
          thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
          profile: getProfile().toUpperCase(), // Static or dynamic based on user
          date: getTimeAgo(workspace.createdAt),
          elements: workspace.elements, // Attach workspace elements
          status: workspace?.status,
          sharedUserProfile: getProfile(workspace.userEmail).toUpperCase(),
          sharedUserProfileEmail: workspace.userEmail,
          sharable: isSharable, // Sharable information
          roomDetails, // Room details if available
          tags: workspace.tags,
        };
      });

      const filteredData = transformedData.filter(
        (item: any) => item.sharedUserProfileEmail !== user.mail,
      );

      props.setCardData(filteredData);
      return filteredData;
    } catch (error) {
      console.error(error);
    }
  };

  const getWorkspaceData = async () => {
    try {
      const workspaceData = await getActiveOrRestoreWorkspace(user?.mail);
      const res = await getWorkspaceUsers(); // Fetch users data
      const transformedData = workspaceData?.result?.map((workspace: any) => {
        // Find matching user workspace data
        const matchingUserWorkspace = res?.find(
          (userWorkspace: any) => userWorkspace.workspaceId === workspace.id,
        );

        const isSharable = !!matchingUserWorkspace; // Check if there's a match
        const expirationDate = isSharable
          ? matchingUserWorkspace.expirationDate
          : null; // Set expiration date if sharable
        const roomDetails = isSharable
          ? matchingUserWorkspace.roomDetails
          : null;

        return {
          id: workspace.id,
          title: workspace.name,
          thumbnail: workspace.previewImage,
          profile: getProfile().toUpperCase(),
          date: getTimeAgo(workspace.createdAt),
          elements: workspace.elements,
          status: workspace?.status,
          sharable: isSharable,
          expirationDate, // Add expiration date
          roomDetails,
          tags: workspace.tags,
        };
      });
      props.setCardData(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex(index === openDropdownIndex ? -1 : index);
  };

  const getTimeAgo = (timestamp: string) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error(`Invalid date format: ${timestamp}`);
      return "Invalid date";
    }

    return moment(date).fromNow();
  };

  const getProfile = (email?: string) => {
    const nameParts = email
      ? email.trim().split(" ")
      : user.displayName
      ? user.displayName.trim().split(" ")
      : user.mail.trim().split(" ");
    const abbreviation =
      nameParts.length >= 2
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : nameParts[0][0];
    return abbreviation;
  };

  const selectArray = [
    {
      title: t("collection.community.template.filter.age"),
      label: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],
      id: "age",
      search: false,
    },
    ...(props.selectedWorkspaceTab ===
    MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
      ? [
          {
            title: t("collection.community.template.filter.author"),
            label: props.authors,
            id: "author",
            search: true,
          },
        ]
      : []),
    {
      title: t("collection.community.template.filter.tags"),
      label: props.filteredTags,
      id: "tags",
      search: true,
    },
  ];

  const handleSelectionChange = (
    type: "age" | "author" | "tags",
    checked: boolean,
    label: string,
  ) => {
    setFilterDataWithKeys((prev) => {
      if (type === "age") {
        // Only one age can be selected
        return {
          ...prev,
          age: checked ? label : "",
        };
      } else if (type === "author") {
        // Add or remove from the author array
        const updatedAuthors = checked
          ? [...prev.author, label]
          : prev.author.filter((author) => author !== label);
        return {
          ...prev,
          author: updatedAuthors,
        };
      } else if (type === "tags") {
        // Add or remove from the tags array
        const updatedTags = checked
          ? [...prev.tags, label]
          : prev.tags.filter((tag) => tag !== label);
        return {
          ...prev,
          tags: updatedTags,
        };
      }
      return prev;
    });
  };

  return (
    <div
      className="offcanvas offcanvas-end filter-drawer"
      id="offcanvasRight"
      style={{ display: props.isDataLoaded ? "block" : "none" }}
    >
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel" className="offcanvas-title title mt-4">
          {t("collection.community.template.filter.title")}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="offcanvas-hide"
          onClick={() => {
            setFilterDataWithKeys({
              ...filterDataWithKeys,
              age: "",
              author: [],
              tags: [],
            });
          }}
        ></button>
      </div>
      <div
        className="offcanvas-body overflow-hidden d-flex flex-column"
        style={{ height: "87%" }}
      >
        {selectArray.map((data, index) => {
          const isOpenDropDown = index === openDropdownIndex;

          const filteredOptions = data?.label?.filter((o) => {
            return o?.toLowerCase().includes(searchText?.toLowerCase());
          });

          const options = searchText ? filteredOptions : data.label;
          return (
            <div className="multiSelect" key={`${data.title}-${index}`}>
              <div className="dropdown">
                <p
                  className="dropdown__select d-flex justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${data.title}`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  onClick={() => handleDropdownToggle(index)}
                >
                  {data.title}
                  <ToolButton
                    key="newPage"
                    type={ToolButtonEnum.BUTTON}
                    title="DownArrow"
                    aria-label="DownArrow"
                    aria-labelledby="offcanvasRightLabel"
                    icon={downArrow_}
                  ></ToolButton>
                </p>
              </div>

              <div
                className={`overflow-auto collapse ${
                  isOpenDropDown ? "show" : ""
                }`}
                style={{ maxHeight: "493px" }}
                id={`${data.title}`}
              >
                {data.search && (
                  <div className="mx-3 mt-3">
                    <input
                      type="search"
                      id="search"
                      name="search"
                      placeholder={`Search ${data.title}`}
                      className="search-box w-100 "
                      style={{ display: "block" }}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                )}
                {options?.length > 0 ? (
                  options.map((label, index) => {
                    const isChecked =
                      data.id === "age"
                        ? filterDataWithKeys.age === label
                        : data.id === "author"
                        ? filterDataWithKeys.author.includes(label)
                        : filterDataWithKeys.tags.includes(label);

                    return (
                      label && (
                        <div className="px-5 pt-3 pb-0 d-flex" key={index}>
                          <input
                            type={data.id === "age" ? "radio" : "checkbox"}
                            id={`${label}`}
                            value={`${label}`}
                            name={data.id} // Group radio buttons by name for age
                            checked={isChecked}
                            onChange={(e) =>
                              handleSelectionChange(
                                data.id as "age" | "author" | "tags",
                                e.target.checked,
                                label,
                              )
                            }
                          />
                          <label htmlFor={`${label}`} className="ms-3">
                            {label}
                          </label>
                          <br />
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className="px-5 pt-3 pb-0">
                    <p>Not found</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="footer">
        <button
          type="button"
          className="bg-brand text-white pr-2 infoBtn"
          data-bs-dismiss="offcanvas"
          aria-label="Apply"
          onClick={async () => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            if (
              props.selectedWorkspaceTab ===
              MyWorkspaceSidebarItemsEnum.MYWORKSPACE
            ) {
              props.setLoading(true);
              setFilterDataWithKeys((prev) => ({
                ...prev,
                author: [user.mail],
              }));

              const data = await getFilteredData({
                ...filterDataWithKeys,
                author: [user.mail],
              });
              const res = await getWorkspaceUsers(); // Fetch users data
              const transformedData = data.data?.map((workspace: any) => {
                // Find matching user workspace data
                const matchingUserWorkspace = res?.find(
                  (userWorkspace: any) =>
                    userWorkspace.workspaceId === workspace.id,
                );

                const isSharable = !!matchingUserWorkspace; // Check if there's a match
                const expirationDate = isSharable
                  ? matchingUserWorkspace.expirationDate
                  : null; // Set expiration date if sharable
                const roomDetails = isSharable
                  ? matchingUserWorkspace.roomDetails
                  : null;

                return {
                  id: workspace.id,
                  title: workspace.name,
                  thumbnail: workspace.previewImage,
                  profile: getProfile().toUpperCase(),
                  date: getTimeAgo(workspace.createdAt),
                  elements: workspace.elements,
                  status: workspace?.status,
                  sharable: isSharable,
                  expirationDate, // Add expiration date
                  roomDetails,
                  tags: workspace.tags,
                };
              });
              props.setCardData(transformedData);
              props.setLoading(false);
            } else {
              props.setLoading(true);
              const data = await getFilteredData(filterDataWithKeys);
              const res = await getWorkspaceUsers(); // Fetch users data
              const getData = await getSharedWithMeData();
              const matchedData = data.data.filter(
                (item: any) =>
                  getData.length &&
                  getData?.some((card: any) => card.id === item.id),
              );

              const transformedData = matchedData?.map((workspace: any) => {
                // Find matching user workspace data
                const matchingUserWorkspace = res?.find(
                  (userWorkspace: any) =>
                    userWorkspace.workspaceId === workspace.id,
                );

                const isSharable = !!matchingUserWorkspace; // Check if there's a match
                const roomDetails = isSharable
                  ? matchingUserWorkspace.roomDetails
                  : null;
                return {
                  id: workspace.id,
                  title: workspace.name,
                  thumbnail: workspace.previewImage, // Assuming previewImage is the thumbnail
                  profile: getProfile().toUpperCase(), // Static or dynamic based on user
                  date: getTimeAgo(workspace.createdAt),
                  elements: workspace.elements, // Attach workspace elements
                  status: workspace?.status,
                  sharedUserProfile: getProfile(
                    workspace.userEmail,
                  ).toUpperCase(),
                  sharedUserProfileEmail: workspace.userEmail,
                  sharable: isSharable, // Sharable information
                  roomDetails, // Room details if available
                  tags: workspace.tags,
                };
              });
              const filteredData = transformedData.filter(
                (item: any) => item.sharedUserProfileEmail !== user.mail,
              );
              props.setCardData(filteredData);
              props.setLoading(false);
            }
          }}
        >
          {t("collection.community.template.filter.apply")}
        </button>
        <button
          type="button"
          className="infoBtn text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={async () => {
            if (
              props.selectedWorkspaceTab ===
              MyWorkspaceSidebarItemsEnum.SHARED_WITH_ME
            ) {
              await getSharedWithMeData();
            } else {
              await getWorkspaceData();
            }
            setFilterDataWithKeys({
              ...filterDataWithKeys,
              age: "",
              author: [],
              tags: [],
            });
          }}
        >
          {t("collection.community.template.filter.cancel")}
        </button>
      </div>
    </div>
  );
};
