import { t } from "../i18n";
import { Dialog, DialogProps } from "./Dialog";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

import "./ConfirmDialog.scss";

interface Props extends Omit<DialogProps, "onCloseRequest"> {
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
  renderButtons?: React.ReactNode;
  disabled?: boolean;
}
const ConfirmDialog = (props: Props) => {
  const {
    onClose,
    onConfirm,
    onCancel,
    children,
    confirmText = t("buttons.confirm"),
    cancelText = t("buttons.cancel"),
    className = "",
    renderButtons,
    disabled = false,
    ...rest
  } = props;
  return (
    <Dialog
      onCloseRequest={onClose || onCancel}
      small={true}
      {...rest}
      className={`confirm-dialog ${className}`}
    >
      {children}
      <div
        className={`confirm-dialog-buttons ${
          renderButtons ? "justify-content-between" : ""
        }`}
      >
        <div>{renderButtons}</div>
        <div>
          <ToolButton
            type={ToolButtonEnum.BUTTON}
            title={cancelText}
            aria-label={cancelText}
            label={cancelText}
            onClick={onCancel}
            className="confirm-dialog--cancel"
          />
          <ToolButton
            type={ToolButtonEnum.BUTTON}
            title={confirmText}
            aria-label={confirmText}
            label={confirmText}
            onClick={onConfirm}
            className="confirm-dialog--confirm"
            disabled={disabled}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default ConfirmDialog;
