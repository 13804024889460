export const getUserInfo = async () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return user;
};

export const getLessonId = async () => {
  const lessonData = localStorage.getItem("lesson");
  // Check if the lesson is valid and not "undefined"
  if (!lessonData || lessonData === "undefined") return;
  // Safely parse the lesson object
  const lesson = JSON.parse(lessonData);

  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
  const lessonId =
    isMyWorkSpace === "true"
      ? "MyWorkSpace"
      : lesson.Title
      ? lesson.Title
      : lesson?.LessonId;
  return lessonId;
};
