import { useEffect, useState } from "react";
import { apiPut } from "../../excalidraw-app/api";
import { API_URL } from "../../excalidraw-app/api/constant";
import { t } from "../../i18n";
import { Avatar } from "../Avatar";
import ConfirmDialog from "../ConfirmDialog";
import { ToolButton, ToolButtonEnum } from "../ToolButton";
import { groupType, studentType } from "./Types";
import "./students.scss";

const Students = ({
  students,
  setStudents,
  group,
  isEditable,
  setGroup,
  setIsEditable,
  setSelectedStudent,
  isOpen,
  groups,
  setGroups,
}: {
  students: studentType[];
  setStudents: (students: studentType[]) => void;
  group: groupType | null;
  setGroup: (group: groupType) => void;
  isEditable: boolean;
  setIsEditable: (value: boolean) => void;
  setSelectedStudent: Function;
  isOpen: boolean;
  groups: groupType[];
  setGroups: (groups: groupType[]) => void;
}) => {
  const [
    openStudentMoveConfirmationDialog,
    setOpenStudentMoveConfirmationDialog,
  ] = useState<boolean>(false);
  // State to store the group data from which a student is removed
  // Scenario: If 'student1' is in 'group1' and then 'group2' is created, adding 'student1' into 'group2',
  // at that time, 'group1' data is stored into this state.
  const [
    removedStudentGroup,
    setRemovedStudentGroup,
  ] = useState<groupType | null>(null);

  // State used to store the email of the checked student and their corresponding group
  const [currentStudentAndGroup, setCurrentStudentAndGroup] = useState<{
    groupId: string;
    studentEmail: string;
  } | null>(null);

  const toggleStudentsData = (
    checked: boolean,
    student: studentType,
    i: number,
  ) => {
    const clonedStudents = [...students];
    const clonedGroup = group;

    if (checked) {
      let isStudentFromOtherGroup = false;
      groups.forEach((g: groupType) => {
        g.students.forEach((stud) => {
          if (stud.studentEmail === student.studentEmail) {
            const updatedGroupStudents = g.students.map((stud) => ({
              ...stud,
              isAssignedGroup: student.studentEmail !== stud.studentEmail,
            }));
            setRemovedStudentGroup({ ...g, students: updatedGroupStudents });
            if (student.isAssignedGroup) {
              isStudentFromOtherGroup = true;
            }
          }
        });
      });

      const providedStudentIndex = students.findIndex(
        (s) => s.studentEmail === student.studentEmail,
      );
      if (clonedGroup) {
        setCurrentStudentAndGroup({
          groupId: clonedGroup.id!,
          studentEmail: student.studentEmail,
        });
      }
      if (isStudentFromOtherGroup) {
        setOpenStudentMoveConfirmationDialog(true);
      } else if (clonedGroup && providedStudentIndex !== -1) {
        clonedStudents[providedStudentIndex].isAssignedGroup = true;
        const updatedStudent = clonedStudents[providedStudentIndex];

        clonedGroup.students.push(updatedStudent);
      }
    } else if (clonedGroup) {
      const studentIndex = clonedGroup.students.findIndex(
        (info) => info.studentEmail === student.studentEmail,
      );

      clonedGroup.students.splice(studentIndex, 1);
      clonedStudents[i].isAssignedGroup = false;
    }

    if (clonedGroup) {
      setGroup({ ...clonedGroup });
    }
    setStudents(clonedStudents);
  };

  const onCheckGroupHasStudent = (student: studentType) => {
    if (group) {
      return Boolean(
        group.students.find(
          (info) => info.studentEmail === student.studentEmail,
        ),
      );
    }
  };

  // If group is in editable mode then show all students else show group students only
  const studentsData = isEditable ? students : group?.students;

  const isAllStudentsAssigned = () => {
    //Check all students are assigned or not
    const isAssignedGroup = students.every(
      (student) => student.isAssignedGroup,
    );

    //check that any student are assigned to current group or not
    const isStudentInGroup = students.some((student) =>
      onCheckGroupHasStudent(student),
    );

    setSelectedStudent(isStudentInGroup);
    return isAssignedGroup && !isStudentInGroup;
  };

  useEffect(() => {
    isAllStudentsAssigned();
    // eslint-disable-next-line
  }, []);

  const onMoveStudentToGroup = async () => {
    const updatedGroup = await apiPut(
      `${API_URL}/flexible-group?lessonId=${removedStudentGroup?.lessonId}`,
      removedStudentGroup,
    );

    if (updatedGroup.status === "Success") {
      const currentGroup = groups.find(
        (g) => g.id === currentStudentAndGroup?.groupId,
      );

      const oldGroup = removedStudentGroup;
      if (oldGroup) {
        const oldtStudentIndex = oldGroup?.students.findIndex(
          (s) => s.studentEmail === currentStudentAndGroup?.studentEmail,
        );
        if (oldtStudentIndex !== -1) {
          oldGroup.students[oldtStudentIndex].isAssignedGroup = false;
        }
        const updatedGroups = [...groups.filter((g) => g.id !== oldGroup.id)];
        const index = groups.findIndex((g) => g.id === oldGroup.id);
        updatedGroups.splice(index, 0, oldGroup);

        setGroups(updatedGroups);
      }

      const currentStudentIndex = students.findIndex(
        (s) => s.studentEmail === currentStudentAndGroup?.studentEmail,
      );
      if (currentStudentIndex !== -1) {
        if (currentGroup) {
          const updatedStudent = students[currentStudentIndex];
          updatedStudent.isAssignedGroup = true;
          currentGroup.students.push(updatedStudent);
          const updatedGroups = [
            ...groups.filter((g) => g.id !== currentGroup.id),
          ];
          const index = groups.findIndex((g) => g.id === currentGroup.id);
          updatedGroups.splice(index, 0, currentGroup);

          setGroups(updatedGroups);
        }
      }
      setOpenStudentMoveConfirmationDialog(false);
    }
  };

  return (
    <>
      {studentsData && studentsData.length > 0 ? (
        studentsData.map((student, i) => {
          const groupHasStudents = onCheckGroupHasStudent(student);

          return (
            <div
              className="d-flex align-items-center border-bottom py-2"
              key={`student-${i}`}
            >
              {isEditable && (
                <input
                  className="student-checkbox cursor-pointer"
                  onChange={(e) => {
                    toggleStudentsData(e.target.checked, student, i);
                  }}
                  checked={groupHasStudents && student.isAssignedGroup}
                  type="checkbox"
                />
              )}
              <div className={`${isEditable ? "ms-3" : ""} me-2`}>
                <Avatar
                  color={"transparent"}
                  border={"transparent"}
                  onClick={() => false}
                >
                  {student.photo.substring(0, 4) !== "null" ? (
                    <img
                      key={i}
                      src={student.photo}
                      alt={student.studentName}
                      className="user-profile"
                    />
                  ) : (
                    <p className="user-profile mb-0 mx-0 text-dark">
                      {!!student.studentName
                        ? student.studentName.substring(0, 2)
                        : "NO"}
                    </p>
                  )}
                </Avatar>
              </div>
              <span className="fw-bold">{student.studentName}</span>
            </div>
          );
        })
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
          {students.length === 0 ? (
            <p>No students Available</p>
          ) : isOpen ? (
            <p style={{ color: "red" }}>{t("labels.noStudentsMsg")}</p>
          ) : (
            <p>{t("labels.noStudentsMsg")}</p>
          )}
          {students.length !== 0 && (
            <ToolButton
              className={"px-3 py-1 bg-brand"}
              onClick={() => {
                window.parent.postMessage(
                  { type: "EDIT_MODAL", isEditable: true },
                  `${process.env.REACT_APP_PARENT_APP}`,
                );
                setIsEditable(true);
              }}
              type={ToolButtonEnum.BUTTON}
              title={"Assign students"}
              aria-label={"Assign students"}
            >
              <span className="text-white">{t("labels.noStudentsBtn")}</span>
            </ToolButton>
          )}
        </div>
      )}

      {openStudentMoveConfirmationDialog && (
        <ConfirmDialog
          onCancel={() => {
            setOpenStudentMoveConfirmationDialog(false);
          }}
          onConfirm={onMoveStudentToGroup}
          title={`Are you sure?`}
          open={openStudentMoveConfirmationDialog}
          children={
            <>
              <p className="mb-0">
                This student has already been selected for another group. If you
                choose to select them, they will be removed from the other
                group.
              </p>
            </>
          }
          setOpen={setOpenStudentMoveConfirmationDialog}
          isEditable={false}
          isShowInput={false}
          closeOnClickOutside={false}
        />
      )}
    </>
  );
};

export default Students;
