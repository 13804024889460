/**
 * This extension adds a plugin to handle parsing files from clipboard,
 * uploading them to Uploadcare, and create Embed nodes with the uploaded urls.
 */

import { Extension } from "@tiptap/core";
import { Plugin } from "@tiptap/pm/state";
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { ConvertHtmlTexttoTiptapEditorTextString } from "../../../utils";

export type FilePasteOptions = {
  disabled?: boolean;
};
export const HtmlElementExtension = Extension.create<FilePasteOptions>({
  name: "EXTENSION_FILE_PASTE",

  // defaultOptions: {
  //   disabled: false,
  // },

  addOptions() {
    return {
      disabled: false,
    };
  },

  addProseMirrorPlugins() {
    if (this.options.disabled) {
      return [];
    }

    return [
      new Plugin({
        props: {
          handlePaste: function (view, e, slice) {
            e.preventDefault();

            // Destructure clipboard data
            const { clipboardData } = e;

            // Check for clipboard data types
            const pastedContent = clipboardData?.getData("text/html");

            if (!pastedContent) {
              return false; // No HTML content pasted, nothing to do
            }

            // Extract the content between <!--StartFragment--> and <!--EndFragment-->
            const startFragment = "<!--StartFragment-->";
            const endFragment = "<!--EndFragment-->";
            const startIndex =
              pastedContent.indexOf(startFragment) + startFragment.length;
            const endIndex = pastedContent.indexOf(endFragment);

            if (startIndex === -1 || endIndex === -1) {
              return false; // Fragments not found, nothing to do
            }

            const fragmentContent = pastedContent
              .substring(startIndex, endIndex)
              .trim();

            const finalContent = ConvertHtmlTexttoTiptapEditorTextString(
              fragmentContent,
            );

            const element = document.createElement("div");

            element.innerHTML = finalContent;

            const _slice = ProseMirrorDOMParser.fromSchema(
              view.state.schema as any,
            ).parseSlice(element);

            const node = view.state.schema.nodes["doc"].create(
              null,
              _slice.content as any,
            );

            const transaction = view.state.tr.replaceSelectionWith(node);
            view.dispatch(transaction);

            return true;
          },
        },
      }),
    ];
  },
});
