export enum SidebarItemsEnum {
  WorkInProgress = "Work in progress",
  AddedFromCollections = "Added from collections",
  PublicSpinWheel = "Public Spin Wheel",
}

export enum CollectionSidebarItemsEnum {
  Template = "Template",
  StockImage = "Stock Image",
  MyCollections = "My Collections",
  Images = "Images",
  Videos = "Videos",
  Audios = "Audios",
  UnderReview = "Under Review",
  Liked = "Liked",
  Bookmark = "Bookmark",
}
