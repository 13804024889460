import { useEffect, useState } from "react";
import Loader from "../../../../../../App/shareComponent/Loader";
import { fetchStockImagesAPI } from "../../../../../../excalidraw-app/api/collection";
import { t } from "../../../../../../i18n";
import { AppState, StockImagesType } from "../../../../../../types";
import { CardWithImage } from "../../../../components/card";

interface StockImageProps {
  insertImageOnCanvas: (file: File) => void;
  setAppState: React.Component<any, AppState>["setState"];
  searchVal: string;
}

export const StockImage = ({
  insertImageOnCanvas,
  setAppState,
  searchVal,
}: StockImageProps) => {
  const [stockImage, setStockImage] = useState<StockImagesType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchStockImages = async (data?: string) => {
    try {
      const lesson = JSON.parse(localStorage.getItem("lesson") || "");
      const parts = lesson ? lesson?.ClassName?.split("-") : ["Nature"];

      setIsLoading(true);
      const apiBody = {
        query: searchVal !== "" ? searchVal : lesson ? parts[0] : "Nature",
      };
      const images = await fetchStockImagesAPI(apiBody);
      setStockImage(images.results.photos);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStockImages();
    // eslint-disable-next-line
  }, [searchVal]);

  const getUrlExtension = (url: any) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  const insertImage = async (url: string) => {
    const imgExt = getUrlExtension(url);

    const response = await fetch(url);
    const blob = await response.blob();

    const file = new File([blob], `image.${imgExt}`, {
      type: blob.type,
    });
    insertImageOnCanvas(file);
    setAppState({ isLibraryOpen: false });
  };

  return (
    <div className="row overflow-auto" style={{ height: "calc(100% - 28%)" }}>
      {isLoading ? (
        <Loader className="h-100" />
      ) : (
        stockImage.map((image, index) => (
          <CardWithImage
            key={index}
            images={image.src.original}
            buttonText={t("cards.importOnCanvas")}
            likeBookmarkIcon={false}
            isHoverPreviewBtns={false}
            isHoverUseThisTemplateBtn={false}
            onAddtoCollectionBtnClick={() => insertImage(image.src.medium)}
          />
        ))
      )}
    </div>
  );
};
