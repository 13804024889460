import React from "react";
import { lockIcon, openLockIcon, preview, templateIcon } from "../../../icons";
import spinWheel from "../../../../assests/spinWheel.png";
import { SegmentType, timerDuration } from "../../../spinner/types";
import { t } from "../../../../i18n";

export const CardWithTextEditor = React.memo(
  ({
    item,
    index,
    deleteElement,
    setPreviewDialog,
    handleSpinnerDialog,
    isMobile,
    description,
  }: {
    item: {
      id: string;
      title: string;
      description: string;
      isPublic: boolean;
      isTimer: boolean;
      timerDuration: {
        [timerDuration.MINUTES]: number;
        [timerDuration.SECONDS]: number;
      };
      wheels: SegmentType[];
    } | null;
    index: number;
    deleteElement: (val: string) => void;
    setPreviewDialog: (
      val: {
        id: string;
        title: string;
        description: string;
        isPublic: boolean;
        isTimer: boolean;
        timerDuration: {
          [timerDuration.MINUTES]: number;
          [timerDuration.SECONDS]: number;
        };
        wheels: SegmentType[];
      } | null,
    ) => void;
    handleSpinnerDialog: (val: {
      isTimer: boolean;
      timerDuration: {
        [timerDuration.MINUTES]: number;
        [timerDuration.SECONDS]: number;
      };
      segments: SegmentType[];
    }) => void;
    isMobile: boolean;
    description: string;
  }) => (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" key={index}>
      <div
        className={`card preview-card spin__wheel-preview-card mb-3`}
        key={index}
      >
        <div className="d-flex justify-content-end mx-2 mt-2">
          <div className="public-badge gap-2 align-items-center  p-2">
            <span>{item?.isPublic ? "Public" : "Private"}</span>
            <div>{item?.isPublic ? openLockIcon : lockIcon}</div>
          </div>
        </div>
        <div className="d-flex align-items-center px-2">
          <div className="w-50">
            <img
              src={spinWheel}
              className={`spin-card-img ${
                item?.isPublic ? "" : "invert-wheel"
              }`}
              alt="..."
            />
          </div>
          <div className="w-50 text-center">
            <h5 className="mb-0">
              {item?.wheels
                ? item?.wheels?.length > 0
                  ? item?.wheels[0]?.segment?.length
                  : 0
                : 0}{" "}
              items
            </h5>
          </div>
        </div>
        <div className="card-title">
          <h5 className="mb-0">{item?.title}</h5>
        </div>
        <div className="d-flex flex-column card-overlay justify-content-between ">
          <div className="d-flex flex-column justify-content-center align-items-center mb-2">
            <p
              className={`${
                isMobile
                  ? "details__description_mobile"
                  : "details__description"
              } d-lg-block`}
            >
              {description.length > 80
                ? `${description.slice(0, 80)}...`
                : description}
            </p>
            <div className="w-100 m-auto d-flex justify-content-center align-items-center">
              <button
                type="button"
                className="btn template-icon-btn btn-remove"
                onClick={() => {
                  item?.id && deleteElement(item?.id);
                }}
              >
                {t("cards.remove")}
              </button>
            </div>
          </div>

          <div className="d-flex flex-lg-row align-items-end">
            <button
              type="button"
              className="btn mb-3 template-preview-btn"
              onClick={() => setPreviewDialog(item)}
            >
              {preview} {t("cards.preview")}
            </button>

            <button
              className="btn mb-3 template-icon-btn"
              onClick={() => {
                if (item?.wheels) {
                  return handleSpinnerDialog({
                    segments: item?.wheels,
                    isTimer: item?.isTimer,
                    timerDuration: item?.timerDuration,
                  });
                }
              }}
            >
              <span className="d-flex " style={{ height: 15, width: 15 }}>
                {templateIcon}
              </span>
              {t("cards.playThisWheel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  ),
);
