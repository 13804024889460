import React, { useEffect, useRef, useState } from "react";
import { copyTextToSystemClipboard } from "../../clipboard";
import { Dialog } from "../../components/Dialog";
import {
  start,
  stop,
  share,
  shareIOS,
  shareWindows,
  copy,
  plusOutlineIcon,
  errorIcon,
  tickCircle,
  invite,
  shareLink,
  download_,
} from "../../components/icons";
import { ToolButton, ToolButtonEnum } from "../../components/ToolButton";
import { t } from "../../i18n";
import "./RoomDialog.scss";
import Stack from "../../components/Stack";
import { AppState } from "../../types";
import ConfirmDialog from "../../components/ConfirmDialog";
import { User } from "./CollabWrapper";
import {
  addParticipants,
  getSharedWithMeWorkspace,
  getSharedWithMeWorkspaceByWorkspaceID,
  sendMail,
  updateEmailsInSharedWorkspace,
  updateExpirationDateTime,
  updateInvitedWorkspaceStatus,
} from "../api/storeElementData";
import { QRCodeCanvas } from "qrcode.react";
import moment from "moment";

const getShareIcon = () => {
  const navigator = window.navigator as any;
  const isAppleBrowser = /Apple/.test(navigator.vendor);
  const isWindowsBrowser = navigator.appVersion.indexOf("Win") !== -1;

  if (isAppleBrowser) {
    return shareIOS;
  } else if (isWindowsBrowser) {
    return shareWindows;
  }

  return share;
};

const RoomDialog = ({
  handleClose,
  activeRoomLink,
  username,
  onUsernameChange,
  onRoomCreate,
  onRoomDestroy,
  setErrorMessage,
  theme,
  openFlexibleGroupsDialog,
  onStartFlexibleGroupCollaboration,
  organizationUsers,
  appState,
  openAlert,
  show,
  isPublic,
  filteredEmails,
  filteredEmailsData,
  isPublicValue,
}: {
  handleClose: () => void;
  activeRoomLink: string;
  username: string;
  onUsernameChange: (username: string) => void;
  onRoomCreate: (val: boolean, addParticipants?: boolean) => void;
  onRoomDestroy: () => void;
  setErrorMessage: (message: string) => void;
  theme: AppState["theme"];
  openFlexibleGroupsDialog: VoidFunction;
  onStartFlexibleGroupCollaboration: () => void;
  organizationUsers: User[];
  appState: AppState;
  openAlert: (value: boolean) => void;
  show: boolean;
  isPublic: (value: boolean) => void;
  filteredEmails: (value: string[]) => void;
  filteredEmailsData: string[];
  isPublicValue: boolean;
}) => {
  const roomLinkInput = useRef<HTMLInputElement>(null);
  const [isShowModel, setIsShowModel] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Type the dropdownRef
  const [isOpen, setIsOpen] = useState(false);
  const [isSaveMode, setSaveMode] = useState(false);
  const [alreadyinvitedUsers, setAlreadyInvitedUsers] = useState<User[]>([]);
  // const [isOpenStopCollabModel, setIsOpenStopCollabModel] = useState<boolean>(
  //   false,
  // );
  // console.log(isOpenStopCollabModel);
  const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");

  // Trigger the effect whenever `show` is set to true
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        openAlert(false); // Set the state to false after 1 second
      }, 3000); // 1000 ms = 1 second

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, [show]);

  // Extract user emails from `res`
  useEffect(() => {
    isMyWorkSpaceData === "true" && getFilteredEmails();
  }, []);

  const getFilteredEmails = async () => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const res = await getSharedWithMeWorkspaceByWorkspaceID(
      selectedWorkspaceCardID as string,
    );
    const emails =
      res?.data?.length &&
      res?.data[0]?.userEmail?.map((user: any) => user.email.toLowerCase());
    isPublic(res?.data?.length && res?.data[0]?.status === "Public");
    filteredEmails(emails);
  };

  // Populate selectedOptions with filtered emails initially
  useEffect(() => {
    const initiallySelected = organizationUsers?.filter(
      (user) =>
        filteredEmailsData?.length &&
        filteredEmailsData?.includes(user?.email?.toLowerCase()),
    );
    setSelectedOptions(initiallySelected);
    setAlreadyInvitedUsers(initiallySelected);
  }, [filteredEmailsData, organizationUsers]);

  const handleToggle = async () => {
    isPublic(!isPublicValue);
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    await updateInvitedWorkspaceStatus(
      selectedWorkspaceCardID,
      !isPublicValue ? "Public" : "Private",
    );
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option: User) => {
    isCollaboration && setSaveMode(true);

    setSelectedOptions((prevOptions) => {
      let updatedOptions;

      if (prevOptions.some((opt) => opt.email === option.email)) {
        // Remove the option if it already exists
        updatedOptions = prevOptions.filter(
          (opt) => opt.email !== option.email,
        );
      } else {
        // Add the option if it doesn't exist
        updatedOptions = [...prevOptions, option];
      }

      // Compare updatedOptions with alreadyInvitedUsers
      const areListsEqual =
        updatedOptions.length === alreadyinvitedUsers.length &&
        updatedOptions.every((opt) =>
          alreadyinvitedUsers.some((user) => user.email === opt.email),
        );

      if (areListsEqual) {
        setSaveMode(false);
      }

      return updatedOptions;
    });
  };

  const copyRoomLink = async () => {
    try {
      await copyTextToSystemClipboard(activeRoomLink);
    } catch (error) {
      setErrorMessage(error.message);
    }
    if (roomLinkInput.current) {
      roomLinkInput.current.select();
    }
  };

  const shareRoomLink = async () => {
    try {
      await navigator.share({
        title: t("roomDialog.shareTitle"),
        text: t("roomDialog.shareTitle"),
        url: activeRoomLink,
      });
    } catch (error) {
      // Just ignore.
    }
  };

  const selectInput = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.target !== document.activeElement) {
      event.preventDefault();
      (event.target as HTMLInputElement).select();
    }
  };

  const renderLinkSection = () => (
    <div className="RoomDialog-linkContainer">
      <input
        value={activeRoomLink}
        readOnly={true}
        className="RoomDialog-link"
        ref={roomLinkInput}
        onPointerDown={selectInput}
      />
      <Stack.Row gap={2}>
        <ToolButton
          type={ToolButtonEnum.BUTTON}
          icon={copy}
          title={t("labels.copy")}
          aria-label={t("labels.copy")}
          onClick={copyRoomLink}
        />
        {"share" in navigator && (
          <ToolButton
            type={ToolButtonEnum.BUTTON}
            icon={getShareIcon()}
            title={t("labels.share")}
            aria-label={t("labels.share")}
            onClick={shareRoomLink}
          />
        )}
      </Stack.Row>
    </div>
  );

  const renderUsernameInput = () => (
    <div className="RoomDialog-usernameContainer">
      <label className="RoomDialog-usernameLabel" htmlFor="username">
        {t("labels.yourName")}
      </label>
      <input
        id="username"
        value={username || ""}
        className="RoomDialog-username TextInput"
        onChange={(event) => onUsernameChange(event.target.value)}
        onKeyPress={(event) => event.key === "Enter" && handleClose()}
      />
    </div>
  );

  const renderUserEmailDropDown = () => (
    <div style={{ margin: "1.5em 0" }}>
      {/* Dropdown Selector */}
      <div style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
        <button
          onClick={toggleDropdown}
          style={{
            width: "100%",
            padding: "10px 15px",
            borderRadius: "8px",
            backgroundColor: "#F0F4F8",
            border: "1px solid #D1D5DB",
            color: "#374151",
            fontSize: "16px",
            textAlign: "left",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          Select an Email:
        </button>
        {isOpen && (
          <ul
            className={`dropdown-menu p-0 ${isOpen ? "show" : ""}`}
            style={{
              maxHeight: "180px",
              width: "100%",
              position: "absolute",
              top: "100%",
              left: 0,
              marginTop: "5px",
              overflowY: "auto",
              borderRadius: "8px",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#FFFFFF",
            }}
          >
            {/* Search Box */}
            <div
              className="p-3"
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#FFFFFF",
                borderBottom: "1px solid #E5E7EB",
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  borderRadius: "4px",
                  border: "1px solid #D1D5DB",
                  fontSize: "14px",
                }}
              />
            </div>

            {/* List of Options */}
            <div>
              {organizationUsers
                .filter((option) =>
                  option.email.toLowerCase().includes(searchTerm.toLowerCase()),
                )
                .map((option) => (
                  <li
                    key={option.userID}
                    className="dropdown-item"
                    style={{
                      padding: "8px 16px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      color: "#4B5563",
                    }}
                  >
                    <label
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedOptions.some(
                          (opt) => opt.email === option.email,
                        )}
                        onChange={() => handleOptionChange(option)}
                        style={{
                          marginRight: "8px",
                          accentColor: "#2563EB",
                          cursor: "pointer",
                        }}
                      />
                      {option.email}
                    </label>
                  </li>
                ))}
            </div>
          </ul>
        )}
      </div>
    </div>
  );

  const getNamesFromEmails = (emails: string[]) => {
    return emails.map((email) => {
      const localPart = email.split("@")[0]; // Get the part before '@'
      const nameParts = localPart.split("."); // Split by '.'
      return nameParts
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
        .join(" "); // Join the parts with spaces
    });
  };

  const addParticipantsForSharedWorkspace = async () => {
    const emails = selectedOptions.map((user) => user.email); // Emails from selected options
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userMail = user?.mail;

    try {
      const res = await getSharedWithMeWorkspaceByWorkspaceID(
        selectedWorkspaceCardID as string,
      );

      // Extract existing emails
      const existingEmails = res?.data?.[0]?.userEmail || [];

      // Create a set for fast lookup
      const selectedEmailsSet = new Set(emails);

      // Prepare updated emails list
      const updatedEmails = [
        // Keep emails already in `res.data[0].userEmail` if they are in `emails`
        ...existingEmails.filter(({ email }: any) =>
          selectedEmailsSet.has(email),
        ),
        // Add new emails from `emails` if not already in `existingEmails`
        ...emails
          .filter(
            (email) =>
              !existingEmails.some(
                (userEmail: any) => userEmail.email === email,
              ),
          )
          .map((email) => ({
            email,
            isActive: false,
            isEnabled: true,
          })),
        // Ensure the current user's email is included
        {
          email: userMail,
          isActive: false,
          isEnabled: true,
        },
      ];

      // Prepare body
      const body = {
        userEmail: updatedEmails,
      };

      // Update or create workspace
      if (
        res?.data?.length &&
        res.data[0].workspaceId === selectedWorkspaceCardID
      ) {
        await updateEmailsInSharedWorkspace(
          res.data[0].workspaceId,
          body.userEmail,
        );

        await updateInvitedWorkspaceStatus(
          res.data[0].workspaceId,
          isPublicValue ? "Public" : "Private",
        );

        const currentTime = moment(); // Local time (defaults to user's system timezone)
        const twoHoursLater = currentTime.add(2, "hours").toDate(); // Add 2 hours

        await updateExpirationDateTime(
          res.data[0].workspaceId,
          twoHoursLater as any,
        );
      } else {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const body = {
          userEmail: updatedEmails,
          editingAccess: false,
          workspaceId: selectedWorkspaceCardID,
          ownerUserEmail: userMail,
          status: isPublicValue ? "Public" : "Private",
          userTimezone: userTimezone,
        };
        await addParticipants(body as any);
      }
      await onRoomCreate(false, true);
      // Filter out emails already invited and owner's email
      const alreadyInvitedEmails = new Set(
        alreadyinvitedUsers.map((user) => user.email),
      );
      const emailsToSend = updatedEmails.filter(
        (item) =>
          !alreadyInvitedEmails.has(item.email) && item.email !== userMail,
      );

      const link = localStorage.getItem("activeRoomLink");
      const emails_ = emailsToSend.map((item) => item.email);
      const names = getNamesFromEmails(emails_);

      // Send emails only to filtered addresses
      emails_.length > 0 &&
        emails_.forEach(async (email, index) => {
          const name = names[index]; // Assume names array aligns with emails array
          await sendMail({
            ownerUserEmail: userMail,
            ownerUserName: user?.name,
            Email: email,
            name: name || "", // Use an empty string if no name exists
            link: link || "",
          });
        });
      setIsShowModel(false);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  const isCollaboration = localStorage.getItem("isCollaborating") === "true";

  const renderButtonContainer = (isAddParticipants = false) => (
    <>
      {/* Display Selected Users */}
      <div className="position-relative" style={{ top: "-30%" }}>
        <ul className="showList">
          {showAll
            ? selectedOptions.map((user, index) => (
                <li key={index} className="user-pill">
                  <span>{user.email}</span>
                  <button
                    className="close-button"
                    onClick={() => handleOptionChange(user)}
                  >
                    X
                  </button>
                </li>
              ))
            : selectedOptions.slice(0, 3).map((user, index) => (
                <li key={index} className="user-pill">
                  <span>{user.email}</span>
                  <button
                    className="close-button"
                    onClick={() => handleOptionChange(user)}
                  >
                    X
                  </button>
                </li>
              ))}
        </ul>
        {selectedOptions.length > 3 && (
          <button
            style={{ zIndex: "10" }}
            className="position-absolute"
            onClick={handleShowAll}
          >
            {showAll ? "Show Less" : "Show All"}
          </button>
        )}
      </div>
      <div
        className="RoomDialog-sessionStartButtonContainer_"
        style={{
          gap: "8px",
          top: "0%",
          position: "relative",
        }}
      >
        {!isCollaboration && isAddParticipants ? (
          <div className="d-flex gap-2 w-100">
            <ToolButton
              className="invite_participant"
              type={ToolButtonEnum.BUTTON}
              icon={invite}
              title="Invite Participant"
              aria-label="Invite Participant"
              showAriaLabel={true}
              onClick={addParticipantsForSharedWorkspace}
              style={{
                background: selectedOptions?.length > 0 ? "#585A96" : "#D3D3D3", // Enable color when there are selected options, else light grey
                cursor: selectedOptions?.length > 0 ? "pointer" : "not-allowed", // Change cursor to indicate disabled state
              }}
              disabled={!selectedOptions?.length}
            />
          </div>
        ) : (
          isAddParticipants &&
          isSaveMode && (
            <div className="d-flex gap-2 w-100">
              <ToolButton
                className="invite_participant"
                type={ToolButtonEnum.BUTTON}
                icon={invite}
                title={isSaveMode ? "Save Participant" : "Invite Participant"}
                aria-label={
                  isSaveMode ? "Save Participant" : "Invite Participant"
                }
                showAriaLabel={true}
                onClick={addParticipantsForSharedWorkspace}
                style={{
                  background: isSaveMode ? "#585A96" : "#D3D3D3",
                  cursor: isSaveMode ? "pointer" : "not-allowed",
                }}
                disabled={!isSaveMode}
              />
            </div>
          )
        )}
        {isCollaboration && (
          <ToolButton
            className="RoomDialog-stopSession"
            type={ToolButtonEnum.BUTTON}
            icon={stop}
            title={t("roomDialog.button_stopSession")}
            aria-label={t("roomDialog.button_stopSession")}
            showAriaLabel={true}
            onClick={async () => {
              // if (isMyWorkSpaceData === "true") {
              //   const selectedWorkspaceCardID = localStorage.getItem(
              //     "selectedWorkspaceCard",
              //   );
              //   const res = await getSharedWithMeWorkspaceByWorkspaceID(
              //     selectedWorkspaceCardID as string,
              //   );
              //   if (res?.data?.length) {
              //     const user = JSON.parse(localStorage.getItem("user") || "{}");
              //     if (res.data[0].ownerUserEmail === user.mail) {
              //       setIsOpenStopCollabModel(true);
              //     }
              //   }
              // } else {
              await onRoomDestroy();
              handleClose();
              // }
            }}
          />
        )}
        {isMyWorkSpaceData === "true" && isCollaboration && activeRoomLink && (
          <div
            className={`d-flex gap-2 w-100 ${
              !isSaveMode && "justify-content-end"
            }`}
          >
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title="Share Link"
              icon={shareLink}
              aria-label="Share Link"
              showAriaLabel={true}
              onClick={() => {
                openAlert(true);
                navigator.clipboard.writeText(activeRoomLink || "");
              }}
              style={{
                background: "#585A96",
              }}
            />
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              icon={download_}
              title="Download QR Code"
              aria-label="Download QR Code"
              showAriaLabel={true}
              onClick={downloadQRCode}
              style={{
                background: "#585A96",
              }}
            />
          </div>
        )}
      </div>
    </>
  );

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrCodeCanvas") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "QRCode.png"; // Filename for the downloaded QR code
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.error("QR Code canvas not found!");
    }
  };

  const renderRoomDialog = () => {
    return (
      <>
        <div className="RoomDialog-modal h-100">
          {isMyWorkSpaceData === "true" ? (
            <>
              <p className="intro">{t("roomDialog.desc_shareLink")}</p>
              {renderUserEmailDropDown()}
            </>
          ) : !activeRoomLink ? (
            <>
              <p className="intro">{t("roomDialog.desc_intro")}</p>
              <p>{`🔐${t("roomDialog.desc_privacy")}`}</p>
              <div className="RoomDialog-sessionStartButtonContainer">
                <ToolButton
                  className="RoomDialog-startSession"
                  type={ToolButtonEnum.BUTTON}
                  icon={start}
                  title={t("roomDialog.button_startSession")}
                  aria-label={t("roomDialog.button_startSession")}
                  showAriaLabel={true}
                  onClick={() => setIsShowModel(true)}
                  id="createRoomBtn"
                />
              </div>
            </>
          ) : (
            <>
              <p className="intro">{t("roomDialog.desc_shareLink")}</p>
              {renderLinkSection()}
              {renderUsernameInput()}
              <p>{t("roomDialog.desc_exitSession")}</p>
              {renderButtonContainer()}
            </>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      {isMyWorkSpaceData === "true" ? (
        <Dialog
          onCloseRequest={handleClose}
          title={"Share Workspace"}
          theme={theme}
          className="workspace-dialog"
        >
          {/* {isOpenStopCollabModel && (
            <Dialog
              onCloseRequest={() => setIsOpenStopCollabModel(false)}
              title={t("whiteBoard.areYousure")}
              closeOnClickOutside={false}
              open={isOpenStopCollabModel}
              setOpen={() => false}
              children={
                <>
                  <p>
                    If you leave the collaboration, the users of the shareable
                    workspace will no longer have access to live editing.
                  </p>
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "5px" }}
                  >
                    <ToolButton
                      type={ToolButtonEnum.BUTTON}
                      title={"Okay"}
                      aria-label={"Okay"}
                      label={"Okay"}
                      onClick={async () => {
                        await onRoomDestroy();
                        handleClose();
                      }}
                      className="text-white w-fit-content save-formula-btn m-0 "
                    />
                    <ToolButton
                      type={ToolButtonEnum.BUTTON}
                      title={"Cancel"}
                      aria-label={"Cancel"}
                      label={"Cancel"}
                      onClick={() => {
                        setIsOpenStopCollabModel(false);
                      }}
                      className="cancel-btn text-white w-fit-content save-formula-btn m-0 me-2"
                    />
                  </div>
                </>
              }
              className="confirmationModal"
            />
          )} */}
          {show && (
            <div className="notes_div" style={{ border: "1px solid #2b8a3e" }}>
              <div className="d-flex">
                <span className="confirmation">{errorIcon}</span>
                <div className="d-flex w-100">
                  <span className="text-success" style={{ width: "90%" }}>
                    Link copied successfully!
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            className="d-flex justify-content-between gap-3"
            style={{ marginBottom: "5px" }}
          >
            <div style={{ width: "80%" }}>
              When the collaboration ends this Workspace will remain as:
            </div>
            <div className="form-check form-switch" style={{ width: "20%" }}>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheck"
                checked={isPublicValue}
                onChange={handleToggle}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheck">
                {isPublicValue ? "Public" : "Private"}
              </label>
            </div>
          </div>
          <div className="d-flex w-100">
            {activeRoomLink ? (
              <>
                {" "}
                <div style={{ width: "67%" }}>
                  <div
                    className="room-dialog-wrapper"
                    style={{
                      height: selectedOptions.length
                        ? isOpen
                          ? "360px"
                          : "200px"
                        : isMyWorkSpaceData === "true"
                        ? isOpen
                          ? "360px"
                          : "200px"
                        : "",
                    }}
                  >
                    {renderRoomDialog()}
                    {isShowModel && (
                      <ConfirmDialog
                        cancelText={t("whiteBoardModel.cancelBtn")}
                        confirmText={t("whiteBoardModel.okBtn")}
                        onCancel={() => {
                          onRoomCreate(false, false);
                          setIsShowModel(false);
                        }}
                        onClose={() => {
                          setIsShowModel(false);
                        }}
                        onConfirm={() => {
                          handleClose();
                          setIsShowModel(false);
                          openFlexibleGroupsDialog();
                        }}
                        children={
                          <>
                            <p>{t(`labels.collaborationConfirmMsg`)}</p>
                          </>
                        }
                        open={isShowModel}
                        title={t(`labels.flexibleGroup`)}
                        setOpen={setIsShowModel}
                        isEditable={false}
                        isShowInput={false}
                      />
                    )}
                  </div>
                </div>
                <div style={{ width: "33%" }}>
                  {" "}
                  <div
                    className="qr-code-action-wrapper d-flex justify-content-center h-100 position-relative"
                    style={{ left: "5%" }}
                  >
                    <div>
                      <div className="qr-image-wrapper align-items-center d-flex">
                        {activeRoomLink && (
                          <QRCodeCanvas
                            value={activeRoomLink}
                            size={150}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"H"}
                            includeMargin={true}
                            id="qrCodeCanvas"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ width: "100%" }}>
                <div
                  className="room-dialog-wrapper"
                  style={{
                    height: selectedOptions.length
                      ? isOpen
                        ? "360px"
                        : selectedOptions.length < 5
                        ? "145px"
                        : "200px"
                      : isMyWorkSpaceData === "true"
                      ? isOpen
                        ? "360px"
                        : selectedOptions.length < 5
                        ? "145px"
                        : "200px"
                      : "",
                  }}
                >
                  {renderRoomDialog()}
                  {isShowModel && (
                    <ConfirmDialog
                      cancelText={t("whiteBoardModel.cancelBtn")}
                      confirmText={t("whiteBoardModel.okBtn")}
                      onCancel={() => {
                        onRoomCreate(false, false);
                        setIsShowModel(false);
                      }}
                      onClose={() => {
                        setIsShowModel(false);
                      }}
                      onConfirm={() => {
                        handleClose();
                        setIsShowModel(false);
                        openFlexibleGroupsDialog();
                      }}
                      children={
                        <>
                          <p>{t(`labels.collaborationConfirmMsg`)}</p>
                        </>
                      }
                      open={isShowModel}
                      title={t(`labels.flexibleGroup`)}
                      setOpen={setIsShowModel}
                      isEditable={false}
                      isShowInput={false}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div> {renderButtonContainer(true)}</div>
        </Dialog>
      ) : (
        <Dialog
          onCloseRequest={handleClose}
          title={t("labels.liveCollaboration")}
          theme={theme}
        >
          <div className="room-dialog-wrapper">
            {renderRoomDialog()}
            {isShowModel && (
              <ConfirmDialog
                cancelText={t("whiteBoardModel.cancelBtn")}
                confirmText={t("whiteBoardModel.okBtn")}
                onCancel={() => {
                  onRoomCreate(false, false);
                  setIsShowModel(false);
                }}
                onClose={() => {
                  setIsShowModel(false);
                }}
                onConfirm={() => {
                  handleClose();
                  setIsShowModel(false);
                  openFlexibleGroupsDialog();
                }}
                children={
                  <>
                    <p>{t(`labels.collaborationConfirmMsg`)}</p>
                  </>
                }
                open={isShowModel}
                title={t(`labels.flexibleGroup`)}
                setOpen={setIsShowModel}
                isEditable={false}
                isShowInput={false}
              />
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default RoomDialog;
