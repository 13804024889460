import moment from "moment";
import { useEffect, useState } from "react";
import exportIcon from "../../../../../../src/assests/exportIcon.png";
import { t } from "../../../../../i18n";
import {
  CollocationType,
  LibraryItem,
  LibraryType
} from "../../../../../types";
import { useIsMobile } from "../../../../App";
import { LibraryCarousel } from "../../../../LibraryCarousel";
import { CollectionImagePreview } from "../../../../collectionImagePreview";
import { compress, expand, zoomIn, zoomOut } from "../../../../icons";
import "./style.scss";

interface TemplateInfoProps {
  templateData: CollocationType;
  loadElementsFromDB: (url: string) => void;
  onInsertShape: (elements: LibraryItem["elements"]) => void;
}

export const TemplateInfo = ({
  templateData,
  loadElementsFromDB,
  onInsertShape,
}: TemplateInfoProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [index, setIndex] = useState(0);
  const [
    storeDataconvertedUrlToJson,
    setStoreDataconvertedUrlToJson,
  ] = useState<LibraryType[]>([]);
  const [getSelectedItems, setGetSelectedElements] = useState<string[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const isMobileView = useIsMobile();

  const handleSelectCategory = (preview: any) => {
    const isExisting = selectedTemplates.filter((t: any) =>
      preview.elements.some((e: any) => e?.id === t.id),
    );
    if (isExisting.length) {
      const updatedElement = selectedTemplates.filter(
        (t: any) => !preview.elements.some((e: any) => e?.id === t.id),
      );
      const updatedElement_ = getSelectedItems.filter(
        (t: any) => preview?.id !== t.id,
      );
      setGetSelectedElements(updatedElement_);
      setSelectedTemplates(updatedElement);
    } else {
      setGetSelectedElements((prev) => [...prev, preview]);
      setSelectedTemplates((prev) => [...prev, ...preview?.elements]);
    }
  };

  const elem = document.getElementById("preview-dialog-fullscreen");

  const openFullScreen = () => {
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    }
    setIsFullscreen(true);
  };

  const closeFullScreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        /* IE11 */
        document.exitFullscreen();
      }
    }
    setIsFullscreen(false);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));
  };

  useEffect(() => {
    setIsFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  }, []);

  useEffect(() => {
    convertUrlToJson(templateData.source);
  }, [templateData.source]);

  const convertUrlToJson = async (url: string) => {
    const response = await fetch(url);
    const result = await response.json();
    const modifiedLibraryItems = result?.libraryItems?.length
      ? result?.libraryItems.map((libraryItem: LibraryType) => {
          return { ...libraryItem, isSelected: false };
        })
      : [];
    setStoreDataconvertedUrlToJson(modifiedLibraryItems);
  };

  return (
    <div className="w-100 h-100">
      <div style={{ height: "35%" }}>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="preview-title">{templateData?.title}</h1>
          <span className="mb-0 d-flex gap-2">
            Published on :{" "}
            {moment.utc(templateData.createdAt).local().format("YYYY-MM-DD")}
          </span>
        </div>
        <p
          className="mt-3"
          style={{
            height: isMobileView ? "30%" : showMore ? "40%" : "",
            overflow: "auto",
          }}
        >
          {showMore
            ? templateData?.description
            : `${templateData?.description.substring(
                0,
                isMobileView ? 25 : 180,
              )}`}
          {templateData?.description.length > 180 && (
            <button
              className="show_more_button"
              style={{ color: "#585A96", marginLeft: "6px" }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show less" : "Show more"}
            </button>
          )}
        </p>
        <button
          className="use-template-btn"
          onClick={() => {
            loadElementsFromDB(templateData.source);
          }}
        >
          {t("previewDialog.useTemplate")}
        </button>
      </div>
      <hr />
      <div className="row" style={{ height: "55%" }}>
        <div className="col-lg-7 col-12 mb-3">
          <div className="h-100 w-100 d-flex flex-column ">
            <div className="export-button position-relative">
              <h5 className="preview-title m-0">
                {t("previewDialog.preview")}
              </h5>
              {getSelectedItems.length ? (
                <>
                  <span
                    className="p-2 border border-light rounded-circle selectedCatagoryLibraryatab position-absolute"
                    style={{ backgroundColor: "green", zIndex: 20 }}
                  >
                    {getSelectedItems?.length}
                  </span>
                  <button
                    className="cursor-pointer position-relative exportButton"
                    style={{ background: "#585a96", color: "white" }}
                    onClick={async () => {
                      if (selectedTemplates?.length) {
                        window.parent.postMessage(
                          { type: "STORE_ELEMENT", isLoading: true },
                          `${process.env.REACT_APP_PARENT_APP}`,
                        );
                        const lesson = JSON.parse(
                          localStorage.getItem("lesson") || "{}",
                        );
                        const combinedElements = selectedTemplates;
                        const lessonId = lesson.Title
                          ? lesson.Title
                          : lesson?.LessonId;
                        if (combinedElements) {
                          combinedElements.map(
                            (element: any) =>
                              (element.lessonId = lessonId
                                ? lessonId
                                : element.lessonId),
                          );

                          return onInsertShape(combinedElements as any);
                        }
                      }
                    }}
                  >
                    <img
                      src={exportIcon}
                      alt="exportIcon"
                      style={{
                        width: "13px",
                        height: "13px",
                        marginLeft: "2px",
                      }}
                    />
                    {t("cards.importOnCanvas")}
                  </button>
                </>
              ) : null}
            </div>
            <div className="acv-previewDialog ms-2 flex-grow-1">
              <div
                className="dialogBox-div h-100 w-100"
                id="preview-dialog-fullscreen"
                style={{ background: isFullscreen ? "whitesmoke" : "" }}
              >
                <div
                  className={"preview-dialog__content position-relative"}
                  style={{
                    height: isFullscreen ? "100%" : "80%",
                    marginTop: isFullscreen ? "3%" : 0,
                  }}
                >
                  <input
                    className="cursor-pointer checkbox_exportCanvas"
                    type="checkbox"
                    checked={
                      storeDataconvertedUrlToJson[index]
                        ? storeDataconvertedUrlToJson[
                            index
                          ]?.elements?.some((e: any) =>
                            selectedTemplates.some(
                              (element: any) => e.id === element.id,
                            ),
                          )
                        : false
                    }
                    onChange={() => {
                      handleSelectCategory(
                        (storeDataconvertedUrlToJson[
                          index
                        ] as unknown) as string[],
                      );
                    }}
                  />
                  {Array.isArray(templateData.preview) &&
                  templateData.preview.length > 0 ? (
                    templateData.preview.length === 1 ? (
                      <CollectionImagePreview
                        previewURL={templateData.preview[0]}
                        className={"collection-card-img"}
                        index={Math.random()}
                        zoomLevel={zoomLevel}
                      />
                    ) : (
                      <LibraryCarousel
                        images={(templateData.preview as unknown) as string[]}
                        name={templateData.name}
                        index={1}
                        zoomLevel={zoomLevel}
                        className="collection-card-img"
                        isFullscreen={isFullscreen}
                        setIndex={setIndex}
                      />
                    )
                  ) : (
                    <CollectionImagePreview
                      previewURL={templateData.preview}
                      className={"collection-card-img"}
                      index={Math.random()}
                      zoomLevel={zoomLevel}
                    />
                  )}
                </div>
                <div
                  className={"preview-dialog-icons"}
                  style={{
                    height: isFullscreen ? "" : "20%",
                    background: isFullscreen ? "whitesmoke" : "",
                    top: isFullscreen ? "0%" : "",
                    position: isFullscreen ? "absolute" : "relative",
                    width: isFullscreen ? "100%" : "",
                  }}
                >
                  <div className="embed-bottom__info d-flex gap-2 justify-content-between align-items-center mt-2 px-2">
                    <div className="d-flex gap-2">
                      <div className="embed-bottom__logo" role="img"></div>
                      <div className="embed-bottom__header">
                        <div className="embed-bottom__board-title">
                          <a href="/" target="_blank">
                            {templateData.name}
                          </a>
                        </div>
                        <div className="embed-bottom__powered-by">acv.app</div>
                      </div>
                    </div>
                    <div className="icons-div zoomIn-Out-icon d-flex cursor-pointer">
                      <div className="icon" onClick={handleZoomIn}>
                        {zoomIn}
                      </div>
                      <div className="icon" onClick={handleZoomOut}>
                        {zoomOut}
                      </div>
                      <div
                        className="icon"
                        onClick={
                          isFullscreen ? closeFullScreen : openFullScreen
                        }
                      >
                        {isFullscreen ? expand() : compress()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-12">
          <h5 className="preview-title">{t("previewDialog.tags")}</h5>
          <div className="d-flex flex-wrap gap-2">
            {templateData?.tags?.length ? (
              templateData.tags.map((data, index) => (
                <div className="preview-badge" style={{ width: "fit-content" }}>
                  {data}
                </div>
              ))
            ) : (
              <>
                <p className="text-center">
                  {t("previewDialog.noTagsAvalible")}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
