import _ from "lodash";
import OpenColor from "open-color";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../../../../../App/shareComponent/Loader";
import { MIME_TYPES } from "../../../../../../constants";
import { ExcalidrawElement } from "../../../../../../element/types";
import {
  fetchFilteredCollectionData,
  fetchMyPublishedCollectionAPI,
  userActionCollectionAPI,
  withdrawCollectionApprovalRequestAPI,
} from "../../../../../../excalidraw-app/api/collection";
import { getUserInfo } from "../../../../../../excalidraw-app/api/getuserInfo";
import { t } from "../../../../../../i18n";
import { exportToSvg } from "../../../../../../scene/export";
import {
  BinaryFiles,
  CollocationType,
  LibraryFileType,
  LibraryType,
} from "../../../../../../types";
import { Toast, ToastType } from "../../../../../Toast";
import { CardWithImage } from "../../../../components/card";
import { LibraryTabsEnum } from "../../../../LibraryItemsDialog";
import { FilterRef, FilteredValueType } from "../../CollectionsTab";
import { TemplateCollectionProps } from "../template";

export const MyCollection = forwardRef<FilterRef, TemplateCollectionProps>(
  (
    {
      searchVal,
      setFullScreenCollectionPreview,
      setPreviewTemplateData,
      selectedSortBy,
      setAppState,
      appState,
      editCollection,
      setSelectedSection,
    },
    ref,
  ) => {
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [sort] = useState(1);
    const [search, setSearch] = useState("");
    const [isFilteredApplied, setIsFilteredAplied] = useState(false);
    const [selectedFilteredValues, setSelectedFilteredValues] = useState<
      Pick<FilteredValueType, "age" | "author" | "tags">
    >();

    const [filteredMyCollections, setFilteredMyCollections] = useState<
      CollocationType[]
    >([]);
    const [toastMessage, setToastMessage] = useState<{
      message: string;
      type: ToastType;
    } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const fetchMyPublishedCollections = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();
        const apiBody: {
          userEmail: string;
          sort: number;
          offset: number;
        } = {
          userEmail: user.mail,
          sort: 1,
          offset: page,
        };

        const { result } = await fetchMyPublishedCollectionAPI(apiBody);
        setTotalCount(result.totalCount);
        setFilteredMyCollections(result.collections);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchMyPublishedCollections();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const onSortCollection = async (index: number) => {
        setIsLoading(true);
        try {
          const user = await getUserInfo();
          const apiBody = {
            userEmail: user.mail,
            sort: index,
            offset: 0,
          };

          //index 1: newest, 2: most like, 3: title(a-z), 4: author(a-z)
          const { result } = await fetchMyPublishedCollectionAPI(apiBody);
          setTotalCount(result.totalCount);
          setFilteredMyCollections([...result.collections]);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      onSortCollection(selectedSortBy ? selectedSortBy : 0);
    }, [selectedSortBy]);

    const debouncedSearch = useCallback(
      _.debounce(async (searchVal) => {
        setIsLoading(true);
        const { result } = await fetchMyPublishedCollectionAPI({
          search: searchVal,
          sort: sort,
          offset: 0,
          userEmail: userMail,
        });
        setPage(0);
        setTotalCount(result.totalCount);
        setIsLoading(false);
        setFilteredMyCollections([...result.collections]);
      }, 500),
      [],
    );

    useEffect(() => {
      debouncedSearch(searchVal);
      setSearch(searchVal);
    }, [searchVal, debouncedSearch]);

    const fetchFilteredData = async (filterDataWithKeys: FilteredValueType) => {
      try {
        setIsLoading(true);
        setPage(0);

        const user = await getUserInfo();

        const { result } = await fetchFilteredCollectionData({
          ...filterDataWithKeys,
          userId: user.mail,
          myPublished: true,
          search,
          offset: 0,
        });
        setTotalCount(result.totalCount);
        setFilteredMyCollections([...result.collections]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    const removeFilters = async () => {
      try {
        setIsLoading(true);
        const user = await getUserInfo();
        const apiBody: {
          userEmail: string;
          sort: number;
          offset: number;
        } = {
          userEmail: user.mail,
          sort: 1,
          offset: page,
        };
        const { result } = await fetchMyPublishedCollectionAPI(apiBody);

        setFilteredMyCollections(result.collections);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      onApply: fetchFilteredData,
      onClear: removeFilters,
      onSetIsFilteredAplied: setIsFilteredAplied,
      onSetSelectedFilteredValues: setSelectedFilteredValues,
    }));

    const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;

    const onLikeCollection = async (templateId: string) => {
      const res = await userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "LIKE",
      });
      const collectionActivity = res.result;
      const templateIndex = filteredMyCollections.findIndex(
        (template) => template.id === templateId,
      );

      //update filteredMyCollections

      const data = [...filteredMyCollections];
      data[templateIndex].CollectionActivities = [collectionActivity];
      if (data[templateIndex].CollectionActivities?.[0]?.isLiked) {
        data[templateIndex].likeCount += 1;
      } else {
        data[templateIndex].likeCount -= 1;
      }
      setFilteredMyCollections([...data]);
    };

    const onBookmarkCollection = async (templateId: string) => {
      const userMail = JSON.parse(localStorage.getItem("user") || "{}").mail;
      const res = await userActionCollectionAPI({
        collectionId: templateId,
        userEmail: userMail,
        userAction: "BOOKMARK",
      });
      const collectionActivity = res.result;
      const templateIndex = filteredMyCollections.findIndex(
        (template) => template.id === templateId,
      );

      const data = [...filteredMyCollections];

      data[templateIndex].CollectionActivities = [collectionActivity];

      setFilteredMyCollections([...data]);
    };

    const fetchMoreCollections = async () => {
      const user = await getUserInfo();
      const pageCount = Number(page) + 1;
      if (isFilteredApplied) {
        const { result } = await fetchFilteredCollectionData({
          ...(selectedFilteredValues as any),
          search,
          offset: pageCount,
          userId: user.mail,
          myPublished: true,
        });
        setTotalCount(result.totalCount);
        setFilteredMyCollections([
          ...filteredMyCollections,
          ...result.collections,
        ]);
      } else {
        const { result } = await fetchMyPublishedCollectionAPI({
          userEmail: user.mail,
          search,
          sort,
          offset: pageCount,
        });
        setTotalCount(result.totalCount);
        setFilteredMyCollections([
          ...filteredMyCollections,
          ...result.collections,
        ]);
      }
      setPage(pageCount);
    };

    const getFilesByLibraryElements = (
      elements: ExcalidrawElement[],
      templateInfo?: CollocationType,
    ) => {
      const files: BinaryFiles = {};

      const hasRequiredType = (element: ExcalidrawElement) =>
        element.type === "image" ||
        element.type === "formula" ||
        element.type === "mermaidDiagram" ||
        element.type === "video" ||
        element.type === "audio" ||
        element.type === "textWithStyles";

      for (const element of elements) {
        if (hasRequiredType(element) && element.fileId !== null) {
          const fileFromAppState = appState.files?.[element.fileId];
          if (fileFromAppState) {
            files[element.fileId] = fileFromAppState;
          } else if (templateInfo?.files) {
            const matchingFileIndex = templateInfo.files.findIndex(
              (file: LibraryFileType) => file.id === element.fileId,
            );
            if (matchingFileIndex !== -1) {
              const elementFile = templateInfo.files[
                matchingFileIndex
              ] as LibraryFileType;
              files[elementFile.id] = {
                mimeType: elementFile.mimeType || MIME_TYPES.binary,
                id: elementFile.id,
                dataURL: elementFile.url as any,
                created: elementFile.created,
                isPublished: true,
              };
            }
          }
        }
      }

      return files;
    };

    const generateSvgForLibrary = async (
      libraryItems: LibraryType[],
      item: CollocationType,
    ) => {
      let files_: BinaryFiles = {};
      await Promise.all(
        libraryItems.map(async (libraryItem: LibraryType) => {
          const files = getFilesByLibraryElements(libraryItem.elements, item);
          files_ = {
            ...files_,
            ...files,
          };
          const svg = libraryItem?.elements?.length
            ? await exportToSvg(
                libraryItem.elements,
                {
                  exportBackground: false,
                  viewBackgroundColor: OpenColor.white,
                  frameRendering: appState.frameRendering,
                },
                files,
              )
            : null;
          return {
            ...libraryItem,
            svg,
          };
        }),
      );

      setAppState({
        files: {
          ...appState.files,
          ...files_,
        },
      });
    };

    const onEdit = async (item: CollocationType) => {
      const response = await fetch(item.source);
      const result = await response.json();

      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryId: appState.editingLibrary.libraryInfo.id,
          isEditing: true,
          libraryInfo: item,
          libraryItems: result?.libraryItems[0]?.elements,
          oldElements: [],
        },
      });
      const modifiedLibraryItems = result?.libraryItems?.length
        ? result?.libraryItems.map((libraryItem: LibraryType) => {
            return { ...libraryItem, isSelected: false };
          })
        : [];

      editCollection(modifiedLibraryItems);
      generateSvgForLibrary(modifiedLibraryItems, item);
      setSelectedSection(LibraryTabsEnum.MyLibrary);
    };

    return (
      <div
        className="row overflow-auto myPublishedCollection"
        style={{ height: "calc(100% - 28%)" }}
      >
        {isLoading ? (
          <Loader className="h-100" />
        ) : (
          <InfiniteScroll
            hasMore={totalCount > filteredMyCollections.length}
            initialLoad={false}
            loadMore={fetchMoreCollections}
            useWindow={false}
            getScrollParent={() =>
              document.querySelector(".myPublishedCollection")
            }
            className="row"
            loader="Loading..."
          >
            {filteredMyCollections.map((item: any, index: number) => {
              const isUserTemplate = item.userEmail === userMail;
              return (
                <CardWithImage
                  key={index}
                  created={item.createdAt}
                  images={item.preview}
                  name={item.name}
                  userProfile={
                    item.author?.url
                      ? item.author?.url +
                        process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
                      : ""
                  }
                  userName={item.author?.name}
                  likes={item.likeCount}
                  isLikefill={item.CollectionActivities[0]?.isLiked}
                  onLike={async () => await onLikeCollection(item.id)}
                  isBookMarkFill={item.CollectionActivities[0]?.isBookmarked}
                  onBookmark={async () => {
                    await onBookmarkCollection(item.id);
                  }}
                  setFullScreenCollectionPreview={
                    setFullScreenCollectionPreview
                  }
                  onPreview={() => {
                    setPreviewTemplateData(item);
                  }}
                  buttonText={t("cards.unPublish")}
                  onAddtoCollectionBtnClick={async () => {
                    await withdrawCollectionApprovalRequestAPI(item.id);
                    setFilteredMyCollections(
                      filteredMyCollections.filter(
                        (collection) => collection.id !== item.id,
                      ),
                    );

                    setToastMessage({
                      message: "Successfully unpublished collection.",
                      type: ToastType.ERROR,
                    });
                    setTotalCount(totalCount - 1);
                  }}
                  isHoverUseThisTemplateBtn={false}
                  isMove={true}
                  id={item.id}
                  isEdit={isUserTemplate}
                  onEdit={() => {
                    isUserTemplate && onEdit(item);
                    localStorage.removeItem("selectedElement");
                  }}
                  onAfterMovedToCategory={(category: string[]) => {
                    if (category.length) {
                      const addCategory = category?.filter(
                        (category: string) => category !== item.id,
                      );
                      const index = filteredMyCollections.findIndex(
                        (data) => data.id === item.id,
                      );
                      if (addCategory) {
                        const data = [...filteredMyCollections];

                        data[index].category = [...addCategory];

                        setFilteredMyCollections([...data]);
                      }
                    }
                  }}
                  setToastMessage={setToastMessage}
                  onAfterDeleteCategory={(id) => {
                    if (item.id !== id) {
                      const updatedCategory = item.category?.filter(
                        (category: string) => category !== id,
                      );
                      const index = filteredMyCollections.findIndex(
                        (data) => data.id === item.id,
                      );
                      if (updatedCategory) {
                        const data = [...filteredMyCollections];

                        data[index].category = [...updatedCategory];

                        setFilteredMyCollections([...data]);
                      }
                    }
                  }}
                  templateCategory={item.category}
                />
              );
            })}
          </InfiniteScroll>
        )}
        {toastMessage !== null && (
          <Toast
            type={toastMessage.type}
            message={toastMessage.message}
            clearToast={() => setToastMessage(null)}
            className="style"
          />
        )}
      </div>
    );
  },
);
