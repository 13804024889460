import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { ActionManager } from "../actions/manager";
import { trackEvent } from "../analytics";
import { useIsMobile } from "../components/App";
import { FRAME_OPTIONS, FRAME_STYLE } from "../constants";
import { getNonDeletedElements } from "../element";
import { newFrameElement } from "../element/newElement";
import {
  ExcalidrawElement,
  ExcalidrawElementType,
  PointerType,
} from "../element/types";
import { t } from "../i18n";
import {
  canChangeSharpness,
  canHaveArrowheads,
  getNormalizedZoom,
  getTargetElements,
  hasBackground,
  hasStrokeStyle,
  hasStrokeWidth,
  hasText,
} from "../scene";
import { hasStrokeColor } from "../scene/comparisons";
import { SHAPES, SHAPES_VIEWMODE, WORKSPACE_SHAPES } from "../shapes";
import { AppClassProperties, AppState, UIAppState, Zoom } from "../types";
import { capitalizeString, isTransparent, setCursorForShape } from "../utils";
import { Popover } from "./Popover";
import Stack from "./Stack";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import {
  celebrationToolIcon,
  extraToolsIcon,
  frameToolIcon,
  laserPointerToolIcon,
  presentationToolIcon,
  stockIcon,
  widgets,
} from "./icons";

export const canChangeStrokeColor = (
  appState: UIAppState,
  targetElements: ExcalidrawElement[],
) => {
  let commonSelectedType: ExcalidrawElementType | null =
    targetElements[0]?.type || null;

  for (const element of targetElements) {
    if (element.type !== commonSelectedType) {
      commonSelectedType = null;
      break;
    }
  }

  return (
    (hasStrokeColor(appState.activeTool.type) &&
      appState.activeTool.type !== "image" &&
      commonSelectedType !== "image" &&
      commonSelectedType !== "frame" &&
      commonSelectedType !== "magicframe") ||
    targetElements.some((element) => hasStrokeColor(element.type))
  );
};

export const SelectedShapeActions = ({
  appState,
  elements,
  renderAction,
}: {
  appState: AppState;
  elements: readonly ExcalidrawElement[];
  renderAction: ActionManager["renderAction"];
}) => {
  const targetElements = getTargetElements(
    getNonDeletedElements(elements),
    appState,
  );
  const isEditing = Boolean(appState.editingElement);
  const isMobile = useIsMobile();
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  const showFillIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some(
      (element) =>
        hasBackground(element.type) && !isTransparent(element.backgroundColor),
    );
  const showChangeBackgroundIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some((element) => hasBackground(element.type));

  return (
    <div className="panelColumn">
      <div>
        {canChangeStrokeColor(appState, targetElements) &&
          renderAction("changeStrokeColor")}
      </div>
      {showChangeBackgroundIcons && renderAction("changeBackgroundColor")}
      {showFillIcons && renderAction("changeFillStyle")}

      {(hasStrokeWidth(appState.activeTool.type) ||
        targetElements.some((element) => hasStrokeWidth(element.type))) &&
        renderAction("changeStrokeWidth")}

      {(appState.activeTool.type === "freedraw" ||
        targetElements.some((element) => element.type === "freedraw")) &&
        renderAction("changeStrokeShape")}

      {(hasStrokeStyle(appState.activeTool.type) ||
        targetElements.some((element) => hasStrokeStyle(element.type))) && (
        <>
          {renderAction("changeStrokeStyle")}
          {renderAction("changeSloppiness")}
        </>
      )}

      {(canChangeSharpness(appState.activeTool.type) ||
        targetElements.some((element) => canChangeSharpness(element.type))) && (
        <>{renderAction("changeSharpness")}</>
      )}

      {(hasText(appState.activeTool.type) ||
        targetElements.some((element) => hasText(element.type))) && (
        <>
          {renderAction("changeFontSize")}

          {renderAction("changeFontFamily")}

          {renderAction("changeTextAlign")}

          {renderAction("changeFontWeight")}
        </>
      )}

      {(canHaveArrowheads(appState.activeTool.type) ||
        targetElements.some((element) => canHaveArrowheads(element.type))) && (
        <>{renderAction("changeArrowhead")}</>
      )}

      {renderAction("changeOpacity")}

      <fieldset>
        <legend>{t("labels.layers")}</legend>
        <div className="buttonList">
          {renderAction("sendToBack")}
          {renderAction("sendBackward")}
          {renderAction("bringToFront")}
          {renderAction("bringForward")}
        </div>
      </fieldset>

      {targetElements.length > 1 && (
        <fieldset>
          <legend>{t("labels.align")}</legend>
          <div className="buttonList">
            {
              // swap this order for RTL so the button positions always match their action
              // (i.e. the leftmost button aligns left)
            }
            {isRTL ? (
              <>
                {renderAction("alignRight")}
                {renderAction("alignHorizontallyCentered")}
                {renderAction("alignLeft")}
              </>
            ) : (
              <>
                {renderAction("alignLeft")}
                {renderAction("alignHorizontallyCentered")}
                {renderAction("alignRight")}
              </>
            )}
            {targetElements.length > 2 &&
              renderAction("distributeHorizontally")}
            <div className="iconRow">
              {renderAction("alignTop")}
              {renderAction("alignVerticallyCentered")}
              {renderAction("alignBottom")}
              {targetElements.length > 2 &&
                renderAction("distributeVertically")}
            </div>
          </div>
        </fieldset>
      )}
      {!isMobile && !isEditing && targetElements.length > 0 && (
        <fieldset>
          <legend>{t("labels.actions")}</legend>
          <div className="buttonList">
            {renderAction("duplicateSelection")}
            {renderAction("deleteSelectedElements")}
            {renderAction("group")}
            {renderAction("ungroup")}
          </div>
        </fieldset>
      )}
    </div>
  );
};

const getAspectRatio = (ratio: string) => {
  /*
width = height (default 1080) * width-ratio / height-ratio
*/
  switch (ratio) {
    case "1:1":
      return { height: 1080, width: 1080 };
    case "4:3":
      return { height: 1080, width: 1440 };
    case "16:9":
      return { height: 1080, width: 1920 };
    case "2:1":
      return { height: 1080, width: 2160 };
    default:
      return { height: 1080, width: 1920 };
  }
};

interface MenuItemProps {
  icon: React.ReactNode;
  label: string;
  shortcut?: string;
  onClick?: () => void;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  shortcut,
  onClick,
  disabled,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <li className="d-flex" onClick={onClick}>
      <button
        className={clsx("context-menu-option")}
        style={{ display: "inline-block" }}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="align-items-center d-flex justify-content-between">
          <div style={{ height: 20, width: 20 }}>
            {icon}
            <span className="ps-1">{label}</span>
          </div>
          <div
            className="px-2 bg-blue-500 rounded"
            style={{ background: "#E0E0E0", fontSize: 12 }}
          >
            {shortcut}
          </div>
        </div>
      </button>
    </li>
  );
};

export default MenuItem;

export const ShapesSwitcher = ({
  canvas,
  activeTool,
  setAppState,
  onImageAction,
  togglekeyboardShortcut,
  toggleZoomInZoomOut,
  appState,
  isStudent,
  isJoinCollaboration,
  app,
  celebration,
  setCelebration,
}: {
  canvas: React.RefObject<HTMLCanvasElement>;
  activeTool: UIAppState["activeTool"];
  setAppState: React.Component<any, AppState>["setState"];
  onImageAction: (data: { pointerType: PointerType | null }) => void;
  togglekeyboardShortcut: (isAdding: boolean) => void;
  toggleZoomInZoomOut: (isZoom: boolean) => void;
  appState: AppState;
  isStudent: boolean;
  isJoinCollaboration: boolean;
  app: AppClassProperties;
  celebration: boolean;
  setCelebration: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isExtraToolsMenuOpen, setIsExtraToolsMenuOpen] = useState(false);
  const [frameOptions, setFrameOptions] = useState(FRAME_OPTIONS.custom);
  const [openFrameOptions, setOpenFrameOptions] = useState(false);

  const frameToolSelected = appState.activeTool?.type === "frame";
  const laserToolSelected = appState.activeTool?.type === "laser";
  const embeddableToolSelected = appState.activeTool?.type === "embeddable";

  useEffect(() => {
    setFrameOptions(FRAME_OPTIONS.custom);
  }, []);

  const getZoomOutIcon = appState?.isFullScreen && (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="expand">
      <path
        fill="currentColor"
        d="M9 20H5a1 1 0 0 1-1-1V15a1 1 0 0 1 2 0v3H9a1 1 0 0 1 0 2zM14 11a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 14 11z"
      ></path>
      <path
        fill="currentColor"
        d="M19 10a1 1 0 0 1-1-1V6H15a1 1 0 0 1 0-2h4a1 1 0 0 1 1 1V9A1 1 0 0 1 19 10zM5 20a1 1 0 0 1-.71-1.71l5-5a1 1 0 0 1 1.41 1.41l-5 5A1 1 0 0 1 5 20z"
      ></path>
    </svg>
  );
  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") === "true";

  const popoverRef = useRef<HTMLDivElement>();

  const handleMouseEnter = () => {
    setOpenFrameOptions(true);
  };

  const handleMouseLeave = () => {
    // Only close if the mouse is not inside the popover
    if (
      popoverRef.current &&
      !popoverRef.current.contains(document.activeElement)
    ) {
      setOpenFrameOptions(false);
    }
  };

  const handleClickInsidePopover = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the click event from closing the popover
  };

  const handleFrameOptionClick = (option: string) => {
    if (option === FRAME_OPTIONS.custom) {
      app.setActiveTool({ type: "frame" });
    } else {
      setAppState({
        isDragAndDrop: true,
        isOutlineOpen: true,
      });
      const lessonId =
        new URLSearchParams(window.location.search)
          .get("lessonId")
          ?.replace(/\//g, "") || "";
      app.canvas?.getBoundingClientRect(); // Optional for accurate dimensions

      const { width, height } = getAspectRatio(option as any);
      setAppState({
        zoom: {
          ...appState.zoom,
          value: getNormalizedZoom(0.5),
        },
      });

      const alreadyElementonCanvas = app.scene.getElements();
      let newX = 50; // 50 is for gap between 2 elements
      let newY = appState.height / 2;

      if (alreadyElementonCanvas.length) {
        const maxCanvasX = alreadyElementonCanvas.reduce((maxX, element) => {
          return Math.max(maxX, element.x + element.width);
        }, 0);
        newX += maxCanvasX;
      } else {
        newX = appState.width / 3.5;
        newY = appState.height / 2;
      }

      const constructorOpts = {
        x: newX,
        y: newY,
        opacity: appState.currentItemOpacity,
        locked: false,
        lessonId,
        page: appState.currentPage,
        width,
        height,
        ...FRAME_STYLE,
      } as const;

      const frame = newFrameElement(constructorOpts as any);
      app.scene.insertElement(frame);
      app.scrollToContent(frame, {
        fitToViewport: true,
      });
    }

    setOpenFrameOptions(false);
  };

  return (
    <>
      {(!isStudent && !appState.viewModeEnabled) || isJoinCollaboration
        ? isMyWorkSpace
          ? WORKSPACE_SHAPES.map(({ value, icon, key }, index) => {
              const label = t(`whiteBoard.toolBar.${value}`);
              const letter = key && (typeof key === "string" ? key : key[0]);
              const shortcut = letter
                ? `${capitalizeString(letter)} ${t("helpDialog.or")} ${
                    index + 1
                  }`
                : `${index + 1}`;

              const _icon = icon;

              return (
                <ToolButton
                  className={"Shape"}
                  key={value}
                  type={ToolButtonEnum.RADIO}
                  icon={_icon}
                  checked={
                    activeTool.type ===
                    (value as UIAppState["activeTool"]["type"])
                  }
                  name="editor-current-shape"
                  title={`${capitalizeString(label)} — ${shortcut}`}
                  keyBindingLabel={`${index + 1}`}
                  aria-label={capitalizeString(label)}
                  aria-keyshortcuts={shortcut}
                  data-testid={value}
                  onChange={({ pointerType }) => {
                    if (appState.activeTool.type !== value) {
                      trackEvent("toolbar", value, "ui");
                    }
                    if (value === "image") {
                      app.setActiveTool({
                        type: value,
                        insertOnCanvasDirectly: pointerType !== "mouse",
                      });
                    } else {
                      if (appState.activeTool.type !== value) {
                        trackEvent("toolbar", value, "ui");
                      }
                      if (value === "text") {
                        togglekeyboardShortcut(false);
                      }
                      if (value === "formula") {
                        setAppState({ showMathInputAndKeyboard: true });
                        togglekeyboardShortcut(false);
                      }
                      app.setActiveTool({ type: value });
                    }

                    setCursorForShape(canvas as any, appState);
                  }}
                />
              );
            })
          : SHAPES.map(({ value, icon, key }, index) => {
              const label = t(`whiteBoard.toolBar.${value}`);
              const letter = key && (typeof key === "string" ? key : key[0]);
              const shortcut = letter
                ? `${capitalizeString(letter)} ${t("helpDialog.or")} ${
                    index + 1
                  }`
                : `${index + 1}`;

              const _icon =
                value === "compress" && appState?.isFullScreen
                  ? getZoomOutIcon
                  : icon;

              return (
                <ToolButton
                  className={"Shape"}
                  key={value}
                  type={ToolButtonEnum.RADIO}
                  icon={_icon}
                  checked={
                    activeTool.type ===
                    (value as UIAppState["activeTool"]["type"])
                  }
                  name="editor-current-shape"
                  title={`${capitalizeString(label)} — ${shortcut}`}
                  keyBindingLabel={`${index + 1}`}
                  aria-label={capitalizeString(label)}
                  aria-keyshortcuts={shortcut}
                  data-testid={value}
                  onChange={({ pointerType }) => {
                    if (appState.activeTool.type !== value) {
                      trackEvent("toolbar", value, "ui");
                    }
                    if (value === "image") {
                      app.setActiveTool({
                        type: value,
                        insertOnCanvasDirectly: pointerType !== "mouse",
                      });
                    } else {
                      if (appState.activeTool.type !== value) {
                        trackEvent("toolbar", value, "ui");
                      }
                      if (value === "text") {
                        togglekeyboardShortcut(false);
                      }
                      if (value === "formula") {
                        setAppState({ showMathInputAndKeyboard: true });
                        togglekeyboardShortcut(false);
                      }
                      if (value === "compress") {
                        toggleZoomInZoomOut(!appState.isFullScreen);
                        setAppState({ isFullScreen: !appState.isFullScreen });
                      }
                      app.setActiveTool({ type: value as any });
                    }
                  }}
                />
              );
            })
        : SHAPES_VIEWMODE.map(({ value, icon, key }, index) => {
            const label = t(`whiteBoard.toolBar.${value}`);
            const letter = key && (typeof key === "string" ? key : key[0]);
            const shortcut = letter
              ? `${capitalizeString(letter)} ${t("helpDialog.or")} ${index + 1}`
              : `${index + 1}`;

            const _icon =
              value === "compress" && appState?.isFullScreen
                ? getZoomOutIcon
                : icon;

            return (
              <ToolButton
                className={"Shapes"}
                key={value}
                type={ToolButtonEnum.RADIO}
                icon={_icon}
                checked={
                  activeTool.type ===
                  (value as UIAppState["activeTool"]["type"])
                }
                name="editor-current-shape"
                title={`${capitalizeString(label)} — ${shortcut}`}
                keyBindingLabel={`${index + 1}`}
                aria-label={capitalizeString(label)}
                aria-keyshortcuts={shortcut}
                data-testid={value}
                onChange={({ pointerType }) => {
                  if (value === "compress") {
                    toggleZoomInZoomOut(!appState.isFullScreen);
                    setAppState({ isFullScreen: !appState.isFullScreen });
                  }
                }}
              />
            );
          })}
      <div className="App-toolbar__divider" />
      <div
        className={clsx(
          "cursor-pointer rounded App-toolbar__extra-tools-trigger position-relative d-flex align-items-center ",
          {
            "App-toolbar__extra-tools-trigger--selected":
              frameToolSelected ||
              embeddableToolSelected ||
              // in collab we're already highlighting the laser button
              // outside toolbar, so let's not highlight extra-tools button
              // on top of it
              (laserToolSelected && !appState.isCollaborating),
          },
        )}
        onClick={() => setIsExtraToolsMenuOpen(!isExtraToolsMenuOpen)}
      >
        <button
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          title={"toolBar.extraTools"}
        >
          <div
            style={{
              width: 25,
            }}
          >
            {extraToolsIcon(appState.theme == "light" ? "black" : "white")}
          </div>
        </button>

        {isExtraToolsMenuOpen && (
          <Popover
            top="100%"
            style={{
              transform: "translate(-50%, 0%)",
            }}
            onCloseRequest={() => setIsExtraToolsMenuOpen(false)}
          >
            <div style={{ width: 200 }}>
              <div className="py-2" onContextMenu={(e) => e.preventDefault()}>
                <MenuItem
                  icon={laserPointerToolIcon()}
                  label="Laser"
                  shortcut="K"
                  onClick={() => {
                    app.setActiveTool({ type: "laser" });
                    setIsExtraToolsMenuOpen(false);
                  }}
                />
                <div className="position-relative">
                  <MenuItem
                    icon={frameToolIcon}
                    label="Frame tool"
                    shortcut="F"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  {openFrameOptions && (
                    <Popover ref={popoverRef}>
                      <div
                        className="d-flex gap-2 px-3 py-1"
                        onClick={handleClickInsidePopover}
                      >
                        {Object.values(FRAME_OPTIONS).map((option) => (
                          <button
                            key={option}
                            className={clsx(
                              `border rounded ${
                                option === frameOptions && "selected"
                              }`,
                            )}
                            style={{ width: 100 }}
                            onClick={() => handleFrameOptionClick(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </Popover>
                  )}
                </div>
                <MenuItem
                  icon={celebrationToolIcon()}
                  label="Celebrate"
                  // shortcut="F"
                  onClick={() => {
                    setCelebration(true);
                    setTimeout(() => setCelebration(false), 5000); // Reset trigger after 5 seconds
                  }}
                  disabled={celebration}
                />
                <MenuItem
                  icon={presentationToolIcon}
                  label="Presentation mode"
                  // shortcut="F"
                  onClick={() =>
                    setAppState({
                      presentationMode: true,
                      viewModeEnabled: true,
                    })
                  }
                />
                <MenuItem
                  icon={stockIcon("black")}
                  label="Outline"
                  // shortcut="F"
                  onClick={() => {
                    setAppState({
                      isOutlineOpen: true,
                    });
                  }}
                  disabled={celebration}
                />
              </div>
            </div>
          </Popover>
        )}
      </div>
    </>
  );
};

export const ZoomActions = ({
  renderAction,
  zoom,
}: {
  renderAction: ActionManager["renderAction"];
  zoom: Zoom;
}) => (
  <Stack.Col gap={1}>
    <Stack.Row gap={1} align="center">
      {renderAction("zoomToFit")}
      {renderAction("zoomIn")}
      {renderAction("resetZoom")}
      {renderAction("zoomOut")}
    </Stack.Row>
  </Stack.Col>
);
