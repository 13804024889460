import React, { useEffect, useState } from "react";
import { actionSaveFileToDisk } from "../actions/actionExport";
import { ActionsManagerInterface } from "../actions/types";
import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import { AppState, ExportOpts } from "../types";
import { useIsMobile } from "./App";
import { Card } from "./Card";
import { Dialog } from "./Dialog";
import { exportFile, image } from "./icons";
import { ToolButton, ToolButtonEnum } from "./ToolButton";

import { nativeFileSystemSupported } from "../data/filesystem";
import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { getElemetDataFromDatabase } from "../excalidraw-app/api/storeElementData";
import "./ExportDialog.scss";
import SelectMenu from "./SelectMenu";

export type ExportCB = (
  elements: readonly NonDeletedExcalidrawElement[],
  scale?: number,
) => void;

const JSONExportModal = ({
  elements,
  appState,
  actionManager,
  exportOpts,
  canvas,
  setAppState,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  actionManager: ActionsManagerInterface;
  onCloseRequest: () => void;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [selectedPagesTitle, setSelectedPagesTitle] = useState<string>("");
  const [allPages, setAllPages] = useState<any[]>([]);

  const getAllPages = async () => {
    try {
      const lessonId = await getLessonId();
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
      if (isMyWorkSpace === "true") {
        return;
      }
      const allPages = await getElemetDataFromDatabase(lessonId);
      if (allPages.result.length > 0) {
        const pagesIds = [];
        for (let index = 0; index < allPages.result.length; index++) {
          const page = allPages.result[index];
          pagesIds.push(page.page);
        }
        setSelectedPages(pagesIds);
        setAllPages(allPages.result);
        setAppState((state) => ({
          ...state,
          DBElements: allPages.result,
        }));
        localStorage.setItem("ACTIVE_PAGE", allPages.activePage);
      } else {
        setAllPages([]);
      }
    } catch (error) {
      console.error("error:getAllPages", error);
    }
  };

  useEffect(() => {
    getAllPages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    allPages?.length && selectAllPages();
    // eslint-disable-next-line
  }, [allPages]);

  useEffect(() => {
    handleSelectedPagesTitle();
    // eslint-disable-next-line
  }, [selectedPages]);

  const handleSelectedPagesTitle = () => {
    if (selectedPages.length > 0) {
      if (selectedPages.length > 1) {
        setSelectedPagesTitle(`Pages ${selectedPages.join(",")}`);
      } else {
        const findedPage: any = allPages.find(
          (page: any) => page.page === selectedPages[0],
        );
        setSelectedPagesTitle(
          findedPage && findedPage?.pageName
            ? findedPage?.pageName
            : `Page ${selectedPages[0]}`,
        );
      }
    } else {
      setSelectedPagesTitle("");
    }
  };

  const toggleSelectedPages = (checked: boolean, id: string) => {
    let clonedSelectedPages = [...selectedPages];
    if (checked) {
      clonedSelectedPages.push(id);
    } else {
      clonedSelectedPages = clonedSelectedPages.filter((page) => page !== id);
    }
    localStorage.setItem("selectedPages", JSON.stringify(clonedSelectedPages));
    setSelectedPages(clonedSelectedPages);
  };

  const selectAllPages = () => {
    const pagesIds: string[] = [];
    allPages.forEach((page: any) => {
      pagesIds.push(String(page.page));
    });
    localStorage.setItem("selectedPages", JSON.stringify(pagesIds));
    setSelectedPages(pagesIds);
  };

  return (
    <div className="ExportDialog ExportDialog--json">
      <div className="ExportDialog-cards">
        <Card color="lime">
          <div className="Card-icon">{image}</div>
          <h2>{t("whiteBoard.actionBar.export.saveAsImage.title")}</h2>
          <div className="Card-details">
            {t("whiteBoard.actionBar.export.saveAsImage.message")}
            {!nativeFileSystemSupported &&
              actionManager.renderAction("changeProjectName")}
          </div>
          <ToolButton
            className="Card-button"
            type={ToolButtonEnum.BUTTON}
            aria-label={t("whiteBoard.actionBar.export.saveAsImage.title")}
            title={t("buttons.exportImage")}
            showAriaLabel={true}
            onClick={() => {
              document.getElementById("exportAsImage")?.click();
            }}
          />
        </Card>
        {exportOpts.saveFileToDisk && (
          <Card color="lime">
            <div className="Card-icon">{exportFile}</div>
            <h2>{t("whiteBoard.actionBar.export.saveToDisk.title")}</h2>
            <div className="Card-details">
              {t("whiteBoard.actionBar.export.saveToDisk.message")}
              {allPages.length > 1 ? (
                <div className="ProjectName">
                  <label className="ProjectName-label" htmlFor="pagesName">
                    Pages name
                  </label>
                  <div className="multi-page-download" id="pagesName">
                    <SelectMenu title={selectedPagesTitle || "Select Page"}>
                      {allPages.map((page: any, i: number) => {
                        return (
                          <label
                            className="select-item d-flex"
                            htmlFor={page.id}
                            key={page.id}
                          >
                            <input
                              className="select-checkbox text-capitalize"
                              type="checkbox"
                              id={page.id}
                              checked={selectedPages.includes(page.page)}
                              onChange={(e) => {
                                toggleSelectedPages(
                                  e.target.checked,
                                  page.page,
                                );
                              }}
                            />
                            {page.pageName || `Page ${page.page}`}
                          </label>
                        );
                      })}
                    </SelectMenu>
                  </div>
                </div>
              ) : (
                !nativeFileSystemSupported &&
                actionManager.renderAction("changeProjectName")
              )}
            </div>
            <ToolButton
              className="Card-button"
              type={ToolButtonEnum.BUTTON}
              title={t("whiteBoard.actionBar.export.saveToDisk.title")}
              aria-label={t("exportDialog.disk_button")}
              showAriaLabel={true}
              onClick={() => {
                actionManager.executeAction(actionSaveFileToDisk);
              }}
            />
          </Card>
        )}
        {/* {exportOpts.renderCustomUI &&
          exportOpts.renderCustomUI(elements, appState, canvas)} */}
      </div>
    </div>
  );
};

export const JSONExportDialog = ({
  elements,
  appState,
  actionManager,
  exportOpts,
  canvas,
  small,
  setAppState,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  actionManager: ActionsManagerInterface;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement | null;
  small?: boolean;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const [modalIsShown, setModalIsShown] = useState(false);

  const handleClose = React.useCallback(() => {
    setModalIsShown(false);
  }, []);

  return (
    <>
      <ToolButton
        onClick={() => {
          setModalIsShown(true);
        }}
        data-testid="json-export-button"
        icon={exportFile}
        type={ToolButtonEnum.BUTTON}
        aria-label={t("buttons.export")}
        showAriaLabel={useIsMobile()}
        title={t("buttons.export")}
        id="exportData"
      />
      {/* TODO: remove this once we find proper solution */}
      {/* added this because ToolButton not working as expected when whiteboard collaboration is end */}
      <button
        onClick={() => {
          setModalIsShown(true);
        }}
        id="exportData1"
        hidden
      >
        Download
      </button>

      {modalIsShown && (
        <Dialog
          onCloseRequest={handleClose}
          small={small}
          title={t("buttons.export")}
        >
          <JSONExportModal
            elements={elements}
            appState={appState}
            actionManager={actionManager}
            onCloseRequest={handleClose}
            exportOpts={exportOpts}
            canvas={canvas}
            setAppState={setAppState}
          />
        </Dialog>
      )}
    </>
  );
};
