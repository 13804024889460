import oc from "open-color";
import {
  ARROW_TYPE,
  DEFAULT_ELEMENT_PROPS,
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_TEXT_ALIGN,
  EXPORT_SCALES,
  THEME,
} from "./constants";
import { STORAGE_KEYS } from "./excalidraw-app/data/localStorage";
import { t } from "./i18n";
import { AppState, NormalizedZoomValue } from "./types";
import { getDateTime } from "./utils";

const defaultExportScale = EXPORT_SCALES.includes(devicePixelRatio)
  ? devicePixelRatio
  : 1;

export const getDefaultAppState = (): Omit<
  AppState,
  "offsetTop" | "offsetLeft" | "width" | "height"
> => {
  let savedState = null;

  savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);

  const lessonId =
    new URLSearchParams(window.location.search)
      .get("lessonId")
      ?.replace(/\//g, "") || "";

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      } as AppState;
    } catch (error) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }

  const currentPage =
    lessonId === appState?.lessonId ? Number(appState?.currentPage) : 1;

  return {
    theme: THEME.LIGHT,
    collaborators: new Map(),
    currentChartType: "bar",
    currentItemBackgroundColor: "transparent",
    currentItemEndArrowhead: "arrow",
    currentItemFillStyle: "hachure",
    currentItemFontFamily: DEFAULT_FONT_FAMILY,
    currentItemFontSize: DEFAULT_FONT_SIZE,
    currentItemFontWeight: DEFAULT_FONT_WEIGHT,
    currentItemLinearStrokeSharpness: "sharp",
    currentItemOpacity: 100,
    currentItemRoughness: 1,
    currentItemStartArrowhead: null,
    currentItemStrokeColor: oc.black,
    currentItemStrokeSharpness: "sharp",
    currentItemRoundness: "sharp",
    currentItemArrowType: ARROW_TYPE.round,
    currentItemStrokeStyle: "solid",
    currentItemStrokeWidth: 1,
    currentItemTextAlign: DEFAULT_TEXT_ALIGN,
    cursorButton: "up",
    draggingElement: null,
    editingElement: null,
    editingGroupId: null,
    editingLinearElement: null,
    elementLocked: false,
    errorMessage: null,
    exportBackground: true,
    exportScale: defaultExportScale,
    exportEmbedScene: false,
    exportWithDarkMode: false,
    fileHandle: null,
    gridSize: null,
    isBindingEnabled: true,
    isLibraryOpen: false,
    isLoading: false,
    isResizing: false,
    isRotating: false,
    lastPointerDownWith: "mouse",
    multiElement: null,
    name: `${t("labels.untitled")}-${getDateTime()}`,
    openMenu: null,
    openPopup: null,
    pasteDialog: { shown: false, data: null },
    previousSelectedElementIds: {},
    resizingElement: null,
    scrolledOutside: false,
    scrollX: 0,
    scrollY: 0,
    selectedElementIds: {},
    selectedGroupIds: {},
    selectionElement: null,
    shouldCacheIgnoreZoom: false,
    showHelpDialog: false,
    showStats: false,
    startBoundElement: null,
    suggestedBindings: [],
    selectedElements: [],
    toastMessage: null,
    viewBackgroundColor: oc.white,
    zenModeEnabled: false,
    sidebarWrapper: false,
    currentPage,
    zoom: { value: 1 as NormalizedZoomValue, translation: { x: 0, y: 0 } },
    viewModeEnabled: false,
    DBElements: [],
    isFirstLoading: true,
    lessonId,
    isCollaborating: false,
    pageName: null,
    pendingImageElement: null,
    pendingVideoElement: null,
    pendingAudioElement: null,
    S: true,
    isShowLibraryTemplate: false,
    duration: "00:00",
    files: {},
    videoInterval: null,
    audioInterval: null,
    videoCache: new Map(),
    audioCache: new Map(),
    updatingFormulaElement: {
      id: "",
      width: 0,
      height: 0,
    },
    showMathInputAndKeyboard: false,
    openTextEditor: false,
    formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
    isOpenVideoRecorder: false,
    isOpenAudioRecorder: false,
    notesTitle: null,
    notesColor: "#000000",
    isCollaboratingWithFlexibleGroups: false,
    isFlexibleGroup: false,
    isShowNoPagesModel: false,
    whiteboardModel: false,
    confirmImportLibraryModel: false,
    updatedPageDataURL: {
      page: 1,
      lessonId: "",
      dataURL: null,
    },
    textUnderline: false,
    textItalic: false,
    updatingTextElement: null,
    textEditor: {
      open: false,
      sceneX: 0,
      sceneY: 0,
      value: "",
      width: 0,
      height: 0,
      resizeWidth: 0,
      resizeHeight: 0,
      resize: false,
    },
    editableSelectedElement: {
      elementId: "",
      isSelected: false,
      selectedElementIds: {},
    },
    hasRenderedTextWithStyles: false,
    editingLibrary: {
      isEditing: false,
      // @ts-ignores
      libraryInfo: {},
      libraryItems: [],
      //Currently editing library elements
    },
    showPublishLibraryDialog: false,
    showElementOnCanvasOrNot: false,
    pdfPageSelectionDialog: false,
    pdfFile: null,
    isFullScreen: false,
    showHyperlinkPopup: false,
    frameRendering: { enabled: true, clip: true, name: true, outline: true },
    frameToHighlight: null,
    editingFrame: null,
    isResizableOrNot: false,
    defaultLibraryTab: "",
    originSnapOffset: {
      x: 0,
      y: 0,
    },
    EditMermaidDiagramDialog: {
      open: false,
      syntax: "",
      id: null,
      x: 0,
      y: 0,
      fileId: null,
    },
    activeTool: {
      type: "selection",
      customType: null,
      locked: DEFAULT_ELEMENT_PROPS.locked,
      lastActiveTool: null,
    },
    selectedElementsAreBeingDragged: false,
    presentationMode: false,
    isDragAndDrop: false,
    isOutlineOpen: false,
  };
};

/**
 * Config containing all AppState keys. Used to determine whether given state
 *  prop should be stripped when exporting to given storage type.
 */
const APP_STATE_STORAGE_CONF = (<
  Values extends {
    /** whether to keep when storing to browser storage (localStorage/IDB) */
    browser: boolean;
    /** whether to keep when exporting to file/database */
    export: boolean;
  },
  T extends Record<keyof AppState, Values>
>(
  config: { [K in keyof T]: K extends keyof AppState ? T[K] : never },
) => config)({
  theme: { browser: true, export: false },
  collaborators: { browser: false, export: false },
  currentChartType: { browser: true, export: false },
  currentItemBackgroundColor: { browser: true, export: false },
  currentItemEndArrowhead: { browser: true, export: false },
  currentItemFillStyle: { browser: true, export: false },
  currentItemFontFamily: { browser: true, export: false },
  currentItemFontSize: { browser: true, export: false },
  currentItemFontWeight: { browser: true, export: false },
  currentItemLinearStrokeSharpness: { browser: true, export: false },
  currentItemOpacity: { browser: true, export: false },
  currentItemRoughness: { browser: true, export: false },
  currentItemArrowType: {
    browser: true,
    export: false,
  },
  currentItemStartArrowhead: { browser: true, export: false },
  currentItemStrokeColor: { browser: true, export: false },
  currentItemStrokeSharpness: { browser: true, export: false },
  currentItemStrokeStyle: { browser: true, export: false },
  currentItemStrokeWidth: { browser: true, export: false },
  currentItemTextAlign: { browser: true, export: false },
  cursorButton: { browser: true, export: false },
  draggingElement: { browser: false, export: false },
  editingElement: { browser: false, export: false },
  editingGroupId: { browser: true, export: false },
  editingLinearElement: { browser: false, export: false },
  elementLocked: { browser: true, export: false },
  errorMessage: { browser: false, export: false },
  exportBackground: { browser: true, export: false },
  exportEmbedScene: { browser: true, export: false },
  exportScale: { browser: true, export: false },
  exportWithDarkMode: { browser: true, export: false },
  fileHandle: { browser: false, export: false },
  gridSize: { browser: true, export: true },
  height: { browser: false, export: false },
  isBindingEnabled: { browser: false, export: false },
  isLibraryOpen: { browser: false, export: false },
  isLoading: { browser: false, export: false },
  isResizing: { browser: false, export: false },
  isRotating: { browser: false, export: false },
  lastPointerDownWith: { browser: true, export: false },
  multiElement: { browser: false, export: false },
  name: { browser: true, export: false },
  offsetLeft: { browser: false, export: false },
  offsetTop: { browser: false, export: false },
  openMenu: { browser: true, export: false },
  openPopup: { browser: false, export: false },
  pasteDialog: { browser: false, export: false },
  previousSelectedElementIds: { browser: true, export: false },
  resizingElement: { browser: false, export: false },
  scrolledOutside: { browser: true, export: false },
  scrollX: { browser: true, export: false },
  scrollY: { browser: true, export: false },
  selectedElementIds: { browser: true, export: false },
  selectedGroupIds: { browser: true, export: false },
  selectionElement: { browser: false, export: false },
  shouldCacheIgnoreZoom: { browser: true, export: false },
  showHelpDialog: { browser: false, export: false },
  showStats: { browser: true, export: false },
  startBoundElement: { browser: false, export: false },
  suggestedBindings: { browser: false, export: false },
  selectedElements: { browser: false, export: false },
  toastMessage: { browser: false, export: false },
  viewBackgroundColor: { browser: true, export: true },
  width: { browser: false, export: false },
  zenModeEnabled: { browser: true, export: false },
  sidebarWrapper: { browser: true, export: false },
  currentPage: { browser: true, export: false },
  zoom: { browser: true, export: false },
  viewModeEnabled: { browser: false, export: false },
  DBElements: { browser: false, export: false },
  isFirstLoading: { browser: true, export: false },
  lessonId: { browser: true, export: false },
  isCollaborating: { browser: true, export: false },
  pageName: { browser: true, export: false },
  pendingImageElement: { browser: false, export: false },
  pendingVideoElement: { browser: false, export: false },
  pendingAudioElement: { browser: false, export: false },
  S: { browser: false, export: false },
  isShowLibraryTemplate: { browser: false, export: false },
  duration: { browser: false, export: false },
  files: { browser: false, export: false },
  videoInterval: { browser: false, export: false },
  audioInterval: { browser: false, export: false },
  videoCache: { browser: false, export: false },
  audioCache: { browser: false, export: false },
  updatingFormulaElement: { browser: false, export: false },
  updatingTextElement: { browser: false, export: false },
  showMathInputAndKeyboard: { browser: false, export: false },
  openTextEditor: { browser: false, export: false },
  formulaValue: { browser: false, export: false },
  isOpenVideoRecorder: { browser: false, export: false },
  isOpenAudioRecorder: { browser: false, export: false },
  notesTitle: { browser: false, export: false },
  notesColor: { browser: false, export: false },
  isFlexibleGroup: { browser: false, export: false },
  isShowNoPagesModel: { browser: false, export: false },
  isShowWhiteboard: { browser: false, export: false },
  isMyWorkSpace: { browser: false, export: false },
  selectedWorkspaceTab: { browser: false, export: false },
  presentationMode: { browser: false, export: false },
  isCollaboratingWithFlexibleGroups: { browser: false, export: false },
  whiteboardModel: { browser: false, export: false },
  confirmImportLibraryModel: { browser: false, export: false },
  updatedPageDataURL: { browser: false, export: false },
  textUnderline: { browser: false, export: false },
  textItalic: { browser: false, export: false },
  textEditor: { browser: false, export: false },
  editableSelectedElement: { browser: false, export: false },
  hasRenderedTextWithStyles: { browser: false, export: false },
  editingLibrary: { browser: false, export: false },
  showPublishLibraryDialog: { browser: false, export: false },
  showElementOnCanvasOrNot: { browser: false, export: false },
  pdfPageSelectionDialog: { browser: false, export: false },
  pdfFile: { browser: false, export: false },
  isFullScreen: { browser: false, export: false },
  activeEmbeddable: { browser: false, export: false },
  activeTool: { browser: false, export: false },
  showHyperlinkPopup: { browser: false, export: false },
  selectedLinearElement: { browser: true, export: false },
  selectedElementsAreBeingDragged: {
    browser: false,
    export: false,
  },
  snapLines: { browser: false, export: false },
  frameRendering: { browser: false, export: false },
  frameToHighlight: { browser: false, export: false },
  editingFrame: { browser: false, export: false },
  isResizableOrNot: { browser: false, export: false },
  defaultLibraryTab: { browser: false, export: false },
  currentItemRoundness: {
    browser: true,
    export: false,
  },
  originSnapOffset: { browser: false, export: false },
  isDragAndDrop: { browser: false, export: false },
  isOutlineOpen: { browser: false, export: false },
});

const _clearAppStateForStorage = <ExportType extends "export" | "browser">(
  appState: Partial<AppState>,
  exportType: ExportType,
) => {
  type ExportableKeys = {
    [K in keyof typeof APP_STATE_STORAGE_CONF]: typeof APP_STATE_STORAGE_CONF[K][ExportType] extends true
      ? K
      : never;
  }[keyof typeof APP_STATE_STORAGE_CONF];
  const stateForExport = {} as { [K in ExportableKeys]?: typeof appState[K] };
  for (const key of Object.keys(appState) as (keyof typeof appState)[]) {
    const propConfig = APP_STATE_STORAGE_CONF[key];
    if (propConfig?.[exportType]) {
      // @ts-ignore see https://github.com/microsoft/TypeScript/issues/31445
      stateForExport[key] = appState[key];
    }
  }
  return stateForExport;
};

export const clearAppStateForLocalStorage = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "browser");
};

export const cleanAppStateForExport = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "export");
};

export const clearAppStateForDatabase = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "browser");
};

export const isEraserActive = ({
  activeTool,
}: {
  activeTool: AppState["activeTool"];
}) => activeTool?.type === "eraser";

export const isHandToolActive = ({
  activeTool,
}: {
  activeTool: AppState["activeTool"];
}) => {
  return activeTool?.type === "hand";
};
