import { useEffect } from "react";
import { ReactSVG } from "react-svg";

export const CollectionImagePreview = ({
  previewURL,
  index,
  className,
  zoomLevel = 1,
}: {
  previewURL: string;
  index: number;
  className?: string;
  zoomLevel?: number;
}) => {
  useEffect(() => {
    const filteredDivs = document.querySelectorAll(
      ".collection-card-img > div",
    );
    filteredDivs.forEach((div) => {
      // Find the <svg> element inside each div
      const svgElement = div.querySelector("svg");

      // Check if svgElement is not null (i.e., <svg> element was found)
      if (svgElement) {
        svgElement.style.transform = `scale(${zoomLevel})`;
        svgElement.style.transformOrigin = "top";
      }
    });
  }, [zoomLevel]); // Re-run the effect when zoomLevel or svgElement changes

  return previewURL?.split(".").pop() === "svg" ? (
    <ReactSVG
      src={previewURL}
      className={className ? className : "collectionImage"}
    />
  ) : (
    <img
      key={index}
      src={previewURL}
      alt=""
      className={className ? className : "collectionImage"}
    />
  );
};
