import React from "react";
import { t } from "../i18n";
import "./AlertDialog.scss";
import { Dialog } from "./Dialog";

type alertDialog = {
  title: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
  small?: boolean;
};

const AlertDialog = ({ title, children, onClose, small }: alertDialog) => {
  return (
    <Dialog
      onCloseRequest={onClose}
      title={title}
      small={small}
      closeOnClickOutside={true}
    >
      <div className="alert-dialog">
        <div className="py-1">{children}</div>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-danger px-2 py-1"
            style={{ backgroundColor: "red", borderColor: "red" }}
            onClick={onClose}
          >
            {t("alertModel.ok") || "Okay"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialog;
