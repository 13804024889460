import React, { useState } from "react";
import { t } from "../../../../i18n";
import { AppState, BinaryFiles, LibraryItem } from "../../../../types";
import { edit, PlusIcon, preview, templateIcon } from "../../../icons";
import { ToastType } from "../../../Toast";
import { MoveToCategoryPopover } from "../../tabs/collections/components/moveToCategoryPopover";
import {
  CollectionSidebarItemsEnum,
  SidebarItemsEnum,
} from "../constant/SidebarItems";
import { ConvertElementsToSvg } from "../convertElementsToSvg";

export const CardWithSvg = React.memo(
  ({
    item,
    index,
    addToCollection,
    setAddToCollection,
    files,
    setDeleteElement,
    deleteElelement,
    selectedTab,
    selectedItems,
    setSelectedItems,
    setPreviewDialog,
    onInsertShape,
    appState,
    resetScene,
    setAppState,
    isMove = false,
    onSaveInProgressLibrary,
    onAfterMovedToCategory,
    onAfterDeleteCategory,
    templateId,
    addCollectionIdToUnPublishedItems,
    setToastMessage,
  }: {
    item: LibraryItem | { id: null; elements: LibraryItem["elements"] };
    index: number;
    addToCollection: number[];
    setAddToCollection: (val: number[]) => void;
    files: BinaryFiles;
    setDeleteElement: (val: number[]) => void;
    deleteElelement: number[];
    selectedTab: SidebarItemsEnum | CollectionSidebarItemsEnum;
    selectedItems: string[];
    setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
    setPreviewDialog: (val: LibraryItem["elements"] | null) => void;
    onInsertShape: (elements: LibraryItem["elements"]) => void;
    appState: AppState;
    resetScene: (opts?: { resetLoadingState: boolean }) => void;
    setAppState: React.Component["setState"];
    isMove: boolean;
    onSaveInProgressLibrary: () => void;
    onAfterMovedToCategory?: (category: string[]) => void;
    onAfterDeleteCategory?: (id: string) => void;
    templateId?: string | null;
    addCollectionIdToUnPublishedItems?: (id: string | null) => void;
    setToastMessage: React.Dispatch<{
      message: string;
      type: ToastType;
    } | null>;
  }) => {
    const [openCategoryPopover, setOpenCategoryPopover] = useState(false);
    return (
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12" key={index}>
        <div
          className={`card preview-card mb-3 ${
            addToCollection?.includes(index) ? "active-preview-card" : ""
          }  ${deleteElelement?.includes(index) ? "delete-preview-card" : ""}`}
          key={index}
        >
          <div className="card-img-top">
            <ConvertElementsToSvg
              elements={item.elements}
              files={files}
              frameRendering={appState.frameRendering}
            />
          </div>
          <div className="d-flex flex-column card-overlay justify-content-between ">
            <div className="d-flex w-100 align-items-center justify-content-between">
              {isMove && (
                <button
                  style={{ width: 28 }}
                  className="m-0"
                  title="Add to category"
                  onClick={() => {
                    setOpenCategoryPopover(!openCategoryPopover);
                  }}
                >
                  <PlusIcon color="#000" />
                </button>
              )}
              <div className="d-flex align-items-center ">
                <div className="delete-checkbox ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={deleteElelement?.includes(index)}
                    onChange={async (e) => {
                      if (item.id && selectedItems.includes(item.id)) {
                        setSelectedItems(
                          selectedItems.filter((id) => id !== item.id),
                        );
                      }

                      if (deleteElelement?.includes(index)) {
                        setDeleteElement(
                          deleteElelement.filter(
                            (item: number) => item !== index,
                          ),
                        );
                      } else {
                        setDeleteElement([...(deleteElelement || []), index]);
                        if (item.id && !selectedItems.includes(item.id)) {
                          setSelectedItems((prev) => [...prev, item.id]);
                        }
                      }
                    }}
                    title={
                      !appState?.editingLibrary?.isEditing
                        ? "Delete or Download the Selected File"
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap w-100 h-100">
              <div
                className={`${
                  appState.editingLibrary.isEditing && "mt-0"
                } m-auto d-flex justify-content-center align-items-center flex-wrap`}
              >
                {appState.editingLibrary.isEditing ? (
                  <p className="details__description d-none d-sm-none d-md-none d-lg-block">
                    {t("cards.connect")}
                  </p>
                ) : (
                  <p className="details__description d-none d-sm-none d-md-none d-lg-block">
                    {t("cards.connect")}
                  </p>
                )}
                {selectedTab === SidebarItemsEnum.WorkInProgress && (
                  <>
                    {appState.editingLibrary.isEditing ? (
                      <button
                        type="button"
                        className="btn template-icon-btn px-4"
                        onClick={() => {
                          window.parent.postMessage(
                            { type: "STORE_ELEMENT", isLoading: true },
                            `${process.env.REACT_APP_PARENT_APP}`,
                          );
                          const lesson = JSON.parse(
                            localStorage.getItem("lesson") || "{}",
                          );
                          const elements = item.elements;
                          const lessonId = lesson.Title
                            ? lesson.Title
                            : lesson?.LessonId;
                          elements &&
                            elements.map(
                              (element: any) =>
                                (element.lessonId = lessonId
                                  ? lessonId
                                  : element.lessonId),
                            );
                          localStorage.setItem(
                            "selectedElement",
                            JSON.stringify(item),
                          );
                          resetScene();
                          setAppState({
                            isLibraryOpen: true,
                            editingLibrary: {
                              ...appState.editingLibrary,
                              isEditing: true,
                            },
                          });
                          return onInsertShape(elements);
                        }}
                      >
                        {edit} Edit Item
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn template-icon-btn"
                        onClick={() => {
                          if (addToCollection?.includes(index)) {
                            setAddToCollection(
                              addToCollection.filter(
                                (item: number) => item !== index,
                              ),
                            );
                            if (item.id && selectedItems.includes(item.id)) {
                              setSelectedItems(
                                selectedItems.filter((id) => id !== item.id),
                              );
                            }
                          } else {
                            setAddToCollection([
                              ...(addToCollection || []),
                              index,
                            ]);
                            if (item.id && !selectedItems.includes(item.id)) {
                              setSelectedItems([...selectedItems, item.id]);
                            }
                          }
                        }}
                      >
                        {t("cards.addToCollection")}
                      </button>
                    )}
                  </>
                )}

                {selectedTab !== SidebarItemsEnum.WorkInProgress && (
                  <button
                    type="button"
                    className="btn template-preview-btn"
                    onClick={() => setPreviewDialog(item.elements)}
                  >
                    {preview} {t("cards.preview")}
                  </button>
                )}
                {selectedTab !== SidebarItemsEnum.WorkInProgress && (
                  <button
                    className="btn template-icon-btn"
                    onClick={() => {
                      window.parent.postMessage(
                        { type: "STORE_ELEMENT", isLoading: true },
                        `${process.env.REACT_APP_PARENT_APP}`,
                      );
                      const lesson = JSON.parse(
                        localStorage.getItem("lesson") || "{}",
                      );
                      const elements = item.elements;
                      const lessonId = lesson.Title
                        ? lesson.Title
                        : lesson?.LessonId;
                      elements &&
                        elements.map(
                          (element: any) =>
                            (element.lessonId = lessonId
                              ? lessonId
                              : element.lessonId),
                        );

                      return onInsertShape(elements);
                    }}
                  >
                    <span className="d-flex " style={{ height: 15, width: 15 }}>
                      {templateIcon}
                    </span>
                    {t("cards.useThisTemplate")}
                  </button>
                )}
              </div>
            </div>
          </div>
          {openCategoryPopover && (
            <MoveToCategoryPopover
              setOpenCategoryPopover={setOpenCategoryPopover}
              templateId={templateId ?? ""}
              onAfterMovedToCategory={
                onAfterMovedToCategory ?? ((category) => category)
              }
              templateCategory={[]}
              onAfterDeleteCategory={onAfterDeleteCategory ?? (() => {})}
              popoverRight={"7%"}
              isInProgress={
                templateId
                  ? false
                  : selectedTab === SidebarItemsEnum.WorkInProgress
              }
              onSubmitInprogressLib={onSaveInProgressLibrary}
              addCollectionIdToUnPublishedItems={
                addCollectionIdToUnPublishedItems
              }
              setToastMessage={setToastMessage}
            />
          )}
        </div>
      </div>
    );
  },
);
