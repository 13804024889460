import { useEffect, useState } from "react";
import {
  close,
  compress,
  expand,
  whiteTemplateIcon,
  zoomIn,
  zoomOut,
} from "../../../icons";
import "../../assets/style.scss";
import spinWheel from "../../../../assests/spinWheel.png";
import { SegmentType, timerDuration } from "../../../spinner/types";
import { t } from "../../../../i18n";
import { useIsMobile } from "../../../App";

interface PreviewDialogProps {
  onClose: VoidFunction;
  elements: {
    id: string;
    title: string;
    description: string;
    isPublic: boolean;
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    wheels: SegmentType[];
  };
  handleSpinnerDialog: (val: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => void;
}

export const SpinWheelPreviewDialog = (props: PreviewDialogProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const isMobile = useIsMobile();

  const openFullScreen = () => {
    const elem = document.getElementById("preview-dialog-fullscreen");
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    }
    setIsFullscreen(true);
  };

  const closeFullScreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.exitFullscreen) {
        /* IE11 */
        document.exitFullscreen();
      }
    }
    setIsFullscreen(false);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));
  };

  useEffect(() => {
    setIsFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  }, []);
  return (
    <div className="previewDialog" style={{ width: isMobile ? "96%" : "50%" }}>
      <div className="preview-dialog__header">
        <div className="preview-dialog__header__title">
          {t("cards.preview")}
        </div>
        <div className="preview-dialog__header__close" onClick={props.onClose}>
          {close("black")}
        </div>
      </div>
      <div className="preview-container" id="preview-dialog-fullscreen">
        <div
          className={
            isFullscreen
              ? "preview-dialog__content-fullscreen"
              : "preview-dialog__content"
          }
        >
          <div
            id="sliderSpinner"
            className="carousel slide h-100"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner h-100">
              {props.elements.wheels.map((element: any, index: number) => {
                const segment = element.segment
                  .map((data: string) => data)
                  .join("\n");
                return (
                  <div
                    className={`h-100 overflow-scroll
                      ${index === 0 ? "carousel-item active" : "carousel-item"}
                    `}
                  >
                    <div
                      className="d-flex h-100 flex-wrap"
                      style={{
                        transform: `scale(${zoomLevel})`,
                        transformOrigin: "left top",
                        alignItems: isMobile ? "end" : "",
                      }}
                    >
                      <div className={`${isMobile ? "w-100" : "w-50"}`}>
                        <img src={spinWheel} className="w-100" alt="..." />
                      </div>
                      <div
                        className={`${
                          isMobile ? "w-100 p-2 h-50" : "w-50 py-4 pe-5"
                        }`}
                      >
                        <textarea
                          name=""
                          id=""
                          className="h-100 w-100"
                          value={segment}
                          disabled
                        ></textarea>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {props.elements.wheels.length > 1 && (
              <div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#sliderSpinner"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#sliderSpinner"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            isFullscreen
              ? "preview-dialog-icons-fullscreen"
              : "preview-dialog-icons"
          }
        >
          <button
            className="btn"
            onClick={() => {
              return props.handleSpinnerDialog({
                segments: props?.elements?.wheels,
                isTimer: props?.elements?.isTimer,
                timerDuration: props?.elements?.timerDuration,
              });
            }}
          >
            <span className="d-flex" style={{ height: 15, width: 15 }}>
              {whiteTemplateIcon}
            </span>
            <p style={{ marginBottom: "0px", fontSize: "16px" }}>
              {t("cards.useThisTemplate")}
            </p>
          </button>
          <div className="icons-div">
            <div className="icon" onClick={handleZoomIn}>
              {zoomIn}
            </div>
            <div className="icon" onClick={handleZoomOut}>
              {zoomOut}
            </div>
            <div
              className="icon"
              onClick={isFullscreen ? closeFullScreen : openFullScreen}
            >
              {isFullscreen ? expand : compress}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
