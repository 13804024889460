import { getImportedKey } from "../data";
import { createIV } from "./index";
import { ExcalidrawElement, FileId } from "../../element/types";
import { getSceneVersion } from "../../element";
import Portal from "../collab/Portal";
import { restoreElements } from "../../data/restore";
import {
  loadDataToCosmosDB,
  saveDataToCosmosDB,
} from "../api/loadDataFromCosmosDB";
import { MIME_TYPES } from "../../constants";
import { FILE_CACHE_MAX_AGE_SEC } from "../app_constants";
import { BinaryFileData, BinaryFileMetadata, DataURL } from "../../types";
import { decompressData } from "../../data/encode";
import { getElemetDataFromDatabase } from "../api/storeElementData";
import { STORAGE_KEYS } from "./localStorage";

// private
// -----------------------------------------------------------------------------

let firebasePromise: Promise<
  typeof import("firebase/app").default
> | null = null;
let firestorePromise: Promise<any> | null = null;
let firebseStoragePromise: Promise<any> | null = null;

const _loadFirebase = async () => {
  const firebase = (
    await import(/* webpackChunkName: "firebase" */ "firebase/app")
  ).default;

  const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
  if (firebaseConfig) firebase.initializeApp(firebaseConfig);

  return firebase;
};

const _getFirebase = async (): Promise<
  typeof import("firebase/app").default
> => {
  if (!firebasePromise) {
    firebasePromise = _loadFirebase();
  }
  return firebasePromise;
};

// -----------------------------------------------------------------------------

const loadFirestore = async () => {
  const firebase = await _getFirebase();
  if (!firestorePromise) {
    firestorePromise = import(
      /* webpackChunkName: "firestore" */ "firebase/firestore"
    );
    await firestorePromise;
  }
  return firebase;
};

export const loadFirebaseStorage = async () => {
  const firebase = await _getFirebase();
  if (!firebseStoragePromise) {
    firebseStoragePromise = import(
      /* webpackChunkName: "storage" */ "firebase/storage"
    );
    await firebseStoragePromise;
  }
  return firebase;
};

interface FirebaseStoredScene {
  sceneVersion: number;
  iv: firebase.default.firestore.Blob;
  ciphertext: firebase.default.firestore.Blob;
}

const encryptElements = async (
  key: string,
  elements: readonly ExcalidrawElement[],
): Promise<{ ciphertext: ArrayBuffer; iv: Uint8Array }> => {
  const importedKey = await getImportedKey(key, "encrypt");
  const iv = createIV();
  const json = JSON.stringify(elements);
  const encoded = new TextEncoder().encode(json);
  const ciphertext = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv,
    },
    importedKey,
    encoded,
  );

  return { ciphertext, iv };
};

const decryptElements = async (
  key: string,
  iv: Uint8Array,
  ciphertext: ArrayBuffer,
): Promise<readonly ExcalidrawElement[]> => {
  try {
    const importedKey = await getImportedKey(key, "decrypt");
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv,
      },
      importedKey,
      ciphertext,
    );

    const decodedData = new TextDecoder("utf-8").decode(
      new Uint8Array(decrypted) as any,
    );
    return JSON.parse(decodedData);
  } catch (error) {
    console.log(":::::::::::", error);
    return [];
  }
};

const firebaseSceneVersionCache = new WeakMap<SocketIOClient.Socket, number>();

export const isSavedToFirebase = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);
    return firebaseSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const saveToFirebase = async (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
) => {
  const { roomId, roomKey, socket } = portal;

  if (
    // if no room exists, consider the room saved because there's nothing we can
    // do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToFirebase(portal, elements)
  ) {
    return true;
  }

  const firebase = await loadFirestore();
  const sceneVersion = getSceneVersion(elements);
  const { ciphertext, iv } = await encryptElements(roomKey, elements);

  const nextDocData = {
    sceneVersion,
    ciphertext: firebase.firestore.Blob.fromUint8Array(
      new Uint8Array(ciphertext),
    ),
    iv: firebase.firestore.Blob.fromUint8Array(iv),
  } as FirebaseStoredScene;

  const db = firebase.firestore();
  const docRef = db.collection("scenes").doc(roomId);

  const body = {
    ...nextDocData,
    roomId,
  };

  try {
    await saveDataToCosmosDB(body);
    return;
  } catch (err) {
    console.log(err);
    return;
  }

  // const didUpdate = await db.runTransaction(async (transaction) => {
  //   const doc = await transaction.get(docRef);

  //   console.log("doc", doc);

  //   if (!doc.exists) {
  //     transaction.set(docRef, nextDocData);
  //     return true;
  //   }

  //   const prevDocData = doc.data() as FirebaseStoredScene;
  //   if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
  //     return false;
  //   }

  //   transaction.update(docRef, nextDocData);
  //   return true;
  // });

  // if (didUpdate) {
  //   firebaseSceneVersionCache.set(socket, sceneVersion);
  // }

  // return didUpdate;
};

export const loadFromFirebase = async (
  roomId: string,
  roomKey: string,
  socket: SocketIOClient.Socket | null,
): Promise<readonly ExcalidrawElement[] | null> => {
  // const firebase = await loadFirestore();
  // const db = firebase.firestore();

  // const docRef = db.collection("scenes").doc(roomId);
  // const doc = await docRef.get();

  // if (!doc.exists) {
  //   return null;
  // }
  // const storedScene = doc.data() as FirebaseStoredScene;

  const body = {
    roomId,
  };
  // try {

  const { result } = await loadDataToCosmosDB(body);

  // const { ciphertext, iv } = data;

  if (!result || !result.length || !result[0].data) return null;

  const ciphertext = JSON.parse(result[0].data.ciphertext).toUint8Array();
  const iv = JSON.parse(result[0].data.iv.toUint8Array());

  // console.log("ciphertext", ciphertext);
  // console.log("iv::", iv);

  const elements = await decryptElements(
    roomKey,
    iv.toUint8Array(),
    ciphertext.toUint8Array(),
  );

  if (socket) {
    firebaseSceneVersionCache.set(
      socket,
      getSceneVersion(result[0].data.elements),
    );
  }

  return restoreElements(result[0].data.elements, null);
  // } catch (err) {
  // console.log(err);
  // }
};

export const saveFilesToFirebase = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const firebase = await loadFirebaseStorage();

  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        await firebase
          .storage()
          .ref(`${prefix}/${id}`)
          .put(
            new Blob([buffer], {
              type: MIME_TYPES.binary,
            }),
            {
              cacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`,
            },
          );
        savedFiles.set(id, true);
      } catch (error: any) {
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

const FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

export const loadFilesFromFirebase = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();
  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const { currentPage } = JSON.parse(
          localStorage.getItem("acv-state") || "{}",
        );

        const lessonId =
          new URLSearchParams(window.location.search)
            .get("lessonId")
            ?.replace(/\//g, "") || "";
        const {
          imageData,
          videoData,
          audioData,
          activePage,
        } = await getElemetDataFromDatabase(lessonId, currentPage);

        const data = imageData.filter((imgData: any) => imgData.data[id]);
        const video_data = videoData.filter((video: any) => video.data[id]);
        const audio_data = audioData.filter((audio: any) => audio.data[id]);

        if (data) {
          data.map((data: any) => {
            const innerData = data.data[id];
            loadedFiles.push({
              mimeType: innerData.mimeType || MIME_TYPES.binary,
              id,
              dataURL: innerData.dataURL,
              created: innerData?.created || Date.now(),
              isPublished: innerData?.isPublished || false,
            });
          });
        }
        if (video_data) {
          video_data.map((video_data: any) => {
            const innerData = video_data.data[id];
            loadedFiles.push({
              mimeType: innerData.mimeType || MIME_TYPES.binary,
              id,
              dataURL: innerData.dataURL,
              created: innerData?.created || Date.now(),
              isPublished: innerData?.isPublished || false,
            });
          });
        }
        if (audio_data) {
          audio_data.map((audio_data: any) => {
            const innerData = audio_data.data[id];
            loadedFiles.push({
              mimeType: innerData.mimeType || MIME_TYPES.binary,
              id,
              dataURL: innerData.dataURL,
              created: innerData?.created || Date.now(),
              isPublished: innerData?.isPublished || false,
            });
          });
        }

        if (!video_data && !audioData && !data) {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
