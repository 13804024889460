import { useMsal } from "@azure/msal-react";

function handleLogout(instance) {
  if (window.parent !== window) {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
    localStorage.clear();
  } else {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }
  localStorage.clear();
}

export const SignOutButton = () => {
  const { instance } = useMsal();
  return (
    <button
      className="btn btn-primary bg-brand text-white px-3 m-0 border-0"
      onClick={() => handleLogout(instance)}
      id="logoutBtn"
    >
      Sign out
    </button>
  );
};

export const BackToWorkspace = () => {
  return (
    <button
      className="btn btn-primary bg-brand text-white px-3 m-0 border-0"
      onClick={() => {
        window.backToWorkspace();
        document.getElementById("clsBtn")?.click();
        Array.from(document.getElementsByClassName("acv"))[0].style.opacity =
          "1";
      }}
    >
      Back to Workspace
    </button>
  );
};
