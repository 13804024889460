import { BlobServiceClient } from "@azure/storage-blob";
import { shortUuid } from "./uuid";

const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net?${process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN}`,
);

export const uploadImageToAzureBlob = async (
  dataURL: string,
  blobName: string,
  containerName?: string,
) => {
  // Convert the Data URL to binary data
  const data = dataURL.split(",")[1];

  const mimeType = dataURL.split(";")[0].slice(5);

  const buffer = Buffer.from(data, "base64");

  const slug = new URLSearchParams(window.location.search)
    .get("slug")
    ?.toLocaleLowerCase();

  const container = containerName || slug || "collecations";
  // Get a reference to the container
  const containerClient = blobServiceClient.getContainerClient(container);

  // Create a new blob client
  const blobClient = containerClient.getBlockBlobClient(blobName);

  // Upload the binary data to the blob
  await blobClient.uploadData(buffer, {
    blobHTTPHeaders: {
      blobContentType: mimeType || "image/png",
    },
  });
  const url = `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net/${container}/${blobName}`;
  return url || "";
};

export const uploadSVGToAzureBlob = async (
  svgElement: SVGElement,
  containerName: string,
) => {
  if (!svgElement) {
    console.error("No SVG element to upload");
    return;
  }

  const svgString = new XMLSerializer().serializeToString(svgElement);
  const blob = new Blob([svgString], { type: "image/svg+xml" });

  const name = shortUuid();
  const blobName = `${name}.svg`; // Change the blob name as needed

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  try {
    await blockBlobClient.uploadBrowserData(blob);
    return `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net/${containerName}/${blobName}`;
  } catch (error) {
    console.error("Error uploading SVG:", error);
    return "";
  }
};

export const uploadBlobToAzure = async (
  buffer: Buffer,
  blobName: string,
  containerName?: string,
  mimeType?: string,
) => {
  const slug = new URLSearchParams(window.location.search)
    .get("slug")
    ?.toLocaleLowerCase();

  const container = containerName || slug || "collecations";
  // Get a reference to the container
  const containerClient = blobServiceClient.getContainerClient(container);

  // Create a new blob client
  const blobClient = containerClient.getBlockBlobClient(blobName);

  // Upload the binary data to the blob
  await blobClient.uploadData(buffer, {
    blobHTTPHeaders: {
      blobContentType: mimeType || "image/png",
    },
  });
  const url = `https://${process.env.REACT_APP_STORAGE}.blob.core.windows.net/${container}/${blobName}`;
  return url || "";
};
