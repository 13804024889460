import clsx from "clsx";
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import clock from "../../src/assests/clock.png";
import countdown from "../../src/assests/countdown.png";
import chronometer from "../../src/assests/chronometer.png";

import axios from "axios";
import moment from "moment";
import MathView, { MathFieldChangeEvent, MathViewRef } from "react-math-view";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { actionDeleteSelected } from "../actions";
import { ActionManager } from "../actions/manager";
import { SignOutButton } from "../App/msal-auth/SignOutButton";
import spin from "../assests/spin.jpg";
import { StartEditingProps, useIsMobile } from "../components/App";
import { EXPORT_IMAGE_TYPES } from "../constants";
import { exportCanvas, prepareElementsForExport } from "../data";
import { isImageFileHandle } from "../data/blob";
import captchScreenImage from "../data/getImage";
import Library from "../data/library";
import {
  isTextElement,
  newElement,
  showSelectedShapeActions,
} from "../element";
import {
  ExcalidrawElement,
  ExcalidrawFrameElement,
  NonDeleted,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { getStudents } from "../excalidraw-app/api";
import {
  ACV_API_URL,
  studentAccessRevokeMessage,
  studentSessionEndMessage,
} from "../excalidraw-app/api/constant";
import {
  getIdWiseWorkspaceData,
  getSharedWithMeWorkspace,
  getSharedWithMeWorkspaceByWorkspaceID,
  getStudentsOrTeachersName,
  getWorkspaceUsers,
  updateWorkspaceDetails,
  updateWorkspaceFeildInInviteWorkspaceModal,
} from "../excalidraw-app/api/storeElementData";
import {
  checkUserRole,
  getLessonInfo,
  getLessonWisePdfImportedData,
  getSettingData,
  onSaveElementInDB,
  pdfImportDocumentCounter,
} from "../excalidraw-app/api/userAPI";
import Portall from "../excalidraw-app/collab/Portall";
import { STORAGE_KEYS } from "../excalidraw-app/data/localStorage";
import { Language, t } from "../i18n";
import { calculateScrollCenter, getSelectedElements } from "../scene";
import {
  AppClassProperties,
  AppProps,
  AppState,
  BinaryFiles,
  CollocationType,
  ExcalidrawProps,
  LibraryData,
  LibraryItem,
  LibraryItems,
  Subscription,
} from "../types";
import {
  muteFSAbortError,
  sceneCoordsToViewportCoords,
  viewportCoordsToSceneCoords,
} from "../utils";
import { midUuid } from "../utils/uuid";
import { SelectedShapeActions, ShapesSwitcher, ZoomActions } from "./Actions";
import AlertDialog from "./AlertDialog";
import AudioRecorder from "./AudioRecorder";
import { Avatar } from "./Avatar";
import { BackgroundPickerAndDarkModeToggle } from "./BackgroundPickerAndDarkModeToggle";
import { Celebration } from "./celebrate";
import CollabButton from "./CollabButton";
import { NotesTitleContext } from "./contexts/NotesTitle.context";
import { StudentMessageContext } from "./contexts/StudentMessage.context";
import { UserProfileContext } from "./contexts/UserProfile.context";
import { Dialog } from "./Dialog";
import { EditMermaidDiagramDialog } from "./EditMermaidDiagramDialog";
import { ErrorDialog } from "./ErrorDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import FlexibleGroupButton from "./flexibleGroupButton";
import { GPTDialog } from "./GPTDialog";
import { HelpDialog } from "./HelpDialog";
import { HintViewer } from "./HintViewer";
import {
  audioIcon,
  backTo,
  close,
  compress,
  expand,
  pin,
  pinFill,
  recording,
  settingOutlineIcon,
  users,
  videoIcon,
  widgets,
} from "./icons";
import { ImageExportDialog } from "./ImageExportDialog";
import { Island } from "./Island";
import { JSONExportDialog } from "./JSONExportDialog";
import "./LayerUI.scss";
import { LibraryButton } from "./LibraryButton";
import { LibraryDialog } from "./LibraryDialog";
import { LibraryMenu } from "./LibraryMenu";
import { LibraryTemplateButton } from "./LibraryTemplateButton";
import { LoadingMessage } from "./LoadingMessage";
import { LockButton } from "./LockButton";
import { MobileMenu } from "./MobileMenu";
import { MyWorkspaceSidebarItemsEnum } from "./myWorkSpace/views/sidebar";
import PdfPageSelectionDialog from "./PdfSelectionDialog";
import { PresentationMode } from "./presentationMode";
import { OutlineSidebar } from "./presentationMode/OutlineSidebar";
import PublishLibrary from "./PublishLibrary";
import { Section } from "./Section";
import { Settings } from "./settings";
import SideBar from "./SideBar";
import SpinnerDialog from "./spinner/index";
import { SegmentType, timerDuration } from "./spinner/types";
import Stack from "./Stack";
import { TiptapEditor } from "./tiptapEditor/TipTapEditor";
import "./Toolbar.scss";
import { ToolButton, ToolButtonEnum } from "./ToolButton";
import VideoRecorder from "./VideoRecorder";
import { getLessonId } from "../excalidraw-app/api/getuserInfo";
import { newChronometerElement, newClockElement } from "../element/newElement";
import { getGridPoint } from "../math";
import { newCountDownElement } from "../element/newElement";

interface LayerUIProps {
  actionManager: ActionManager;
  appState: AppState;
  files: BinaryFiles;
  onPageDelete?: (pageId: string) => void;
  canvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  onCollabButtonClick?: () => void;
  onFlexibleGroupButtonClick?: () => void;
  onLockToggle: () => void;
  onInsertElements: (elements: readonly NonDeletedExcalidrawElement[]) => void;
  importCustomCategory: (elements: LibraryItem["elements"]) => void;
  importLibraryFromUrl: (urls: string[]) => void;
  zenModeEnabled: boolean;
  sidebarWrapper: boolean;
  showExitZenModeBtn: boolean;
  showThemeBtn: boolean;
  toggleZenMode: () => void;
  langCode: Language["code"];
  isCollaborating: boolean;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  renderCustomFooter?: (isMobile: boolean, appState: AppState) => JSX.Element;
  viewModeEnabled: boolean;
  libraryReturnUrl: ExcalidrawProps["libraryReturnUrl"];
  UIOptions: AppProps["UIOptions"];
  focusContainer: () => void;
  library: Library;
  id: string;
  onPageChange?: any;
  onImageAction: (data: { insertOnCanvasDirectly: boolean }) => void;
  onImageActionFromLibrary: (imageFile: File) => void;
  mathfieldRef: React.RefObject<MathViewRef>;
  onMathFormulaAction: (
    mathfieldRef: React.RefObject<MathViewRef>,
    formulaValue?: string,
  ) => void;
  togglekeyboardShortcut: (isAdding: boolean) => void;
  toggleZoomInZoomOut: (isZoom: boolean) => void;
  mathFormulaValueRef: { current: string | null | undefined };
  executeFinalizeAction: () => void;

  onVideoActionFromLibrary: (
    file: File,
    filename?: string | null,
    color?: string | null,
  ) => void;
  onAudioActionFromLibrary: (
    file: File,
    filename?: string | null,
    color?: string | null,
  ) => void;
  startTextEditing: (data: StartEditingProps) => void;
  onTextWithStylesAction: ({
    textString,
    show,
    handleCloseDialogue,
    isAIGenerated,
    width,
  }: {
    textString: string;
    show?: boolean;
    handleCloseDialogue?: VoidFunction;
    isAIGenerated?: boolean;
    width?: number;
  }) => void;
  handlePdfFile: (
    selectedPages: Array<{ index: number; page: string }>,
    pdfId: string,
  ) => void;
  resetScene: (opts?: { resetLoadingState: boolean }) => void;
  initializeSceneWithWorkspace: (
    id: string,
    sharedUserProfileEmail: string,
    isActive?: boolean,
  ) => void;
  app: AppClassProperties;
}

export const languages = [
  "English",
  "Español",
  "Português",
  "Français",
  "Deutsch",
  "हिन्दी",
];

export let videoRecorder: any,
  audioRecorder: any,
  notes: any,
  settings: any,
  settingsPopover: any;

const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  canvas,
  elements,
  onCollabButtonClick,
  onFlexibleGroupButtonClick,
  onLockToggle,
  onInsertElements,
  zenModeEnabled,
  sidebarWrapper,
  showExitZenModeBtn,
  showThemeBtn,
  toggleZenMode,
  isCollaborating,
  renderTopRightUI,
  renderCustomFooter,
  viewModeEnabled,
  libraryReturnUrl,
  UIOptions,
  focusContainer,
  library,
  id,
  onPageChange,
  onPageDelete,
  onImageAction,
  mathfieldRef,
  onImageActionFromLibrary,
  onVideoActionFromLibrary,
  onAudioActionFromLibrary,
  onMathFormulaAction,
  togglekeyboardShortcut,
  mathFormulaValueRef,
  executeFinalizeAction,
  startTextEditing,
  onTextWithStylesAction,
  handlePdfFile,
  toggleZoomInZoomOut,
  importCustomCategory,
  importLibraryFromUrl,
  resetScene,
  initializeSceneWithWorkspace,
  app,
}: LayerUIProps) => {
  const isMobile = useIsMobile();
  const isMyWorkSpace = localStorage.getItem("isMyWorkSpace");
  const [isStudent, setIsStudent] = useState<boolean>(
    isMyWorkSpace === "true" ? false : appState.S,
  );
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerHeight <= 768,
  );
  const [lesson, setLesson] = useState({
    ClassName: null,
    LessonDate: "",
  });
  const editorRef = React.useRef<HTMLDivElement>(null); // Ref for the editor
  const [editorDimensions, setEditorDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [celebration, setCelebration] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<boolean>(false);
  const [language, setLanguage] = useState(languages[0]);
  const [isOwnerUser, setIsOwnerUser] = useState<boolean>(false);
  const [isCollaborating_, setIsCollaborating_] = useState(false);
  const isReloadingWorkspace = localStorage.getItem("isReloadingWorkspace");
  const [isCollaborationAlert, setIsCollaborationAlert] = useState<boolean>(
    false,
  );
  const [workspaceData, setWorkspaceData] = useState<{
    id: string;
    userEmail: string;
    name: string;
    status: string;
    elements: [];
    createdAt: string;
    previewImage: string;
    tags: string[];
  }>({
    id: "",
    userEmail: "",
    name: "",
    status: "",
    elements: [],
    createdAt: "",
    previewImage: "",
    tags: [],
  });
  const [isWidgetDialogOpen, setIsWidgetDialogOpen] = useState(false);
  const navigate = useNavigate();
  const alreadyElementonCanvas = app.scene.getElements();

  useEffect(() => {
    const getWorkspaceData = async () => {
      const selectedWorkspaceCardID = localStorage.getItem(
        "selectedWorkspaceCard",
      );
      const workspaceData_ = await getIdWiseWorkspaceData(
        selectedWorkspaceCardID,
      );
      const slug = new URLSearchParams(window.location.search).get("slug");
      const getName = await getStudentsOrTeachersName(
        user.role,
        slug,
        (workspaceData_?.data?.length && workspaceData_?.data[0]?.userEmail) ||
          user.mail,
      );
      const date = new Date(
        workspaceData_?.data?.length && workspaceData_.data[0]?.createdAt,
      );
      const formattedDate = date.toISOString()?.split("T")[0];
      const namePart =
        workspaceData_?.data?.length &&
        workspaceData_?.data[0]?.userEmail.split("@")[0]; // Extract the part before "@"
      setWorkspaceData({
        ...workspaceData_.data[0],
        userEmail:
          user.role === "Student"
            ? getName?.data?.length
              ? getName?.data[0]?.StudentName
              : namePart
            : getName?.data?.length
            ? getName?.data[0]?.TeacherName
            : namePart,
        createdAt: formattedDate,
      });
    };
    isMyWorkSpace === "true" && getWorkspaceData();
    // eslint-disable-next-line
  }, []);

  const getSharedWithMeWorkspaceByWorkspaceID_ = async () => {
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const res = await getSharedWithMeWorkspaceByWorkspaceID(
      selectedWorkspaceCardID as string,
    );
    return res;
  };

  useEffect(() => {
    if (appState.activeTool.type === "frame") {
      setAppState({ isDragAndDrop: true, isOutlineOpen: true });
    }
    // eslint-disable-next-line
  }, [appState.activeTool.type === "frame"]);

  useEffect(() => {
    if (appState.activeTool.type === "frame") {
      setAppState({ isDragAndDrop: true });
    }
    // eslint-disable-next-line
  }, [appState.activeTool.type === "frame"]);

  useEffect(() => {
    if (isMyWorkSpace === "true") {
      const checkExpirationDate = async () => {
        try {
          const res = await getWorkspaceUsers(); // Fetch users data
          const selectedWorkspaceCardID = localStorage.getItem(
            "selectedWorkspaceCard",
          );
          res?.forEach((user: any) => {
            if (user.workspaceId === selectedWorkspaceCardID) {
              const now = new Date().toISOString(); // Current UTC time in ISO format
              const expiration = new Date(user.expirationDate).toISOString(); // Expiration time in ISO format
              // Compare up to the minute
              if (now >= expiration) {
                const isCollaboration =
                  localStorage.getItem("isCollaborating") || "false";
                if (isCollaboration === "true") {
                  setIsCollaborationAlert(true);
                }
                window.publicClosePortal(); // Call the function
              }
            }
          });
        } catch (error) {
          console.error("Error checking expiration dates:", error);
        }
      };

      // Run the check immediately
      isMyWorkSpace === "true" && !appState.isLoading && checkExpirationDate();

      // Set interval to check every minute (60000 ms)
      const intervalId = setInterval(checkExpirationDate, 60000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [!appState.isLoading]);

  const handleWorkspaceData = async () => {
    try {
      const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") === "true";
      if (!isMyWorkSpace) {
        return;
      }

      const workspaceData = await getSharedWithMeWorkspaceByWorkspaceID_();

      if (!workspaceData?.data?.length) {
        return;
      }

      const [workspaceDetails] = workspaceData.data; // Destructure first element
      const currentUser = JSON.parse(localStorage.getItem("user") || "{}");

      if (!workspaceDetails?.userEmail?.length || !currentUser.mail) {
        return;
      }

      const userWithDisabledViewMode = workspaceDetails?.userEmail?.find(
        (workspaceUser: any) =>
          workspaceUser.email === currentUser.mail && !workspaceUser.isEnabled,
      );
      if (
        userWithDisabledViewMode &&
        (workspaceDetails?.status === "Private" ||
          (workspaceDetails?.roomDetails &&
            Object.keys(workspaceDetails.roomDetails).length &&
            workspaceDetails?.status === "Public"))
      ) {
        window.toggleZoomInZoomOut();
        setStudentMessage({
          ...studentSessionEndMessage,
        });
        const isModalOpen = document
          .getElementsByClassName("modal fade Karla")[0]
          .classList.contains("show");

        if (!isModalOpen) {
          Array.from(
            document.getElementsByClassName(
              "acv",
            ) as HTMLCollectionOf<HTMLElement>,
          )[0].style.opacity = "0";
          document.getElementById("errModalBtn")?.click();
        }
      } else if (
        userWithDisabledViewMode &&
        workspaceDetails?.status === "Public"
      ) {
        setAppState({ viewModeEnabled: true });
      }
    } catch (error) {
      console.error("Error fetching workspace data:", error);
    }
  };

  useEffect(() => {
    if (isReloadingWorkspace === "true") {
      setAppState({
        ...(appState || {}),
        isMyWorkSpace: false,
        isShowWhiteboard: true,
      });
      const cardId = localStorage.getItem("selectedWorkspaceCard") || "";
      const sharedUserProfileEmail =
        localStorage.getItem("sharedUserProfileEmail") || "";
      initializeSceneWithWorkspace(cardId, sharedUserProfileEmail, true);
      handleWorkspaceData();
      window.parent.postMessage(
        {
          type: "SELECTED_WORKSPACE",
          selectedWorkspaceCardId: cardId,
        },
        `${`${process.env.REACT_APP_PARENT_APP}`}`,
      );
    }
    // eslint-disable-next-line
  }, [isReloadingWorkspace === "true"]);

  useEffect(() => {
    const selectedWorkspaceCardID =
      localStorage.getItem("selectedWorkspaceCard") || "";

    const getShareWorkspaceData = async () => {
      const res = await getSharedWithMeWorkspaceByWorkspaceID(
        selectedWorkspaceCardID as string,
      );
      if (isMyWorkSpaceData === "true") {
        if (res?.data?.length) {
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          if (res.data[0].ownerUserEmail === user.mail) {
            setIsOwnerUser(true);
          }
        } else {
          setIsOwnerUser(true);
        }
      } else {
        setIsOwnerUser(true);
      }
    };
    isMyWorkSpaceData === "true"
      ? getShareWorkspaceData()
      : setIsOwnerUser(true);
    // eslint-disable-next-line
  }, [isReloadingWorkspace === "true", isCollaborating]);

  // Effect to get the dimensions of the editor
  useEffect(() => {
    if (editorRef.current) {
      const { width, height } = editorRef.current.getBoundingClientRect();
      setEditorDimensions({ width, height });
    }
  }, [appState.textEditor.open]); // Update when the editor is opened
  const [isLoading, setIsLoading] = useState(false);

  // notes btn ref
  const notesRef = React.useRef<HTMLDivElement>(null);

  //setting btn ref
  const settingRef = React.useRef<HTMLDivElement>(null);

  const { setStudentMessage } = useContext(StudentMessageContext);

  const [formulaInnerValue, setFormulaInnerValue] = useState(
    appState.formulaValue,
  );
  const [
    isCollaboratingWithFlexibleGroups,
    setIsCollaboratingWithFlexibleGroups,
  ] = useState(false);

  const [canUploadMedia, setCanUploadMedia] = useState({
    video: false,
    audio: false,
  });

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [openGPTDialog, setOpenGPTDialog] = useState<boolean>(false);
  const [openSpinnerDialog, setOpenSpinnerDialog] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [showPublishLibraryDialog, setShowPublishLibraryDialog] = useState(
    false,
  );
  const [publishLibSuccess, setPublishLibSuccess] = useState<null | {
    url: string;
    authorName: string;
  }>(null);
  const [libraryItems, setLibraryItems] = useState<LibraryItems>([]);
  const [subscription, setSubscription] = useState<Subscription>();
  const [limitExceedModal, setLimitExceedModal] = useState(false);
  const [allowedDocumentsPerLesson, setAllowedDocumentsPerLesson] = useState(
    null,
  );
  const [open, setOpen] = useState(false);
  const [segments, setSegments] = useState<{
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }>({
    isTimer: false,
    timerDuration: { [timerDuration.MINUTES]: 1, [timerDuration.SECONDS]: 0 },
    segments: [],
  });
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [pinSidebar, setPinSidebar] = useState<boolean>(false);
  const [openSettingPopover, setOpenSettingPopover] = useState<boolean>(false);
  const [deleteElelement, setDeleteElement] = useState<number[]>([]);
  const [addToCollection, setAddToCollection] = useState<number[]>([]);
  const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
  const { gptCredit, setGptCredit } = useContext(UserProfileContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerHeight <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getBackgroundColorBtn = document.querySelector(
      '[data-testid="background-color"]',
    );

    if (getBackgroundColorBtn) {
      const backgroundColorBtnPopover = document.getElementById(
        "background-color-popover",
      );
      if (backgroundColorBtnPopover) {
        backgroundColorBtnPopover.style.top = `${
          getBackgroundColorBtn.getBoundingClientRect().top +
          getBackgroundColorBtn.getBoundingClientRect().height / 2
        }px`;
        backgroundColorBtnPopover.style.left = `10px`;
      }
    }
    // eslint-disable-next-line
  }, [appState.openPopup === "canvasColorPicker"]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isStudent && viewModeEnabled && event.altKey && event.key === "r") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const removeOldLibraryElements = () => {
    let currentPageElements = [] as any;
    if (isMyWorkSpaceData === "true") {
      currentPageElements = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS) ||
          "[]",
      );
    } else {
      currentPageElements = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS) || "[]",
      );
    }
    const currentPageOldElements =
      appState.editingLibrary.currentPageOldElements;
    const elementsIdsForDelete: { [id: string]: boolean } = {};

    if (currentPageElements?.length) {
      if (currentPageOldElements?.length) {
        for (let index = 0; index < currentPageElements.length; index++) {
          const element = currentPageElements[index];
          // Check that element is available on page before starting editing library.
          const isOldElementOfPage = currentPageOldElements.some(
            (oldElement) => oldElement.id === element.id,
          );
          if (!isOldElementOfPage) {
            elementsIdsForDelete[element.id] = true;
          }
        }
      } else {
        for (let index = 0; index < currentPageElements.length; index++) {
          const element = currentPageElements[index];
          elementsIdsForDelete[element.id] = true;
        }
      }
    }

    if (elementsIdsForDelete) {
      setAppState({
        selectedElementIds: elementsIdsForDelete,
      });
      setTimeout(() => {
        actionManager.executeAction(actionDeleteSelected);
      }, 5);
    }
    setAppState({
      editingLibrary: {
        ...appState.editingLibrary,
        libraryId: "",
        isEditing: false,
        libraryInfo: {} as CollocationType,
        oldElements: [],
        currentPageOldElements: [],
      },
    });
  };
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const profile = window.localStorage.getItem("profile-blob");
  const displayName = user?.displayName;
  const isCollaborationStart = (val: string) => {
    switch (val) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false; // Default case to handle unexpected values
    }
  };
  const isJoinCollaboration = isCollaborationStart(
    localStorage.getItem("isCollaborating") || "false",
  );

  const words = displayName?.split(" ").filter((word: string) => !!word.trim());
  let name = "";

  if (words?.length > 1) {
    name = words[0]?.substring(0, 1) + words[words.length - 1]?.substring(0, 1);
  } else {
    name = displayName?.substring(0, 1);
  }

  const role = localStorage.getItem("Role");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search).get("element");
    if (params) {
      axios
        .get(params)
        .then((res) => {
          const elements: NonDeleted<ExcalidrawElement>[] = [];

          if (res.data?.library) {
            res.data.library.forEach((lib: any) => {
              lib.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }

          if (res.data?.libraryItems) {
            res.data.libraryItems.forEach((lib: any) => {
              lib.elements.forEach((data: NonDeleted<ExcalidrawElement>) => {
                elements.push(data);
              });
            });
          }

          onInsertElements(elements);
        })
        .catch((error) => console.error(error));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsCollaboratingWithFlexibleGroups(
      JSON.parse(
        localStorage.getItem("isCollaboratingWithFlexibleGroups") || "false",
      ),
    );
    // eslint-disable-next-line
  }, [localStorage.getItem("isCollaboratingWithFlexibleGroups")]);
  useEffect(() => {
    setIsCollaborating_(
      JSON.parse(localStorage.getItem("isCollaborating") || "false"),
    );
    // eslint-disable-next-line
  }, [localStorage.getItem("isCollaborating")]);

  useEffect(() => {
    const isMyWorkSpace = localStorage.getItem("isMyWorkSpace") || "false";

    if (isMyWorkSpace === "false") {
      const storedLesson = localStorage.getItem("lesson");
      const lesson = storedLesson ? JSON.parse(storedLesson) : {};

      if (lesson?.ClassName) {
        setLesson(lesson);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const lessonId = (urlParams.get("lessonId") || "").replace(/\//g, "");
        const slug = (urlParams.get("slug") || "").replace(/\//g, "");

        getLessonInfo(lessonId, slug).then((res) => {
          const className = res?.result?.[0]?.ClassName;
          if (className) {
            setLesson({ ClassName: className, LessonDate: lesson.LessonDate });
          }
        });
      }
    }

    const isStudentState =
      isMyWorkSpace === "true"
        ? false
        : JSON.parse(localStorage.getItem("S") || "{}");
    setIsStudent(isStudentState);

    if (["SuperAdmin", "Admin", "Teacher"].includes(user.role)) {
      setIsStudent(false);
    }
    // eslint-disable-next-line
  }, [appState.S]);

  useEffect(() => {
    const slug = new URLSearchParams(window.location.search).get("slug");
    const validSlug = slug ?? "";
    axios
      .post(
        `${process.env.REACT_APP_ACV_BACKEND_API}/api/startup/get-DB-data?slug=${validSlug}`,
      )
      .then((res) => {
        setSubscription(res.data.subscription);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setFormulaInnerValue(appState.formulaValue);
    setFontSizeByDivision(appState.formulaValue);
    mathfieldRef.current?.focus();
    // eslint-disable-next-line
  }, [appState.formulaValue]);

  useEffect(() => {
    if (mathfieldRef.current) {
      // Open virtual keyboard in iframe tag

      mathfieldRef.current.mathVirtualKeyboardPolicy = "sandboxed";
      const mathField = document.querySelector(
        ".math-formula-wrapper math-field",
      );
      if (mathField) {
        const shadowRoot = mathField.shadowRoot;
        if (shadowRoot) {
          const firstSpan = shadowRoot.querySelector("span");

          if (firstSpan) {
            firstSpan.style.pointerEvents = "auto";
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [mathfieldRef.current]);

  useEffect(() => {
    const closeOnOutsideClick = (event: MouseEvent) => {
      if (showPopover && event.target) {
        setShowPopover(false);
      }

      const gptDialog = document.getElementById("gpt-model");
      const gptButton = document.getElementById("gpt-button");

      if (gptButton && gptDialog) {
        if (
          !gptDialog.contains(event.target as Node) &&
          !gptButton.contains(event.target as Node)
        ) {
          setOpenGPTDialog(false);
        }
      }
    };

    window.addEventListener("click", closeOnOutsideClick);

    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    };
  }, [showPopover, openGPTDialog]);

  useEffect(() => {
    setAppState({
      ...calculateScrollCenter(elements, appState),
    });
    // eslint-disable-next-line
  }, [appState.isLoading]);

  useEffect(() => {
    appState?.editingLibrary?.libraryItems?.length &&
      appState?.editingLibrary?.isEditing &&
      handleEditingLibrary();
  }, [localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS)]);

  const updateLibraryItemElements = (
    newElements: ExcalidrawElement[],
    i: number,
  ) => {
    const clonedLibraryItems: LibraryItems = [
      ...appState.editingLibrary.libraryItems,
    ];

    if (i > 0) {
      clonedLibraryItems[i].elements = newElements;
    }
    return clonedLibraryItems;
  };

  const handleEditingLibrary = () => {
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");
    let currentPageElements = [] as any;
    if (isMyWorkSpaceData === "true") {
      currentPageElements = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS) ||
          "[]",
      );
    } else {
      currentPageElements = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS) || "[]",
      );
    }
    const libraryItems = appState.editingLibrary.libraryItems;
    const editingLibIndex = libraryItems.findIndex(
      (lib) => lib.id === appState.editingLibrary.libraryId,
    );
    if (editingLibIndex !== -1) {
      setAppState({
        editingLibrary: {
          ...appState.editingLibrary,
          libraryItems: updateLibraryItemElements(
            currentPageElements as ExcalidrawElement[],
            editingLibIndex,
          ),
        },
      });
    }
  };

  const handleSpinnerDialog = (data: {
    isTimer: boolean;
    timerDuration: {
      [timerDuration.MINUTES]: number;
      [timerDuration.SECONDS]: number;
    };
    segments: SegmentType[];
  }) => {
    // closeLibrary();
    setAppState({ isLibraryOpen: false });
    setOpenSpinnerDialog(!openSpinnerDialog);
    setSegments(data);
  };

  const closeMathInput = () => {
    setAppState(
      {
        formulaValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
        updatingFormulaElementId: "",
        showMathInputAndKeyboard: false,
      },
      () => {
        executeFinalizeAction();
      },
    );
    togglekeyboardShortcut(true);
    // @ts-ignore
    const divElement = document.querySelector(
      ".ML__keyboard.is-math-mode.can-paste.is-visible",
    );
    if (divElement) {
      // @ts-ignore
      mathfieldRef.current?.executeCommand("toggleVirtualKeyboard");
    }
  };

  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    const exportOpts = isStudent
      ? { saveFileToDisk: false }
      : UIOptions.canvasActions.export;

    return (
      <JSONExportDialog
        elements={elements}
        appState={appState}
        actionManager={actionManager}
        exportOpts={exportOpts}
        canvas={canvas}
        small={isStudent}
        setAppState={setAppState}
      />
    );
  };

  const createExporter = async (
    type: keyof typeof EXPORT_IMAGE_TYPES,
    exportedElements_: readonly NonDeletedExcalidrawElement[],
  ) => {
    const { exportedElements, exportingFrame } = prepareElementsForExport(
      elements,
      appState,
      true,
    );

    const fileHandle = await exportCanvas(
      type,
      exportedElements,
      appState,
      files,
      {
        ...appState,
        exportingFrame,
        name: app.getName(),
        exportBackground: appState.exportBackground,
        viewBackgroundColor: appState.viewBackgroundColor,
      },
    )
      .catch(muteFSAbortError)
      .catch((error) => {
        console.error(error);
        setAppState({ errorMessage: error.message });
      });

    if (
      appState.exportEmbedScene &&
      fileHandle &&
      isImageFileHandle(fileHandle)
    ) {
      setAppState({ fileHandle });
    }
  };

  const renderImageExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage) {
      return null;
    }

    return (
      <>
        <ImageExportDialog
          elements={elements}
          appState={appState}
          files={files}
          actionManager={actionManager}
          onExportToPng={createExporter}
          onExportToSvg={createExporter}
          onExportToClipboard={createExporter}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
        />

        {isStudent && appState.viewModeEnabled ? (
          !appState.isFullScreen ? (
            <ToolButton
              onClick={() => {
                setAppState({ isFullScreen: true });
                toggleZoomInZoomOut(true);
              }}
              data-testid="expand"
              icon={expand()}
              type={ToolButtonEnum.BUTTON}
              aria-label={t(`whiteBoard.toolBar.expand`)}
            />
          ) : (
            <ToolButton
              onClick={() => {
                setAppState({ isFullScreen: false });
                toggleZoomInZoomOut(false);
              }}
              data-testid="compress"
              icon={compress()}
              type={ToolButtonEnum.BUTTON}
              aria-label={t(`whiteBoard.toolBar.compress`)}
            />
          )
        ) : null}
      </>
    );
  };

  const renderSpinWheelDialog = useMemo(() => {
    return (
      <>
        {appState.selectedWorkspaceTab !==
          MyWorkspaceSidebarItemsEnum.NEW_WORKSPACE && (
          <ToolButton
            icon={<img src={spin} height="23px" width="23px" alt="" />}
            type={ToolButtonEnum.BUTTON}
            aria-label={t("buttons.spinner")}
            showAriaLabel={isMobile}
            title={t("buttons.spinner")}
            id="spinner-button"
            onClick={() => {
              setOpenSpinnerDialog(!openSpinnerDialog);
              setSegments({
                isTimer: false,
                timerDuration: {
                  [timerDuration.MINUTES]: 1,
                  [timerDuration.SECONDS]: 0,
                },
                segments: [],
              });
            }}
          />
        )}
        {openSpinnerDialog && (
          <SpinnerDialog
            open={openSpinnerDialog}
            setOpen={setOpenSpinnerDialog}
            onClose={() => setOpenSpinnerDialog(false)}
            insertImageOnCanvas={onImageActionFromLibrary}
            onTextWithStylesAction={onTextWithStylesAction}
            segmentsData={segments}
            onSelectLanguage={setLanguage}
            selectedlanguage={language}
          />
        )}
      </>
    );
    // eslint-disable-next-line
  }, [
    openSpinnerDialog,
    segments,
    isMobile,
    setSegments,
    setOpenSpinnerDialog,
    onImageActionFromLibrary,
    onTextWithStylesAction,
    language,
  ]);

  const renderWidgetButton = useMemo(() => {
    return (
      <ToolButton
        icon={widgets}
        type={ToolButtonEnum.BUTTON}
        aria-label={t("buttons.spinner")}
        showAriaLabel={isMobile}
        title={t("buttons.spinner")}
        className="widget"
        onClick={() => {
          setIsWidgetDialogOpen(true);
        }}
      />
    );
    // eslint-disable-next-line
  }, []);

  const renderSettingPopover = useMemo(() => {
    return (
      openSettingPopover && (
        <Settings
          actionManager={actionManager}
          setOpenSettingPopover={setOpenSettingPopover}
          appState={appState}
          left={isMobile ? 0 : 10}
          top={
            settingRef.current
              ? `${settingRef.current?.getBoundingClientRect().top - 10}px`
              : "50%"
          }
          elements={elements}
        />
      )
    );
    // eslint-disable-next-line
  }, [openSettingPopover, settingRef, isMobile]);

  const renderGPTDialog = () => {
    return (
      <GPTDialog
        appState={appState}
        startTextEditing={startTextEditing}
        openGPTDialog={openGPTDialog}
        setOpenGPTDialog={setOpenGPTDialog}
        setAppState={setAppState}
        onTextWithStylesAction={onTextWithStylesAction}
        gptCredit={gptCredit}
        setGptCredit={setGptCredit}
      />
    );
  };

  const renderEditMermaidDiagramDialog = useMemo(() => {
    return appState.EditMermaidDiagramDialog.open ? (
      <>
        <EditMermaidDiagramDialog
          editMermaidDiagramDialog={appState.EditMermaidDiagramDialog}
          appState={appState}
          setAppState={setAppState}
        />
      </>
    ) : (
      <></>
    );
    // eslint-disable-next-line
  }, [appState.EditMermaidDiagramDialog]);

  settings = (
    <div
      style={{ position: isMobile ? "absolute" : "relative" }}
      className={`${isMobile ? "settings-icon" : ""}`}
      ref={settingRef}
    >
      <ToolButton
        data-testid="setting-button"
        icon={settingOutlineIcon(appState.theme === "light" ? "#000" : "#fff")}
        type={ToolButtonEnum.BUTTON}
        aria-label={t("buttons.setting")}
        title={t("buttons.setting")}
        id="setting-button"
        onClick={() => setOpenSettingPopover(!openSettingPopover)}
      />
      {isMobile && renderSettingPopover}
    </div>
  );

  const handleButtonClick = () => {
    const perPageAudioLimit = subscription ? subscription?.audioLimit : 0;
    const perPageVideoLimit = subscription ? subscription.videoLimit : 0;
    const nonDeletedVideo = elements.filter(
      (data) => data.type === "video" && data.isDeleted === false,
    );
    const nonDeletedAudio = elements.filter(
      (data) => data.type === "audio" && data.isDeleted === false,
    );
    setCanUploadMedia({
      video: nonDeletedVideo.length < Number(perPageVideoLimit),
      audio: nonDeletedAudio.length < Number(perPageAudioLimit),
    });
    setShowPopover(!showPopover);
  };
  const renderViewModeCanvasActions = () => {
    return (
      <Section
        heading="canvasActions"
        className={clsx("zen-mode-transition", {
          "transition-left": zenModeEnabled,
        })}
      >
        {/* the zIndex ensures this menu has higher stacking order,
         see https://github.com/excalidraw/excalidraw/pull/1445 */}
        <Island
          padding={isStudent ? 0 : 2}
          style={{ zIndex: 1 }}
          className="vScroll"
        >
          <Stack.Col gap={1}>
            <Stack.Row gap={1}>
              {!isStudent && (
                <span
                  title={user?.mail}
                  className="d-flex justify-content-center"
                >
                  <Avatar
                    onClick={() => {
                      // const msg =
                      //   user?.displayName +
                      //   " clicked profile!\nYour Email is : " +
                      //   user?.mail;
                      // alert(msg);
                    }}
                    color={"#4c6ef5"}
                    border={"#4c6ef5"}
                  >
                    {user?.UrlPhoto ? (
                      <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                    ) : profile ? (
                      <img src={profile} alt={name} className="Avatar" />
                    ) : (
                      name
                    )}
                  </Avatar>
                </span>
              )}
              {renderJSONExportDialog()}
              {isStudent ? (
                <div className="d-none">{renderImageExportDialog()}</div>
              ) : (
                renderImageExportDialog()
              )}
            </Stack.Row>
          </Stack.Col>
        </Island>
      </Section>
    );
  };

  const renderPDFDialog = () => {
    return (
      <PdfPageSelectionDialog
        open={appState.pdfPageSelectionDialog}
        setAppState={setAppState}
        appState={appState}
        pdfFile={appState.pdfFile || null}
        onConfirmPageSelection={async (selectedPages) => {
          const slug =
            new URLSearchParams(window.location.search).get("slug") || "";
          const lessonId =
            new URLSearchParams(window.location.search)
              .get("lessonId")
              ?.replace(/\//g, "") || "";
          const getSettingsData = await getSettingData(slug);
          const allowedDocumentsPerLesson =
            getSettingsData?.data[0]?.allowedDocumentsPerLesson;
          setAllowedDocumentsPerLesson(allowedDocumentsPerLesson);
          const res = await getLessonWisePdfImportedData(lessonId);
          const pdfCount = res?.result.length ? res.result[0]?.pdfIds : [];
          const uuid = midUuid();

          if (allowedDocumentsPerLesson !== pdfCount?.length) {
            if (selectedPages.length > 0) {
              handlePdfFile(selectedPages, uuid);
              const body = {
                userId: user?.mail,
                slug,
                lessonId,
                pdfId: uuid,
              };
              pdfImportDocumentCounter(body);
            } else {
              setOpen(true);
            }
          } else {
            setLimitExceedModal(true);
          }
        }}
        app={app}
      />
    );
  };

  const handleAddClock = async () => {
    setAppState({
      activeTool: {
        type: "clock" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const clientX = appState.width / 2 + appState.offsetLeft;
    const clientY = appState.height / 2 + appState.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      appState,
    );
    const [gridX, gridY] = getGridPoint(x, y, appState.gridSize);
    const lessonId = await getLessonId();

    const element = newClockElement({
      type: "clock",
      x: gridX,
      y: gridY,
      strokeColor: appState.currentItemStrokeColor,
      backgroundColor: appState.currentItemBackgroundColor,
      fillStyle: appState.currentItemFillStyle,
      strokeWidth: appState.currentItemStrokeWidth,
      strokeStyle: appState.currentItemStrokeStyle,
      roundness: null,
      opacity: appState.currentItemOpacity,
      page: appState.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: null,
      isVisible: true,
      height: 300,
      width: 300,
    });
    setAppState({
      selectedElementIds: { [element.id]: true },
      activeTool: {
        type: "selection" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const localElement = JSON.parse(localStorage.getItem("acv") || "[]");
    const clockElement = [...localElement, element];
    app.scene.replaceAllElements(clockElement);
    setIsWidgetDialogOpen(false);
  };

  const handleAddCountDown = async () => {
    setAppState({
      activeTool: {
        type: "countdown" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const clientX = appState.width / 2 + appState.offsetLeft;
    const clientY = appState.height / 2 + appState.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      appState,
    );
    const [gridX, gridY] = getGridPoint(x, y, appState.gridSize);
    const lessonId = await getLessonId();

    const element = newCountDownElement({
      type: "countdown",
      x: gridX,
      y: gridY,
      strokeColor: appState.currentItemStrokeColor,
      backgroundColor: appState.currentItemBackgroundColor,
      fillStyle: appState.currentItemFillStyle,
      strokeWidth: appState.currentItemStrokeWidth,
      strokeStyle: appState.currentItemStrokeStyle,
      roundness: null,
      opacity: appState.currentItemOpacity,
      page: appState.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: null,
      isVisible: true,
      height: 300,
      width: 300,
    });
    setAppState({
      selectedElementIds: { [element.id]: true },
      activeTool: {
        type: "selection" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const localElement = JSON.parse(localStorage.getItem("acv") || "[]");
    const countDownElement = [...localElement, element];
    app.scene.replaceAllElements(countDownElement);
    setIsWidgetDialogOpen(false);
  };

  const handleChronometer = async () => {
    setAppState({
      activeTool: {
        type: "chronometer" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const clientX = appState.width / 2 + appState.offsetLeft;
    const clientY = appState.height / 2 + appState.offsetTop;

    const { x, y } = viewportCoordsToSceneCoords(
      { clientX, clientY },
      appState,
    );
    const [gridX, gridY] = getGridPoint(x, y, appState.gridSize);
    const lessonId = await getLessonId();

    const element = newChronometerElement({
      type: "chronometer",
      x: gridX,
      y: gridY,
      strokeColor: appState.currentItemStrokeColor,
      backgroundColor: appState.currentItemBackgroundColor,
      fillStyle: appState.currentItemFillStyle,
      strokeWidth: appState.currentItemStrokeWidth,
      strokeStyle: appState.currentItemStrokeStyle,
      roundness: null,
      opacity: appState.currentItemOpacity,
      page: appState.currentPage,
      lessonId: lessonId || "",
      link: null,
      index: null,
      locked: false,
      frameId: null,
      isVisible: true,
      height: 300,
      width: 300,
    });
    setAppState({
      selectedElementIds: { [element.id]: true },
      activeTool: {
        type: "selection" as any,
        customType: null,
        locked: false,
        lastActiveTool: null,
      },
    });
    const localElement = JSON.parse(localStorage.getItem("acv") || "[]");
    const chronometerElement = [...localElement, element];
    app.scene.replaceAllElements(chronometerElement);
    setIsWidgetDialogOpen(false);
  };

  const renderCanvasActions = () => (
    <Section
      heading="canvasActions"
      className={clsx(`zen-mode-transition`, {
        "transition-left": zenModeEnabled,
      })}
    >
      {/* the zIndex ensures this menu has higher stacking order,
         see https://github.com/excalidraw/excalidraw/pull/1445 */}

      {renderNotesPopover}
      {renderSettingPopover}
      <div id="background-color"></div>

      {!zenModeEnabled && (
        <Island padding={2} style={{ zIndex: 1 }} className="vScroll">
          <Stack.Col gap={4}>
            <Stack.Row gap={3} justifyContent="space-between">
              <span
                title={user?.mail}
                className="d-flex justify-content-center"
              >
                <Avatar onClick={() => {}} color={"#4c6ef5"} border={"#4c6ef5"}>
                  {user?.UrlPhoto ? (
                    <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                  ) : profile ? (
                    <img src={profile} alt={name} className="Avatar" />
                  ) : (
                    name
                  )}
                </Avatar>
              </span>
              <LibraryButton appState={appState} setAppState={setAppState} />
              <BackgroundPickerAndDarkModeToggle
                actionManager={actionManager}
                appState={appState}
                setAppState={setAppState}
                showThemeBtn={showThemeBtn}
                containerId="background-color"
              />
              {notes}
              {renderJSONExportDialog()}
              {actionManager.renderAction("loadScene")}
              {renderImageExportDialog()}
              {actionManager.renderAction("clearCanvas")}
              {subscription?.MagicACVBar &&
                subscription?.Diagrams &&
                renderGPTDialog()}
              {subscription?.SpinWheel && renderSpinWheelDialog}
              {renderWidgetButton}
              {renderEditMermaidDiagramDialog}
              <div className="position-relative d-flex justify-content-center">
                {settings}
              </div>
            </Stack.Row>
            {appState.fileHandle && (
              <>{actionManager.renderAction("saveToActiveFile")}</>
            )}
            {appState.pdfPageSelectionDialog && renderPDFDialog()}
            {confirmationModal && (
              <Dialog
                onCloseRequest={() => setConfirmationModal(false)}
                title={t("whiteBoard.areYousure")}
                closeOnClickOutside={false}
                open={confirmationModal}
                setOpen={() => false}
                children={
                  <>
                    <p>{t("whiteBoard.confirmation")}</p>
                    <div
                      className="d-flex justify-content-end"
                      style={{ gap: "5px" }}
                    >
                      <ToolButton
                        type={ToolButtonEnum.BUTTON}
                        title={"Yes"}
                        aria-label={"Yes"}
                        label={"Yes"}
                        onClick={async () => {
                          window.parent.postMessage(
                            { type: "STORE_ELEMENTS", isLoading: true },
                            `${process.env.REACT_APP_PARENT_APP}`,
                          );
                          setAppState({
                            ...appState,
                            isLoading: true,
                          });
                          const lessonId =
                            new URLSearchParams(window.location.search)
                              .get("lessonId")
                              ?.replace(/\//g, "") || "";

                          await onSaveElementInDB(
                            appState.currentPage,
                            lessonId || null,
                            appState.DBElements,
                            appState.viewBackgroundColor,
                          );
                          window.parent.postMessage(
                            { type: "STORE_ELEMENTS", isLoading: false },
                            `${process.env.REACT_APP_PARENT_APP}`,
                          );
                          setAppState({
                            ...appState,
                            isLoading: false,
                            textEditor: {
                              ...appState.textEditor,
                              open: false,
                            },
                          });
                          setPageChange(true);
                          setConfirmationModal(false);
                        }}
                        className="text-white w-fit-content save-formula-btn m-0 "
                      />
                      <ToolButton
                        type={ToolButtonEnum.BUTTON}
                        title={"No"}
                        aria-label={"No"}
                        label={"No"}
                        onClick={() => {
                          setConfirmationModal(false);
                          setPageChange(false);
                        }}
                        className="cancel-btn text-white w-fit-content save-formula-btn m-0 me-2"
                      />
                    </div>
                  </>
                }
                className="confirmationModal"
              />
            )}
            {isWidgetDialogOpen && (
              <Dialog
                onCloseRequest={() => setIsWidgetDialogOpen(false)}
                title={"Widgets"}
                closeOnClickOutside={false}
                open={isWidgetDialogOpen}
                setOpen={() => false}
                children={
                  <div className="card-content clearfix">
                    <div className="card left-half">
                      <div>
                        <img src={clock} alt="clock" className="clock_img" />
                        <div className="widget">
                          <div className="card-header-title">Clock</div>
                          <button
                            className="add-button"
                            onClick={handleAddClock}
                            disabled={
                              alreadyElementonCanvas.length > 0 &&
                              alreadyElementonCanvas.some(
                                (ele) => ele.type === "clock",
                              )
                            }
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card right-half">
                      <div>
                        <img
                          style={{
                            width: "auto",
                            height: "290px",
                            marginLeft: "-21px",
                          }}
                          src={countdown}
                          alt="countdown"
                          className="clock_img"
                        />
                        <div className="widget">
                          <div className="card-header-title">Count Down</div>
                          <button
                            className="add-button"
                            onClick={handleAddCountDown}
                            disabled={
                              alreadyElementonCanvas.length > 0 &&
                              alreadyElementonCanvas.some(
                                (ele) => ele.type === "countdown",
                              )
                            }
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="card left-half">
                      <div>
                        <img
                          src={chronometer}
                          alt="clock"
                          className="clock_img"
                          style={{
                            width: "auto",
                            height: "291px",
                            marginLeft: "-21px",
                          }}
                        />
                        <div className="widget">
                          <div className="card-header-title">Chronometer</div>
                          <button
                            className="add-button"
                            onClick={handleChronometer}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                className="widgetsModal"
              />
            )}
            {open && (
              <AlertDialog
                title="Warning"
                onClose={() => {
                  setOpen(false);
                }}
                small={true}
              >
                <p>
                  You need to select at least one page to import the PDF onto
                  the canvas.
                </p>
              </AlertDialog>
            )}
            {isCollaborationAlert && (
              <AlertDialog
                title="Warning"
                onClose={() => {
                  setIsCollaborationAlert(false);
                }}
                small={true}
              >
                <p>
                  Your collaboration time has expired. If you wish to extend it,
                  please restart the collaboration.
                </p>
              </AlertDialog>
            )}
            {limitExceedModal && (
              <AlertDialog
                title="Warning"
                onClose={() => {
                  setLimitExceedModal(false);
                }}
                small={true}
              >
                <p>{`Per lesson, you are only allowed to use ${allowedDocumentsPerLesson} document. Your limit has been exceeded`}</p>
                <span>
                  (Note: If you wish to import the PDF, please delete any
                  previously imported PDF from the canvas.)
                </span>
              </AlertDialog>
            )}
          </Stack.Col>
        </Island>
      )}
    </Section>
  );

  const renderSelectedShapeActions = () => (
    <Section
      heading="selectedShapeActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
    >
      <Island
        className={"App-menu__left shdw ml-6px"}
        padding={2}
        style={{
          // we want to make sure this doesn't overflow so substracting 200
          // which is approximately height of zoom footer and top left menu items with some buffer
          // if active file name is displayed, subtracting 248 to account for its height
          maxHeight: `${appState.height - (appState.fileHandle ? 248 : 200)}px`,
          left: appState.sidebarWrapper ? "15rem" : 0,
        }}
      >
        <SelectedShapeActions
          appState={appState}
          elements={elements}
          renderAction={actionManager.renderAction}
        />
      </Island>
    </Section>
  );

  const renderTextEditor = useCallback(() => {
    const { open, sceneX, sceneY } = appState.textEditor;
    const zoomFactor = appState.zoom.value; // Assuming you have a zoom level in appState

    let top = 0; // Default to 0
    let left = 0; // Default to 0

    if (sceneX || sceneY) {
      const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
        {
          sceneX,
          sceneY,
        },
        appState,
      );

      top = viewportY;
      left = viewportX;
    }

    // Use the dimensions of the editor for positioning
    const { width: editorWidth, height: editorHeight } = editorDimensions;

    // Calculate top and left ensuring the editor stays within the canvas bounds
    top = Math.min(top, window.innerHeight - editorHeight);
    left = Math.min(left, window.innerWidth - editorWidth);

    // Ensure top and left don't go negative
    top = Math.max(0, top);
    left = Math.max(0, left);

    return (
      open && (
        <div
          ref={editorRef} // Attach the ref to this div
          style={{
            top: `${top}px`,
            left: `${left}px`,
            position: "fixed",
            transform: `scale(${zoomFactor})`, // Apply scaling
            transformOrigin: "top left", // Ensures scaling from the editor's top-left corner
          }}
        >
          <TiptapEditor
            startTextEditing={startTextEditing}
            appState={appState}
            onTextWithStylesAction={onTextWithStylesAction}
            setAppState={setAppState}
            top={top}
            left={left}
          />
        </div>
      )
    );
    // eslint-disable-next-line
  }, [
    appState.textEditor,
    appState.updatingTextElement,
    editorDimensions, // Include editor dimensions in dependencies
    onTextWithStylesAction,
    appState.zoom, // Include zoom in dependencies
    setAppState,
    startTextEditing,
  ]);

  const setFontSizeByDivision = (
    mathFormulaValue: string | null | undefined,
  ) => {
    if (mathfieldRef.current) {
      if (!mathFormulaValue) {
        return;
      }
      const count = (mathFormulaValue.match(/\\frac/g) || []).length;
      mathfieldRef.current.setAttribute(
        "style",
        `font-size: ${16 + count * 1.7}px`,
      );
    }
  };

  const onChangeFormulaString = (
    e: React.SyntheticEvent<HTMLInputElement, MathFieldChangeEvent>,
  ) => {
    const mathFormulaValue = e.currentTarget.value;
    setFormulaInnerValue(mathFormulaValue);
    setFontSizeByDivision(mathFormulaValue);
    setAppState({
      selectedElementIds: {
        [Object.keys(appState.selectedElementIds)[0]]: true,
      },
    });
    mathFormulaValueRef.current = e.currentTarget.value;
    // mathFormulaValueRef.current = formulaInnerValue;
  };

  const renderFormulaTextBox = () => (
    <Section
      key="react-math-input-container"
      heading="mathFormulaInput"
      className="math-formula-container d-flex flex-column"
    >
      <div
        className="math-modal-layer"
        onClick={() => {
          closeMathInput();
        }}
      ></div>
      <div className="math-modal-wrapper">
        <div>
          <h5 className="modal-header p-0 border-0 text-center">
            {t("formula.title")}
          </h5>
        </div>
        {/* {!appState.updatingFormulaElementId ? ( */}
        <ToolButton
          key="newPage"
          type={ToolButtonEnum.BUTTON}
          aria-label="close"
          className={`position-absolute closeIcon`}
          icon={close("black")}
          size="medium"
          onClick={() => {
            closeMathInput();
          }}
        ></ToolButton>

        <div className="math-formula-wrapper">
          <MathView
            key={"react-math-input"}
            className="react-math-field mx-auto d-block"
            value={appState.formulaValue}
            onChange={onChangeFormulaString}
            ref={mathfieldRef}
            onLoad={() => console.error("math field input loaded")}
          />
        </div>
        {/* {!appState.updatingFormulaElementId ? ( */}
        <ToolButton
          className="bg-brand text-white mt-3 w-fit-content save-formula-btn mx-auto"
          key="save formula"
          type={ToolButtonEnum.BUTTON}
          title="Save"
          aria-label="Save"
          onClick={async () => {
            onMathFormulaAction(mathfieldRef, formulaInnerValue);
            const divElement = document.querySelector(
              ".ML__keyboard.is-math-mode.can-paste.is-visible",
            );
            if (divElement) {
              // @ts-ignore
              mathfieldRef.current?.executeCommand("toggleVirtualKeyboard");
            }
          }}
        >
          <span>{t("formula.button")}</span>
        </ToolButton>
        {/* ) : (
          <div className="pt-5"></div>
        )} */}
      </div>
    </Section>
  );

  const getWhiteboardLink = () => {
    const slug =
      new URLSearchParams(window.location.search)
        .get("slug")
        ?.replace(/\//g, "") || "";
    const lessonId =
      new URLSearchParams(window.location.search)
        .get("lessonId")
        ?.replace(/\//g, "") || "";

    const isCollaborating = localStorage.getItem("isCollaborating");
    const roomData = JSON.parse(localStorage.getItem("roomLinkData") || "{}");

    const whiteBoardLink =
      isCollaborating && roomData
        ? `${process.env.REACT_APP_URI}?slug=${slug}&lessonId=${lessonId}&W&F/#room=${roomData.roomId},${roomData.roomKey}`
        : `${process.env.REACT_APP_URI}?slug=${slug}&lessonId=${lessonId}&W&F`;

    return whiteBoardLink;
  };

  const renderWhiteboardModel = () => (
    <Section
      key="react-math-input-container"
      heading="mathFormulaInput"
      className="math-formula-container d-flex flex-column"
    >
      <div
        className="math-modal-layer"
        onClick={() => {
          setAppState({ whiteboardModel: false });
        }}
      ></div>
      <div className="math-modal-wrapper">
        <div>
          <h5 className="modal-header p-0 border-0 text-center ">
            {t("whiteBoardModel.title")}
          </h5>
        </div>
        <p className="mb-0 my-2">{t("whiteBoardModel.message")}</p>
        <ToolButton
          key="close"
          type={ToolButtonEnum.BUTTON}
          aria-label="close"
          className={`position-absolute closeIcon`}
          icon={close("black")}
          size="medium"
          onClick={() => {
            app.setActiveTool({ type: "selection" });
            setAppState({ whiteboardModel: false });
          }}
        ></ToolButton>
        <div className="d-flex justify-content-end">
          <ToolButton
            className="cancel-btn bg-danger text-white mt-3 w-fit-content save-formula-btn m-0 me-2"
            key={t("whiteBoardModel.cancelBtn")}
            type={ToolButtonEnum.BUTTON}
            title={t("whiteBoardModel.cancelBtn")}
            aria-label={t("whiteBoardModel.cancelBtn")}
            onClick={() => {
              app.setActiveTool({ type: "selection" });
              setAppState({ whiteboardModel: false });
            }}
          >
            <span>{t("whiteBoardModel.cancelBtn")}</span>
          </ToolButton>
          <ToolButton
            className="bg-brand text-white mt-3 w-fit-content save-formula-btn m-0 "
            key={t("whiteBoardModel.okBtn")}
            type={ToolButtonEnum.BUTTON}
            title={t("whiteBoardModel.okBtn")}
            aria-label={t("whiteBoardModel.okBtn")}
            onClick={() => {
              window.open(getWhiteboardLink(), "_blank");
              localStorage.setItem("openFormula", "true");
              localStorage.setItem("aOpenFormula", "true");
              app.setActiveTool({ type: "selection" });
              setAppState({ whiteboardModel: false });
            }}
          >
            <span>{t("whiteBoardModel.okBtn")}</span>
          </ToolButton>
        </div>
      </div>
    </Section>
  );

  const onPublishLibSuccess = useCallback(
    (data: LibraryData, libraryItems: LibraryItems) => {
      setShowPublishLibraryDialog(false);
      setPublishLibSuccess({ url: data.url, authorName: data.authorName });
      const nextLibItems = [...libraryItems];
      nextLibItems.forEach((libItem) => {
        libItem.status = "unpublished";
      });
      library.saveLibrary(nextLibItems);
      setLibraryItems(nextLibItems);
      setAppState({ isLibraryOpen: true });
    },
    // eslint-disable-next-line
    [
      setShowPublishLibraryDialog,
      setPublishLibSuccess,
      libraryItems,
      // selectedItems,
      library,
    ],
  );

  const editCollection = useCallback(
    (libraryItems: LibraryItems) => {
      const nextLibItems = [...libraryItems];
      nextLibItems.forEach((libItem) => {
        libItem.status = "unpublished";
      });
      library.saveLibrary(nextLibItems);
      setLibraryItems(nextLibItems);
      setAppState({ isLibraryOpen: true });
    },
    // eslint-disable-next-line
    [
      libraryItems,
      // selectedItems,
      library,
    ],
  );

  const closeLibrary = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({ isLibraryOpen: false });
  }, [setAppState]);

  const closeVideoRecorder = useCallback(() => {
    setAppState({ isOpenVideoRecorder: false, isOpenAudioRecorder: false });
  }, [setAppState]);

  const deselectItems = useCallback(() => {
    setAppState({
      selectedElementIds: {},
      selectedGroupIds: {},
    });
  }, [setAppState]);

  const renderNotesPopover = useMemo(() => {
    return (
      showPopover && (
        <div
          style={
            showPopover && !isMobile
              ? {
                  // transition: "all 0.3s",
                  display: "block",
                  left: isMobile ? 0 : 10,
                  top: `${
                    notesRef.current
                      ? `${
                          notesRef.current?.getBoundingClientRect().top +
                          notesRef.current?.getBoundingClientRect().height / 2
                        }px`
                      : "50%"
                  }`,
                }
              : isMobile && showPopover
              ? { display: "block" }
              : { display: "none" }
          }
          className={isMobile ? "popover-mobile" : "popover"}
        >
          <div
            className={`text-nowrap d-flex justify-content-center align-items-center rounded notes ${
              !canUploadMedia.video ? "disabled" : ""
            }`}
            onClick={() => {
              if (canUploadMedia.video) {
                setAppState({
                  isOpenVideoRecorder: true,
                  isOpenAudioRecorder: false,
                });
                setShowPopover(false);
              }
            }}
            data-tip="React-tooltip"
            data-for="video-tooltip"
          >
            <span className="ms-2">{videoIcon}</span>
            <span className="fs-6 px-3 py-2">{t("notes.video.title")}</span>
            {!canUploadMedia.video && (
              <ReactTooltip
                place="bottom"
                effect="solid"
                multiline={true}
                id="video-tooltip"
              >
                You've exceeded the limit. <br />
                You're allowed to upload a maximum of{" "}
                {subscription && subscription.videoLimit.toString()} videos per
              </ReactTooltip>
            )}
          </div>
          <div
            className={`text-nowrap d-flex justify-content-center align-items-center rounded mt-2 notes ${
              !canUploadMedia.audio ? "disabled" : ""
            }`}
            data-tip="React-tooltip"
            data-for="audio-tooltip"
            onClick={() => {
              if (canUploadMedia.audio) {
                setAppState({
                  isOpenAudioRecorder: true,
                  isOpenVideoRecorder: false,
                });
                setShowPopover(false);
              }
            }}
          >
            <span className="ms-2">{audioIcon}</span>
            <span className="fs-6 px-3 py-2">{t("notes.audio.title")}</span>
            {!canUploadMedia.audio && (
              // @ts-ignore
              <ReactTooltip
                place="bottom"
                effect="solid"
                multiline={true}
                id="audio-tooltip"
              >
                You've exceeded the limit. <br />
                You're allowed to upload a maximum of{" "}
                {subscription && subscription.audioLimit.toString()} audios per
                page.
              </ReactTooltip>
            )}
          </div>
        </div>
      )
    );
    // eslint-disable-next-line
  }, [showPopover, notesRef, isMobile]);

  const libraryMenu = appState.isLibraryOpen ? (
    <LibraryMenu
      pendingElements={getSelectedElements(elements, appState)}
      onClose={closeLibrary}
      onInsertShape={onInsertElements}
      onAddToLibrary={deselectItems}
      setAppState={setAppState}
      libraryReturnUrl={libraryReturnUrl}
      focusContainer={focusContainer}
      library={library}
      theme={appState.theme}
      files={files}
      id={id}
      appState={appState}
      insertImageOnCanvas={onImageActionFromLibrary}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      setShowPublishLibraryDialog={setShowPublishLibraryDialog}
      showPublishLibraryDialog={showPublishLibraryDialog}
      setPublishLibSuccess={setPublishLibSuccess}
      publishLibSuccess={publishLibSuccess}
      setLibraryItems={setLibraryItems}
      libraryItems={libraryItems}
      togglekeyboardShortcut={togglekeyboardShortcut}
      handleSpinnerDialog={handleSpinnerDialog}
      subscription={subscription}
      elements={elements}
      importCustomCategory={importCustomCategory}
      editCollection={editCollection}
      importLibraryFromUrl={importLibraryFromUrl}
      addToCollection={addToCollection}
      setAddToCollection={setAddToCollection}
      deleteElelement={deleteElelement}
      setDeleteElement={setDeleteElement}
      resetScene={resetScene}
    />
  ) : null;

  videoRecorder = appState.isOpenVideoRecorder ? (
    <VideoRecorder
      onClose={closeVideoRecorder}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      subscription={subscription}
    />
  ) : null;

  audioRecorder = appState.isOpenAudioRecorder ? (
    <AudioRecorder
      onClose={closeVideoRecorder}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      subscription={subscription}
    />
  ) : null;

  notes = (
    <div
      style={{ position: isMobile ? "absolute" : "relative" }}
      className={`${isMobile ? "notes-icon" : ""}`}
      ref={notesRef}
    >
      <div
        className="tool-button"
        onClick={handleButtonClick}
        role="button"
        tabIndex={0}
      >
        <ToolButton
          type={ToolButtonEnum.ICON}
          icon={recording}
          title={t("toolBar.video-audio-notes")}
          aria-label={t("toolBar.video-audio-notes")}
        />
      </div>
      {isMobile && renderNotesPopover}
    </div>
  );

  const libraryTemplateMenu = appState.isLibraryOpen ? (
    <LibraryMenu
      pendingElements={getSelectedElements(elements, appState)}
      onClose={closeLibrary}
      onInsertShape={onInsertElements}
      onAddToLibrary={deselectItems}
      setAppState={setAppState}
      libraryReturnUrl={libraryReturnUrl}
      focusContainer={focusContainer}
      library={library}
      theme={appState.theme}
      files={files}
      id={id}
      appState={appState}
      insertImageOnCanvas={onImageActionFromLibrary}
      insertVideoOnCanvas={onVideoActionFromLibrary}
      insertAudioOnCanvas={onAudioActionFromLibrary}
      setShowPublishLibraryDialog={setShowPublishLibraryDialog}
      showPublishLibraryDialog={showPublishLibraryDialog}
      setPublishLibSuccess={setPublishLibSuccess}
      publishLibSuccess={publishLibSuccess}
      setLibraryItems={setLibraryItems}
      libraryItems={libraryItems}
      togglekeyboardShortcut={togglekeyboardShortcut}
      handleSpinnerDialog={handleSpinnerDialog}
      subscription={subscription}
      elements={elements}
      importCustomCategory={importCustomCategory}
      editCollection={editCollection}
      importLibraryFromUrl={importLibraryFromUrl}
      addToCollection={addToCollection}
      setAddToCollection={setAddToCollection}
      deleteElelement={deleteElelement}
      setDeleteElement={setDeleteElement}
      resetScene={resetScene}
    />
  ) : null;

  const handleWorkspaceUpdate = async () => {
    setIsLoading(true); // Start the loader
    const selectedWorkspaceCardID = localStorage.getItem(
      "selectedWorkspaceCard",
    );
    const dataUrl = await captchScreenImage(appState.currentPage);

    const personalWorkSpaceElements = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_WORKSPACE_ELEMENTS) ||
        "[]",
    );

    try {
      if (selectedWorkspaceCardID) {
        await updateWorkspaceDetails(
          selectedWorkspaceCardID,
          personalWorkSpaceElements.length && personalWorkSpaceElements,
          dataUrl as string,
        );
      }
      const url = window.location.href;
      // Create a URL object
      const parsedUrl = new URL(url);
      // Get hash (fragment identifier)
      const room = parsedUrl.hash.replace("#room=", ""); // Removes "#room=" if needed
      if (room) {
        navigate(`/whiteboard?slug=${parsedUrl.searchParams.get("slug")}`);
      }

      if (
        appState.selectedWorkspaceTab ===
        MyWorkspaceSidebarItemsEnum.MYWORKSPACE
      ) {
        localStorage.setItem("isMyWorkSpace", "true");
      }
      const res = await getSharedWithMeWorkspace(user.mail);
      const inviteWorkspaceMembers = res.data.map((workspace: any) => {
        return workspace.InviteWorkspaceMembers[0]?.roomDetails || null;
      });
      // Find the workspace that matches the selectedWorkspaceCardID
      const activeWorkspace = res?.data?.find(
        (workspace: any) => workspace.id === selectedWorkspaceCardID,
      );

      // If a matching workspace is found, return the userEmail, otherwise return null
      const activeWorkspaceMembers = activeWorkspace
        ? activeWorkspace.InviteWorkspaceMembers[0]?.userEmail || null
        : null;

      const matchedUser = activeWorkspaceMembers?.find(
        (member: any) => member.email === user.mail,
      );

      const unmatchedUsers = activeWorkspaceMembers?.filter(
        (member: any) => member.email !== user.mail,
      );
      // Ensure that matchedUser is active, while unmatchedUsers remain as is
      const isActiveUser =
        unmatchedUsers?.length &&
        ([
          ...unmatchedUsers,
          { ...matchedUser, isActive: false }, // Ensure matched user has isActive set to true
        ] as [{ email: string | null; isActive: boolean }]);

      inviteWorkspaceMembers?.filter(
        (roomData: any) => roomData.workspaceId === selectedWorkspaceCardID,
      );

      await updateWorkspaceFeildInInviteWorkspaceModal(
        selectedWorkspaceCardID,
        isActiveUser,
      );

      // await window.publicClosePortal();
      toggleZoomInZoomOut(false);
      // Update state after successful API call
      setAppState({
        ...(appState || {}),
        selectedWorkspaceTab: MyWorkspaceSidebarItemsEnum.MYWORKSPACE,
        isShowWhiteboard: false,
        isMyWorkSpace: true,
        isFullScreen: false,
      });
    } catch (error) {
      console.error("Error updating or creating workspace", error);
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };

  const renderFixedSideContainer = () => {
    const shouldRenderSelectedShapeActions = showSelectedShapeActions(
      appState,
      elements,
    );
    const isMyWorkSpaceData = localStorage.getItem("isMyWorkSpace");

    return (
      <NotesTitleContext.Provider
        value={{
          fileName: appState.notesTitle,
          color: appState.notesColor,
          onColorChange: (color) => {
            setAppState({
              ...appState,
              notesColor: color,
            });
          },
          onFileNameChange: (title) => {
            setAppState({
              ...appState,
              notesTitle: title,
            });
          },
        }}
      >
        <FixedSideContainer side="top">
          <div
            className={`App-menu ${
              !appState.presentationMode
                ? "App-menu_top"
                : "App-menu_top App-menu_top_presentation"
            }`}
          >
            <Stack.Col
              gap={4}
              className={clsx({ "disable-pointerEvents": zenModeEnabled })}
            >
              {isMyWorkSpaceData === "true" ? (
                <button
                  className="bg-brand d-flex align-items-center justify-content-center text-white pageBtn"
                  style={{ gap: "10px", width: "90px" }}
                  onClick={async () => {
                    localStorage.removeItem("activeRoomLink");
                    localStorage.removeItem("isClicked");
                    await handleWorkspaceUpdate();
                    window.Portal();
                  }}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Back {backTo}</>
                  )}
                </button>
              ) : (
                !appState.presentationMode && (
                  <button
                    className="bg-brand px-3 py-2 text-white pageBtn"
                    onClick={() => {
                      setAppState({ sidebarWrapper: true });
                    }}
                  >
                    {t("multipage.pageNumber")}
                    {" " + appState.currentPage}
                  </button>
                )
              )}
              <SideBar
                appState={appState}
                onPageChange={onPageChange}
                onPageDelete={onPageDelete}
                setAppState={(
                  appState:
                    | AppState
                    | ((
                        prevState: Readonly<AppState>,
                        props: Readonly<any>,
                      ) => AppState | Pick<AppState, keyof AppState> | null)
                    | Pick<AppState, keyof AppState>
                    | null,
                ) => setAppState(appState)}
                isStudent={isStudent}
                setConfirmationModal={setConfirmationModal}
                pageChange={pageChange}
                setPageChange={setPageChange}
              />

              {shouldRenderSelectedShapeActions && renderSelectedShapeActions()}
            </Stack.Col>

            {(viewModeEnabled && isMyWorkSpaceData === "true") ||
              (((!viewModeEnabled &&
                !isStudent &&
                !appState.presentationMode) ||
                isJoinCollaboration) && (
                <>
                  <Section heading="shapes" className="top-menu-section">
                    {(heading: string) => (
                      <Stack.Col
                        gap={5}
                        align="start"
                        className="d-flex align-items-center"
                      >
                        <Stack.Row gap={1} className="shdw fit-content">
                          <LockButton
                            zenModeEnabled={zenModeEnabled}
                            checked={appState.elementLocked}
                            onChange={onLockToggle}
                            title={t("toolBar.lock")}
                          />
                          <Island
                            padding={1}
                            className={clsx({ "zen-mode": zenModeEnabled })}
                          >
                            <HintViewer
                              appState={appState}
                              elements={elements}
                              isMobile={isMobile}
                              library={library}
                              setLibraryItems={setLibraryItems}
                              focusContainer={focusContainer}
                              setAddToCollection={setAddToCollection}
                              setDeleteElement={setDeleteElement}
                              setAppState={setAppState}
                              resetScene={resetScene}
                            />
                            {heading}
                            <Stack.Row gap={1}>
                              <ShapesSwitcher
                                canvas={canvas}
                                activeTool={appState.activeTool}
                                setAppState={setAppState}
                                onImageAction={({ pointerType }) => {
                                  onImageAction({
                                    insertOnCanvasDirectly:
                                      pointerType !== "mouse",
                                  });
                                }}
                                appState={appState}
                                togglekeyboardShortcut={togglekeyboardShortcut}
                                toggleZoomInZoomOut={toggleZoomInZoomOut}
                                isStudent={isStudent}
                                isJoinCollaboration={isJoinCollaboration}
                                app={app}
                                celebration={celebration}
                                setCelebration={setCelebration}
                              />
                            </Stack.Row>
                          </Island>

                          {onCollabButtonClick &&
                            (isMyWorkSpaceData === "true" ? (
                              isOwnerUser ? (
                                <>
                                  {!isStudent && (
                                    <>
                                      <Island
                                        padding={1}
                                        className={`${
                                          !isCollaborating_ ? "pl-0 px-3" : ""
                                        } ${clsx({
                                          "zen-mode collabBtnIsland": `${zenModeEnabled} collabBtnIsland`,
                                        })}`}
                                      >
                                        <CollabButton
                                          isCollaborating={isCollaborating_}
                                          collaboratorCount={
                                            appState.collaborators.size
                                          }
                                          onClick={onCollabButtonClick}
                                        />
                                      </Island>
                                    </>
                                  )}

                                  {!isStudent &&
                                    isCollaboratingWithFlexibleGroups && (
                                      <>
                                        <Island
                                          padding={1}
                                          className={clsx({
                                            "zen-mode collabBtnIsland": `${zenModeEnabled} collabBtnIsland`,
                                          })}
                                        >
                                          <FlexibleGroupButton
                                            onClick={onFlexibleGroupButtonClick}
                                          />
                                        </Island>
                                      </>
                                    )}

                                  {!isStudent && isCollaborating_ && (
                                    <Island
                                      padding={1}
                                      className={clsx({
                                        "zen-mode collabBtnIsland p-1": `${zenModeEnabled} collabBtnIsland`,
                                      })}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary d-none"
                                        id="collaborationBtn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#collaboration"
                                      >
                                        Collaboration
                                      </button>
                                      <ToolButton
                                        type={ToolButtonEnum.BUTTON}
                                        icon={users}
                                        onClick={() => {
                                          const lessonId =
                                            new URLSearchParams(
                                              window.location.search,
                                            )
                                              .get("lessonId")
                                              ?.replace(/\//g, "") || "";
                                          const slug =
                                            new URLSearchParams(
                                              window.location.search,
                                            )
                                              .get("slug")
                                              ?.replace(/\//g, "") || "";
                                          getStudents(
                                            `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
                                          ).then(async (res) => {
                                            const studList = res?.result;
                                            const studs = localStorage.getItem(
                                              "students",
                                            )
                                              ? JSON.parse(
                                                  localStorage.getItem(
                                                    "students",
                                                  ) || "",
                                                )
                                              : [];
                                            const students = await studList.map(
                                              (stud: any) => {
                                                const student =
                                                  studs.length > 0 &&
                                                  studs.find(
                                                    (data: {
                                                      studEmail: string;
                                                    }) =>
                                                      data.studEmail ===
                                                      stud.StudentEmailId,
                                                  );
                                                if (
                                                  student.isWhiteboard === 2
                                                ) {
                                                  setStudentMessage({
                                                    ...studentSessionEndMessage,
                                                  });
                                                } else if (
                                                  student.isWhiteboard === false
                                                ) {
                                                  setStudentMessage({
                                                    ...studentAccessRevokeMessage,
                                                  });
                                                }

                                                return {
                                                  studEmail:
                                                    stud.StudentEmailId,
                                                  studName: stud.StudentName,
                                                  photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
                                                  isWhiteboard: student
                                                    ? student.isWhiteboard !== 2
                                                      ? student.isWhiteboard
                                                      : true
                                                    : true,
                                                  idle: false,
                                                };
                                              },
                                            );
                                            window.localStorage.setItem(
                                              "students",
                                              JSON.stringify(students),
                                            );
                                          });
                                          document
                                            .getElementById("collaborationBtn")
                                            ?.click();
                                        }}
                                        title={`Collaborators`}
                                        aria-label={`Collaborators`}
                                      />
                                    </Island>
                                  )}
                                </>
                              ) : null
                            ) : (
                              <>
                                {!isStudent && (
                                  <>
                                    <Island
                                      padding={1}
                                      className={`${
                                        !isCollaborating_ ? "pl-0 px-3" : ""
                                      } ${clsx({
                                        "zen-mode collabBtnIsland": `${zenModeEnabled} collabBtnIsland`,
                                      })}`}
                                    >
                                      <CollabButton
                                        isCollaborating={isCollaborating_}
                                        collaboratorCount={
                                          appState.collaborators.size
                                        }
                                        onClick={onCollabButtonClick}
                                      />
                                    </Island>
                                  </>
                                )}

                                {!isStudent &&
                                  isCollaboratingWithFlexibleGroups && (
                                    <>
                                      <Island
                                        padding={1}
                                        className={clsx({
                                          "zen-mode collabBtnIsland": `${zenModeEnabled} collabBtnIsland`,
                                        })}
                                      >
                                        <FlexibleGroupButton
                                          onClick={onFlexibleGroupButtonClick}
                                        />
                                      </Island>
                                    </>
                                  )}

                                {!isStudent && isCollaborating_ && (
                                  <Island
                                    padding={1}
                                    className={clsx({
                                      "zen-mode collabBtnIsland p-1": `${zenModeEnabled} collabBtnIsland`,
                                    })}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary d-none"
                                      id="collaborationBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#collaboration"
                                    >
                                      Collaboration
                                    </button>
                                    <ToolButton
                                      type={ToolButtonEnum.BUTTON}
                                      icon={users}
                                      onClick={() => {
                                        const lessonId =
                                          new URLSearchParams(
                                            window.location.search,
                                          )
                                            .get("lessonId")
                                            ?.replace(/\//g, "") || "";
                                        const slug =
                                          new URLSearchParams(
                                            window.location.search,
                                          )
                                            .get("slug")
                                            ?.replace(/\//g, "") || "";
                                        getStudents(
                                          `${ACV_API_URL}/api/record/get-student-record-for-whiteBaord/${lessonId}?slug=${slug}`,
                                        ).then(async (res) => {
                                          const studList = res?.result;
                                          const studs = localStorage.getItem(
                                            "students",
                                          )
                                            ? JSON.parse(
                                                localStorage.getItem(
                                                  "students",
                                                ) || "",
                                              )
                                            : [];
                                          const students = await studList.map(
                                            (stud: any) => {
                                              const student =
                                                studs.length > 0 &&
                                                studs.find(
                                                  (data: {
                                                    studEmail: string;
                                                  }) =>
                                                    data.studEmail ===
                                                    stud.StudentEmailId,
                                                );
                                              if (student.isWhiteboard === 2) {
                                                setStudentMessage({
                                                  ...studentSessionEndMessage,
                                                });
                                              } else if (
                                                student.isWhiteboard === false
                                              ) {
                                                setStudentMessage({
                                                  ...studentAccessRevokeMessage,
                                                });
                                              }

                                              return {
                                                studEmail: stud.StudentEmailId,
                                                studName: stud.StudentName,
                                                photo: `${stud.StudentPhoto.Foto}?${res?.SASToken}`,
                                                isWhiteboard: student
                                                  ? student.isWhiteboard !== 2
                                                    ? student.isWhiteboard
                                                    : true
                                                  : true,
                                                idle: false,
                                              };
                                            },
                                          );
                                          window.localStorage.setItem(
                                            "students",
                                            JSON.stringify(students),
                                          );
                                        });
                                        document
                                          .getElementById("collaborationBtn")
                                          ?.click();
                                      }}
                                      title={`Collaborators`}
                                      aria-label={`Collaborators`}
                                    />
                                  </Island>
                                )}
                              </>
                            ))}
                        </Stack.Row>
                        {videoRecorder}
                        {audioRecorder}
                        {showPublishLibraryDialog && (
                          <PublishLibrary
                            onClose={() => setShowPublishLibraryDialog(false)}
                            libraryItems={appState.editingLibrary.libraryItems}
                            appState={appState}
                            onSuccess={(data: LibraryData) => {
                              onPublishLibSuccess(
                                data,
                                appState.editingLibrary.libraryItems,
                              );
                              removeOldLibraryElements();
                              setShowPublishLibraryDialog(false);
                            }}
                            onError={(error) => window.alert(error)}
                            updateItemsInStorage={() =>
                              library.saveLibrary(libraryItems)
                            }
                            onRemove={(id: string) => {}}
                            files={files}
                            isTrusted={0}
                          />
                        )}
                      </Stack.Col>
                    )}
                  </Section>
                  {renderTextEditor()}
                  {appState.showMathInputAndKeyboard && renderFormulaTextBox()}
                </>
              ))}
            {appState.presentationMode && (
              <PresentationMode
                app={app}
                setCelebration={setCelebration}
                setAppState={setAppState}
                appState={appState}
                actionManager={actionManager}
              />
            )}
            <Celebration
              trigger={celebration}
              setCelebration={setCelebration}
            />
            {!zenModeEnabled && !appState.presentationMode && (
              <div>
                <div className={`sidebar ${openSidebar ? "open" : ""}`}>
                  <Section heading="canvasActions" className="fixedBtmLsn">
                    <div
                      className="pin d-flex"
                      onClick={() => setPinSidebar(!pinSidebar)}
                    >
                      {pinSidebar ? pinFill : pin}
                    </div>
                    <Island padding={1}>
                      {isMyWorkSpaceData === "true"
                        ? `Owner Name: ${workspaceData?.userEmail}`
                        : `${role ? `${role} : ` : "Student : "}${displayName}`}
                    </Island>
                    <Island padding={1}>
                      {isMyWorkSpaceData === "true"
                        ? `Workspace Name: ${workspaceData?.name}`
                        : lesson.ClassName
                        ? `Class: ${lesson?.ClassName}`
                        : "Collaboration mode"}
                    </Island>
                    <Island padding={1}>
                      {isMyWorkSpaceData === "true"
                        ? `Created Date: ${workspaceData?.createdAt}`
                        : lesson?.LessonDate
                        ? `LessonDate: ${moment
                            .utc(lesson?.LessonDate)
                            .local()
                            .format("YYYY-MM-DD")}`
                        : "Collaboration mode"}
                    </Island>
                  </Section>
                </div>

                <button
                  className="arrow-button"
                  onClick={() => setOpenSidebar(!pinSidebar && !openSidebar)}
                >
                  <span className={`arrow-icon ${openSidebar ? "open" : ""}`}>
                    ➤
                  </span>
                </button>
              </div>
            )}

            {isStudent && (
              <>
                <div className="fixedTopProfile" title={user.mail}>
                  <Avatar
                    onClick={() => {}}
                    color={"#4c6ef5"}
                    border={"#4c6ef5"}
                  >
                    {user?.UrlPhoto ? (
                      <img src={user?.UrlPhoto} alt={name} className="Avatar" />
                    ) : profile ? (
                      <img src={profile} alt={name} className="Avatar" />
                    ) : (
                      name
                    )}
                  </Avatar>
                  {isJoinCollaboration && (
                    <>
                      <LibraryButton
                        appState={appState}
                        setAppState={setAppState}
                      />
                      <LibraryTemplateButton
                        appState={appState}
                        setAppState={setAppState}
                      />
                    </>
                  )}

                  {viewModeEnabled && renderViewModeCanvasActions()}
                  {/* <ToolButton
                      className="Card-button"
                      icon={exportImage}
                      type="button"
                      aria-label={t(
                        "whiteBoard.actionBar.export.saveAsImage.title",
                      )}
                      title={t("buttons.exportImage")}
                      showAriaLabel={false}
                      onClick={() => {
                        document.getElementById("exportAsImage")?.click();
                      }}
                    /> */}
                  {isJoinCollaboration === false && (
                    <ShapesSwitcher
                      canvas={canvas}
                      activeTool={appState.activeTool}
                      setAppState={setAppState}
                      onImageAction={({ pointerType }) => {
                        onImageAction({
                          insertOnCanvasDirectly: pointerType !== "mouse",
                        });
                      }}
                      appState={appState}
                      togglekeyboardShortcut={togglekeyboardShortcut}
                      toggleZoomInZoomOut={toggleZoomInZoomOut}
                      isStudent={isStudent}
                      isJoinCollaboration={isJoinCollaboration}
                      app={app}
                      celebration={celebration}
                      setCelebration={setCelebration}
                    />
                  )}
                </div>
                <div className="d-flex">{libraryMenu}</div>
              </>
            )}

            {isStudent ? (
              <div className="students">
                <Section heading="canvasActions" className="fixedBtm">
                  <Island padding={2}>
                    <Portall containerId="zoom_btn">
                      <ZoomActions
                        renderAction={actionManager.renderAction}
                        zoom={appState.zoom}
                      />
                    </Portall>
                  </Island>
                </Section>
              </div>
            ) : !appState.presentationMode ? (
              <div
                className="d-flex right-side-panel h-100"
                style={{ position: "absolute" }}
              >
                {libraryTemplateMenu}
                <div
                  className={clsx(
                    "layer-ui__wrapper__top-right zen-mode-transition w80px justifycenter",
                    {
                      "transition-right": zenModeEnabled,
                    },
                  )}
                >
                  <Stack.Col
                    gap={10}
                    style={{ margin: "15vh" }}
                    className={clsx({
                      "disable-pointerEvents m15vh": zenModeEnabled,
                    })}
                  >
                    {viewModeEnabled
                      ? renderViewModeCanvasActions()
                      : renderCanvasActions()}
                    <Section heading="canvasActions" className="fixedBtm">
                      <Island padding={2}>
                        <Portall containerId="zoom_btn">
                          <ZoomActions
                            renderAction={actionManager.renderAction}
                            zoom={appState.zoom}
                          />
                        </Portall>
                      </Island>
                    </Section>
                  </Stack.Col>
                </div>
                {appState.isOutlineOpen && (
                  <OutlineSidebar
                    app={app}
                    setAppState={setAppState}
                    appState={appState}
                  />
                )}
              </div>
            ) : null}
          </div>
        </FixedSideContainer>
      </NotesTitleContext.Provider>
    );
  };

  const [frames, setFrames] = useState<ExcalidrawFrameElement[]>([]);

  useEffect(() => {
    const elements = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS) ?? "[]",
    );
    const frames_ = elements.filter(
      (item: { type: string }) => item.type === "frame",
    );
    // .sort((a: { order: number }, b: { order: number }) => a.order - b.order);

    setFrames([...frames_]);
  }, [localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS)]);

  const moveFrame = (dragIndex: number, dropIndex: number) => {
    setAppState({ isDragAndDrop: true });
    const updatedFrames = [...frames];
    const [draggedFrame] = updatedFrames.splice(dragIndex, 1);
    updatedFrames.splice(dropIndex, 0, draggedFrame);

    const updatedFramesWithOrder = updatedFrames.map((frame, index) => ({
      ...frame,
      order: index,
    }));

    setFrames(updatedFramesWithOrder);

    const allElements = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS) ?? "[]",
    );
    const shapeElemets = allElements.filter(
      (element: any) => element.type !== "frame",
    );

    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
      JSON.stringify([...updatedFramesWithOrder, ...shapeElemets]),
    );
    app.scene.replaceAllElements([...updatedFramesWithOrder, ...shapeElemets]);
  };

  const renderBottomAppMenu = () => {
    return (
      <footer
        role="contentinfo"
        className="layer-ui__wrapper__footer App-menu App-menu_bottom"
      >
        <div
          className={clsx(
            "layer-ui__wrapper__footer-left zen-mode-transition",
            {
              "layer-ui__wrapper__footer-left--transition-left": zenModeEnabled,
            },
          )}
        >
          <Stack.Col gap={2}>
            <Section heading="canvasActions">
              {/* <Island padding={1}>
                <ZoomActions
                  renderAction={actionManager.renderAction}
                  zoom={appState.zoom}
                />
              </Island> */}
              {!viewModeEnabled && (
                <>
                  <div
                    className={clsx(
                      "undo-redo-buttons zen-mode-transition pb-2",
                      {
                        "layer-ui__wrapper__footer-left--transition-bottom": zenModeEnabled,
                      },
                    )}
                  >
                    {!isCollaborating_ &&
                      actionManager.renderAction("undo", { size: "small" })}
                    {!isCollaborating_ &&
                      actionManager.renderAction("redo", { size: "small" })}
                    {/* <ToolButton
                    className="bg-brand text-white pr-2"
                    key="moreTools"
                    type="button"
                    title={"More Tools"}
                    aria-label={"More Tools"}
                    icon={moreTools}
                    onClick={() => {
                      alert("Fuctionality will be added soon!");
                    }}
                  >
                    <span>More Tools</span>
                  </ToolButton> */}
                    {!user.role && <SignOutButton />}
                  </div>
                  {isMobileView && <div id="zoom_btn"></div>}
                </>
              )}
            </Section>
          </Stack.Col>
        </div>
        {/* <div
          className={clsx(
            "layer-ui__wrapper__footer-center zen-mode-transition",
            {
              "layer-ui__wrapper__footer-left--transition-bottom": zenModeEnabled,
            },
          )}
        >
          {renderCustomFooter?.(false, appState)}
        </div> */}
        {/* <div
          className={clsx(
            "layer-ui__wrapper__footer-right zen-mode-transition",
            {
              "transition-right disable-pointerEvents": zenModeEnabled,
            },
          )}
        >
          {actionManager.renderAction("toggleShortcuts")}
        </div> */}
        <button
          className={clsx("disable-zen-mode", {
            "disable-zen-mode--visible": showExitZenModeBtn,
          })}
          onClick={toggleZenMode}
        >
          {t("buttons.exitZenMode")}
        </button>
      </footer>
    );
  };

  const dialogs = (
    <>
      {appState.isLoading && <LoadingMessage />}
      {appState.errorMessage && (
        <ErrorDialog
          message={appState.errorMessage}
          onClose={() => setAppState({ errorMessage: null })}
        />
      )}
      {appState.showHelpDialog && (
        <HelpDialog
          onClose={() => {
            setAppState({ showHelpDialog: false });
          }}
        />
      )}
      {/* {appState.pasteDialog.shown && (
        <PasteChartDialog
          setAppState={setAppState}
          appState={appState}
          onInsertChart={onInsertElements}
          onClose={() =>
            setAppState({
              pasteDialog: { shown: false, data: null },
            })
          }
        />
      )} */}
    </>
  );

  const renderPublishSuccess = useCallback(() => {
    return (
      <Dialog
        onCloseRequest={() => setPublishLibSuccess(null)}
        title={t("publishSuccessDialog.title")}
        className="publish-library-success"
        small={true}
        children={
          <>
            <p>
              {t("publishSuccessDialog.content", {
                authorName: publishLibSuccess!.authorName,
              })}{" "}
            </p>
            <ToolButton
              type={ToolButtonEnum.BUTTON}
              title={t("buttons.close")}
              aria-label={t("buttons.close")}
              label={t("buttons.close")}
              onClick={() => setPublishLibSuccess(null)}
              data-testid="publish-library-success-close"
              className="publish-library-success-close"
            />
          </>
        }
        closeOnClickOutside={false}
        open={false}
        setOpen={() => false}
      />
    );
  }, [setPublishLibSuccess, publishLibSuccess]);

  return isMobile ? (
    <div className="mobile-menu">
      {appState.showMathInputAndKeyboard && renderFormulaTextBox()}
      {renderTextEditor()}
      {appState.whiteboardModel && renderWhiteboardModel()}
      {appState.pdfPageSelectionDialog && renderPDFDialog()}

      {dialogs}
      <MobileMenu
        appState={appState}
        elements={elements}
        actionManager={actionManager}
        libraryMenu={libraryMenu}
        renderJSONExportDialog={renderJSONExportDialog}
        renderImageExportDialog={renderImageExportDialog}
        renderGPTDialog={renderGPTDialog}
        renderEditMermaidDiagramDialog={renderEditMermaidDiagramDialog}
        setAppState={setAppState}
        onCollabButtonClick={onCollabButtonClick}
        onLockToggle={onLockToggle}
        canvas={canvas}
        isCollaborating={isCollaborating_}
        renderCustomFooter={renderCustomFooter}
        viewModeEnabled={viewModeEnabled}
        showThemeBtn={showThemeBtn}
        renderTopRightUI={renderTopRightUI}
        isStudent={isStudent}
        onImageAction={onImageAction}
        togglekeyboardShortcut={togglekeyboardShortcut}
        onFlexibleGroupButtonClick={onFlexibleGroupButtonClick}
        isCollaboratingWithFlexibleGroups={isCollaboratingWithFlexibleGroups}
        zenModeEnabled={zenModeEnabled}
        sidebarWrapper={sidebarWrapper}
        onPageChange={onPageChange}
        onPageDelete={onPageDelete}
        toggleZoomInZoomOut={toggleZoomInZoomOut}
        renderSpinWheelDialog={renderSpinWheelDialog}
        library={library}
        setLibraryItems={setLibraryItems}
        focusContainer={focusContainer}
        setAddToCollection={setAddToCollection}
        setDeleteElement={setDeleteElement}
        resetScene={resetScene}
        subscription={subscription}
        setConfirmationModal={setConfirmationModal}
        pageChange={pageChange}
        setPageChange={setPageChange}
        isOwnerUser={isOwnerUser}
        app={app}
        celebration={celebration}
        setCelebration={setCelebration}
        frames={frames}
        setFrames={setFrames}
        moveFrame={moveFrame}
      />
    </div>
  ) : (
    <div
      className={clsx("layer-ui__wrapper", {
        "disable-pointerEvents":
          appState.draggingElement ||
          appState.resizingElement ||
          (appState.editingElement && !isTextElement(appState.editingElement)),
      })}
    >
      {dialogs}
      {isStudent && viewModeEnabled && (
        <div className="m-3" style={{ position: "absolute", left: 0, top: 0 }}>
          {renderViewModeCanvasActions()}
        </div>
      )}
      {renderFixedSideContainer()}
      {renderBottomAppMenu()}
      {publishLibSuccess && renderPublishSuccess()}
      {appState.scrolledOutside && (
        <button
          className="scroll-back-to-content"
          onClick={() => {
            setAppState({
              ...calculateScrollCenter(elements, appState),
            });
          }}
        >
          {t("buttons.scrollBackToContent")}
        </button>
      )}
    </div>
  );
};

const areEqual = (prev: LayerUIProps, next: LayerUIProps) => {
  const getNecessaryObj = (appState: AppState): Partial<AppState> => {
    const {
      suggestedBindings,
      startBoundElement: boundElement,
      ...ret
    } = appState;
    return ret;
  };
  const prevAppState = getNecessaryObj(prev.appState);
  const nextAppState = getNecessaryObj(next.appState);

  const keys = Object.keys(prevAppState) as (keyof Partial<AppState>)[];
  return (
    prev.renderCustomFooter === next.renderCustomFooter &&
    prev.langCode === next.langCode &&
    prev.elements === next.elements &&
    prev.files === next.files &&
    keys.every((key) => prevAppState[key] === nextAppState[key])
  );
};

export default React.memo(LayerUI, areEqual);
