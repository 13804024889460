import { useEffect, useState } from "react";
import { IconPickerItem } from "react-fa-icon-picker";
import {
  getCategories,
  getUserTemplateCategories,
  removeCategoryFromLibraryTemplate,
  updateLibraryTemplateCategory,
} from "../../../../../../excalidraw-app/api/collection";
import { t } from "../../../../../../i18n";
import { del } from "../../../../../icons";
import { Popover } from "../../../../../Popover";
import { Toast, ToastType } from "../../../../../Toast";
import { SidebarItemsProps } from "../../../../LibraryItemsDialog";
import "./style.scss";
import Loader from "../../../../../../App/shareComponent/Loader";

interface MoveToCategoryPopoverProps {
  setOpenCategoryPopover: (val: boolean) => void;
  templateId: string;
  onAfterMovedToCategory: (category: string[]) => void;
  templateCategory: string[];
  onAfterDeleteCategory: (id: string) => void;
  popoverRight?: string;
  isInProgress?: boolean;
  onSubmitInprogressLib?: () => void;
  addCollectionIdToUnPublishedItems?: (id: string | null) => void;
  setToastMessage: React.Dispatch<{
    message: string;
    type: ToastType;
  } | null>;
}

export const MoveToCategoryPopover = ({
  setOpenCategoryPopover,
  templateId,
  onAfterMovedToCategory,
  templateCategory: template_category,
  onAfterDeleteCategory,
  popoverRight = "0",
  isInProgress = false,
  onSubmitInprogressLib,
  addCollectionIdToUnPublishedItems,
  setToastMessage: setToastMessageForMoveToCategory,
}: MoveToCategoryPopoverProps) => {
  const [categories, setCategories] = useState<SidebarItemsProps[]>([]);
  const [filterCategory, setFilterCategory] = useState<SidebarItemsProps[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [applyCategoryLoader, setApplyCategoryLoader] = useState<boolean>(
    false,
  );

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const slug = new URLSearchParams(window.location.search).get("slug");

  const [templateCategory, setTemplateCategory] = useState<string[]>([]);

  useEffect(() => {
    setLoading(true);
    const fetchCategory = async () => {
      const templateCategory = await getUserTemplateCategories(
        user?.username ? user.username : user.mail,
        slug ?? "",
        templateId,
      );

      if (templateCategory?.result) {
        setTemplateCategory([...templateCategory?.result.category]);
      }

      const categoryList = await getCategories(
        user?.username ? user.username : user.mail,
        slug ?? "",
      );
      categoryList?.result && setCategories(categoryList.result);
      setLoading(false);
    };
    fetchCategory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (search) {
      const filteredData = categories.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase()),
      );
      return setFilterCategory(filteredData);
    }
    setFilterCategory(categories);
  }, [search, categories]);

  const handleCheckboxChange = (itemId: string) => {
    // Check if itemId is already in checkedItems
    if (selectedCategory.includes(itemId)) {
      // Remove itemId if already checked
      setSelectedCategory(selectedCategory.filter((id) => id !== itemId));
    } else {
      // Add itemId if not already checked
      setSelectedCategory([...selectedCategory, itemId]);
    }
  };

  const onSubmit = async () => {
    if (selectedCategory.length === 0) {
      return;
    }

    setApplyCategoryLoader(true);
    let libId = "";
    if (isInProgress) {
      libId = (onSubmitInprogressLib && (await onSubmitInprogressLib())) || "";
    }

    const payload = {
      categoryIds: selectedCategory,
      collectionId: isInProgress ? libId : templateId,
      userEmail: user?.username ? user.username : user.mail,
      slug,
    };

    await updateLibraryTemplateCategory(payload);
    onAfterMovedToCategory(selectedCategory);
    setSelectedCategory([]);
    setOpenCategoryPopover(false);
    setApplyCategoryLoader(false);
    setToastMessageForMoveToCategory({
      message: "Collection successfully imported.",
      type: ToastType.SUCCESS,
    });
  };

  return (
    <div className="category_popover">
      <Popover right={popoverRight}>
        <div className="h-lg-25 d-flex">
          <input
            type="search"
            className="category_search mb-1"
            placeholder="Search"
            value={search ?? ""}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
          />
        </div>
        <div className="h-lg-50 overflow-auto">
          {loading ? (
            <div className="d-flex justify-content-center w-100 p-1">
              Loading..
            </div>
          ) : (
            filterCategory.map((item, index) => (
              <div className="d-flex gap-2 category_wrapper w-100 align-items-center">
                <input
                  type="checkbox"
                  className="filter-checkbox"
                  id={`${item.title}-${index}`}
                  onChange={() => handleCheckboxChange(item.id)}
                  disabled={templateCategory.includes(item.id)}
                />
                {<IconPickerItem icon={item.icon} size={16} />}
                <label
                  title={item.title}
                  className="category_name"
                  htmlFor={`${item.title}-${index}`}
                >
                  {item.title}
                </label>
                {templateCategory.includes(item.id) && (
                  <div
                    className="btn-remove mb-2"
                    style={{ height: 12, width: 12 }}
                    onClick={() => {
                      if (templateCategory.length === 1) {
                        addCollectionIdToUnPublishedItems &&
                          addCollectionIdToUnPublishedItems(null);
                      }
                      removeCategoryFromLibraryTemplate(
                        templateId,
                        user?.username ? user.username : user.mail,
                        slug ?? "",
                        item.id,
                      );
                      setToastMessageForMoveToCategory({
                        message: "Successfully removed collection.",
                        type: ToastType.ERROR,
                      });
                      onAfterDeleteCategory(item.id);
                      const updatedTemplateCategory = templateCategory.filter(
                        (category) => category !== item.id,
                      );
                      setTemplateCategory([...updatedTemplateCategory]);
                    }}
                  >
                    {del}
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        <div className="d-flex gap-2 justify-content-end align-items-center h-lg-25">
          <button
            type="button"
            className="filter-submit-btn"
            onClick={onSubmit}
          >
            {applyCategoryLoader ? (
              <Loader className="category_loader" />
            ) : (
              t("previewDialog.submit")
            )}
          </button>
          <button
            type="button"
            className="filter-cancel-btn"
            onClick={() => {
              setSelectedCategory([]);
              setOpenCategoryPopover(false);
            }}
          >
            {t("previewDialog.cancel")}
          </button>
        </div>
      </Popover>
    </div>
  );
};
