import { useContext, useState } from "react";
import celebration from "../../../../../../assests/celebration.gif";
import { Dialog } from "../../../../../Dialog";
import { SpinnerContext } from "../../../../contexts";
import "./style.css";

type ResultDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  results: string[];
  startSpinnerTimer: () => void;
  setResults: (results: string[]) => void;
};

export const ResultDialog = (props: ResultDialogProps) => {
  const { open, setOpen, results, startSpinnerTimer } = props;
  const { segments, setSegments } = useContext(SpinnerContext);
  const [showRemove, setShowRemove] = useState<boolean>(true);
  return (
    <Dialog
      children={
        <div className="text-center position-relative result-wrapper">
          <p>Your win result is:</p>
          <div className="text-left pb-2">
            {/* {segments && segments.length > 0 ? (
              segments.map((data, index) => {
                return data.results.map((result, index) => {
                  return (
                    <h4 key={index}>{`Wheel ${data.wheel}: ${result}`}</h4>
                  );
                });
              })
            ) : (
              <h3>No results</h3>
            )} */}
            {results && results.length > 0 ? (
              results.map((result, index) => (
                <h4 key={index}>{`${index + 1}) ${result}`}</h4>
              ))
            ) : (
              <h3>No results</h3>
            )}
          </div>

          <div className="position-absolute w-100 bottom-0 d-flex gap-2 justify-content-center">
            {showRemove && (
              <button
                className="btn btn-sw-remove"
                onClick={() => {
                  const updatedArray1 = segments.map((wheel) => ({
                    ...wheel,
                    segment: wheel.segment.filter(
                      (item) => !results.includes(item),
                    ),
                  }));
                  setSegments(updatedArray1);
                  setShowRemove(false);
                  // setResults([]);
                }}
              >
                Remove
              </button>
            )}
            <button
              className="btn btn_close"
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </button>
          </div>

          <div className="position-absolute celebrartion-wrapper">
            <img src={celebration} alt="Celebration" className="w-100" />
          </div>
        </div>
      }
      closeOnClickOutside={false}
      open={open}
      setOpen={setOpen}
      small={true}
      onCloseRequest={() => {
        setOpen(false);
        startSpinnerTimer();
      }}
      className="result-dialog"
    />
  );
};
