//To be deprecated

import { useEffect, useState } from "react";
import { fetchTemplatesFilterOptions } from "../../../../../../excalidraw-app/api/collection";
import { t } from "../../../../../../i18n";
import { Filter } from "../../../../../../types";
import { Popover } from "../../../../../Popover";
import { SortIcon, filterIcon } from "../../../../../icons";
import { FilteredValueType } from "../../CollectionsTab";
import "./style.scss";

interface SortAndFilterCollectionProps {
  selectedSortBy: number;
  setSelectedSortBy: React.Dispatch<React.SetStateAction<number | null>>;
  setSeachVal: React.Dispatch<React.SetStateAction<string>>;
  setFilterDataWithKeys: (data: any) => void;
  filterDataWithKeys: any;
  onFilterClear: () => void;
  onFilterApply: (val: FilteredValueType) => void;
}

const SortPopoverItem = [
  {
    title: t("collection.community.template.sortBy.types.newest"),
    icon: SortIcon,
  },
  {
    title: t("collection.community.template.sortBy.types.mostLiked"),
    icon: filterIcon,
  },
  {
    title: t("collection.community.template.sortBy.types.title"),
    icon: filterIcon,
  },
  {
    title: t("collection.community.template.sortBy.types.author"),
    icon: filterIcon,
  },
];

export const SortAndFilterCollection = ({
  selectedSortBy,
  setSelectedSortBy,
  setSeachVal,
  filterDataWithKeys,
  setFilterDataWithKeys,
  onFilterClear,
  onFilterApply,
}: SortAndFilterCollectionProps) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [filterOption, setFilterOption] = useState<Filter>({
    authors: [],
    tags: [],
  });
  const [filterData, setFilterData] = useState<string[]>([]);

  useEffect(() => {
    const getFilterData = async () => {
      const { result: filter } = await fetchTemplatesFilterOptions();
      setFilterOption(filter);
    };
    getFilterData();
  }, []);

  const handleCheckboxChange = (index: number) => {
    setSeachVal("");
    setSelectedSortBy((prev) => (prev === index ? 0 : index));
  };

  const updateFilterDataWithKeys = (
    checked: boolean,
    val: string,
    title: string,
    id: string,
  ) => {
    if (filterDataWithKeys) {
      const clonedFilterDataWithKeys = JSON.parse(
        JSON.stringify(filterDataWithKeys),
      );
      if (checked) {
        if (id !== "age") {
          clonedFilterDataWithKeys[id.toLowerCase()].push(val);
        } else if (
          val === t("collection.community.template.filter.options.7day")
        ) {
          clonedFilterDataWithKeys.age = "7";
        } else if (
          val === t("collection.community.template.filter.options.1month")
        ) {
          clonedFilterDataWithKeys.age = "30";
        } else if (
          val === t("collection.community.template.filter.options.3month")
        ) {
          clonedFilterDataWithKeys.age = "90";
        } else if (
          val === t("collection.community.template.filter.options.6month")
        ) {
          clonedFilterDataWithKeys.age = "180";
        }
      } else if (id !== "age") {
        clonedFilterDataWithKeys[id.toLowerCase()] = clonedFilterDataWithKeys[
          id.toLowerCase()
        ].filter((value: string) => value !== val);
      } else {
        clonedFilterDataWithKeys[id.toLowerCase()] = "";
      }
      setFilterDataWithKeys(clonedFilterDataWithKeys);
    }
  };

  const toggle = (checked: boolean, val: string, title: string, id: string) => {
    updateFilterDataWithKeys(checked, val, title, id);
    if (id === "age") {
      if (checked) {
        const ageIndex = FilterPopoverItem.findIndex((data) => data.id === id);
        const agesData = FilterPopoverItem[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.items.includes(data),
        );
        updatedFilterData.push(val);
        setFilterData(updatedFilterData);
      } else {
        const ageIndex = FilterPopoverItem.findIndex((data) => data.id === id);
        const agesData = FilterPopoverItem[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.items.includes(data),
        );
        setFilterData(updatedFilterData);
      }
    } else if (!checked) {
      const updatedCheckedCategories = filterData.filter((cat) => cat !== val);
      setFilterData(updatedCheckedCategories);
    } else {
      const updatedCheckedCategories = [...filterData];
      updatedCheckedCategories.push(val);
      setFilterData(updatedCheckedCategories);
    }
  };

  const PopoverItem = [
    {
      title: t("collection.community.template.sortBy.title"),
      icon: SortIcon,
      onClick: () => {
        setOpenPopover(!openPopover);
        setOpenFilterPopover(false);
      },
    },
    {
      title: t("collection.community.template.filter.title"),
      icon: filterIcon,
      onClick: () => {
        setOpenFilterPopover(!openFilterPopover);
        setOpenPopover(false);
      },
    },
  ];

  const FilterPopoverItem = [
    {
      title: t("collection.community.template.filter.age"),
      items: [
        t("collection.community.template.filter.options.7day"),
        t("collection.community.template.filter.options.1month"),
        t("collection.community.template.filter.options.3month"),
        t("collection.community.template.filter.options.6month"),
      ],
      id: "age",
    },
    {
      title: "Author",
      items: filterOption.authors,
      id: "author",
    },
    {
      title: "Tags",
      items: filterOption.tags,
      id: "tags",
    },
  ];

  return (
    <>
      <div className="position-relative sub-popover-wrapper">
        {openPopover && (
          <Popover>
            {SortPopoverItem.map((item, index) => (
              <p
                key={index}
                className="cursor-pointer d-flex gap-2 popper-item w-100"
              >
                <span>
                  <input
                    type="checkbox"
                    className="filter-checkbox mt-1"
                    id={`${item.title}-${index}`}
                    checked={selectedSortBy === index + 1}
                    onChange={() => handleCheckboxChange(index + 1)}
                  />
                </span>
                <label htmlFor={`${item.title}-${index}`}>{item.title}</label>
              </p>
            ))}
          </Popover>
        )}
        {openFilterPopover && (
          <Popover>
            {FilterPopoverItem.map((item, index) => (
              <div>
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#multiCollapse${index}`}
                  aria-expanded="false"
                  aria-controls={`multiCollapse${index}`}
                  className="popper-item"
                >
                  {item.title}
                </button>

                <div
                  className="collapse multi-collapse "
                  id={`multiCollapse${index}`}
                >
                  {item.items.map((subItem, subIndex) => (
                    <p
                      key={subIndex}
                      className="popper-item ms-2 w-100 gap-2 cursor-pointer"
                    >
                      <span>
                        <input
                          type="checkbox"
                          className="filter-checkbox mt-1"
                          id={`${item.title}-${subIndex}`}
                          checked={filterData.includes(subItem)}
                          onChange={(e) => {
                            toggle(
                              e.target.checked,
                              subItem,
                              item.title,
                              item.id,
                            );
                          }}
                        />
                      </span>
                      <label
                        htmlFor={`${item.title}-${subIndex}`}
                        className="cursor-pointer"
                      >
                        {subItem}
                      </label>
                    </p>
                  ))}
                </div>
              </div>
            ))}
            <div className="d-flex gap-2 justify-content-center py-2">
              <button
                type="button"
                className="filter-submit-btn"
                onClick={() => {
                  onFilterApply(filterDataWithKeys);
                  setOpenFilterPopover(false);
                }}
              >
                SUBMIT
              </button>
              <button
                type="button"
                className="filter-cancel-btn"
                onClick={() => {
                  setFilterData([]);
                  onFilterClear();
                  setFilterDataWithKeys({
                    ...filterDataWithKeys,
                    age: "",
                    author: [],
                    tags: [],
                  });
                  setOpenFilterPopover(false);
                }}
              >
                CANCEL
              </button>
            </div>
          </Popover>
        )}
      </div>
      <Popover>
        {PopoverItem.map((item, index) => (
          <p
            key={index}
            className="cursor-pointer d-flex gap-2 popper-item w-100 align-items-center"
            onClick={item.onClick}
          >
            <span
              style={{
                height: 15,
                width: 15,
                marginBottom: 5,
              }}
            >
              {item.icon}
            </span>
            {item.title}
          </p>
        ))}
      </Popover>
    </>
  );
};
